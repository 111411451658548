import * as React from 'react';
import { useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import { IAvailableLanguage } from '../../../config';
import Icon from '@mui/material/Icon';

type OnLanguageChangeFunc = (index: number) => void;

const renderLanguageOption =
  (
    onChange: OnLanguageChangeFunc,
    selectedIndex: number,
    closeMenu: () => void,
  ) =>
  (language: IAvailableLanguage, i: number) =>
    (
      <MenuItem
        key={`language-menu-${i}`}
        onClick={() => {
          onChange(i);
          closeMenu();
        }}
        selected={selectedIndex === i}
        disabled={selectedIndex === i}
      >
        {language.label}
      </MenuItem>
    );

interface BottomBarProps {
  languages: IAvailableLanguage[];
  currentLanguage: number;
  onLanguageChange: OnLanguageChangeFunc;
}

const BottomBar: React.FC<BottomBarProps> = ({
  languages,
  currentLanguage,
  onLanguageChange,
}) => {
  const [languageMenuOpen, setLanguageMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const currentLanguageObj = languages[currentLanguage];

  const onLanguageButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
    setLanguageMenuOpen(true);
  };

  return (
    <Box
      sx={{
        height: '68px',
        width: '100%',
        top: 0,
      }}
    >
      <Box
        sx={{
          position: 'relative',
          top: '50%',
          transform: 'translateY(-50%)',
          display: 'flex',
          justifyContent: 'flex-end',
          paddingLeft: '16px',
          paddingRight: '16px',
        }}
      >
        <Box>
          <Button
            onClick={onLanguageButtonClick}
            data-testid="bottom-bar-language-switch-btn"
            variant="outlined"
            sx={{
              color: 'black',
              backgroundColor: 'rgba(255,255,255,0.5)',
              fontWeight: 600,
              '&.MuiButtonBase-root': {
                borderWidth: '1px',
                borderRadius: '25px',
                borderColor: 'rgba(22, 22, 22, 0.3)',
                '&:hover': {
                  backgroundColor: 'rgba(255,255,255,0.7)',
                },
              },
            }}
          >
            <Icon sx={{ paddingRight: '4px', opacity: '0.7' }}>language</Icon>
            {currentLanguageObj && currentLanguageObj.label}
          </Button>
          <Popover
            open={languageMenuOpen}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={() => setLanguageMenuOpen(false)}>
                <MenuList>
                  {languages.map(
                    renderLanguageOption(
                      onLanguageChange,
                      currentLanguage,
                      () => setLanguageMenuOpen(false),
                    ),
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Popover>
        </Box>
      </Box>
    </Box>
  );
};

export default BottomBar;
