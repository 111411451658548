import * as React from 'react';
import { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';

import { styled as muiStyled } from '@mui/material/styles';

import { ApiStatus } from '../../models/ApiStatus';
import { Translate } from '../Translate';
import { UserStore } from '../../stores/UserStore';

export const isValidPhone = (phoneNumber: string): boolean => {
  const re = /^\+\d+$/;

  return re.test(phoneNumber);
};

const Controls = muiStyled(DialogActions)(({ theme }) => ({
  display: 'block',
  padding: '16px 24px',

  button: {
    width: '100%',
    marginBottom: '8px',

    '&:not(:first-of-type)': {
      marginLeft: 0,
    },
  },

  [theme.breakpoints.up('md')]: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'flex-end',

    button: {
      width: 'auto',
      marginBottom: '0',

      '&:not(:first-of-type)': {
        marginLeft: '8px',
      },
    },
  },
}));

interface Props {
  open: boolean;
  setOpen: (val: boolean) => void;
  userStore: UserStore;
}

const ContactInfoComponent: FC<Props> = ({ open, setOpen, userStore }) => {
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const { updatePhoneNumberApiStatus, updatePhoneNumber } = userStore;

  useEffect(() => {
    if (userStore?.userProfile?.user?.phone_number) {
      setPhoneNumber(userStore.userProfile.user.phone_number);
    }
  }, [userStore.userProfile]);

  useEffect(() => {
    if (updatePhoneNumberApiStatus === ApiStatus.SUCCESS) {
      setOpen(false);
    }
  }, [updatePhoneNumberApiStatus]);

  const onPhoneNumberInput = (event) => {
    const value = event?.target?.value || '';

    setPhoneNumber(value);
  };

  const onCloseBtnClick = () => {
    setOpen(false);
  };

  const onSaveBtnClick = async () => {
    await updatePhoneNumber(phoneNumber);
  };

  const isPhoneNumberValid = !phoneNumber ? true : isValidPhone(phoneNumber);

  const phoneNumberHelperText = isPhoneNumberValid ? (
    ''
  ) : (
    <>
      <Translate code="global.contact_info.form.invalid_phone_number_error" />.{' '}
      <Translate code="global.contact_info.form.phone_number_description" />
    </>
  );

  const phoneNumberLabel = (
    <Translate code="global.contact_info.form.phone_number" />
  );

  const originalPhoneNumber =
    userStore?.userProfile?.user?.phone_number || null;

  const isPhoneNumberChanged = originalPhoneNumber
    ? originalPhoneNumber !== phoneNumber
    : true;

  const phoneNumberRemoved = originalPhoneNumber && phoneNumber === '';

  const canSubmit =
    (phoneNumber || phoneNumberRemoved) &&
    isPhoneNumberValid &&
    isPhoneNumberChanged &&
    updatePhoneNumberApiStatus !== ApiStatus.LOADING;

  const submitBtnContent =
    updatePhoneNumberApiStatus === ApiStatus.LOADING ? (
      <CircularProgress sx={{ color: '#ffffff' }} size={24} thickness={3.5} />
    ) : (
      <Translate code="global.save" />
    );

  return (
    <Dialog open={open}>
      <Box style={{ maxWidth: '480px' }}>
        <DialogTitle>
          <Translate code="global.contact_info.form.title" />
        </DialogTitle>
        <DialogContent>
          <Box>
            <Translate code="global.contact_info.form.subtitle" />
            <Box sx={{ display: 'flex', marginTop: '24px' }}>
              <TextField
                sx={{ width: '100%' }}
                name="contact-info-phone-number"
                label={phoneNumberLabel}
                value={phoneNumber}
                error={!isPhoneNumberValid}
                helperText={phoneNumberHelperText}
                onChange={onPhoneNumberInput}
                autoFocus={true}
                autoComplete="off"
              />
            </Box>
          </Box>
        </DialogContent>
        <Controls>
          <Button
            color="primary"
            onClick={onCloseBtnClick}
            data-testid="dialog-menu-cancel"
          >
            <Translate code="global.cancel" />
          </Button>
          <Button
            color="primary"
            variant="contained"
            disabled={!canSubmit}
            onClick={onSaveBtnClick}
            data-testid="dialog-menu-save"
          >
            {submitBtnContent}
          </Button>
        </Controls>
        {updatePhoneNumberApiStatus === ApiStatus.ERROR && (
          <Box
            sx={{
              fontSize: '0.8rem',
              padding: '0 24px 16px',
              color: '#f44336',
              textAlign: 'right',
            }}
          >
            <Translate code="global.contact_info.form.submit_error" />
          </Box>
        )}
      </Box>
    </Dialog>
  );
};

export const ContactInfo = observer(ContactInfoComponent);
