import { withDependencies } from '@deliveryhero/react-inversify-provider';
import MasterRoutes from './MasterRoutes';
import { PluginStore } from '../../../stores/PluginStore';
import { NotificationsStore } from '../../../stores/NotificationsStore';
import FwfStore from '../../../stores/FwfStore';
import { observer } from 'mobx-react';
import { TYPES } from '../../../types';

export default observer(
  withDependencies({
    sessionStore: TYPES.SessionStore,
    vendorStore: TYPES.VendorStore,
    pluginStore: PluginStore,
    notificationsStore: NotificationsStore,
    fwfStore: FwfStore,
  })(MasterRoutes),
);
