import React from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';

import { Link, List, ListItem, ListItemIcon } from '@mui/material';
import { styled } from '@mui/material/styles';

import { Translate } from '../../../components/Translate';
import LanguageSelector from '../../../components/LanguageSelector';
import Icon from '@mui/material/Icon';

export type SideBarFooterProps = {
  onContactInfoClick: () => void;
};

const FooterLink = styled(Link)(() => ({
  color: '#000000',
  textDecoration: 'none',
  padding: '10px 20px',
  cursor: 'pointer',
}));

const SideBarFooter: React.FC<SideBarFooterProps> = ({
  onContactInfoClick,
}) => {
  const history = useHistory();

  return (
    <List
      sx={{
        position: 'absolute',
        bottom: '20px',
        left: 0,
        color: '#000',
        fontSize: '14px',
      }}
    >
      <ListItem>
        <FooterLink
          data-testid="contact-info-button"
          onClick={onContactInfoClick}
        >
          <ListItemIcon
            sx={{
              fontSize: '16px',
              minWidth: 0,
              marginRight: '12px',
              color: 'black',
            }}
          >
            <Icon fontSize="inherit">call</Icon>
          </ListItemIcon>
          <Translate code="global.menu.contact_info" />
        </FooterLink>
      </ListItem>
      <LanguageSelector
        renderComponent={(_label, onClick) => (
          <ListItem>
            <FooterLink data-testid="language-selector" onClick={onClick}>
              <ListItemIcon
                sx={{
                  fontSize: '16px',
                  minWidth: 0,
                  marginRight: '12px',
                  color: 'black',
                }}
              >
                <Icon fontSize="inherit">language</Icon>
              </ListItemIcon>
              <Translate code="sidebar.language" />
            </FooterLink>
          </ListItem>
        )}
      />
      <ListItem>
        <FooterLink
          data-testid="logout-button"
          onClick={() => history.push('/logout')}
        >
          <ListItemIcon
            sx={{
              fontSize: '16px',
              minWidth: 0,
              marginRight: '12px',
              color: 'black',
            }}
          >
            <Icon fontSize="inherit">logout</Icon>
          </ListItemIcon>
          <Translate code="global.menu.logout" />
        </FooterLink>
      </ListItem>
    </List>
  );
};

export default observer(SideBarFooter);
