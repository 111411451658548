/* eslint-disable max-len */
import * as React from 'react';

export const HungerstationPartnerLogo: React.FC = () => (
  <svg
    data-testid="platform-logo-svg"
    width="212"
    height="48"
    viewBox="0 0 212 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M72.798 20.1748L71.1103 17.1406C71.3059 16.904 71.5606 16.5856 71.7244 16.3172C72.334 15.3119 72.6479 14.2019 72.6479 13.0055C72.6479 11.104 71.9427 9.43 70.6008 8.16538C69.2998 6.93714 67.6257 6.28663 65.7515 6.28663H60.7749H59.2146H51.3038C50.3349 6.28663 49.4979 6.85981 49.1158 7.68773C48.4243 7.18279 47.651 6.77338 46.7958 6.48224C45.381 5.99549 43.8798 5.87722 42.3423 6.12741C40.7501 6.39126 39.3672 6.99173 38.2072 7.91518C37.8797 6.96898 36.9835 6.28663 35.9236 6.28663H32.7575C31.7977 6.28663 30.9697 6.84616 30.5831 7.65134L30.3465 7.31471C29.8962 6.66875 29.1547 6.28663 28.3677 6.28663H25.8975H23.9961H20.8118C20.1294 6.28663 19.5107 6.57322 19.0695 7.02812C18.6282 6.56867 18.0141 6.28663 17.3272 6.28663H14.1429C13.7835 6.28663 12.5416 6.32302 12.2141 6.32302H9.06617C8.38382 6.32302 7.76517 6.60506 7.32391 7.06451C6.88266 6.60961 6.26851 6.32302 5.5816 6.32302H2.4155C1.08264 6.32302 0 7.40569 0 8.73855V21.1119C0 22.3993 1.04172 23.441 2.3291 23.441H5.63169C6.28675 23.4228 6.88264 23.1453 7.3057 22.704C7.72876 23.1453 8.32471 23.4228 8.97976 23.441H12.3096C13.1785 23.441 13.92 22.9406 14.2884 22.2173C15.5895 23.2272 17.1907 23.7412 19.0604 23.7412C20.9755 23.7412 22.6041 23.1999 23.9187 22.1491C24.3782 22.9406 25.2379 23.4728 26.2205 23.4728H30.1873C30.856 23.4728 31.3973 22.9315 31.3973 22.2628L31.8841 22.9588C32.1115 23.2818 32.48 23.4728 32.8757 23.4728H35.5779C36.7379 23.4728 37.7205 22.7313 38.0844 21.6987C38.2573 21.8397 38.4301 21.9853 38.6166 22.1172C40.1132 23.1862 41.8191 23.7276 43.6842 23.7276C44.2346 23.7276 44.8033 23.6775 45.3765 23.5866C46.8185 23.35 48.065 22.8496 49.1203 22.1081C49.4479 22.9088 50.2348 23.4774 51.1537 23.4774H58.4276H59.4148H70.8373C72.5478 23.4774 73.6213 21.6578 72.798 20.1748Z"
      fill="white"
    />
    <path
      d="M9.06408 13.224L5.58407 13.8473V12.8692V8.61133H2.41797V20.9801H5.58407V16.6904L9.06408 16.0672V16.5494V20.9801H12.212V8.61133H9.06408V13.224Z"
      fill="#6E3D2F"
    />
    <path
      d="M22.6128 20.0928C21.6939 20.8889 20.5111 21.2847 19.0645 21.2847C17.6179 21.2847 16.4352 20.8889 15.5163 20.0928C14.5974 19.2967 14.1379 18.2459 14.1379 16.9267V8.57471H17.3223V16.6265C17.3223 17.6682 17.9045 18.1913 19.0645 18.1913C20.2245 18.1913 20.8068 17.6682 20.8068 16.6265V8.57471H23.9911V16.9313C23.9911 18.2459 23.5317 19.3013 22.6128 20.0928Z"
      fill="#6E3D2F"
    />
    <path
      d="M32.753 8.57471H35.9191V21.0163H33.4672L29.0728 14.7614V21.0163H25.8931V8.57471H28.3632L32.753 14.8296V8.57471Z"
      fill="#6E3D2F"
    />
    <path
      d="M49.8926 12.8598L50.0973 14.1063C50.393 15.9077 50.07 17.4589 49.1238 18.7599C48.1776 20.0609 46.8083 20.8616 45.0069 21.1573C43.0872 21.4711 41.4132 21.1163 39.9757 20.0928C38.5383 19.0693 37.6739 17.6545 37.3782 15.8531C37.0825 14.0517 37.4465 12.4322 38.47 10.9902C39.4935 9.54815 40.9128 8.67929 42.7233 8.37905C43.8697 8.19254 44.9615 8.27443 45.9986 8.6338C47.0358 8.99317 47.8955 9.5527 48.5734 10.3169L46.1579 12.2821C45.8485 11.9364 45.4391 11.6862 44.9251 11.5315C44.411 11.3769 43.8606 11.345 43.2601 11.4451C42.2912 11.6043 41.5451 12.0501 41.0311 12.7871C40.5171 13.5194 40.3396 14.3792 40.4988 15.3618C40.6535 16.308 41.0948 17.0586 41.8135 17.6136C42.5368 18.1685 43.4512 18.3551 44.5611 18.1685C45.9895 17.932 46.8402 17.2405 47.1177 16.0896L44.1335 16.5809L43.6877 13.8788L49.8926 12.8598Z"
      fill="#6E3D2F"
    />
    <path
      d="M54.4819 18.0321H59.2994V21.0163H51.2976V8.57471H59.2084V11.5407H54.4819V13.2647H58.7626V16.1989H54.4819V18.0321Z"
      fill="#6E3D2F"
    />
    <path
      d="M67.2627 21.0163L65.0928 16.9995L63.9556 17.1906V21.0163H60.7712V8.57471H65.7478C67.017 8.57471 68.0815 8.97957 68.9367 9.79384C69.7965 10.6036 70.2241 11.6316 70.2241 12.8781C70.2241 13.6241 70.0331 14.311 69.6555 14.9297C69.2779 15.5529 68.7593 16.0487 68.1088 16.4127L70.6699 21.0163H67.2627ZM63.9556 11.5088V14.5931L65.666 14.3793C65.9753 14.3611 66.4302 14.1882 66.6759 13.9289C66.9261 13.6696 67.0489 13.3284 67.0489 12.9145C67.0489 12.5005 66.9261 12.1639 66.6759 11.9C66.4257 11.6407 66.1072 11.5088 65.716 11.5088H63.9556Z"
      fill="#6E3D2F"
    />
    <path
      d="M93.2405 23.4677C94.1548 23.4677 94.96 22.8718 95.2238 21.9938L95.5559 20.893L95.847 20.8384L96.2565 22.0575C96.5385 22.8991 97.33 23.4677 98.2171 23.4677H106.942C107.847 23.4677 108.621 22.9309 108.971 22.1576C109.298 22.9264 110.063 23.4677 110.95 23.4677H114.498C115.54 23.4677 116.409 22.7262 116.604 21.7436C118.196 23.0629 120.093 23.7361 122.263 23.7361C124.292 23.7361 126.084 23.1402 127.613 21.9802C127.909 22.8445 128.723 23.4677 129.687 23.4677H134.109C134.782 23.4677 135.333 22.9218 135.333 22.244L135.601 22.6216C135.974 23.1493 136.579 23.4677 137.225 23.4677H139.722C141.214 23.4677 142.197 22.4897 142.197 21.2796V8.54238C142.197 7.3278 141.21 6.34066 139.995 6.34066H135.587C134.914 6.34066 134.364 6.88654 134.364 7.56434L133.872 6.85925C133.645 6.53172 133.272 6.34066 132.872 6.34066H129.478C128.568 6.34066 127.804 6.95932 127.572 7.79634C126.053 6.65909 124.278 6.07227 122.268 6.07227C120.102 6.07227 118.201 6.74097 116.609 8.06474C116.413 7.08215 115.544 6.34066 114.503 6.34066H101.042C100.264 6.34066 99.5636 6.65909 99.0586 7.16858C98.4718 6.65454 97.7122 6.34521 96.8888 6.34521H94.2412C93.6226 6.34521 93.0403 6.52717 92.5399 6.8274C92.1532 6.52717 91.6711 6.34066 91.1388 6.34066H81.7132C81.0354 6.34066 80.4349 6.64089 80.0119 7.10944C78.8519 6.42254 77.5327 6.07681 76.0861 6.07681C74.2801 6.07681 72.7289 6.62725 71.4734 7.70991C70.8547 8.2467 70.368 8.86991 70.0268 9.57046C69.631 10.3802 71.3824 11.4083 71.3824 12.3909C71.3824 14.1013 70.0177 15.1612 70.6591 15.9391L70.0541 16.2939C69.1125 16.8443 69.8084 17.7905 70.418 18.7003C71.9556 20.9931 70.4544 21.6754 71.7827 22.5306C73.0291 23.3313 74.6213 23.5633 76.3863 23.5633C78.3424 23.5633 79.9209 23.2221 81.2083 22.1985C81.736 21.78 82.1682 21.2978 82.5048 20.7565V20.8702C82.5048 22.3077 83.6693 23.4723 85.1068 23.4723H93.2405V23.4677Z"
      fill="white"
    />
    <path
      d="M76.3269 21.457C75.0077 21.457 73.9159 21.1795 73.0516 20.62C72.1828 20.0605 71.5777 19.3099 71.2366 18.3591L73.9614 16.7761C74.4254 17.8815 75.2443 18.4319 76.4179 18.4319C77.3914 18.4319 77.8781 18.1544 77.8781 17.604C77.8781 17.2401 77.5961 16.9444 77.0366 16.7215C76.8 16.6214 76.2769 16.4576 75.4762 16.2256C74.3344 15.8935 73.4337 15.425 72.7696 14.8291C72.1054 14.2286 71.7733 13.3961 71.7733 12.3271C71.7733 11.1762 72.1873 10.2437 73.0107 9.52947C73.8341 8.81527 74.8667 8.46045 76.0994 8.46045C77.1457 8.46045 78.0692 8.7061 78.8789 9.19284C79.6886 9.67959 80.3028 10.3847 80.7304 11.3127L78.0601 12.8821C77.6689 11.9541 77.0229 11.4946 76.1177 11.4946C75.7355 11.4946 75.4489 11.572 75.2533 11.7266C75.0577 11.8813 74.9576 12.0769 74.9576 12.318C74.9576 12.591 75.1032 12.8275 75.3944 13.0186C75.6855 13.2096 76.2496 13.4325 77.0912 13.6827C77.6825 13.8965 78.1693 14.0694 78.5423 14.2059C78.9153 14.3423 79.3247 14.5516 79.766 14.8427C80.2072 15.1339 80.5348 15.5069 80.7486 15.9618C80.9624 16.4167 81.0716 16.9535 81.0716 17.5721C81.0716 18.7958 80.6394 19.7511 79.7705 20.438C78.9017 21.1113 77.7553 21.457 76.3269 21.457Z"
      fill="#6E3D2F"
    />
    <path
      d="M91.1092 8.71973V11.804H88.0295V21.1886H84.8407V11.804H81.761V8.71973H91.1092Z"
      fill="#6E3D2F"
    />
    <path
      d="M109.938 8.71973V11.804H106.853V21.1886H103.665V11.804H100.585V8.71973H109.938Z"
      fill="#6E3D2F"
    />
    <path d="M114.329 8.71973H111.14V21.1886H114.329V8.71973Z" fill="#6E3D2F" />
    <path
      d="M126.911 19.5784C125.647 20.8294 124.1 21.4572 122.271 21.4572C120.443 21.4572 118.896 20.8294 117.631 19.5784C116.367 18.3274 115.734 16.7853 115.734 14.9566C115.734 13.1279 116.367 11.5858 117.631 10.3348C118.896 9.08382 120.443 8.45605 122.271 8.45605C124.1 8.45605 125.647 9.08382 126.911 10.3348C128.176 11.5858 128.808 13.1279 128.808 14.9566C128.808 16.7853 128.176 18.3229 126.911 19.5784ZM119.865 17.3858C120.506 18.0226 121.307 18.3411 122.271 18.3411C123.231 18.3411 124.036 18.0226 124.678 17.3858C125.319 16.7489 125.638 15.9392 125.638 14.9566C125.638 13.9695 125.319 13.1597 124.678 12.5274C124.036 11.8906 123.236 11.5767 122.271 11.5767C121.311 11.5767 120.506 11.8951 119.865 12.5274C119.224 13.1643 118.905 13.974 118.905 14.9566C118.905 15.9392 119.224 16.7489 119.865 17.3858Z"
      fill="#6E3D2F"
    />
    <path
      d="M136.693 8.71973H139.863V21.1886H137.407L133.008 14.92V21.1886H129.819V8.71973H132.294L136.693 14.9883V8.71973Z"
      fill="#6E3D2F"
    />
    <path
      d="M93.5372 8.72461L89.5249 21.3208H93.0095L93.4689 19.7878L93.7327 18.9053L97.4266 18.2412L97.9679 19.8788L98.4455 21.3208H101.762L97.5949 8.72461H93.5372ZM96.5713 15.5663L94.6289 15.8939L95.6434 12.4503L96.5713 15.5663Z"
      fill="#6E3D2F"
    />
    <path
      d="M67.0321 39.1705L70.0571 23.5999C70.2754 22.4855 71.2534 21.6758 72.3906 21.6758H139.853C141.345 21.6758 142.469 23.0359 142.187 24.5051L139.162 40.0757C138.943 41.1901 137.965 41.9998 136.828 41.9998H69.3657C67.8691 41.9953 66.7456 40.6352 67.0321 39.1705Z"
      fill="white"
    />
    <path
      d="M136.798 39.5847H69.4084L72.4198 24.0869H139.809L136.798 39.5847Z"
      fill="#FF6085"
    />
    <path
      d="M74.0989 36.4555C75.3921 36.4555 76.2486 36.4555 76.6685 36.4555C76.9509 34.9877 77.1413 33.9982 77.2333 33.5199C77.8211 33.5199 78.2578 33.5199 78.5601 33.5199C79.5678 33.5199 80.471 33.19 81.2833 32.5468C82.0956 31.9036 82.607 31.079 82.8005 30.073C82.9909 29.0834 82.7968 28.2588 82.232 27.6156C81.6672 26.9724 80.8909 26.6426 79.8832 26.6426C77.9518 26.6426 76.6418 26.6426 75.9868 26.6426C75.0445 31.5408 74.413 34.8228 74.0989 36.4555ZM77.6934 31.1285C77.8933 30.0895 78.0297 29.3803 78.0963 29.034C78.6842 29.034 79.1208 29.034 79.4231 29.034C79.7086 29.034 79.9415 29.1329 80.0882 29.3308C80.2548 29.5122 80.308 29.7596 80.2477 30.073C80.1874 30.3863 80.0391 30.6337 79.7995 30.8316C79.5766 31.0295 79.3057 31.1285 79.0202 31.1285C78.3484 31.1285 77.9117 31.1285 77.6934 31.1285Z"
      fill="white"
    />
    <path
      d="M81.7026 36.472C82.9286 36.472 83.8523 36.472 84.4737 36.472C84.7264 35.9443 84.9201 35.5484 85.0549 35.2846C85.1991 34.9712 85.3139 34.7238 85.3928 34.5754C86.6937 34.361 87.6691 34.1796 88.3127 34.0641C88.3864 34.6414 88.4551 35.0702 88.5019 35.3505C88.5747 35.8453 88.6192 36.2246 88.6556 36.472C89.8816 36.472 90.8054 36.472 91.41 36.472C90.7897 32.1015 90.3126 28.8196 90.0093 26.6426C88.6153 26.6426 87.574 26.6426 86.8854 26.6426C86.8823 26.6591 86.8823 26.6591 86.8823 26.6591C84.5835 31.013 82.8468 34.2785 81.7026 36.472ZM86.5688 32.217C87.1499 31.0295 87.5899 30.139 87.8889 29.5452C88.0018 30.6172 88.0982 31.4254 88.1615 31.9696C87.4507 32.085 86.9142 32.1675 86.5688 32.217Z"
      fill="white"
    />
    <path
      d="M92.1238 36.4555C93.2826 36.4555 94.1391 36.4555 94.6934 36.4555C94.9504 35.1196 95.1439 34.1136 95.274 33.4374C95.7066 33.3714 96.0184 33.322 96.2127 33.2725C96.7291 34.6908 97.0971 35.7463 97.3469 36.4555C98.5761 36.439 99.4831 36.439 100.088 36.439C99.4749 34.8228 99.0379 33.6023 98.7399 32.7942C99.0204 32.6458 99.2873 32.4809 99.5237 32.2994C99.7633 32.1015 99.9893 31.8871 100.188 31.6398C100.387 31.3924 100.552 31.145 100.673 30.8646C100.811 30.5842 100.915 30.3039 100.969 30.0235C101.061 29.5452 101.063 29.0999 100.958 28.6876C100.852 28.2753 100.67 27.9125 100.394 27.5991C100.119 27.2858 99.7801 27.0384 99.392 26.8735C98.9839 26.725 98.5295 26.6426 98.0256 26.6426C96.2454 26.6426 94.9018 26.6426 94.0117 26.6426C93.1708 31.013 92.5426 34.2785 92.1238 36.4555ZM95.6706 31.3759C95.88 30.2874 96.0355 29.4793 96.1402 28.935C96.7784 28.935 97.2487 28.935 97.551 28.935C97.7021 28.935 97.8501 28.9515 97.975 29.001C98.083 29.0505 98.1847 29.1329 98.2801 29.2484C98.3586 29.3638 98.4036 29.4793 98.4318 29.5947C98.4536 29.7431 98.4586 29.8916 98.4269 30.0565C98.3983 30.2049 98.3362 30.3534 98.2572 30.5018C98.1815 30.6337 98.0753 30.7492 97.9723 30.8481C97.8693 30.9471 97.719 31.0295 97.5584 31.079C97.3977 31.1285 97.237 31.178 97.0827 31.1945C96.4622 31.2769 95.9961 31.3429 95.6706 31.3759Z"
      fill="white"
    />
    <path
      d="M102.811 36.4555C104.104 36.4555 104.961 36.4555 105.381 36.4555C106.091 32.7612 106.564 30.3039 106.802 29.067C108.028 29.067 108.851 29.067 109.271 29.067C109.506 27.8465 109.661 27.0384 109.737 26.6426C105.992 26.6426 103.49 26.6426 102.23 26.6426C101.998 27.8465 101.843 28.6546 101.764 29.067C103.006 29.067 103.829 29.067 104.232 29.067C103.522 32.7612 103.049 35.2186 102.811 36.4555Z"
      fill="white"
    />
    <path
      d="M108.755 36.4555C110.048 36.4555 110.904 36.4555 111.324 36.4555C111.797 33.9982 112.114 32.3489 112.273 31.5243C113.564 33.9817 114.422 35.6309 114.851 36.4555C115.842 36.4555 116.497 36.4555 116.816 36.4555C117.762 31.5408 118.39 28.2753 118.704 26.6426C117.444 26.6426 116.588 26.6426 116.168 26.6426C115.692 29.1164 115.375 30.7657 115.216 31.5903C113.929 29.1164 113.07 27.4672 112.641 26.6426C111.65 26.6426 110.978 26.6426 110.642 26.6426C109.7 31.5408 109.069 34.8228 108.755 36.4555Z"
      fill="white"
    />
    <path
      d="M118.398 36.4555C121.623 36.4555 123.773 36.4555 124.831 36.4555C125.056 35.2846 125.208 34.4929 125.285 34.0971C123.353 34.0971 122.06 34.0971 121.422 34.0971C121.558 33.3879 121.653 32.8932 121.701 32.6458C123.431 32.6458 124.573 32.6458 125.127 32.6458C125.346 31.5078 125.495 30.7327 125.568 30.3534C123.855 30.3534 122.713 30.3534 122.142 30.3534C122.272 29.6772 122.361 29.2154 122.405 28.9845C124.303 28.9845 125.58 28.9845 126.201 28.9845C126.426 27.8135 126.575 27.0384 126.652 26.6426C123.477 26.6426 121.344 26.6426 120.286 26.6426C119.344 31.5408 118.713 34.8228 118.398 36.4555Z"
      fill="white"
    />
    <path
      d="M126.041 36.4555C127.2 36.4555 128.057 36.4555 128.611 36.4555C128.868 35.1196 129.061 34.1136 129.192 33.4374C129.624 33.3714 129.936 33.322 130.13 33.2725C130.647 34.6908 131.015 35.7463 131.265 36.4555C132.494 36.439 133.401 36.439 134.005 36.439C133.392 34.8228 132.955 33.6023 132.657 32.7942C132.938 32.6458 133.205 32.4809 133.441 32.2994C133.681 32.1015 133.907 31.8871 134.106 31.6398C134.304 31.3924 134.47 31.145 134.591 30.8646C134.729 30.5842 134.833 30.3039 134.887 30.0235C134.979 29.5452 134.981 29.0999 134.875 28.6876C134.77 28.2753 134.588 27.9125 134.312 27.5991C134.036 27.2858 133.698 27.0384 133.31 26.8735C132.901 26.725 132.447 26.6426 131.943 26.6426C130.163 26.6426 128.819 26.6426 127.929 26.6426C127.088 31.013 126.46 34.2785 126.041 36.4555ZM129.588 31.3759C129.798 30.2874 129.953 29.4793 130.058 28.935C130.696 28.935 131.166 28.935 131.469 28.935C131.62 28.935 131.768 28.9515 131.893 29.001C132.001 29.0505 132.102 29.1329 132.198 29.2484C132.276 29.3638 132.321 29.4793 132.349 29.5947C132.371 29.7431 132.376 29.8916 132.344 30.0565C132.316 30.2049 132.254 30.3534 132.175 30.5018C132.099 30.6337 131.993 30.7492 131.89 30.8481C131.787 30.9471 131.637 31.0295 131.476 31.079C131.315 31.1285 131.155 31.178 131 31.1945C130.38 31.2769 129.914 31.3429 129.588 31.3759Z"
      fill="white"
    />
  </svg>
);
