import React, { useState } from 'react';
import {
  getMessageColor,
  getNeutralColor,
  getSpacing,
  useIsMobile,
} from '@deliveryhero/vt-portal-chardonnay/cjs/core/helpers';
import { getCurrentBrandColor } from '../../utils/getCurrentBrandColor';
import { useHistory } from 'react-router-dom';
import {
  Menu,
  MenuItem,
  IconButton,
  Avatar,
  ListItemIcon,
  MenuList,
  Typography,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import { translate } from '../Translate';
import { MobileBottomDrawer } from '../MobileBottomDrawer';
import Icon from '@mui/material/Icon';

type Props = {
  userName: string;
  userRole: string;
};

const MobileDrawerRightSection = ({ userRole }) => {
  return (
    <Typography
      variant="secondary-700"
      sx={{
        paddingRight: getSpacing('spacing-20'),
        color: getNeutralColor('gray-40'),
      }}
    >
      {userRole}
    </Typography>
  );
};

export const HeaderAvatar = ({ userName, userRole }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const history = useHistory();
  const brandColor = getCurrentBrandColor();
  const isMobile = useIsMobile();
  const avatarSize = isMobile ? '20px' : '27px';
  const avatarTypoVariant = isMobile ? 'caption-700' : 'paragraph-700';
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const translatedUserRole = translate({
    code: `global.user_role.${userRole}`,
  });

  const handleAvatarClick = (event: React.MouseEvent<HTMLElement>) => {
    if (isMobile) {
      toggleDrawer();
    } else {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const onResetPassword = () => {
    history.push('/reset-password');
  };
  const onLogout = () => {
    history.push('/logout');
  };
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const renderMobileDrawer = () => {
    return (
      <MobileBottomDrawer
        title={userName}
        open={isDrawerOpen}
        onClose={toggleDrawer}
        action={<MobileDrawerRightSection userRole={translatedUserRole} />}
      >
        <MenuList sx={{ padding: `${getSpacing('spacing-8')} 0` }}>
          <MenuItem
            onClick={onResetPassword}
            data-testid="onboarding-header-avatar-menu-mobile-change-password-btn"
          >
            <ListItemIcon>
              <Icon fontSize="medium">key</Icon>
            </ListItemIcon>
            <Typography variant="paragraph-500">
              {translate({
                code: 'plugins.onboarding.header.change_password',
              })}
            </Typography>
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={onLogout}
            data-testid="onboarding-header-avatar-menu-mobile-logout-btn"
          >
            <ListItemIcon>
              <Icon fontSize="medium">logout</Icon>
            </ListItemIcon>
            <Typography variant="paragraph-500" color={brandColor}>
              {translate({ code: 'plugins.onboarding.header.save_logout' })}
            </Typography>
          </MenuItem>
        </MenuList>
      </MobileBottomDrawer>
    );
  };

  return (
    <>
      <IconButton
        data-testid="onboarding-header-avatar-icon-btn"
        onClick={handleAvatarClick}
        size="small"
        aria-controls={isMenuOpen ? 'account-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isMenuOpen ? 'true' : undefined}
      >
        <Avatar
          sx={{
            width: avatarSize,
            height: avatarSize,
            backgroundColor: getMessageColor('main', 'information'),
          }}
        >
          <Typography variant={avatarTypoVariant}>
            {userName.substring(0, 1).toUpperCase()}
          </Typography>
        </Avatar>
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuList sx={{ padding: `${getSpacing('spacing-8')} 0` }}>
          <MenuItem>
            <div>
              <Typography variant="secondary-700">{userName}</Typography>
              <Typography
                variant="caption-700"
                color={getNeutralColor('gray-40')}
              >
                {translatedUserRole}
              </Typography>
            </div>
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={onResetPassword}
            data-testid="onboarding-header-avatar-menu-desktop-change-password-btn"
          >
            <ListItemIcon>
              <Icon fontSize="small">key</Icon>
            </ListItemIcon>
            <Typography variant="caption-400">
              {translate({
                code: 'plugins.onboarding.header.change_password',
              })}
            </Typography>
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={onLogout}
            data-testid="onboarding-header-avatar-menu-desktop-logout-btn"
          >
            <ListItemIcon>
              <Icon fontSize="small">logout</Icon>
            </ListItemIcon>
            <Typography variant="caption-400" color={brandColor}>
              {translate({ code: 'plugins.onboarding.header.save_logout' })}
            </Typography>
          </MenuItem>
        </MenuList>
      </Menu>
      {renderMobileDrawer()}
    </>
  );
};
