import * as React from 'react';
import Typography from '@mui/material/Typography';

import { Headline as TabletHeadline } from './Tablet/Headline';

export const Headline = ({ isForceReset }) => {
  return (
    <Typography
      variant="heading4-700"
      sx={{ display: 'flex', justifyContent: 'center', mb: '12px' }}
    >
      <TabletHeadline isForceReset={isForceReset} />
    </Typography>
  );
};
