import * as React from 'react';
import { useState, useEffect } from 'react';
import { withFwf, WithFwfProps } from './FwfProvider';

export type FwfVariationProps = {
  children: React.ReactNode;
  flagKey: string;
  loadingComponent?: React.ComponentType;
};

export type InnerFwfVariationProps = WithFwfProps & FwfVariationProps;

const InnerFwfVariation: React.FC<InnerFwfVariationProps> = ({
  children,
  flagKey,
  fwfClient,
  loadingComponent,
}) => {
  const [showComponent, setShowComponent] = useState(false);
  const [variationFetched, setVariationFetched] = useState(false);

  useEffect(() => {
    fwfClient.getVariation(flagKey).then(
      (result) => {
        setShowComponent(result[flagKey].variation);
        setVariationFetched(true);
      },
      () => setVariationFetched(true),
    );
  }, []);

  if (showComponent) {
    return <>{children}</>;
  }

  if (!variationFetched && loadingComponent) {
    const LoadingComponent = loadingComponent;
    return <LoadingComponent />;
  }

  return null;
};

export const FwfVariation = withFwf<FwfVariationProps>(InnerFwfVariation);
