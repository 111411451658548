import * as React from 'react';

import TextField, { TextFieldProps } from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';

export type PasswordFieldProps = TextFieldProps & {
  buttonDisabled?: boolean;
  visible?: boolean;
};

export const PasswordField: React.FC<PasswordFieldProps> = ({
  visible = false,
  buttonDisabled = false,
  ...other
}) => {
  const [isVisible, setIsVisible] = React.useState(visible);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const handleButtonMouseDown = (e) => {
    e.preventDefault();
  };

  return (
    <TextField
      {...other}
      type={isVisible ? 'text' : 'password'}
      fullWidth={true}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={toggleVisibility}
              onMouseDown={handleButtonMouseDown}
              disabled={other.disabled || buttonDisabled}
              tabIndex={-1}
              data-testid="password-field-visibility-toggle"
            >
              <Icon>{isVisible ? 'visibility' : 'visibility_off'}</Icon>
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
