import * as React from 'react';
import { styled as muiStyled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const InfoItemsWrapper = muiStyled(Box)({
  display: 'flex',
  margin: '-8px',
  paddingBottom: '16px',
});

const InfoItemWrapper = muiStyled(Box)({
  margin: '8px',
  paddingBottom: '8px',
  textAlign: 'center',
});

const InfoItemTitle = muiStyled(Box)({
  marginBottom: '8px',
});

const InfoItemDescription = muiStyled(Box)({
  fontSize: '12px',
});

const ItemImage = muiStyled('img')({
  width: '100%',
  paddingBottom: '12px',
});

interface Props {
  title: string | JSX.Element;
  description: string | JSX.Element;
  imagePath: string;
}

export const InfoItem: React.FC<Props> = ({
  title,
  description,
  imagePath,
}) => (
  <InfoItemWrapper>
    <ItemImage src={imagePath} />
    <InfoItemTitle>{title}</InfoItemTitle>
    <InfoItemDescription>{description}</InfoItemDescription>
  </InfoItemWrapper>
);
