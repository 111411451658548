import * as React from 'react';
import { useHistory } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import { SessionStore } from '../../stores/SessionStore';
import GtmManager from '../../utils/gtm/GtmManager';
import { NotificationsStore } from '../../stores/NotificationsStore';
import { LocaleStore } from '../../stores/LocaleStore';
import NotificationBell from '../NotificationBell';
import { IconWrapper, LeftContent, TopBarWrapper } from './MobileTopBar.styles';
import Icon from '@mui/material/Icon';

interface Props {
  backUrl: string;
  toggleMenu: () => void;
  isNewNotifications: boolean;
  gtmManager: GtmManager;
  sessionStore: SessionStore;
  notificationsStore: NotificationsStore;
  localeStore: LocaleStore;
  pluginName: string | React.ReactNode;
}

export const MobileTopBar: React.FC<Props> = ({
  backUrl,
  toggleMenu,
  isNewNotifications,
  gtmManager,
  sessionStore,
  notificationsStore,
  localeStore,
  pluginName,
}) => {
  const history = useHistory();

  const onBackButtonClick = (url: string) => {
    history.push(url);
  };

  const showNotificationBell =
    isNewNotifications &&
    !history.location.pathname.match(/onboarding|notifications/);

  return (
    <>
      <TopBarWrapper
        id="mobile-top-navigation-bar-placeholder"
        data-testid="header-top-row"
      >
        <LeftContent>
          <IconWrapper>
            {backUrl ? (
              <IconButton
                color="inherit"
                aria-label="Back"
                data-testid="back-button"
                sx={{ padding: 0, fontSize: '20px' }}
                onClick={() => onBackButtonClick(backUrl)}
              >
                <Icon fontSize="inherit">arrow_back</Icon>
              </IconButton>
            ) : (
              <IconButton
                id="settings-menu-toggle-button"
                color="inherit"
                aria-label="Menu"
                onClick={toggleMenu}
                sx={{ padding: 0, fontSize: '20px' }}
                data-testid="master-menu-toggle"
              >
                <Icon fontSize="inherit">menu</Icon>
              </IconButton>
            )}
          </IconWrapper>
          {pluginName}
        </LeftContent>
        {showNotificationBell && (
          <NotificationBell
            sessionStore={sessionStore}
            localeStore={localeStore}
            notificationsStore={notificationsStore}
            history={history}
            gtmManager={gtmManager}
            isMobile
          />
        )}
      </TopBarWrapper>
    </>
  );
};
