import * as React from 'react';
import { withDependencies } from '@deliveryhero/react-inversify-provider';

import ErrorMessage from './ErrorMessage';
import { TranslationsStore } from '../../stores/TranslationsStore';

const PluginModuleLoadError: React.FC = withDependencies({
  translationsStore: TranslationsStore,
})((props) => (
  <ErrorMessage
    title={props.translationsStore.translate(
      'global.error.loading_plugin.headline',
    )}
    message={props.translationsStore.translate(
      'global.error.loading_plugin.message',
    )}
  />
));

PluginModuleLoadError.displayName = 'PluginModuleLoadError';

export default PluginModuleLoadError;
