import * as React from 'react';

import { useTheme } from '@mui/material/styles';
import {
  Box,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { PartnerLogo } from '../../../components/PartnerLogo';

const EfoodPrivacy: React.FC = ({}) => {
  const theme = useTheme();

  return (
    <Container maxWidth="lg">
      <Box padding={4}>
        <Box marginBottom={2}>
          <PartnerLogo platform="efood" width={212} height={40} />
        </Box>
        <Typography
          variant="h1"
          gutterBottom
          sx={{ color: `${theme.palette.primary.main}` }}
        >
          Privacy Policy
        </Typography>

        <Typography variant="overline">Created: 19/04/2024</Typography>

        <Typography variant="body1" paragraph>
          Welcome to our partner program. If you are reading this Privacy
          Policy, you are a store partnering on the online delivery platform of
          efood.
        </Typography>
        <Typography variant="body1" paragraph>
          The aim of this Privacy Policy is not only to describe our standard
          privacy practices, but also to explain the technical background, or
          provide you with information about your legal rights under applicable
          data protection laws, eg. General Data Protection Regulation (GDPR) in
          the European Union. We would also like to reassure you that the
          Delivery Hero group and efood take the protection of your personal
          data seriously and will fully honor the trust you put in us to keep
          your personal data safe and secure. If you require additional
          information or have any concerns about the processing of your personal
          data please contact us by email, please contact us by sending an email
          to privacy@e-food.gr.
        </Typography>

        <Typography
          variant="h6"
          sx={{ color: `${theme.palette.primary.main}` }}
        >
          Who is the data controller?
        </Typography>
        <Typography variant="body1" paragraph>
          Our partner portal is being provided by Delivery Hero group for use by
          our local entities providing delivery services to their customers.
          This means that several parties are involved in the data processing.
          However, the party determining the purposes and means of the
          processing specified in this privacy policy is Delivery Hero SE,
          Oranienburger Straße 70, 10117 Berlin, Germany hereinafter referred to
          as “Delivery Hero” “we”, “our” or “controller”).
        </Typography>
        <Typography variant="body1" paragraph>
          Delivery Hero has developed the partner restaurant portal and provides
          the technical infrastructure to keep it running. However, please note
          that, generally, your contracting partner as a restaurant or store
          owner will generally not be Delivery Hero. Rather, you will be with
          the local company of the Delivery Hero group in the country where you
          operate your restaurant or store, efood in your case. If you have any
          questions about data processing by these further parties, please check
          their privacy policies or submit a request for information directly to
          them.
        </Typography>
        <Typography variant="body1" paragraph>
          Please note that Delivery Hero as our group’s parent company will act
          as joint controller together with the respective local entity in the
          jurisdiction where you work. This also means that Delivery Hero and
          your respective contract partner, efood in your case, are each
          responsible for complying with data protection requirements when you
          are onboarded on our operating portal. You can therefore address any
          requests both to the local entity, efood, or to Delivery Hero.
        </Typography>

        <Typography
          variant="h6"
          sx={{ color: `${theme.palette.primary.main}` }}
        >
          Which personal data do we process and why?
        </Typography>
        <Typography variant="body1" paragraph>
          In order to provide our partner program, we use various tools and
          systems that are absolutely necessary for the operation of the app. We
          may collect, process and store the following categories of personal
          data when you use the app:
        </Typography>

        <Box marginBottom={4}>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography
                      variant="subtitle1"
                      sx={{ color: theme.palette.primary.main }}
                    >
                      Data categories
                    </Typography>{' '}
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="subtitle1"
                      sx={{ color: theme.palette.primary.main }}
                    >
                      Explanation
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Company Information</TableCell>
                  <TableCell>
                    Name, address, order information, trading license
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Identification data of the legal owner</TableCell>
                  <TableCell>
                    Name, surname, address, copy of passport/ID
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Contact data</TableCell>
                  <TableCell>Email address, phone number</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Account data</TableCell>
                  <TableCell>User password</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Order data</TableCell>
                  <TableCell>
                    Missing items, order history, customer information
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Geolocation data</TableCell>
                  <TableCell>Location by city/country</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Technical data</TableCell>
                  <TableCell>
                    Device Info, language settings, usage data
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Financial information (selected restaurants)
                  </TableCell>
                  <TableCell>Bank account name, bank account number</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        <Box marginBottom={4}>
          <Typography variant="body1" paragraph>
            We process the above categories of personal data for the following
            purposes:
          </Typography>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography
                      variant="subtitle1"
                      sx={{ color: theme.palette.primary.main }}
                    >
                      Purpose
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="subtitle1"
                      sx={{ color: theme.palette.primary.main }}
                    >
                      Description and Legal Basis
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Accounts</TableCell>
                  <TableCell>
                    <Typography variant="body1" paragraph>
                      Creation of required accounts for the applications used
                    </Typography>
                    <Typography variant="subtitle1">
                      Categories of personal data:
                    </Typography>
                    <ul>
                      <li>Identification data</li>
                      <li>Account data</li>
                    </ul>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Product Analytics</TableCell>
                  <TableCell>
                    <Typography variant="body1" paragraph>
                      To be able to analyse the usage of the partner portal,
                      ensure its security and continuously improve, we track
                      usage of the app. We use this data only for the creation
                      of pseudonymized/aggregated reports. We will not use this
                      data to identify you as a person.
                    </Typography>
                    <Typography variant="subtitle1">
                      Categories of personal data:
                    </Typography>
                    <ul>
                      <li>Geolocation data</li>
                      <li>Technical data</li>
                    </ul>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Internal communication</TableCell>
                  <TableCell>
                    <Typography variant="body1" paragraph>
                      Different tools are used for communication between you and
                      the partnering entity, i.e. efood. The purpose of the
                      processing is the communication of necessary information
                      between the parties involved to ensure we can adequately
                      process your order. For this purpose, we use integrations
                      with services such as WhatsApp.
                    </Typography>
                    <Typography variant="subtitle1">
                      Categories of personal data:
                    </Typography>
                    <ul>
                      <li>Identification data</li>
                      <li>Contact data</li>
                    </ul>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Order Processing & Delivery</TableCell>
                  <TableCell>
                    <Typography variant="body1" paragraph>
                      To ensure prompt delivery of the order, we might forward
                      the order to the delivery partner.
                    </Typography>
                    <Typography variant="subtitle1">
                      Categories of personal data:
                    </Typography>
                    <ul>
                      <li>Identification data</li>
                      <li>Contact data</li>
                      <li>Order data</li>
                      <li>Geolocation data</li>
                      <li>Technical data</li>
                    </ul>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Typography variant="body1" paragraph>
          Please be reminded that you are not obliged to provide the above data.
          If you would not like to provide this data, please do not use the
          partner supply chain app.
        </Typography>

        <Typography
          variant="h6"
          sx={{ color: `${theme.palette.primary.main}` }}
        >
          How long do we store personal data?
        </Typography>
        <Typography variant="body1" paragraph>
          We generally delete your data after the purpose of their collection
          has been fulfilled. Different deletion rules apply depending on the
          purpose of the processing. Within our deletion concepts we have
          defined various data classes and have assigned deletion periods to
          them. When the retention period is met, the stored data will be
          deleted accordingly.
        </Typography>
        <Typography variant="body1" paragraph>
          You are also free to request the deletion of your personal data at any
          time. However, under certain circumstances, such requests for deletion
          may be opposed by legal retention periods, which prevent us from
          deleting the stored data for a fixed minimum period of time. In order
          to comply with these legal requirements, we block the relevant data
          after the purpose has been fulfilled and thereby guarantee data
          completeness and data integrity.
        </Typography>

        <Typography
          variant="h6"
          sx={{ color: `${theme.palette.primary.main}` }}
        >
          Cookies and similar technologies
        </Typography>
        <Typography variant="body1" paragraph>
          For the web app, we use software development kits (SDKs). SDKs are
          part of the built-in code of our mobile applications and function in a
          way similar to cookies: They collect certain information about your
          device or the interaction with our service. We use the SDK provided by
          our external data processor adjust GmbH.
        </Typography>
        <Typography variant="body1" paragraph>
          We only use SDKs strictly required to provide the parnter supply chain
          app. This means you will generally not be able to opt out of this
          processing.
        </Typography>

        <Typography
          variant="h6"
          sx={{ color: `${theme.palette.primary.main}` }}
        >
          Automated individual decision-making and profiling
        </Typography>
        <Typography variant="body1" paragraph>
          You can trust that we will not process your personal data in the
          partner restaurant program for the purposes of automated
          decision-making which produces legal effects or similarly
          significantly affects you.
        </Typography>

        <Typography
          variant="h6"
          sx={{ color: `${theme.palette.primary.main}` }}
        >
          With whom do we share your personal data?
        </Typography>
        <Typography variant="body1" paragraph>
          We never give your data to unauthorized third parties. However, as
          part of our work we obtain the services of selected service providers
          and give them limited and strictly monitored access to some of our
          data. However, before we forward personal data to these data
          processors for processing on our behalf, each individual company
          undergoes an audit. All data recipients must meet the legal data
          protection requirements and prove their data protection level with
          appropriate documentation and information. Our categories of
          recipients:
        </Typography>

        <Box marginBottom={4}>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography
                      variant="subtitle1"
                      sx={{ color: theme.palette.primary.main }}
                    >
                      Type of recipient
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="subtitle1"
                      sx={{ color: theme.palette.primary.main }}
                    >
                      Purpose
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>External service provider</TableCell>
                  <TableCell>
                    <Typography variant="body1" paragraph>
                      They support our business activities by providing us with
                      IT solutions and infrastructure or by ensuring the
                      security of our business operations, for example by
                      identifying and rectifying faults. Specifically, our
                      partner portal run on servers provided by the EU
                      subsidiary of Amazon Web Services, with a registered seat
                      of business in Luxembourg as well as adjust GmbH, out of
                      Germany. Furthermore, in select instances, personal data
                      may also be disclosed to external tax consultants, lawyers
                      or auditors who are subject to strict confidentiality
                      agreements.
                    </Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Group companies</TableCell>
                  <TableCell>
                    <Typography variant="body1" paragraph>
                      Within a group of companies we try to work as efficiently
                      as possible to be able to provide the best services to our
                      customers and staff members. In this context, the group
                      companies support each other in optimizing our processes.
                      This includes, for example, the technical support of
                      systems, web and database hosting or software engineering
                      services.
                    </Typography>
                    <Typography variant="body1" paragraph>
                      You can reach the data protection officer of Delivery Hero
                      at dpo@deliveryhero.com.
                    </Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Public authorities</TableCell>
                  <TableCell>
                    <Typography variant="body1" paragraph>
                      Unfortunately, it can happen that a few of our staff
                      members do not behave fairly and want to harm our
                      customers or our company. In these cases we are not only
                      obliged to hand over personal data due to legal
                      obligations, it is of course also in our interest to
                      prevent damage and to enforce our claims and to reject
                      unjustified claims.
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        <Typography
          variant="h6"
          sx={{ color: `${theme.palette.primary.main}` }}
        >
          Data processing outside the EU and EEA
        </Typography>
        <Typography variant="body1" paragraph>
          We process your data mainly within the European Union (EU) and the
          European Economic Area (EEA). However, some data recipients may be
          located outside this territory. The GDPR has high requirements for the
          transfer of personal data to third countries. All our data recipients
          have to measure up to these requirements. Before we transfer your data
          to a service provider in third countries, every service provider is
          first assessed with regard to its data protection level. Only if they
          can demonstrate an adequate level of data protection will they be
          onboarded and be granted permission to process personal data.
        </Typography>
        <Typography variant="body1" paragraph>
          Regardless of whether our service providers are located within the
          EU/EEA or in third countries, each service provider must sign a data
          processing agreement with us where such agreement is required by the
          GDPR. Service providers outside the EU/EEA must meet additional
          requirements. According to Art. 44 ff. GDPR personal data may be
          transferred to recipients who are either located in a country
          generally providing an adequate level of protection (such as, e.g.,
          Japan or Argentina). Transfers are also permissible where the
          recipient provides appropriate safeguards to protect personal data,
          such as agreeing to standard contractual clauses, complying with
          binding corporate rules, approved codes of conduct or approved
          certification mechanisms. Where additional supplementary measures are
          required on top of such appropriate safeguards we will make sure these
          are implemented.
        </Typography>

        <Typography
          variant="h6"
          sx={{ color: `${theme.palette.primary.main}` }}
        >
          What are your rights as data subjects and how can they be asserted?
        </Typography>
        <Typography variant="body1" paragraph>
          You have the following legal rights as an individual person:
        </Typography>

        <Box marginBottom={4}>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Right to access</TableCell>
                  <TableCell>
                    You have the right to be informed which data we store about
                    you and how we process this data. You can also request a
                    copy of your data.
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Right to rectification</TableCell>
                  <TableCell>
                    If you notice that stored data is incorrect, you can always
                    ask us to correct it.
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Right to erasure</TableCell>
                  <TableCell>
                    You can ask us at any time to delete the data we have stored
                    about you.
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Right to restriction of processing</TableCell>
                  <TableCell>
                    Sometimes we will need to continue to process your data even
                    where you have requested them to be deleted. In this case,
                    even if we cannot delete your data, we will archive your
                    data and only reintegrate it into our operative systems if
                    you so wish. However, during this time you will not be able
                    to use our services, otherwise we will need to process your
                    data again.
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Right to data portability</TableCell>
                  <TableCell>
                    You can ask us to transmit the data stored about you in a
                    machine-readable format to you or to another responsible
                    person. In this context, we will make the data available to
                    you in JSON or comparable format.
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Right to object</TableCell>
                  <TableCell>
                    You have the right, for reasons arising from your particular
                    situation, to object at any time to the processing of
                    personal data relating to you, which is processed on the
                    basis of our legitimate interests, or for marketing
                    purposes. This also applies to any associated profiling. If
                    you file an objection, we will no longer process your
                    personal data unless we can prove compelling grounds for the
                    processing that outweigh your interests, rights and freedoms
                    or the processing serves to assert, exercise, or defend
                    against legal claims.
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Right of complaint</TableCell>
                  <TableCell>
                    You also have the right to lodge a complaint with a
                    supervisory authority. The competent supervisory authority
                    can be the authority in the EU Member State of your habitual
                    residence, at your place of work, or place of the alleged
                    infringement. You are also free to lodge a complaint with
                    our lead supervisory authority in Berlin, Germany.
                    <ul>
                      <li>
                        The supervisory authority responsible for entities
                        operating in Greece is:
                      </li>
                      <li>Hellenic Data Protection Authority</li>
                      <li>1-3 Kifissias Av. 11523, Athens, Greece</li>
                      <li>Tel: +30 210 647 5600</li>
                      <li>Email: contact@dpa.gr</li>
                    </ul>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        <Typography variant="body1">
          To exercise your data subject rights, you can contact the app support
          by mailing to privacy@e-food.gr at any time.
        </Typography>
      </Box>
    </Container>
  );
};

export default EfoodPrivacy;
