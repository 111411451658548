import * as React from 'react';
import { PlatformStore } from '../../stores/PlatformStore';
import FoodPandaPrivacy from './PartnerPrivacy/FoodPandaPrivacy';
import TalabatPrivacy from './PartnerPrivacy/TalabatPrivacy';
import FoodoraPrivacy from './PartnerPrivacy/FoodoraPrivacy';
import EfoodPrivacy from './PartnerPrivacy/EfoodPrivacy';
import FoodyPrivacy from './PartnerPrivacy/FoodyPrivacy';
import PedidosYaEcuadorPrivacy from './PartnerPrivacy/PedidosYaEcuadorPrivacy';
import YemekspetiPrivacy from './PartnerPrivacy/YemeksepetiPrivacy';
import HungerStationPrivacy from './PartnerPrivacy/HungerStationPrivacy';

type PrivacyContainerProps = {
  platformStore: PlatformStore;
};

const PrivacyContainer: React.FC<PrivacyContainerProps> = ({
  platformStore,
}) => {
  const currentPlatform = platformStore.currentPlatform.name;

  switch (currentPlatform) {
    case 'foodora':
      return <FoodoraPrivacy />;
    case 'foodpanda':
      return <FoodPandaPrivacy />;
    case 'talabat':
      return <TalabatPrivacy />;
    case 'efood':
      return <EfoodPrivacy />;
    case 'foody':
      return <FoodyPrivacy />;
    case 'pedidosya':
      return <PedidosYaEcuadorPrivacy />;
    case 'yemeksepeti':
      return <YemekspetiPrivacy />;
    case 'hungerstation':
      return <HungerStationPrivacy />;
    default:
      return <FoodPandaPrivacy />;
  }
};

export default PrivacyContainer;
