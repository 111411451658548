import jwtDecode from 'jwt-decode';
import { computed, observable, toJS } from 'mobx';
import { Audience } from '../stores/SessionStore';

export interface ISession {
  keymakerAccessToken: string;
  keymakerRefreshToken: string;
  keymakerDeviceToken: string;
  pinToken: string;
  accessToken: string;
  accessTokenV2: string;
  accessTokenContent: IAccessTokenContent;
  refreshToken: string;
  expiresIn: number;
  tokenType: string;
  subject: string;
  role?: string;
  user: IUserData;
  isForceResetPassword?: boolean;
}

export interface IAccessTokenContent {
  aud: Audience;
  country: string;
  exp: number;
  iat: number;
  impersonator: boolean;
  iss: string;
  sub: string;
  version: string;
  vendors:
    | {
        [key: string]: Array<string>;
      }
    | {
        [key: string]: {
          codes: Array<string>;
        };
      };
  user: IUserData;
}

export interface IKeymakerAccessTokenContent {
  iss: string;
  sub: string;
  aud: string;
  exp: number;
  iat: number;
  jti: string;
  scope: string;
  metadata: {
    email: string;
    impersonator?: string;
  };
}

export type IUserRole =
  | 'ADMIN'
  | 'MANAGER'
  | 'OPERATIONS'
  | 'REPORTS'
  | 'SUPPORT'
  | 'FINANCE';

export interface IUserData {
  locale: string;
  name: string;
  email: string;
  phone?: string;
  role: IUserRole;
  userId: string;
  region: string;
  country: string;
  createdAt: string;
}

export default class Session implements ISession {
  @observable accessToken: string;
  @observable accessTokenV2: string;
  @observable accessTokenContent: IAccessTokenContent;
  @observable refreshToken: string;
  @observable expiresIn: number;
  @observable tokenType: string;
  @observable role: string;
  @observable user: IUserData;
  @observable isForceResetPassword: boolean = false;

  /** @title KEYMAKER TOKENS */
  /** @desc this access token tells user is authenticated by keymaker. */
  @observable keymakerAccessToken: string;

  /** @desc this refresh token is to refresh the keymaker access token on its expiry. */
  @observable keymakerRefreshToken: string;

  /** @desc this token should be used in conjunction with keymaker refresh token to refresh keymaker access token. */
  @observable keymakerDeviceToken: string;

  /** @desc this pin token will be added by mobile auth for additionally enabling vendors to use certain functions */
  @observable pinToken: string;

  constructor(props) {
    // TODO: user data to be added from /profile call
    const sessionInfo = { user: {}, ...props };

    if (props.user) {
      // this to extract the region and userId e.g. eu-34439b25-5ca8-4efb-a867-d9fb77b8d7de
      const [, region, userId] = /([a-z]+)-(.*)/.exec(
        props.accessTokenContent?.sub,
      );
      sessionInfo.user = {
        ...props.user,
        role: props.accessTokenContent?.user?.role,
        userId,
        uniqueUserId: props.accessTokenContent?.sub,
        region,
        country: props.accessTokenContent.country,
        createdAt: new Date(props.user.createdAt),
      };
    }

    this.keymakerAccessToken = sessionInfo.keymakerAccessToken;
    this.keymakerRefreshToken = sessionInfo.keymakerRefreshToken;
    this.keymakerDeviceToken = sessionInfo.keymakerDeviceToken;
    this.accessToken = sessionInfo.accessToken;
    this.accessTokenV2 = sessionInfo.accessTokenV2;
    this.accessTokenContent = sessionInfo.accessTokenContent;
    this.refreshToken = sessionInfo.refreshToken;
    this.expiresIn = sessionInfo.expiresIn;
    this.tokenType = sessionInfo.tokenType;
    this.role = sessionInfo.role;
    this.user = sessionInfo.user;
    this.isForceResetPassword = sessionInfo.isForceResetPassword;
    this.keymakerAccessToken = sessionInfo.keymakerAccessToken;
    this.keymakerRefreshToken = sessionInfo.keymakerRefreshToken;
    this.keymakerDeviceToken = sessionInfo.keymakerDeviceToken;
    this.pinToken = sessionInfo.pinToken;
  }

  @computed get keymakerJwtData(): IKeymakerAccessTokenContent {
    if (this.keymakerAccessToken) {
      const keymakerJwtData: IKeymakerAccessTokenContent = toJS(
        jwtDecode(this.keymakerAccessToken),
      );
      return keymakerJwtData;
    }
    return null;
  }

  @computed get jwtData(): IAccessTokenContent {
    const data = toJS(this.accessTokenContent);
    return data;
  }

  @computed get audience(): Audience {
    return (this.jwtData && this.jwtData.aud) || Audience.MASTER;
  }

  @computed get subject(): string {
    return this.jwtData && this.jwtData.sub;
  }

  @computed get isImpersonator(): boolean {
    return (
      this.keymakerJwtData &&
      // TODO: Remove optional indicator in legacy cleanup, metadata will always be present in Keymaker Token
      this.keymakerJwtData.metadata?.impersonator &&
      this.keymakerJwtData.metadata.impersonator !== ''
    );
  }

  @computed get firstVendorId(): string {
    return this.platformVendorIds[0];
  }

  // TODO: Remove this method after keymaker migration
  @computed get hasRefreshToken(): boolean {
    return !!toJS(this.refreshToken);
  }

  @computed get hasKeymakerRefreshToken(): boolean {
    return !!toJS(this.keymakerRefreshToken);
  }

  @computed get platformVendorIds(): string[] {
    if (!this.accessTokenContent.vendors) {
      return [];
    }
    return Object.entries(this.accessTokenContent.vendors).reduce(
      (acc, [globalEntityId, vendorIds]) => {
        let globalEntityVendorIds;
        if (vendorIds?.codes && Array.isArray(vendorIds.codes)) {
          globalEntityVendorIds = [
            ...vendorIds.codes.map(
              (vendorId) => `${globalEntityId};${vendorId}`,
            ),
          ];
        } else {
          globalEntityVendorIds = [
            ...vendorIds.map((vendorId) => `${globalEntityId};${vendorId}`),
          ];
        }
        return [...acc, ...globalEntityVendorIds];
      },
      [],
    );
  }

  @computed get platformIds(): string[] {
    return Object.keys(this.accessTokenContent.vendors);
  }

  getUserData(prop: keyof IUserData): any {
    if (this.user[prop]) {
      return this.user[prop];
    }

    return this.jwtData && this.jwtData.user && this.jwtData.user[prop];
  }

  getVendorCountry(): string {
    return this.jwtData && this.jwtData.country;
  }

  getUserId(): string {
    return this.keymakerJwtData && this.keymakerJwtData.sub;
  }
}
