import React, { useState } from 'react';
import {
  AccordionWithoutLine,
  AccordionWrapper,
  Title,
} from './SidebarV2.styles';
import AccordionDetails from '@mui/material/AccordionDetails';
import { getInteractionColor } from '@deliveryhero/vt-portal-chardonnay/cjs/core/helpers';
import { Brand } from '@deliveryhero/vt-portal-chardonnay/cjs/core/tokens/brands';

import { Translate } from '../../../components/Translate';
import { MenuItem } from './SidebarV2';
import StyledLink from './StyledLink';
import { NotificationsStore } from 'src/stores/NotificationsStore';
import { useLocation } from 'react-router-dom';
import Icon from '@mui/material/Icon';

interface LinksAccordionProps {
  items: MenuItem[];
  setIsDirty: (dirty: boolean) => void;
  notificationsStore: NotificationsStore;
  isDirty: boolean;
  platform: string;
}
const LinksAccordion = ({
  items,
  notificationsStore,
  isDirty,
  setIsDirty,
  platform,
}: LinksAccordionProps) => {
  const [activeCategory, setActiveCategory] = useState<string>('');
  const location = useLocation();
  const currentCategory = items[0].groupCode;
  const isPluginSelectedViaURl = Boolean(
    items
      .map((item) => item.path.split('?')[0])
      .find((item) => location.pathname.startsWith(item)),
  );

  return (
    <AccordionWrapper>
      <AccordionWithoutLine
        data-testid={`accordion-${items[0].groupCode}`}
        onClick={() => {
          // eslint-disable-next-line no-unused-expressions
          currentCategory === activeCategory
            ? setActiveCategory('')
            : setActiveCategory(currentCategory);
          // eslint-disable-next-line no-unused-expressions
          !isDirty && setIsDirty(true);
        }}
        expanded={
          !isDirty ||
          activeCategory === currentCategory ||
          isPluginSelectedViaURl
        }
      >
        <Title
          data-testid={`accordion-title-${items[0].groupCode}`}
          expandIcon={
            <Icon
              sx={{
                color: `${getInteractionColor(
                  Brand[platform] || Brand.foodora,
                  'main',
                )}`,
              }}
            >
              expand_more
            </Icon>
          }
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Translate code={items[0].groupCode} />
        </Title>
        <AccordionDetails
          sx={{ marginTop: '12px' }}
          data-testid={`accordion-details-${items[0].groupCode}`}
        >
          {items.map((item) => (
            <StyledLink
              key={item.label}
              isNestedLink={true}
              label={item.label}
              name={item.name}
              path={item.path}
              onClick={(e) => {
                e.stopPropagation();
                setActiveCategory(currentCategory);
                if (location.pathname.includes('notifications')) {
                  notificationsStore.getUnreadMessages();
                }
              }}
            />
          ))}
        </AccordionDetails>
      </AccordionWithoutLine>
    </AccordionWrapper>
  );
};

export default LinksAccordion;
