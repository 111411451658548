import {
  pushGA4Event,
  pushPluginLoadCompletedEvent,
  pushPluginLoadFailedEvent,
  pushPluginLoadStartedEvent,
  pushPluginViewEvent,
  pushSectionViewEvent,
} from '@deliveryhero/vendor-portal-sdk';
import GtmManager from './GtmManager';
import { UserStore } from './../../stores/UserStore';
import { VendorStore } from './../../stores/VendorStore';
import { getGA4VendorsCountString } from './../getGA4VendorsCountString';
import { SentrySDK } from '../../types';
import { Severity } from '@sentry/types';

type GetPushPluginEventsParams = {
  pluginCode: string;
  pluginName: string;
  userStore: UserStore;
  vendorStore: VendorStore;
  gtmManager: GtmManager;
  environment: string;
  sentry: SentrySDK;
};

type PushPluginEvents = {
  pushPluginLoadStartedEvent: typeof pushPluginLoadStartedEvent;
  pushPluginLoadCompletedEvent: typeof pushPluginLoadCompletedEvent;
  pushPluginLoadFailedEvent: typeof pushPluginLoadFailedEvent;
  pushPluginViewEvent: typeof pushPluginViewEvent;
  pushSectionViewEvent: typeof pushSectionViewEvent;
  pushGA4Event: typeof pushGA4Event;
};

type GetPushPluginEvents = (
  params: GetPushPluginEventsParams,
) => PushPluginEvents;

const getPushPluginEvents: GetPushPluginEvents = ({
  pluginCode,
  pluginName,
  userStore,
  vendorStore,
  gtmManager,
  environment,
  sentry,
}) => {
  let endTime: number | null = null;
  const pluginInfo = {
    plugin_id: pluginCode,
    plugin_name: pluginName,
  };

  return {
    /**
     * @deprecated this method is discouraged for use for plugins
     * as the responsibility of firing the plugin load start event has been moved to webapp shell.
     */
    pushPluginLoadStartedEvent() {
      if (environment === 'stg') {
        // eslint-disable-next-line no-console
        console.warn(
          `Please remove the use of pushPluginLoadStartedEvent() SDK method. 
          This will be soon removed from SDK, as the responsibility of firing
          the plugin load start event has been moved to webapp shell on bundle load.`,
        );
      }
      return;
    },
    pushPluginLoadCompletedEvent({ timestamp } = {}) {
      // will be called once
      if (endTime !== null) {
        return;
      }

      endTime = timestamp ? timestamp : Date.now();
      gtmManager.pushEvent('plugin_load_completed', {
        eventStatus: JSON.stringify({
          event_time: `${endTime}`,
        }),
        eventLabel: pluginCode,
        eventAction: 'plugin_load_completed',
        eventCategory: 'load_plugin',
      });
    },
    pushPluginLoadFailedEvent({ reason, timestamp } = {}) {
      // will be called once
      if (endTime !== null) {
        return;
      }

      endTime = timestamp ? timestamp : Date.now();
      let eventParams = {
        eventStatus: JSON.stringify({
          event_time: `${endTime}`,
        }),
        ...getReason({ reason }),
        eventLabel: pluginCode,
        eventAction: 'plugin_load_failed',
        eventCategory: 'load_plugin',
      };
      gtmManager.pushEvent('plugin_load_failed', eventParams);
      sentry.captureException(reason, {
        level: Severity.Error,
        user: userStore.userProfile.user,
        tags: { plugin: pluginCode.toLowerCase(), category: 'load_plugin' },
        extra: {
          error: depictProperties({
            subject: reason,
            maxDepth: 3,
            exclude: ['stack'],
          }),
        },
      });
    },
    pushPluginViewEvent() {
      gtmManager.pushEvent('plugin_view', {
        ...pluginInfo,
      });
    },
    pushSectionViewEvent({ sectionId }) {
      gtmManager.pushEvent('section_view', {
        ...pluginInfo,
        section_id: sectionId,
      });
    },
    pushGA4Event(event: string, data: Record<string, string> = {}) {
      const isImpersonated = window.localStorage.getItem('isImpersonated');
      gtmManager.pushEvent(event, {
        ...data, // override input data to avoid junk values in below common attributes
        user_id: userStore.userProfile.user.id,
        selected_vendor_id: vendorStore.currentVendorId,
        selected_vendor_count: getGA4VendorsCountString(
          vendorStore.selectedVendorIds,
        ),
        global_vendor_count: getGA4VendorsCountString(
          vendorStore.allVendors.map((v) => v.id),
        ),
        locale: userStore.userProfile.user.locale,
        user_country: userStore.userProfile.user.country,
        plugin_code: pluginCode,
        is_impersonated: isImpersonated === 'true' ? 'yes' : 'no',
      });
    },
  };
};

export default getPushPluginEvents;

function getReason({
  reason,
}): Record<'errorMessage', string> | Record<string, never> {
  if (!reason) {
    return {};
  }
  const { message } = reason;
  return { errorMessage: message };
}

/**
 * The function makes an object out of the supplied subject's properties,
 * in particular: instances of Errors, having not enumerable properties.
 */
const depictProperties = ({
  subject,
  depth = 0,
  maxDepth,
  exclude = [],
}: {
  subject: any;
  depth?: number;
  /** Max depth level of depicted nested properties */
  maxDepth: number;
  /** Properties to exclude */
  exclude?: string[];
}) => {
  if (!subject || depth > maxDepth) {
    return subject;
  }
  if (typeof subject === 'object') {
    const getNext = (item: any) =>
      depictProperties({
        subject: item,
        depth: depth + 1,
        maxDepth,
        exclude,
      });
    if (Array.isArray(subject)) {
      return subject.map(getNext);
    }
    // Error props are not enumerable, therefore getOwnPropertyNames() is used here
    return Object.getOwnPropertyNames(subject).reduce((agg, prop) => {
      if (!exclude.includes(prop)) {
        agg[prop] = getNext(subject[prop]);
      }
      return agg;
    }, {});
  }
  return subject;
};
