import { inject, injectable } from 'inversify';
import GtmManager from './GtmManager';

@injectable()
export default class GtmPerformanceTiming {
  @inject('window') window: Window;
  @inject(GtmManager)
  gtmManager: GtmManager;

  startTimeNormalise = {};

  start(event = '') {
    if (!this.window.performance) {
      return;
    }

    this.startTimeNormalise[event] = this.window.performance.now();
  }

  async pushEvent(event: string): Promise<void> {
    if (!this.window.performance) {
      return Promise.resolve();
    }

    const normaliseTime = this.startTimeNormalise[event] || 0;

    const timeSincePageLoad = Math.round(
      this.window.performance.now() - normaliseTime,
    );

    // Sends the timing hit to Google Analytics.
    await this.gtmManager.pushEvent(event, {
      timingValue: timeSincePageLoad,
    });
  }
}
