import { injectable, inject } from 'inversify';
import subDays from 'date-fns/subDays';
import PageVisibilityManager from './PageVisibilityManager';

/**
 * Reloads applications that are running for more than 24 hours.
 * This is to give the user the newest version.
 */
@injectable()
class VersionUpdateHandler {
  @inject('window') private window;
  @inject(PageVisibilityManager)
  private pageVisibilityManager: PageVisibilityManager;

  init() {
    this.checkForFullRefresh();
    // When page is visible (e.g. switch tab to the app), check if a full refresh needs to be done
    this.pageVisibilityManager.addEventListener(() => {
      if (this.pageVisibilityManager.isPageVisible()) {
        this.checkForFullRefresh();
      }
    });
  }

  /**
   * Checks if full refresh needs to be done and in case the last refresh is older than 24h, refresh the page
   */
  checkForFullRefresh() {
    // Last refresh is stored in localStorage
    const lastFullRefreshString =
      this.window.localStorage.getItem('lastFullRefresh');

    // If there is no last full refresh date, set it and return
    if (!lastFullRefreshString) {
      this.setLastFullRefresh();
      return;
    }

    const lastFullRefresh = new Date(lastFullRefreshString);
    const yesterday = subDays(new Date(), 1);

    // If last full refresh is older than 24h, update last full refresh in localStorage and reload the page
    if (lastFullRefresh <= yesterday) {
      this.setLastFullRefresh();
      this.window.location.reload(true);
    }
  }

  private setLastFullRefresh() {
    this.window.localStorage.setItem('lastFullRefresh', new Date());
  }
}

export default VersionUpdateHandler;
