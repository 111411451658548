import { observable } from 'mobx';
import { inject, injectable, postConstruct } from 'inversify';
import { IGroup } from '@deliveryhero/vendor-portal-sdk';
import { Api } from '@deliveryhero/portal-api-client';

import { SessionStore } from './SessionStore';
import { VendorStore } from './VendorStore';
import GtmManager from '../utils/gtm/GtmManager';
import { ApiStatus } from '../models/ApiStatus';
import { TYPES } from '../types';
import { withInterceptor } from '@deliveryhero/captcha';

export interface PhoneLoginError {
  status: number;
}

export interface UserProfileUser {
  id: string;
  country: string;
  created_at: string;
  email: string;
  name: string;
  locale: string;
  phone_number: string;
  role: string;
}

export interface TransmissionMethod {
  type: string;
  is_primary: true;
  global_transmission_method_id: string;
}

export interface Transmission {
  methods: TransmissionMethod[];
}

export interface UserProfileAccount {
  id: string;
  parent_id: string;
  name: string;
  chain_name: string;
  branch: boolean;
  account_type: string;
  global_vendor_id: string;
  global_entity_id: string;
  vendor_id: string;
  timezone: string;
  vertical_type: string;
  address: {
    city: {
      name: string;
    };
    country: {
      code: string;
      name: string;
    };
  };
  currency: {
    code: string;
    symbol: string;
  };
  is_owner: boolean;
  is_concept: boolean;
  transmission: Transmission;
  delivery_types: string[];
  chain_id: string;
}

interface UserProfileOnboardingInfo {
  required: boolean;
  shown: boolean;
}

export interface UserProfile {
  user: UserProfileUser;
  accounts: UserProfileAccount[];
  onboarding_info: UserProfileOnboardingInfo;
}

@injectable()
export class UserStore {
  // api status variables
  @observable updatePhoneNumberApiStatus: ApiStatus = ApiStatus.IDLE;
  @observable postPhoneNumberToLoginApiStatus: ApiStatus = ApiStatus.IDLE;
  @observable postVerificationCodeApiStatus: ApiStatus = ApiStatus.IDLE;
  @observable postExchangeTokenApiStatus: ApiStatus = ApiStatus.IDLE;
  @observable postPhoneNumberToLoginError: PhoneLoginError | null = null;
  @observable fetchUserProfileError = null;

  // store data variables
  @observable userProfile: UserProfile | null = null;
  @observable userOwnedGroups: IGroup[] = [];
  @observable userProfileApiStatus: ApiStatus = ApiStatus.IDLE;

  @inject('bffApiUrl') private baseUrl: string;
  @inject(TYPES.GlobalApi) private api: Api;
  @inject(TYPES.GlobalApiV2) private httpClient: Api;
  @inject(TYPES.VendorStore) private vendorStore: VendorStore;
  @inject(TYPES.SessionStore) private sessionStore: SessionStore;
  @inject(GtmManager) private gtm: GtmManager;

  @postConstruct() init() {
    this.api = withInterceptor(this.api);
    this.httpClient = withInterceptor(this.httpClient);
  }

  fetchSignupRedirectUrl = async (): Promise<any> => {
    try {
      const resp = await this.httpClient.fetch(
        `${this.baseUrl}/ssu/v1/redirect-link`,
        200,
        { method: 'GET' },
      );

      if (!resp?.redirect_link) {
        throw new Error();
      }

      return resp;
    } catch (err) {
      throw new Error();
    }
  };

  fetchUserProfile = async (): Promise<any> => {
    try {
      this.userProfileApiStatus = ApiStatus.LOADING;
      this.gtm.pushEvent('vpwebapp_load', {
        timestamp: Date.now(),
        label: 'vpwebapp_user_profile_api_start',
        section: 'performance',
      });

      const userId = this.sessionStore.getUserData('userId');

      const data = await this.httpClient.fetch(
        `${this.baseUrl}/profile/v1/profile/${userId}?view=2`,
        200,
        {
          method: 'GET',
        },
      );

      const groupData = await this.httpClient.fetch(
        `${this.baseUrl}/profile/v1/profile/${userId}/groups`,
        200,
        { method: 'GET' },
      );

      this.userProfile = data;
      this.userOwnedGroups = groupData?.groups.length ? groupData.groups : [];
      this.fetchUserProfileError = null;

      // setting vendor store
      this.vendorStore.setVendors(
        this.userProfile.accounts.filter((a) => a.branch),
      );

      this.userProfileApiStatus = ApiStatus.SUCCESS;

      this.gtm.pushEvent('vpwebapp_load', {
        timestamp: Date.now(),
        label: 'vpwebapp_user_profile_api_success',
        section: 'performance',
      });
    } catch (err) {
      this.fetchUserProfileError = err;
      this.userProfileApiStatus = ApiStatus.ERROR;

      // setting error in vendor store
      this.vendorStore.setVendorsError(this.fetchUserProfileError);

      this.gtm.pushEvent('vpwebapp_load', {
        timestamp: Date.now(),
        label: 'vpwebapp_user_profile_api_failed',
        section: 'performance',
      });
    }
  };

  updatePhoneNumber = async (phoneNumber: string): Promise<void> => {
    if (!this.userProfile) {
      throw new Error('No user data: please fetch user first');
    }

    const { id, email, name, role } = this.userProfile.user;

    const accountIds = this.userProfile.accounts.map((account) => account.id);

    const payload = {
      user_id: id,
      email,
      name,
      role,
      account_ids: accountIds,
      phone_number: phoneNumber || null,
    };

    try {
      this.updatePhoneNumberApiStatus = ApiStatus.LOADING;

      await this.api.fetch(`${this.baseUrl}/ump/v1/users/${id}`, 200, {
        method: 'POST',
        body: payload,
      });

      this.userProfile.user.phone_number = phoneNumber;
      this.updatePhoneNumberApiStatus = ApiStatus.SUCCESS;
    } catch (err) {
      this.updatePhoneNumberApiStatus = ApiStatus.ERROR;
    }
  };

  postPhoneNumberToLogin = async (phoneNumber: string): Promise<void> => {
    try {
      this.postPhoneNumberToLoginApiStatus = ApiStatus.LOADING;

      await this.api.fetch(`${this.baseUrl}/pbl/request-otp`, 200, {
        method: 'POST',
        body: {
          phone_number: phoneNumber,
        },
      });

      this.postPhoneNumberToLoginApiStatus = ApiStatus.SUCCESS;
      this.postPhoneNumberToLoginError = null;
    } catch (err) {
      this.postPhoneNumberToLoginApiStatus = ApiStatus.ERROR;

      this.postPhoneNumberToLoginError = {
        status: err.status,
      };
    }
  };

  postVerificationCode = async (
    phoneNumber: string,
    verificationCode: string,
  ): Promise<any> => {
    try {
      this.postVerificationCodeApiStatus = ApiStatus.LOADING;

      const response = await this.api.fetch(
        `${this.baseUrl}/pbl/generate-tokens`,
        200,
        {
          method: 'POST',
          body: {
            phone_number: phoneNumber,
            otp_code: verificationCode,
          },
        },
      );

      this.postVerificationCodeApiStatus = ApiStatus.SUCCESS;

      return response;
    } catch (err) {
      this.postVerificationCodeApiStatus = ApiStatus.ERROR;
    }
  };

  setPostPhoneNumberToLoginApiStatus = (status: ApiStatus) => {
    this.postPhoneNumberToLoginApiStatus = status;
  };

  setPostVerificationCodeApiStatus = (status: ApiStatus) => {
    this.postVerificationCodeApiStatus = status;
  };

  postExchangeToken = async (keymakerToken: string): Promise<any> => {
    try {
      this.postExchangeTokenApiStatus = ApiStatus.LOADING;

      const response = await this.api.fetch(
        `${this.baseUrl}/auth/v3/token/global:exchange`,
        200,
        {
          method: 'POST',
          body: {
            token: keymakerToken,
          },
        },
      );

      this.postExchangeTokenApiStatus = ApiStatus.SUCCESS;

      return response;
    } catch (err) {
      this.postExchangeTokenApiStatus = ApiStatus.ERROR;
    }
  };

  resetStore = () => {
    this.userProfile = null;
    this.userOwnedGroups = [];
    this.userProfileApiStatus = ApiStatus.IDLE;
  };
}
