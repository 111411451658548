export const SELF_SIGN_UP_LINK_PER_COUNTRY = [
  {
    isoCode: 'BD',
    country: 'Bangladesh',
    link: 'https://partner.foodpanda.com.bd/',
    brand: 'foodpanda',
  },
  {
    isoCode: 'KH',
    country: 'Cambodia',
    link: 'https://partner.foodpanda.com.kh/',
    brand: 'foodpanda',
  },
  {
    isoCode: 'HK',
    country: 'Hong Kong',
    link: 'https://partner.foodpanda.hk/',
    brand: 'foodpanda',
  },
  {
    isoCode: 'LA',
    country: 'Laos',
    link: 'https://partner.foodpanda.la/',
    brand: 'foodpanda',
  },
  {
    isoCode: 'MY',
    country: 'Malaysia',
    link: 'https://partner.foodpanda.my/',
    brand: 'foodpanda',
  },
  {
    isoCode: 'MM',
    country: 'Myanmar',
    link: 'https://partner.foodpanda.com.mm',
    brand: 'foodpanda',
  },
  {
    isoCode: 'PK',
    country: 'Pakistan',
    link: 'https://partner.foodpanda.pk',
    brand: 'foodpanda',
  },
  {
    isoCode: 'PH',
    country: 'Philippines',
    link: 'https://partner.foodpanda.ph',
    brand: 'foodpanda',
  },
  {
    isoCode: 'SG',
    country: 'Singapore',
    link: 'https://partner.foodpanda.sg',
    brand: 'foodpanda',
  },
  {
    isoCode: 'TW',
    country: 'Taiwan',
    link: 'https://partner.foodpanda.com.tw',
    brand: 'foodpanda',
  },
  {
    isoCode: 'TH',
    country: 'Thailand',
    link: 'https://partner.foodpanda.co.th',
    brand: 'foodpanda',
  },
  {
    isoCode: 'AR',
    country: 'Argentina',
    link: 'https://socios.pedidosya.com.ar/',
    brand: 'pedidosya',
  },
  {
    isoCode: 'BO',
    country: 'Bolivia',
    link: 'https://socios.pedidosya.com.bo/',
    brand: 'pedidosya',
  },
  {
    isoCode: 'CL',
    country: 'Chile',
    link: 'https://socios.pedidosya.com.cl/',
    brand: 'pedidosya',
  },
  {
    isoCode: 'CR',
    country: 'Costa Rica',
    link: 'https://socios.pedidosya.com.cr/',
    brand: 'pedidosya',
  },
  {
    isoCode: 'EC',
    country: 'Ecuador',
    link: 'https://socios.pedidosya.com.ec/',
    brand: 'pedidosya',
  },
  {
    isoCode: 'GT',
    country: 'Guatemala',
    link: 'https://socios.pedidosya.com.gt/',
    brand: 'pedidosya',
  },
  {
    isoCode: 'HN',
    country: 'Honduras',
    link: 'https://socios.pedidosya.com.hn/',
    brand: 'pedidosya',
  },
  {
    isoCode: 'SV',
    country: 'El Salvador',
    link: 'https://socios.pedidosya.com.sv/',
    brand: 'pedidosya',
  },
  {
    isoCode: 'NI',
    country: 'Nicaragua',
    link: 'https://socios.pedidosya.com.ni/',
    brand: 'pedidosya',
  },
  {
    isoCode: 'PA',
    country: 'Panamá',
    link: 'https://socios.pedidosya.com.pa/',
    brand: 'pedidosya',
  },
  {
    isoCode: 'PY',
    country: 'Paraguay',
    link: 'https://socios.pedidosya.com.py/',
    brand: 'pedidosya',
  },
  {
    isoCode: 'PE',
    country: 'Perú',
    link: 'https://socios.pedidosya.com.pe/',
    brand: 'pedidosya',
  },
  {
    isoCode: 'DO',
    country: 'Dominican Republic',
    link: 'https://socios.pedidosya.com.do/',
    brand: 'pedidosya',
  },
  {
    isoCode: 'UY',
    country: 'Uruguay',
    link: 'https://socios.pedidosya.com.uy/',
    brand: 'pedidosya',
  },
  {
    isoCode: 'VE',
    country: 'Venezuela',
    link: 'https://socios.pedidosya.com.ve/',
    brand: 'pedidosya',
  },
  {
    isoCode: 'AE',
    country: 'United Arab Emirates',
    link: 'https://ae.partner.talabat.com/',
    brand: 'talabat',
  },
  {
    isoCode: 'KW',
    country: 'Kuwait',
    link: 'https://kw.partner.talabat.com/',
    brand: 'talabat',
  },
  {
    isoCode: 'BH',
    country: 'Bahrain',
    link: 'https://bh.partner.talabat.com/',
    brand: 'talabat',
  },
  {
    isoCode: 'IQ',
    country: 'Iraq',
    link: 'https://iq.partner.talabat.com/',
    brand: 'talabat',
  },
  {
    isoCode: 'OM',
    country: 'Oman',
    link: 'https://om.partner.talabat.com/',
    brand: 'talabat',
  },
  {
    isoCode: 'QA',
    country: 'Qatar',
    link: 'https://qa.partner.talabat.com/',
    brand: 'talabat',
  },
  {
    isoCode: 'JO',
    country: 'Jordan',
    link: 'https://jo.partner.talabat.com/',
    brand: 'talabat',
  },
  {
    isoCode: 'EG',
    country: 'Egypt',
    link: 'https://eg.partner.talabat.com/',
    brand: 'talabat',
  },
  {
    isoCode: 'TR',
    country: 'Turkey',
    link: 'https://partner.yemeksepeti.com/',
    brand: 'yemeksepeti',
  },
  {
    isoCode: 'HU',
    country: 'Hungary',
    link: 'https://join.foodpanda.hu/',
    brand: 'foodora',
  },
  {
    isoCode: 'SK',
    country: 'Slovakia',
    link: 'https://partner.foodpanda.sk/',
    brand: 'foodora',
  },
  {
    isoCode: 'SE',
    country: 'Sweden',
    link: 'https://partner.foodora.se/',
    brand: 'foodora',
  },
  {
    isoCode: 'NO',
    country: 'Norway',
    link: 'https://partner.foodora.no/',
    brand: 'foodora',
  },
  {
    isoCode: 'DK',
    country: 'Denmark',
    link: 'https://partner.foodora.dk/',
    brand: 'foodora',
  },
  {
    isoCode: 'FI',
    country: 'Finland',
    link: 'https://partner.foodora.fi/',
    brand: 'foodora',
  },
  {
    isoCode: 'CZ',
    country: 'Czechia',
    link: 'https://join.foodora.cz/',
    brand: 'foodora',
  },
];
