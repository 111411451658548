import { observable } from 'mobx';
import {
  IPluginConfig,
  RestaurantSelectorOptions,
} from '@deliveryhero/vendor-portal-sdk';

const DEFAULT_PLUGIN_CONFIG = {
  restaurantSelector: RestaurantSelectorOptions.SINGLE,
};

export interface IPluginModule {
  moduleName: string;
  bundleUrl: string;
  instance: any;
  promise: Promise<PluginModule>;
  config: IPluginConfig;
  scriptDomNode: HTMLScriptElement;
  sdk: any;
  isReady: boolean;
}

export default class PluginModule implements IPluginModule {
  @observable moduleName: string;
  @observable bundleUrl: string;
  @observable instance: any;
  @observable promise: Promise<PluginModule>;
  @observable scriptDomNode: HTMLScriptElement;
  @observable sdk: any;
  @observable config: IPluginConfig;
  @observable isReady: boolean;

  constructor(props) {
    this.isReady = props.isReady || false;
    this.moduleName = props.moduleName;
    this.bundleUrl = props.bundleUrl;
    this.instance = props.instance;
    this.promise = props.promise;
    this.scriptDomNode = props.scriptDomNode;
    this.sdk = props.sdk;
    this.config = mergeConfigWithDefault(props.config);
  }

  setConfig(newConfig: IPluginConfig = {}) {
    this.config = mergeConfigWithDefault(newConfig);
  }
}

function mergeConfigWithDefault(config: IPluginConfig) {
  return {
    ...DEFAULT_PLUGIN_CONFIG,
    ...config,
  };
}
