import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const TopBarWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
}));

export const LeftContent = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const IconWrapper = styled(Box)(() => ({
  marginRight: '20px',
}));
