import { withDependencies } from '@deliveryhero/react-inversify-provider';
import AuthService from '../../services/AuthService';
import SetPasswordContainer from './SetPasswordContainer';
import { TYPES } from '../../types';
import GtmManager from '../../utils/gtm/GtmManager';

const SetPassword = withDependencies({
  authService: AuthService,
  sessionStore: TYPES.SessionStore,
  gtmManager: GtmManager,
})(SetPasswordContainer);

export default SetPassword;
