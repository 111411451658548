import * as React from 'react';

import { styled as muiStyled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import { FoodoraPartnerLogo } from './partnerLogos/FoodoraPartnerLogo';
import { FoodpandaPartnerLogo } from './partnerLogos/FoodpandaPartnerLogo';
import { FoodyPartnerLogo } from './partnerLogos/FoodyPartnerLogo';
import { HungerstationPartnerLogo } from './partnerLogos/HungerstationPartnerLogo';
import { TalabatPartnerLogo } from './partnerLogos/TalabatPartnerLogo';
import { PedidosyaPartnerLogo } from './partnerLogos/PedidosyaPartnerLogo';
import { YemeksepetiPartnerLogo } from './partnerLogos/YemeksepetiPartnerLogo';
import { DeliveryHeroPartnerLogo } from './partnerLogos/DeliveryHeroPartnerLogo';
import { EfoodPartnerLogo } from './partnerLogos/EfoodPartnerLogo';

const PLATFORMS_AND_LOGOS = [
  {
    platform: 'foodora',
    logo: FoodoraPartnerLogo,
  },
  {
    platform: 'foodpanda',
    logo: FoodpandaPartnerLogo,
  },
  {
    platform: 'hungerstation',
    logo: HungerstationPartnerLogo,
  },
  {
    platform: 'talabat',
    logo: TalabatPartnerLogo,
  },
  {
    platform: 'pedidosya',
    logo: PedidosyaPartnerLogo,
  },
  {
    platform: 'yemeksepeti',
    logo: YemeksepetiPartnerLogo,
  },
  {
    platform: 'efood',
    logo: EfoodPartnerLogo,
  },
  {
    platform: 'foody',
    logo: FoodyPartnerLogo,
  },
  {
    platform: 'default',
    logo: DeliveryHeroPartnerLogo,
  },
];

const getPartnerLogoByPlatform = (platform: string) => {
  const filteredLogoObj = PLATFORMS_AND_LOGOS.find(
    (p) => p.platform === platform,
  );
  return filteredLogoObj.logo;
};

const PartnerLogoWrapper = muiStyled(Box, {
  shouldForwardProp: (prop) => !['width', 'height'].includes(prop.toString()),
})<{ width: number; height: number }>(({ width, height }) => ({
  width: `${width}px`,
  height: `${height}px`,
  overflow: 'hidden',
}));

export type PartnerLogoProps = {
  platform: string;
  width: number;
  height: number;
};

export const PartnerLogo: React.FC<PartnerLogoProps> = ({
  platform,
  width,
  height,
}) => {
  const Logo = getPartnerLogoByPlatform(platform);

  if (!Logo) {
    throw new Error('Logo of platform not available.');
  }

  return (
    <PartnerLogoWrapper width={width} height={height}>
      <a data-testid="logo-click-redirect-to-dashboard" href={'/dashboard'}>
        <Logo />
      </a>
    </PartnerLogoWrapper>
  );
};
