import { QueryStringParser } from './QueryStringParser';
import { Location } from 'history';

const QUERY_STRING_REDIRECT_BLACKLIST = ['_ga'];

/**
 * Get redirect search parameter content for login and excluded parameters that should be again used
 * as an actual search parameter in login (excluded from redirect string)
 * @param location current/previous location
 */
export function getLoginRedirectUrlParts(location: Location) {
  const searchQuery = QueryStringParser.parse(location.search);
  const excludedQueryParameters = QUERY_STRING_REDIRECT_BLACKLIST.reduce(
    (acc, key) => ({
      ...acc,
      [key]: searchQuery[key],
    }),
    {},
  );

  const includedQueryParameters = Object.entries(searchQuery)
    .filter(([key]) => !QUERY_STRING_REDIRECT_BLACKLIST.includes(key))
    .reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: value,
      }),
      {},
    );

  return {
    excludedQueryParameters,
    redirectQueryParameter: encodeURIComponent(
      location.pathname + QueryStringParser.stringify(includedQueryParameters),
    ),
  };
}
