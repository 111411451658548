import React, { useEffect, useRef, useState } from 'react';
import { PlatformLogo } from '../../../components/SquaredPartnerLogo';
import SidebarFooter from '../SidebarFooter';
import { getSidebarOverlayPosition } from '../../../utils/overlay';
import { SCROLLABLE_AREA_OFFSET } from '../../../utils/overlay';
import {
  SidebarWrapper,
  SidebarList,
  NavSidebar,
  LogoWrapper,
} from './SidebarV2.styles';
import LinksAccordion from './LinksAccordion';
import StyledLink from './StyledLink';
import { NotificationsStore } from 'src/stores/NotificationsStore';
import { useLocation } from 'react-router-dom';

export interface MenuItem {
  name: string;
  label: string;
  path: string;
  groupCode?: string;
}
interface SidebarV2Props {
  menuItems: Array<MenuItem | MenuItem[]>;
  platform: string;
  onContactInfoClick: () => void;
  notificationsStore: NotificationsStore;
  isMobile?: boolean;
}

const SideBarV2 = ({
  menuItems,
  platform,
  onContactInfoClick,
  isMobile,
  notificationsStore,
}: SidebarV2Props) => {
  const extraContentHeight = isMobile ? '280px' : '350px';
  const sidebarRef = useRef(null);
  const [overlayPosition, setOverlayPosition] = useState<{
    top: boolean;
    bottom: boolean;
  }>({ top: false, bottom: false });
  const location = useLocation();

  const handleScroll = () => {
    const {
      clientHeight,
      scrollHeight,
      scrollTop: scrolledArea,
    } = sidebarRef.current;
    const scrollableArea = scrollHeight - clientHeight;

    // don't compute if scrollable area is less than SCROLLABLE_AREA_OFFSET
    if (scrollableArea > SCROLLABLE_AREA_OFFSET) {
      setOverlayPosition(
        getSidebarOverlayPosition({ scrollableArea, scrolledArea }),
      );
    }
  };
  useEffect(() => {
    if (sidebarRef.current) {
      handleScroll();
      sidebarRef.current.addEventListener('scroll', handleScroll);

      return () => {
        if (sidebarRef.current) {
          sidebarRef.current.removeEventListener('scroll', handleScroll);
        }
      };
    }
  }, [sidebarRef.current]);

  const [isDirty, setIsDirty] = useState<boolean>(false);

  return (
    <NavSidebar data-testid="sidebar-v2" isMobile={isMobile} id={'sidebar-v2'}>
      <LogoWrapper>
        <PlatformLogo width={40} platform={platform} hyperlink="/dashboard" />
      </LogoWrapper>
      <SidebarWrapper isMobile={isMobile} overlayPosition={overlayPosition}>
        <SidebarList ref={sidebarRef} extraContentHeight={extraContentHeight}>
          {menuItems.map((item: MenuItem | MenuItem[]) => {
            if (item instanceof Array) {
              return (
                <LinksAccordion
                  isDirty={isDirty}
                  setIsDirty={setIsDirty}
                  key={item[0].groupCode}
                  items={item}
                  platform={platform}
                  notificationsStore={notificationsStore}
                />
              );
            } else {
              return (
                <StyledLink
                  key={item.label}
                  label={item.label}
                  name={item.name}
                  path={item.path}
                  onClick={async () => {
                    if (location.pathname.includes('notifications')) {
                      notificationsStore.getUnreadMessages();
                    }
                  }}
                />
              );
            }
          })}
        </SidebarList>
      </SidebarWrapper>
      <SidebarFooter onContactInfoClick={onContactInfoClick} />
    </NavSidebar>
  );
};

export default SideBarV2;
