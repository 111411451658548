import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { Stack, Box, IconButton, Chip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { getNeutralColor } from '@deliveryhero/vt-portal-chardonnay/cjs/core/helpers';

import LanguageSelector from '../../../components/LanguageSelector';
import { getCurrentBrandInteractionMainColorRgba } from '../../../utils/getCurrentBrandInteractionMainColorRgba';
import Icon from '@mui/material/Icon';

const ITEM_BACKGROUND_COLOR = getNeutralColor('gray-05');

export type NavbarFooterType = {
  currentLanguage: string;
  onContactInfoClick?: Function;
};

export const IconButtonWrapper = styled(IconButton)(() => ({
  backgroundColor: ITEM_BACKGROUND_COLOR,
  color: getNeutralColor('black'),
  width: '36px',
  height: '36px',
  border: `1px solid ${getNeutralColor('gray-20')}`,
  '&:hover': {
    backgroundColor: `${getCurrentBrandInteractionMainColorRgba(0.1)}`,
    border: `1px solid ${getCurrentBrandInteractionMainColorRgba()}`,
  },
  '&:active': {
    backgroundColor: `${getCurrentBrandInteractionMainColorRgba(0.24)}`,
    border: `1px solid ${getCurrentBrandInteractionMainColorRgba()}`,
  },
}));

const NavbarFooter: React.FC<NavbarFooterType> = ({ currentLanguage }) => {
  const history = useHistory();
  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        color: '#000',
        fontSize: '14px',
        padding: '0px 24px 24px 24px',
      }}
    >
      <LanguageSelector
        renderComponent={(_label, onClick) => (
          <Chip
            data-testid="language-selector"
            icon={<Icon fontSize="small">language</Icon>}
            label={currentLanguage}
            onClick={onClick}
            sx={{
              width: '168px',
              align: 'left',
              paddingLeft: '8px',
              justifyContent: 'flex-start',
              border: `1px solid ${getNeutralColor('gray-20')}`,
              '&.MuiChip-root': {
                backgroundColor: ITEM_BACKGROUND_COLOR,
                height: '36px',
                '&:hover': {
                  backgroundColor: `${getCurrentBrandInteractionMainColorRgba(
                    0.1,
                  )}`,
                  border: `1px solid ${getCurrentBrandInteractionMainColorRgba()}`,
                },
                '&:active': {
                  backgroundColor: `${getCurrentBrandInteractionMainColorRgba(
                    0.24,
                  )}`,
                  border: `1px solid ${getCurrentBrandInteractionMainColorRgba()}`,
                },
              },
              '& .MuiChip-icon': {
                color: getNeutralColor('black'),
              },
            }}
          />
        )}
      />
      <Box>
        <IconButtonWrapper
          data-testid="logout-button"
          onClick={() => history.push('/logout')}
        >
          <Icon fontSize="small">logout</Icon>
        </IconButtonWrapper>
      </Box>
    </Stack>
  );
};

export default NavbarFooter;
