import i18next from 'i18next';
import { injectable, inject } from 'inversify';
import { initializeI18Next } from '../i18n';
import { VendorStore } from './VendorStore';
import { AvailableLanguagesStore } from './AvailableLanguagesStore';
import { getSubtagLang } from '../utils/getSubtagLanguage';
import { IConfig } from '../config';
import { TYPES } from '../types';

export type LangTagSeparator = '_' | '-';

export const replaceLocaleInUrl = (url: string, locale: string) =>
  url.replace('{locale}', locale);

@injectable()
export class TranslationsStore {
  @inject('config') private config: IConfig;
  @inject(TYPES.VendorStore) private vendorStore: VendorStore;

  @inject(AvailableLanguagesStore)
  private availableLangStore: AvailableLanguagesStore;

  isLangAvailable = (lang: string): boolean => {
    const { availableLanguages } = this.availableLangStore;

    return !!availableLanguages.find((item) => item.code === lang);
  };

  fetchTranslationsForLocale = async (
    lang: string,
    fallbackLng: string = i18next?.options?.fallbackLng?.[0] || 'en',
  ): Promise<void> => {
    try {
      if (!i18next.isInitialized) {
        await initializeI18Next();
      }
      const translationUrl = this.config.translationsUrl;
      const { defaultNS } = i18next.options;
      const mainLanguage = lang;

      const mainLanguageUrl = this.isLangAvailable(mainLanguage)
        ? replaceLocaleInUrl(translationUrl, mainLanguage)
        : '';

      const fallbackLngUrl =
        fallbackLng !== mainLanguage
          ? replaceLocaleInUrl(translationUrl, fallbackLng)
          : '';

      const generalLng = mainLanguage.includes('_')
        ? mainLanguage.split('_')[0]
        : mainLanguage.split('-')[0];

      const generalLngUrl =
        generalLng !== mainLanguage &&
        generalLng !== fallbackLng &&
        this.isLangAvailable(generalLng)
          ? replaceLocaleInUrl(translationUrl, generalLng)
          : '';

      const subtagLang = getSubtagLang(mainLanguage);

      const subtagLangUrl =
        subtagLang && this.isLangAvailable(subtagLang)
          ? replaceLocaleInUrl(translationUrl, subtagLang)
          : '';

      if (!mainLanguageUrl) {
        if (subtagLangUrl) {
          await i18next.changeLanguage(subtagLang);
        }

        if (!subtagLangUrl) {
          if (generalLngUrl) {
            await i18next.changeLanguage(generalLng);
          } else {
            if (fallbackLngUrl) {
              await i18next.changeLanguage(fallbackLng);
            } else {
              await i18next.changeLanguage('en');
            }
          }
        }
      }

      const urlsToFetch = [
        mainLanguageUrl,
        subtagLangUrl,
        generalLngUrl,
        fallbackLngUrl,
      ];
      const languages = [mainLanguage, subtagLang, generalLng, fallbackLng];
      const translationArr: any = await Promise.allSettled(
        this.getFetchPromiseArr(urlsToFetch),
      );

      languages.forEach((lng, i) => {
        if (
          translationArr[i].status === 'fulfilled' &&
          translationArr[i].value
        ) {
          i18next.addResourceBundle(lng, defaultNS, translationArr[i].value);
        }
      });
    } catch (err) {
      throw new Error(`TranslationsStore error: ${err}`);
    }
  };

  translate = (
    key: string,
    params?: Record<string, string | number>,
    originalKey?: string,
  ): string => {
    const globalEntityId = this.vendorStore.currentVendor?.globalEntityId;
    const brand = globalEntityId?.split('_')[0];
    const globalEntityKey = globalEntityId && `${key}.${globalEntityId}`;
    const brandKey = brand && `${key}.${brand}`;

    if (globalEntityKey && i18next.exists(globalEntityKey)) {
      return i18next.t(globalEntityKey, params);
    }

    if (brandKey && i18next.exists(brandKey)) {
      return i18next.t(brandKey, params);
    }

    const text = i18next.t(key, key, params);
    const keyToReturn = originalKey || key;

    return text === key ? keyToReturn : text;
  };

  fetchTranslations = async (url: string): Promise<any> => {
    try {
      const response = await fetch(url, { mode: 'cors' });

      return await response.json();
    } catch (error) {
      throw new Error(
        `While trying to fetch translations, an error occurred: ${error}`,
      );
    }
  };

  getFetchPromiseArr = (urlArr: string[]) =>
    urlArr.reduce(
      (acc, url) => [...acc, url && this.fetchTranslations(url)],
      [],
    );
}
