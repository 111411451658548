/* eslint-disable max-len */
import * as React from 'react';

export const EfoodPartnerLogo: React.FC = () => (
  <svg
    data-testid="platform-logo-svg"
    width="212"
    height="48"
    viewBox="0 0 212 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M52.0166 36L56.2087 12H136.802L132.641 36H52.0166Z"
      fill="#ED2E2E"
    />
    <path
      d="M56.2014 34.309L59.0075 19.7233H62.3802L62.1678 20.8273H62.2111C63.1312 19.978 64.2764 19.5322 65.4655 19.5322C68.2113 19.5322 69.862 21.7402 69.2493 24.9249C68.6326 28.1307 66.1404 30.2963 63.2216 30.2963C62.0109 30.2963 61.1629 29.8717 60.6222 29.0861L60.5749 29.1074L59.5741 34.309H56.2014ZM63.1151 27.3664C64.4123 27.3664 65.5515 26.3898 65.8333 24.9249C66.1192 23.4387 65.3342 22.4621 64.037 22.4621C62.6749 22.4621 61.5018 23.5024 61.2282 24.9249C60.9423 26.411 61.7746 27.3664 63.1151 27.3664Z"
      fill="white"
    />
    <path
      d="M74.4537 30.2963C71.5566 30.2963 69.8761 28.1307 70.4928 24.9249C71.1055 21.7402 73.6275 19.5322 76.3517 19.5322C77.4759 19.5322 78.4495 19.978 79.0212 20.8273L79.0686 20.806L79.2769 19.7233H82.6713L80.6739 30.1052H77.4525L77.6608 29.0224L77.6216 29.0012C76.7623 29.8717 75.7293 30.2963 74.4537 30.2963ZM75.7309 27.3664C76.9416 27.3664 78.1416 26.411 78.4275 24.9249C78.7012 23.5024 77.9284 22.4621 76.6744 22.4621C75.3772 22.4621 74.2164 23.4387 73.9305 24.9249C73.6486 26.3898 74.3904 27.3664 75.7309 27.3664Z"
      fill="white"
    />
    <path
      d="M82.9376 30.1052L84.935 19.7233H88.3078L88.03 21.167H88.0733C89.0558 20.1054 90.2688 19.5322 91.4362 19.5322H91.566L90.8757 23.1202C90.5555 23.099 90.2569 23.0778 90.0839 23.0778C88.484 23.0778 87.4704 24.0756 87.209 25.4344L86.3104 30.1052H82.9376Z"
      fill="white"
    />
    <path
      d="M94.4039 30.2963C92.5878 30.2963 91.503 29.1923 91.9564 26.8356L92.8101 22.3984H91.6209L92.1356 19.7233H93.3247L93.966 16.39L97.4257 16.0503L96.7191 19.7233H99.2703L98.7556 22.3984H96.2044L95.4692 26.2199C95.3385 26.8993 95.3959 27.3877 96.0445 27.3877C96.2823 27.3877 96.727 27.324 97.1272 27.1541L97.5007 29.5957C96.688 29.9991 95.8741 30.2963 94.4039 30.2963Z"
      fill="white"
    />
    <path
      d="M98.9418 30.1052L100.939 19.7233H104.312L104.079 20.9334H104.122C105.124 19.9993 106.338 19.5322 107.57 19.5322C109.754 19.5322 111.149 20.5937 110.516 23.8845L109.32 30.1052H105.947L106.988 24.6913C107.319 22.9716 106.829 22.4833 105.727 22.4833C104.559 22.4833 103.63 23.2688 103.319 24.8824L102.315 30.1052H98.9418Z"
      fill="white"
    />
    <path
      d="M122.643 24.7975C122.586 25.0947 122.449 25.583 122.386 25.7953H115.187C115.174 26.9843 116.072 27.6 117.369 27.6C118.238 27.5787 119.186 27.2603 120.051 26.4747L121.621 28.428C120.526 29.5108 118.904 30.2963 116.505 30.2963C113.305 30.2963 111.386 28.1307 112.007 24.9036C112.612 21.7614 115.289 19.5322 118.489 19.5322C121.473 19.5322 123.236 21.719 122.643 24.7975ZM117.939 22.1648C116.902 22.1648 116.005 22.7805 115.588 23.8208H119.545C119.525 22.8018 118.955 22.1648 117.939 22.1648Z"
      fill="white"
    />
    <path
      d="M123.412 30.1052L125.41 19.7233H128.783L128.505 21.167H128.548C129.531 20.1054 130.744 19.5322 131.911 19.5322H132.041L131.35 23.1202C131.03 23.099 130.732 23.0778 130.559 23.0778C128.959 23.0778 127.945 24.0756 127.684 25.4344L126.785 30.1052H123.412Z"
      fill="white"
    />
    <path
      d="M2.92999 24.219C2.90393 24.219 2.80825 24.2189 2.80825 24.2189C2.80825 24.2189 2.84567 24.1366 2.85857 24.1092C3.24878 23.2755 4.06907 22.7019 5.01732 22.703C5.96522 22.7041 6.78416 23.2794 7.17248 24.114C7.18429 24.1396 7.22126 24.2237 7.22126 24.2237C7.22126 24.2237 7.12883 24.2238 7.1006 24.2237L2.92999 24.219ZM9.94492 26.3205C9.99993 26.0128 10.0285 25.6954 10.029 25.371C10.0293 25.0333 9.99903 24.7036 9.93978 24.3835C9.5005 21.9683 7.46556 20.1413 5.01958 20.1385C2.25057 20.1354 0.00325004 22.4733 3.52126e-06 25.3597C-0.003243 28.2477 2.23884 30.5906 5.00777 30.5937C6.66349 30.5956 7.82764 29.8302 8.83929 28.8369C8.88493 28.7922 9.02498 28.6538 9.02498 28.6538C9.02498 28.6538 8.85832 28.5589 8.80052 28.5258L6.70849 27.3257C6.6726 27.305 6.589 27.2593 6.589 27.2593C6.589 27.2593 6.51614 27.321 6.48394 27.3472C6.07731 27.6771 5.56626 27.8732 5.01155 27.8725C4.40779 27.8718 3.85534 27.6385 3.43446 27.2533C3.1558 26.9983 2.93342 26.6771 2.79228 26.3125L9.94492 26.3205Z"
      fill="#ED2E2E"
    />
    <path
      d="M17.6326 20.3075L15.8854 23.1651L14.4184 23.1654L14.41 30.604L11.5438 30.6008L11.5522 23.1623L10.1794 23.1607L10.1826 20.3008L11.5553 20.3023L11.5571 18.82C11.5603 15.9668 14.0071 14.4406 16.2986 15.092C17.0773 15.3133 17.6444 15.6668 18.2927 16.285C18.3619 16.3509 18.5464 16.5253 18.5464 16.5253L16.2883 18.186C16.2883 18.186 16.1959 18.1388 16.1631 18.1219C15.1634 17.6073 14.3094 18.2476 14.4171 20.2306C14.4183 20.2529 14.4216 20.305 14.4216 20.305L17.6326 20.3075Z"
      fill="#ED2E2E"
    />
    <path
      d="M21.8715 27.7272C20.3263 27.9203 19.0291 26.6192 19.2253 25.0744C19.3593 24.0222 20.21 23.173 21.2626 23.0417C22.8081 22.8487 24.1062 24.1487 23.9097 25.6936C23.7761 26.7463 22.9246 27.5959 21.8715 27.7272ZM21.9154 20.1678C18.7594 19.9632 16.1538 22.5622 16.35 25.7188C16.5121 28.3203 18.62 30.4337 21.2213 30.6011C24.3753 30.8045 26.9792 28.2082 26.7852 25.0531C26.6253 22.451 24.5173 20.3366 21.9154 20.1678Z"
      fill="#ED2E2E"
    />
    <path
      d="M32.8841 27.7394C31.3387 27.9324 30.0415 26.6314 30.2378 25.0866C30.3717 24.0343 31.2226 23.1852 32.2751 23.0539C33.8205 22.8609 35.1187 24.1608 34.9222 25.7059C34.7882 26.7585 33.937 27.6081 32.8841 27.7394ZM32.928 20.18C29.7718 19.9753 27.1663 22.5743 27.3624 25.731C27.5245 28.3324 29.6325 30.4459 32.2339 30.6133C35.3878 30.8166 37.9919 28.2204 37.7978 25.0653C37.6378 22.4632 35.5297 20.3488 32.928 20.18Z"
      fill="#ED2E2E"
    />
    <path
      d="M44.3768 27.6544C42.6589 28.1876 41.093 26.8167 41.2752 25.1437C41.3933 24.0588 42.2701 23.181 43.3551 23.062C44.7794 22.9062 45.9839 24.0162 45.9843 25.4078V25.4151C45.9831 26.4288 45.3448 27.3537 44.3768 27.6544ZM45.9942 16.644L45.9897 20.6232C45.9897 20.6504 45.9892 20.7451 45.9892 20.7451C45.9892 20.7451 45.9051 20.7063 45.8775 20.6928C44.9944 20.2696 43.9749 20.0856 42.9016 20.2298C40.3078 20.5787 38.3691 22.851 38.395 25.4681C38.4232 28.326 40.5253 30.6338 43.3908 30.6369C44.2303 30.6378 45.2502 30.2159 45.8487 29.6541C45.8738 29.6308 45.982 29.5217 45.982 29.5217C45.982 29.5217 45.9795 29.6779 45.9795 29.712L45.9785 30.6398L48.8447 30.6431L48.862 15.2692C48.8621 15.1629 48.862 14.759 48.862 14.759C48.862 14.759 48.5247 14.9538 48.4203 15.0141L46.1897 16.3053C46.1292 16.3403 45.9969 16.4183 45.9969 16.4183C45.9969 16.4183 45.9943 16.5741 45.9942 16.644Z"
      fill="#ED2E2E"
    />
  </svg>
);
