import * as React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Radio from '@mui/material/Radio';
import Button from '@mui/material/Button';

import { Translate } from './Translate';

export type Option = {
  label: string;
  Icon?: React.Component | JSX.Element | React.ReactFragment;
  initiallySelected: boolean;
};

const isInitiallySelected = ({ initiallySelected }: Option): boolean =>
  initiallySelected;

const isNumber = (value: any): boolean => typeof value === 'number';

export type Props = {
  open: boolean;
  onClose: (event: any) => void;
  options: Option[];
  onSelect: Function;
  title: string | JSX.Element;
};

const DialogMenu: React.FC<Props> = ({
  open,
  onClose,
  options,
  onSelect,
  title,
}) => {
  const initiallySelected = options.findIndex(isInitiallySelected);
  const [selected, selectOption] = React.useState(
    isNumber(initiallySelected) ? initiallySelected : -1,
  );

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent
        sx={{
          paddingLeft: '0',
        }}
      >
        <List>
          {options.map((option: Option, i: number) => {
            const { label, Icon } = option;

            return (
              <ListItem
                key={`dialog-menu-${i}`}
                onClick={() => selectOption(i)}
                data-testid={`dialog-menu-option-${i}`}
                sx={{ textAlign: 'left' }}
              >
                <Radio checked={selected === i} color="primary" />
                <ListItemText primary={label} />
                {Icon || null}
              </ListItem>
            );
          })}
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={onClose}
          data-testid="dialog-menu-cancel"
        >
          <Translate code="global.cancel" />
        </Button>

        <Button
          color="primary"
          disabled={selected === -1}
          onClick={() => onSelect(selected)}
          data-testid="dialog-menu-save"
        >
          <Translate code="global.save" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogMenu;
