/* eslint-disable max-len */
import * as React from 'react';

import { LogoProps } from '../LogoProps';

export const DeliveryHeroSquaredPartnerLogo: React.FC<LogoProps> = ({
  width = 64,
}) => (
  <svg
    data-testid="platform-logo-svg"
    width={width}
    height={width}
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="64" height="64" fill="#D61F26" />
    <path
      d="M43.6692 31.158C43.6597 31.1626 43.6543 31.1666 43.6469 31.1703L39.8321 32.7269L39.7148 32.7801L38.7854 37.0288C38.7233 37.174 38.5378 37.2083 38.4123 37.0868L35.6333 33.8004L35.6196 33.7913L19.9468 40.5167C19.9334 40.5238 19.918 40.527 19.9028 40.527C19.8448 40.527 19.7976 40.481 19.7976 40.4212C19.7976 40.3878 19.8142 40.3569 19.8405 40.3372L33.3908 30.1951L31.6755 26.2808C31.5904 26.1024 31.7481 25.9126 31.9577 25.9647H31.9594L36.1115 26.9882L39.335 24.1182V24.1191C39.4748 24.0099 39.6637 24.0876 39.6989 24.2603L40.0138 28.5714L43.7312 30.7464C43.891 30.8482 43.8681 31.0837 43.6692 31.158ZM41.7739 18.836C36.9641 16.9487 31.6738 18.3655 28.4092 21.9937L15.6701 35.6403C15.4986 35.8238 15.5777 36.0541 15.7873 36.0853L19.1803 36.2937C19.4523 36.3108 19.4861 36.5438 19.3492 36.7047L11.0532 45.6529C10.9089 45.8084 11.0829 46.0519 11.2813 45.9902L23.126 42.2479C23.3761 42.1616 23.569 42.3846 23.4633 42.5804L21.8789 45.3814C21.7969 45.5411 21.9507 45.7598 22.1497 45.7455L39.2263 41.9415C43.3068 41.2956 46.9504 38.5719 48.5696 34.4487C51.008 28.2595 47.9614 21.27 41.7739 18.836Z"
      fill="white"
    />
  </svg>
);
