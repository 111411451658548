import { MasterPluginContainer } from './MasterPluginContainer';
import { withDependencies } from '@deliveryhero/react-inversify-provider';
import { NavStore } from '../../stores/NavStore';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import PluginModuleStore from '../../stores/PluginModuleStore';
import { TYPES } from '../../types';
import GtmManager from '../../utils/gtm/GtmManager';
import { NotificationsStore } from '../../stores/NotificationsStore';
import { LocaleStore } from '../../stores/LocaleStore';
import FwfStore from '../../stores/FwfStore';

const MasterPluginWrapper = withDependencies({
  navStore: NavStore,
  vendorStore: TYPES.VendorStore,
  pluginModuleStore: PluginModuleStore,
  config: 'config',
  gtmManager: GtmManager,
  sessionStore: TYPES.SessionStore,
  notificationsStore: NotificationsStore,
  localeStore: LocaleStore,
  fwfStore: FwfStore,
})(
  withRouter<any, typeof MasterPluginContainer>(
    observer(MasterPluginContainer),
  ),
);

export default MasterPluginWrapper;
