import React, { useEffect, useState, forwardRef } from 'react';
import { match as Match } from 'react-router';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { History } from 'history';

import {
  RestaurantSelectorOptions,
  SdkProvider,
} from '@deliveryhero/vendor-portal-sdk';
import { useIsMobile } from '@deliveryhero/vt-portal-chardonnay/cjs/core/helpers';

import { styled as muiStyled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';

import FrontendPluginContainer from '../FrontendPluginContainer';
import { FrontendPluginContainerProps } from '../FrontendPluginContainer/FrontendPluginContainer';
import { NavStore } from '../../stores/NavStore';
import { MasterContentWrapper } from '../../components/MasterContentWrapper';
import { Translate } from '../../components/Translate';
import ContentWrapper from '../../components/ContentWrapper';
import Header from '../../components/Header';
import PluginModuleStore from '../../stores/PluginModuleStore';
import PluginModule from '../../models/PluginModule';
import { RouterProvider } from '../../utils/CustomRouter';
import { VendorStore } from '../../stores/VendorStore';
import { IConfig } from '../../config';
import GtmManager from '../../utils/gtm/GtmManager';
import { SessionStore } from '../../stores/SessionStore';
import { NotificationsStore } from '../../stores/NotificationsStore';
import { LocaleStore } from '../../stores/LocaleStore';
import { isMobileWebView } from '../../utils/isMobileWebView';
import { COLOR_BACKGROUND } from '../../constants';
import FwfStore from '../../stores/FwfStore';
import { PlatformStore } from 'src/stores/PlatformStore';

export type MasterPluginContainerProps = FrontendPluginContainerProps & {
  isNewNotifications: boolean;
  navStore: NavStore;
  vendorStore: VendorStore;
  platformStore: PlatformStore;
  match: Match<{}>;
  history: History;
  pluginModuleStore: PluginModuleStore;
  config: IConfig;
  gtmManager: GtmManager;
  sessionStore: SessionStore;
  notificationsStore: NotificationsStore;
  localeStore: LocaleStore;
  fwfStore: FwfStore;
};

const StickyContentWrapper = muiStyled(Paper)(({ theme }) => ({
  position: 'sticky',
  top: '64px',
  zIndex: 1000,
  marginTop: '-56px',
  marginBottom: '32px',

  [theme.breakpoints.up('md')]: {
    marginTop: '-64px',
    top: '0',
    backgroundColor: COLOR_BACKGROUND,
    boxShadow: 'none',
  },
}));

type PluginSecondaryBarProps = {
  pluginName: string;
  pluginModule: PluginModule;
  navStore: NavStore;
  vendorStore: VendorStore;
  platformStore: PlatformStore;
  match: Match<{}>;
  history: History;
  config: IConfig;
  isNewNotifications: boolean;
  gtmManager: GtmManager;
  sessionStore: SessionStore;
  notificationsStore: NotificationsStore;
  localeStore: LocaleStore;
  fwfStore: FwfStore;
};

const shouldRenderOutletSelector = (configValue) =>
  [
    RestaurantSelectorOptions.MULTIPLE_VENDORS,
    RestaurantSelectorOptions.SINGLE_VENDOR,
  ].includes(configValue);

const InternalPluginSecondaryBar: React.FC<PluginSecondaryBarProps> = ({
  pluginName,
  pluginModule,
  navStore,
  vendorStore,
  platformStore,
  history,
  match,
  isNewNotifications,
  gtmManager,
  sessionStore,
  notificationsStore,
  localeStore,
  fwfStore,
}) => {
  const isMobile = useIsMobile();
  const [isHeaderShrunk, setIsHeaderShrunk] = useState<boolean>(false);
  const [currentScrollPos, setCurrentScrollPos] = useState(0);

  useEffect(() => {
    if (navStore.hasShrunkHeader === undefined) {
      const handler = () => {
        if (isMobile) {
          setIsHeaderShrunk(
            window.scrollY > currentScrollPos && window.scrollY > 60,
          );
          setCurrentScrollPos(window.scrollY);
        } else {
          const distanceY = window.scrollY;
          const shrinkOn = 50;
          setIsHeaderShrunk(distanceY > shrinkOn);
        }
      };
      window.addEventListener('scroll', handler, {
        passive: true, // improves the scrolling listener performance
      });
      return () => {
        window.removeEventListener('scroll', handler);
      };
    } else {
      setIsHeaderShrunk(navStore.hasShrunkHeader);
    }
  }, [navStore.hasShrunkHeader, currentScrollPos, isMobile]);

  const navFilters = navStore.filters;

  const title = <Translate code={`global.menu.${pluginName.toLowerCase()}`} />;
  const subtitle = navStore.navigationTitle;

  const isMultipleVendors =
    RestaurantSelectorOptions.MULTIPLE_VENDORS ===
    pluginModule?.config.restaurantSelector;

  const getHeader = () => {
    return (
      <Header
        history={history}
        title={title}
        platformStore={platformStore}
        vendorStore={vendorStore}
        isMultipleVendors={isMultipleVendors}
        isShrunk={isHeaderShrunk}
        shouldElevateHeader={currentScrollPos > 10}
        subtitle={subtitle}
        navFilters={navFilters}
        backUrl={navStore.backUrl}
        backLinkComponent={
          navStore.backUrl
            ? forwardRef((props, ref) => (
                <Link ref={ref as any} {...props} to={navStore.backUrl} />
              ))
            : undefined
        }
        shouldRenderOutletSelector={shouldRenderOutletSelector(
          pluginModule?.config.restaurantSelector,
        )}
        ActionButtons={navStore.actionButtons}
        gtmManager={gtmManager}
        isNewNotifications={isNewNotifications}
        sessionStore={sessionStore}
        notificationsStore={notificationsStore}
        localeStore={localeStore}
        fwfStore={fwfStore}
      />
    );
  };

  return (
    <RouterProvider baseurl={match.path}>
      {getHeader()}
      {navStore.stickyContent && (
        <StickyContentWrapper>
          <navStore.stickyContent />
          <ContentWrapper>
            <Box mx={-2}>
              <Divider />
            </Box>
          </ContentWrapper>
        </StickyContentWrapper>
      )}
    </RouterProvider>
  );
};

const PluginSecondaryBar = observer(InternalPluginSecondaryBar);

export const MasterPluginContainer: React.FC<MasterPluginContainerProps> = ({
  bundleUrl,
  pluginName,
  isNewNotifications,
  navStore,
  vendorStore,
  platformStore,
  match,
  history,
  pluginModuleStore,
  config,
  gtmManager,
  sessionStore,
  notificationsStore,
  localeStore,
  fwfStore,
}) => {
  const isMobile = useIsMobile();
  const pluginModule = pluginModuleStore.getModuleByName(pluginName);

  useEffect(() => {
    navStore.setNewNavigation({ name: '', title: '' });
  }, []);

  const getContentTopMargin = () => {
    const hasHeaderBottomRow =
      shouldRenderOutletSelector(pluginModule?.config.restaurantSelector) ||
      navStore.filters.length;
    // for webview, header's top row is hidden, hence small height
    if (isMobileWebView()) {
      return hasHeaderBottomRow ? '100px' : '36px';
    }
    if (isMobile) {
      return hasHeaderBottomRow ? '140px' : '70px';
    }
    // desktop case
    return '130px';
  };

  return (
    <>
      <SdkProvider sdk={pluginModule?.sdk}>
        {pluginModule?.isReady && (
          <PluginSecondaryBar
            pluginModule={pluginModule}
            match={match}
            history={history}
            pluginName={pluginName}
            navStore={navStore}
            vendorStore={vendorStore}
            platformStore={platformStore}
            config={config}
            isNewNotifications={isNewNotifications}
            gtmManager={gtmManager}
            sessionStore={sessionStore}
            notificationsStore={notificationsStore}
            localeStore={localeStore}
            fwfStore={fwfStore}
          />
        )}
      </SdkProvider>
      <MasterContentWrapper
        style={{ marginTop: getContentTopMargin() }}
        id="master-content-wrapper"
      >
        <FrontendPluginContainer
          bundleUrl={bundleUrl}
          pluginName={pluginName}
        />
      </MasterContentWrapper>
    </>
  );
};
