import * as React from 'react';
import { styled as muiStyled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ListItem, Title } from './ApplicationDown.styles';

const ErrorMessageWrapper = muiStyled(Box)({
  padding: '0 25px',
  margin: '50px auto',
  maxWidth: '500px',
});

const CenteredMessageWrapper = muiStyled(Box)({
  position: 'absolute',
  top: '0',
  right: '0',
  bottom: '0',
  left: '0',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  pointerEvents: 'none',
});

type ITranslate = (args: {
  code: string;
  params?: Record<string, string | number>;
}) => string;

const ApplicationDownPage = ({ translate }: { translate: ITranslate }) => (
  <CenteredMessageWrapper>
    <ErrorMessageWrapper style={{ maxWidth: '700px' }}>
      <Title>{translate({ code: 'global.error.app_down.title' })}</Title>
      <Typography>
        {translate({ code: 'global.error.app_down.description_start' })} :
      </Typography>
      <ul>
        <ListItem>
          {translate({ code: 'global.error.app_down.reason_maintenance' })}
        </ListItem>
        <ListItem>
          {translate({ code: 'global.error.app_down.reason_certificates' })}
        </ListItem>
      </ul>

      <Typography>
        {translate({ code: 'global.error.app_down.description_end' })}.
      </Typography>
    </ErrorMessageWrapper>
  </CenteredMessageWrapper>
);

export default ApplicationDownPage;
