import * as React from 'react';
import { match, Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { Location } from 'history';
import { observer } from 'mobx-react';

// stores
import { PluginStore } from '../../../stores/PluginStore';
import { SessionStore } from '../../../stores/SessionStore';
import { VendorStore } from '../../../stores/VendorStore';
import { NotificationsStore } from '../../../stores/NotificationsStore';
import FwfStore from '../../../stores/FwfStore';

// components
import MasterContainerDefault from '../../MasterContainer';
import MasterPluginContainerDefault from '../../MasterPluginContainer';
import NotFound from '../../../components/errors/NotFoundPage';
import { QueryStringParser } from '../../../utils/QueryStringParser';

// models
import { Plugin } from '../../../models/Plugin';
import { ApiStatus } from '../../../models/ApiStatus';

// utils
import { getLoginRedirectUrlParts } from '../../../utils/getLoginRedirectUrlParts';
import { isMobileWebView } from '../../../utils/isMobileWebView';
import Box from '@mui/material/Box';

// constants
import { NEW_NOTIFICATIONS_FLAG } from '../../../constants';

export type MasterRoutesProps = {
  sessionStore: SessionStore;
  pluginStore: PluginStore;
  match: match<{}>;
  location: Location;
  vendorStore: VendorStore;
  notificationsStore: NotificationsStore;
  fwfStore: FwfStore;
  MasterContainer?: React.ComponentType<any>;
  MasterPluginContainer?: React.ComponentType<any>;
};

export type NavigationItem = {
  label: string;
  name: string;
  path: string;
};

const LightWeightComponent = () => (
  <Box justifyContent="center" display="flex">
    <h1>LightWeight Component for measuring webApp performance</h1>
  </Box>
);

const MasterRoutesComponent: React.FC<MasterRoutesProps> = ({
  sessionStore,
  pluginStore,
  location,
  vendorStore,
  notificationsStore,
  fwfStore,
  MasterContainer = MasterContainerDefault,
  MasterPluginContainer = MasterPluginContainerDefault,
}) => {
  const [isNewNotifications, setIsNewNotifications] =
    React.useState<boolean>(false);

  const history = useHistory();
  const isLoggedIn = sessionStore.isLoggedIn;

  const setCurrentVendorFromUrl = () => {
    const { vendor: vendorId } = QueryStringParser.parse(location.search);

    if (vendorId) {
      vendorStore.queueCurrentVendorId(vendorId);
    }
  };

  const getSortedPluginsByRoutes = () => {
    const sortRoutesByLengthDesc = (a: Plugin, b: Plugin) =>
      b.route.length - a.route.length;

    return [...pluginStore.frontendPlugins].sort(sortRoutesByLengthDesc);
  };

  React.useEffect(() => {
    setCurrentVendorFromUrl();
  }, [location.search]);

  React.useEffect(() => {
    let getMessageInterval: number;

    const checkNewNotificationsFlag = async () => {
      const newNotificationsVariation = fwfStore.featureFlags.get(
        NEW_NOTIFICATIONS_FLAG,
      );
      setIsNewNotifications(newNotificationsVariation);
      if (newNotificationsVariation) {
        // call once then start polling every 30 seconds
        notificationsStore.getUnreadMessages();
        getMessageInterval = setInterval(() => {
          notificationsStore.getUnreadMessages();
        }, 30000);
      }
    };

    if (fwfStore.ready) {
      checkNewNotificationsFlag();
    }

    return () => {
      if (getMessageInterval) {
        // remove the interval when component is unmounted
        clearInterval(getMessageInterval);
      }
    };
  }, [fwfStore.ready, notificationsStore]);

  React.useEffect(() => {
    if (!isLoggedIn) {
      return;
    }

    // Vendor store should be the source of truth,
    // so we correct the URL if search parameter "vendorId" is not matching vendor store
    if (vendorStore.currentVendorId) {
      const search = QueryStringParser.parse(location.search);
      const { vendor: vendorId } = search;
      if (
        typeof vendorId === 'string' &&
        vendorStore.currentVendorId !== vendorId
      ) {
        history.replace({
          search: QueryStringParser.stringify({
            ...search,
            vendor: vendorStore.currentVendorId,
          }),
        });
      }
    }
  }, [isLoggedIn, vendorStore.currentVendorId]);

  if (!isLoggedIn) {
    const { redirectQueryParameter, excludedQueryParameters } =
      getLoginRedirectUrlParts(location);
    return (
      <Redirect
        to={{
          pathname: '/login',
          search: QueryStringParser.stringify({
            ...excludedQueryParameters,
            redirect: redirectQueryParameter,
          }),
        }}
      />
    );
  }

  // Sort plugins by route length to put more specific routes first
  const sortedPlugins = getSortedPluginsByRoutes();
  return (
    <MasterContainer>
      {/* Don't render any route till all the route data is fetched/generated */}
      <Switch>
        <Route
          key="lightWeightTestComponent"
          path="/lightWeightTestComponent"
          component={LightWeightComponent}
        />
        {pluginStore.pluginApiStatus === ApiStatus.SUCCESS &&
          sortedPlugins.map(({ bundleUrl, route, code }) => (
            <Route
              path={route}
              key={code}
              exact={false}
              render={() => (
                <MasterPluginContainer
                  pluginName={code}
                  bundleUrl={bundleUrl}
                  isNewNotifications={isNewNotifications}
                />
              )}
            />
          ))}

        {/* show NOT FOUND page to users on unknown routes only after plugin API is successfully completed */}
        {!isMobileWebView() &&
        pluginStore.pluginApiStatus === ApiStatus.SUCCESS ? (
          <Route component={NotFound} />
        ) : null}
      </Switch>
    </MasterContainer>
  );
};

const MasterRoutes = observer(MasterRoutesComponent);

export default MasterRoutes;
