import * as React from 'react';
import MaterialUISvgIcon from '@material-ui/core/SvgIcon';
import portalComponents from '../components/PortalComponents';

import * as styledComponents from 'styled-components';
import * as ReactDom from 'react-dom';
import * as ReactRouter from 'react-router';
import * as ReactRouterDom from 'react-router-dom';
import * as mobx from 'mobx';
import * as mobxReact from 'mobx-react';
import * as reactIntl from 'react-intl';
import * as inversify from 'inversify';
import {
  PluginRoute,
  PluginLink,
  PluginRedirect,
  PluginSwitch,
} from '../utils/CustomRouter';

@inversify.injectable()
export default class PluginModuleDependencyStore {
  async resolveDependencies(
    dependenciesNames: string[],
    customDependencies: { [x: string]: any } = {},
  ): Promise<unknown[]> {
    return Promise.all(
      dependenciesNames.map(async (depName) => {
        // Try to resolve from custom dependencies
        for (const key of Object.keys(customDependencies)) {
          if (key === depName) {
            return customDependencies[key];
          }
        }

        // Otherwise try to switch from hardcoded dependencies, (`import()` signalizes lazily loaded dependencies)
        switch (depName) {
          case 'react':
            return React;
          case '@material-ui/core':
          case 'material-ui': // For legacy support
            return import(
              /* webpackChunkName: "mui-core" */ '@material-ui/core'
            );
          case '@material-ui/core/SvgIcon':
            return MaterialUISvgIcon;
          case '@deliveryhero/vendor-portal-components':
          case '@rps/portal-components': // For legacy support
            return portalComponents;
          case 'styled-components':
            return styledComponents;
          case 'react-dom':
            return ReactDom;
          case 'recharts':
            return import(/* webpackChunkName: "recharts" */ 'recharts');
          case '@material-ui/pickers':
            return import(
              /* webpackChunkName: "mui-pickers" */ '@material-ui/pickers'
            );
          case 'notistack':
            return import(/* webpackChunkName: "notistack" */ 'notistack');
          case 'react-router':
            return {
              ...ReactRouter,
              Route: PluginRoute,
              Link: PluginLink,
              Redirect: PluginRedirect,
              Switch: PluginSwitch,
            };
          case 'react-router-dom':
            return {
              ...ReactRouterDom,
              Route: PluginRoute,
              Link: PluginLink,
              Redirect: PluginRedirect,
              Switch: PluginSwitch,
            };
          case 'mobx':
            return mobx;
          case 'mobx-react':
            return mobxReact;
          case 'react-intl':
            return reactIntl;
          case 'inversify':
            return inversify;

          default:
            throw new Error(
              `The requested plugin dependency "${depName}" is unknown`,
            );
        }
      }),
    );
  }
}
