import { styled as muiStyled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const MasterContentWrapper = muiStyled(Box)(({ theme }) => ({
  width: 'auto',
  padding: '0 16px',
  position: 'relative',
  minHeight: 'calc(100vh - 200px)',
  [theme.breakpoints.up('md')]: {
    maxWidth: '1312px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));
