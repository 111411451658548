import AuthService from '../../services/AuthService';
import { PlatformStore } from '../../stores/PlatformStore';
import { OnboardingSetPasswordContainer as Container } from './OnboardingSetPasswordContainer';
import { withDependencies } from '@deliveryhero/react-inversify-provider';
import { withRouter } from 'react-router-dom';
import { TYPES } from '../../types';

const OnboardingSetPasswordContainer = withDependencies({
  platformStore: PlatformStore,
  authService: AuthService,
  userStore: TYPES.UserStore,
})(withRouter<any, typeof Container>(Container));

export default OnboardingSetPasswordContainer;
