import { styled as muiStyled } from '@mui/material/styles';
import { getSpacing } from '@deliveryhero/vt-portal-chardonnay/core';
import Box from '@mui/material/Box';

const ContentWrapper = muiStyled(Box)(({ theme }) => ({
  width: 'auto',
  padding: `0 ${getSpacing('spacing-16')}`,
  [theme.breakpoints.up('sm')]: {
    maxWidth: '1312px',
    margin: 'auto',
  },
}));

export const MobileFullWidthContent = muiStyled(Box)(({ theme }) => ({
  margin: `0 -${getSpacing('spacing-16')}`,
  [theme.breakpoints.up('sm')]: {
    margin: 0,
  },
}));

export default ContentWrapper;
