import { inject, injectable, postConstruct } from 'inversify';
import { observable, autorun, action } from 'mobx';
import { SessionStore } from '../stores/SessionStore';
import { TranslationsStore } from '../stores/TranslationsStore';
import { UserStore } from '../stores/UserStore';
import { VendorStore } from '../stores/VendorStore';
import { Api } from '@deliveryhero/portal-api-client';
import { LanguageStore } from '../stores/LanguageStore';
import { TYPES } from '../types';
import { IAccessTokenContent } from '../models/Session';
import FwfStore from '../stores/FwfStore';
import GtmManager from '../utils/gtm/GtmManager';
import { PlatformStore } from '../stores/PlatformStore';
import { USE_KEYMAKER_ACCESS_TOKEN } from '../constants';
import { HelpCenterOptions } from '@deliveryhero/vendor-portal-sdk';
import { withInterceptor } from '@deliveryhero/captcha';

export enum LoginType {
  MASTER,
}

export class InvalidTokenError extends Error {}

export class ExpiredTokenError extends Error {}

@injectable()
export default class AuthService {
  @observable lastLoginEmail: string;
  @observable refreshStatusSubscribers: ((isExpired: boolean) => void)[] = [];
  @observable keymakerRefreshTokenRequest: Promise<any>;

  @inject(TYPES.SessionStore) private sessionStore: SessionStore;
  @inject(TYPES.GlobalApi) private api: Api;
  @inject(TYPES.GlobalApiV2) private apiV2: Api;

  @inject('authServiceUrl') private authServiceUrl: string;
  @inject('bffApiUrl') private bffApiUrl: string;
  @inject('pluginApiUrl') private pluginApiUrl: string;
  @inject('helpCenterUrl') private helpCenterUrl: string;
  @inject(TranslationsStore) private translationsStore: TranslationsStore;
  @inject('window') private window: Window;
  @inject(LanguageStore) private languageStore: LanguageStore;
  @inject(TYPES.UserStore) private userStore: UserStore;
  @inject(TYPES.VendorStore) private vendorStore: VendorStore;
  @inject(FwfStore) private fwfStore: FwfStore;
  @inject(GtmManager) private gtmManager: GtmManager;
  @inject(PlatformStore) private platformStore: PlatformStore;

  private lastImpersonationToken: string;

  @postConstruct() init() {
    // Setup last login email from local storage if exists
    this.lastLoginEmail =
      this.window.localStorage.getItem('lastLoginEmail') || '';

    this.api = withInterceptor(this.api);
    this.apiV2 = withInterceptor(this.apiV2);

    // Save the lastLoginEmail localStorage value every time this.lastLoginEmail is changed
    autorun(() => {
      this.window.localStorage.setItem('lastLoginEmail', this.lastLoginEmail);
    });
  }

  /**
   * Login method, `master` name is historical
   * @todo change method name to just `login`
   * @param email Email the user has typed in
   * @param password Password the user has typed in
   */
  loginMaster = async (email: string, password: string): Promise<any> => {
    const fetchOptions = this.createLoginOptions(email, password);

    try {
      this.gtmManager.pushEvent('vpwebapp_load', {
        timestamp: Date.now(),
        label: 'vpwebapp_login_start',
        section: 'performance',
      });
      const response = await this.api.fetch(
        `${this.bffApiUrl}/auth/v4/token`,
        200,
        fetchOptions,
      );
      this.handleLoginSuccess(response);

      this.gtmManager.pushEvent('vpwebapp_load', {
        timestamp: Date.now(),
        label: 'vpwebapp_login_success',
        section: 'performance',
      });

      return response;
    } catch (err) {
      const invalidCredentialsMsg = this.translationsStore.translate(
        'global.error.invalid_credentials',
      );
      const generalErrorMsg = this.translationsStore.translate(
        'global.error.error_occurred',
      );
      const errorMsg =
        err.status === 401 ? invalidCredentialsMsg : generalErrorMsg;

      this.gtmManager.pushEvent('vpwebapp_load', {
        timestamp: Date.now(),
        label: 'vpwebapp_login_failed',
        section: 'performance',
      });

      return Promise.reject({
        response: {
          status: err.status,
          message: errorMsg,
        },
      });
    }
  };

  /**
   * This method is used for impersonation into Vendor Portal from Radmin using a keymaker token
   * @param keymakerToken string: keymaker token generated by a Radmin User to impersonate into Vendor Portal
   */
  loginByImpersonation = async (keymakerToken: string): Promise<void> => {
    if (this.lastImpersonationToken === keymakerToken) {
      return Promise.resolve();
    }

    try {
      this.lastImpersonationToken = keymakerToken;
      const response = await this.userStore.postExchangeToken(keymakerToken);
      // replacing accessTokenV2 in session with keymaker impersonation token
      response.accessTokenV2 = keymakerToken;
      // preparing the response with keymaker tokens so they can be stored in session
      response.keymaker_response = {};
      response.keymaker_response.access_token = keymakerToken;
      response.keymaker_response.refresh_token = '';
      response.keymaker_response.device_token = '';

      return this.handleLoginSuccess(response);
    } catch (err) {
      switch (err.status) {
        case 401:
          return Promise.reject(new ExpiredTokenError('Expired Token'));
        case 403:
          return Promise.reject(new InvalidTokenError('Bad Token'));
        default:
          return Promise.reject(err);
      }
    }
  };

  logout() {
    this.sessionStore.isDirty = false;
    this.sessionStore.clearSession();
    this.window.localStorage.removeItem('mode');
    this.window.localStorage.removeItem('isImpersonated');
  }

  getAuthToken() {
    const { tokenType, keymakerAccessToken } =
      this.sessionStore.mainSessionInfo;

    return {
      tokenType,
      accessToken: keymakerAccessToken,
    };
  }

  async refreshToken() {
    return this.refreshKeymakerToken();
  }

  /**
   * This method can be used to refresh Keymaker token using keymaker refresh and device tokens
   */
  refreshKeymakerToken() {
    // to return an existing keymaker refresh request
    if (this.keymakerRefreshTokenRequest) {
      return this.keymakerRefreshTokenRequest;
    }

    // If user is not logged in, send a fake 401 response, which redirects the user to the login page
    if (!this.sessionStore.isLoggedInUsingKeymaker) {
      this.sessionStore.clearSession();
      return Promise.reject({
        response: {
          code: 'INVALID_REFRESH_TOKEN',
        },
        status: 401,
      });
    }

    // If user has no refresh token, send a fake 401 response, which redirects the user to the login page
    if (!this.sessionStore.hasKeymakerRefreshToken) {
      this.sessionStore.clearSession();
      return Promise.reject({
        response: {
          code: 'INVALID_REFRESH_TOKEN',
        },
        status: 401,
      });
    }
    const mainSession = this.sessionStore.getMainSession();

    this.refreshStatusSubscribers.forEach((cb) => cb(true));

    const fetchPromise = this.api
      .fetch(
        `${this.bffApiUrl}/auth/v3/token:refresh`,
        200,
        {
          body: {
            refresh_token: mainSession.keymakerRefreshToken,
            device_token: mainSession.keymakerDeviceToken,
          },
          method: 'POST',
        },
        true,
      )
      .then(
        action(async (response) => {
          const legacyAuthResponse = await this.api.fetch(
            `${this.bffApiUrl}/auth/v3/token/global:exchange`,
            200,
            {
              method: 'POST',
              body: {
                token: response.access_token,
              },
            },
            true,
          );
          const sessionInfo = {
            ...legacyAuthResponse,
            keymakerAccessToken: response.access_token,
            keymakerRefreshToken: response.refresh_token,
            keymakerDeviceToken: mainSession.keymakerDeviceToken,
          };

          this.sessionStore.setSessionInfo(sessionInfo);
          // Inform all keymaker refresh status subscribers, that refresh finished (`false` => not running anymore)
          this.refreshStatusSubscribers.forEach((cb) => cb(false));
          this.keymakerRefreshTokenRequest = undefined;
          return response;
        }),
        (err) => {
          this.sessionStore.clearSession();
          this.keymakerRefreshTokenRequest = undefined;
          throw err;
        },
      );

    // Store the refresh promise, so it can be used when an additional refresh request comes in
    this.keymakerRefreshTokenRequest = fetchPromise;

    return fetchPromise;
  }

  /**
   * Send reset password request with a new password and a reset token
   * @param token Token that was sent to the user's email address
   * @param newPassword New password that the user typed in
   */
  async resetPassword(token: string, newPassword: string) {
    const fetchOptions = {
      body: {
        newPassword,
      },
      method: 'PUT',
    };

    const expectedResponseStatus = 200;

    const response = await this.api.fetch(
      `${this.authServiceUrl}/v3/master/password-reset/${token}`,
      expectedResponseStatus,
      fetchOptions,
    );

    return this.loginMaster(response.user.email, newPassword);
  }

  /**
   * Initiates the password reset flow
   * @param email Email the user has typed in the reset password form
   */
  requestMasterPasswordReset(email: string): Promise<void> {
    const url = `${this.authServiceUrl}/v2/master/password-reset`;
    const method = 'POST';
    const locale = this.languageStore.currentLanguage;
    const domain = this.window.location.hostname;
    const body = {
      email,
      locale,
      domain,
    };

    return this.api.fetch(url, 202, { method, body });
  }

  /**
   * Subscribe to changes in refresh requests. Takes a callback function that receives a boolean
   * value that describes if the refresh request is running or not
   * @param cb Function that is called when the refresh request is called and has ended.
   * The function receives a boolean value that indicates if the refresh is running or not.
   */
  subscribeToRefreshStatus(cb: (isRefreshing: boolean) => void): () => void {
    this.refreshStatusSubscribers.push(cb);
    return () => {
      this.refreshStatusSubscribers.splice(
        this.refreshStatusSubscribers.indexOf(cb),
        1,
      );
    };
  }

  async getHelpCenterUrl(_options?: HelpCenterOptions) {
    const locale = this.languageStore.currentLanguage;

    const localeForHelpCenter = `${
      locale.split('-')[0]
    }-${this.sessionStore.getUserData('country')}`;

    const fetchOptions = {
      header: {
        'Access-Control-Allow-Origin': '*',
      },
      body: {
        service_type: 'vendor_portal',
        guest: false,
        user_id: this.sessionStore.getUserData('userId'),
        email: this.sessionStore.getUserData('email'),
        name: this.sessionStore.getUserData('name'),
        global_entity_id: this.vendorStore.allVendors[0].globalEntityId,
        locale: localeForHelpCenter,
        app_version: 'dWeb',
        verification_token: await this.getHelpCenterVerificationToken(),
        bridge: true,
        brand: this.platformStore.currentPlatform.name,
        page_id: _options?.pageId,
        order_id: _options?.orderId,
      },
      method: 'POST',
    };

    const endpoint = `${this.helpCenterUrl}/conditions-api/v1/init`;

    const url = await this.apiV2.fetch(endpoint, 200, fetchOptions);
    return url;
  }

  async getHelpCenterChatMessagesCount() {
    const endpoint = `${this.helpCenterUrl}/chat-api/v1/unread-message-count`;
    const fetchOptions = {
      headers: {
        'x-helpcenter-gei': this.vendorStore.allVendors[0].globalEntityId,
        'x-service-type': 'vendor_portal',
        'x-device-type': 'dWeb',
      },
      method: 'GET',
    };
    const response = await this.api.fetch(endpoint, 200, fetchOptions);
    return response;
  }

  public async getHelpCenterVerificationToken(): Promise<string> {
    const { mainSessionInfo } = this.sessionStore;
    let useKeyMakerAccessToken: string | boolean = false;

    if (this.fwfStore.ready) {
      useKeyMakerAccessToken = await this.fwfStore.getVariationValue(
        USE_KEYMAKER_ACCESS_TOKEN,
        false,
      );
    }

    return useKeyMakerAccessToken
      ? mainSessionInfo.keymakerAccessToken
      : mainSessionInfo.accessToken;
  }

  /**
   * Method to call when the login has been successfully ran.
   * Sets session info in session store.
   * Is an arrow function to make it possible to pass it directly to `.then()`.
   * @param response response from the login or password reset endpoint
   */
  private handleLoginSuccess = (response: any) => {
    if (response.accessToken) {
      if (!this.hasVendors(response.accessTokenContent)) {
        return Promise.reject({
          response: {
            status: 500,
            message: this.translationsStore.translate(
              'global.login.error.no_restaurants_assigned',
            ),
          },
        });
      }
      const sessionInfo = {
        ...response,
        isForceResetPassword: response.force_reset_password,
        keymakerAccessToken: response.keymaker_response?.access_token,
        keymakerRefreshToken: response.keymaker_response?.refresh_token,
        keymakerDeviceToken: response.keymaker_response?.device_token,
      };

      this.sessionStore.setSessionInfo(sessionInfo);

      return response;
    }

    return response;
  };

  /**
   * Indicates if user has vendors by looking at the access token content
   * @param accessTokenContent JSON Content of the access token, includes vendors array
   */
  private hasVendors(accessTokenContent: IAccessTokenContent): boolean {
    const vendorsIds = accessTokenContent.vendors;
    return Object.keys(vendorsIds).length > 0;
  }

  /**
   * Create the login options and has side effect to set the lastLoginEmail with the passed email
   * @param email Email the user has typed in
   * @param password Password the user has typed in
   */
  private createLoginOptions(email: string, password: string) {
    const username = email.toLowerCase().trim();
    this.lastLoginEmail = email;
    return {
      body: { username, password },
      method: 'POST',
    };
  }
}
