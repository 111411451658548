import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';

export type StartPromptFooterProps = {
  phoneNumber?: string;
};

const StartPromptFooter: React.FC<StartPromptFooterProps> = ({ phoneNumber }) =>
  phoneNumber ? (
    <Box sx={{ fontSize: '14px' }}>
      <Divider />
      <Box sx={{ padding: '10px' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
          data-testid="start-prompt-bottom-bar-buttons-container"
        >
          <Button href={`tel:${phoneNumber}`} data-testid="bottom-bar-phone">
            {phoneNumber}
          </Button>
        </Box>
      </Box>
    </Box>
  ) : null;

export default StartPromptFooter;
