import { injectable, inject } from 'inversify';
import i18next from 'i18next';
import { TranslationsStore, replaceLocaleInUrl } from './TranslationsStore';

@injectable()
export default class PluginTranslationsStore {
  @inject(TranslationsStore) translationsStore: TranslationsStore;

  private translationUrl: string = '';
  private pluginNamespace: string = '';

  setTranslationForPlugin = async (
    pluginCode: string,
    translations: Record<string, string>,
    url: string = '',
  ): Promise<void> => {
    const lang = i18next.language;
    const fallbackLng = i18next.options.fallbackLng[0];
    const pluginNS = pluginCode.toLowerCase();

    if (url) {
      try {
        const mainUrl = replaceLocaleInUrl(url, lang);
        const fallbackUrl =
          lang !== fallbackLng ? replaceLocaleInUrl(url, fallbackLng) : '';
        const translationData = await Promise.allSettled([
          this.fetch(mainUrl),
          fallbackUrl && this.fetch(fallbackUrl),
        ]);

        if (translationData[0].status === 'fulfilled') {
          i18next.addResourceBundle(lang, pluginNS, translationData[0].value);
        }

        if (fallbackUrl && translationData[1].status === 'fulfilled') {
          i18next.addResourceBundle(
            fallbackLng,
            pluginNS,
            translationData[1].value,
          );
        }

        this.translationUrl = url;
        this.pluginNamespace = pluginNS;
      } catch (err) {
        throw new Error(`PluginTranslationsStore error: ${err}`);
      }
    }

    if (translations && Object.keys(translations).length !== 0) {
      i18next.addResourceBundle(lang, pluginNS, translations);
      this.pluginNamespace = pluginNS;
    }
  };

  translateForPlugin = (
    pluginCode: string,
    key: string,
    params?: Record<string, string | number>,
  ) => {
    const lang = i18next.language;
    const fallbackLng = i18next.options.fallbackLng[0];
    const pluginNS = pluginCode.toLowerCase();
    const isMainTranslationKey = i18next.exists(key, {
      ns: i18next.options.defaultNS,
    });

    if (isMainTranslationKey) {
      return this.translationsStore.translate(key, params);
    }

    const isPluginNS =
      this.pluginNamespace ||
      i18next.hasResourceBundle(lang, pluginNS) ||
      i18next.hasResourceBundle(fallbackLng, pluginNS);

    if (isPluginNS) {
      const hasKeyAsIs = i18next.exists(key, { ns: pluginNS });

      if (hasKeyAsIs) {
        return this.translationsStore.translate(
          `${pluginNS}:${key}`,
          params,
          key,
        );
      }

      const prefixedKey = key.startsWith(pluginNS)
        ? key.slice(pluginNS.length + 1)
        : key;

      return this.translationsStore.translate(
        `${pluginNS}:${prefixedKey}`,
        params,
        key,
      );
    }

    return this.translationsStore.translate(key, params);
  };

  fetch = async (url: string): Promise<any> => {
    try {
      const response = await fetch(url, { mode: 'cors' });

      return await response.json();
    } catch (error) {
      throw new Error(
        `While trying to fetch translations for plugin, an error occurred: ${error}`,
      );
    }
  };
}
