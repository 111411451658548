import * as React from 'react';

import { InfoItemsWrapper, InfoItem } from '../../common/components/InfoItem';
import { Translate } from '../../../../../components/Translate';
import { getStaticPath } from '../../../../../utils/PathHelper';

interface Props {}

export const InfoGroup: React.FC<Props> = () => (
  <InfoItemsWrapper>
    <InfoItem
      title={<Translate code="set_password.info.1.title" />}
      description={<Translate code="set_password.info.1.text" />}
      imagePath={getStaticPath('images/welcome-info-1.jpg')}
    />

    <InfoItem
      title={<Translate code="set_password.info.2.title" />}
      description={<Translate code="set_password.info.2.text" />}
      imagePath={getStaticPath('images/welcome-info-2.jpg')}
    />
  </InfoItemsWrapper>
);
