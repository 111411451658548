import * as React from 'react';
import { PlatformLogo } from '../../components/SquaredPartnerLogo';

import { styled as muiStyled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import TintedBackground from '../../components/TintedBackground';
import { getStaticPath } from '../../utils/PathHelper';

const LogoWrapper = muiStyled(Box)({
  margin: 'auto',
  paddingTop: '16px',
  display: 'flex',
  justifyContent: 'center',
  transform: 'translateY(-50%)',
});

const LogoImage = muiStyled(Box)({
  marginLeft: '-10px',
  '&:first-of-type': {
    marginLeft: '0',
    zIndex: 100,
  },
});

const LOGO_WIDTH = 64;

const BackgroundWrapper = muiStyled(Box)({
  background: '#1e91d6',
  position: 'absolute',
  width: '100%',
  height: '100%',
});

const Wrapper = muiStyled(Box)({
  background: '#fff',
  padding: '0 20px 32px 20px',
  boxShadow: '0px 0px 20px #000000ad',
  maxWidth: '400px',
  margin: 'auto',
  marginTop: '8%',
  borderRadius: '8px',
  boxSizing: 'border-box',
});

const HeaderLogos = ({ currentPlatform }) => {
  const platform = currentPlatform.name;
  return (
    <LogoWrapper>
      <LogoImage>
        <PlatformLogo
          width={LOGO_WIDTH}
          platform={platform}
          hyperlink="/dashboard"
        />
      </LogoImage>
    </LogoWrapper>
  );
};

const PasswordHandlingView = ({ children, currentPlatform }) => {
  return (
    <BackgroundWrapper>
      <TintedBackground image={getStaticPath('images/login-bg.jpg')}>
        <Wrapper>
          <HeaderLogos currentPlatform={currentPlatform} />
          {children}
        </Wrapper>
      </TintedBackground>
    </BackgroundWrapper>
  );
};

export default PasswordHandlingView;
