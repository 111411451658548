export const SIDEBAR_WIDTH = '260px';
export const COLOR_BACKGROUND = '#EDF0F7';
export const ENV_PROD = 'prd';

// fwf feature flag names for the portal shell
export const SIDEBAR_CATEGORISATION_FLAG = 'use-sidebar-categorisation';
export const HELP_CENTER_IFRAME = 'help-center-iframe';
export const SIDEBAR_CATEGORISATION_DISCOUNTS =
  'sidebar-categorisation-discounts';
export const NEW_NOTIFICATIONS_FLAG = 'use-new-notifications';
export const USE_NAVBAR_V3 = 'use-navbar-v3';
export const USE_ONBOARDING_TIMELINE_FLOW = 'use-onboarding-timeline-flow';
export const ONBOARDING_TIMELINE_PLUGIN_CODE = 'ONBOARDING_TIMELINE';
export const ONBOARDING_PLUGIN_CODE = 'ONBOARDING';
export const USE_KEYMAKER_ACCESS_TOKEN = 'use-keymaker-access-token';
export const DISABLE_IMPERSONATER_UMP = 'disable-impersonater-ump';
export const USE_ONE_WEB_BANNER = 'use-one-web-banner';
export const FORCE_REDIRECT_ONEWEB_AFTER_LOGIN =
  'force-redirect-oneweb-after-login';

export const RESET_PASSWORD_ERROR_MESSAGES = {
  FIELD_NOT_ENOUGH_CHARACTERS: 'login.set_password.error.not_enough_characters',
  FIELD_CANNOT_BE_EMPTY: 'login.set_password.error.empty',
  CURRENT_PASSWORD_NOT_CORRECT:
    'force password reset: current password not correct',
  CURRENT_AND_NEW_PASSWORD_CANNOT_BE_SAME:
    'current and new password cannot be the same',
  AN_ERROR_OCCURRED: 'global.error.error_occurred',
};
