import * as React from 'react';

import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Alert from '@mui/material/Alert';
import { Box, Icon, List, ListItem, Typography } from '@mui/material';

import { Translate } from '../../../../components/Translate';
import { Headline } from '../Content';
import { PasswordField } from '../../../../components/PasswordField';
import { ResetPasswordSubHeader } from '../Content';
import {
  PASSWORD_REQUIREMENT_BRAND_ALLOWLIST,
  PasswordFormErrors,
} from './PasswordContainer';

export type WelcomePasswordViewProps = {
  passwordCurrent?: string;
  password?: string;
  passwordRepeat?: string;
  onChange: Function;
  getError: (key: string) => any;
  onSubmit: (e: any) => void;
  onBackClick: () => void;
  currentPlatform: any;
  skipIntro?: boolean;
  isForceReset?: boolean;
  errorMessage: string;
  errors: PasswordFormErrors;
  isDirty: boolean;
};

type PasswordInputProps = {
  name: string;
  value: string;
  label: JSX.Element | string;
  onChange: (event: any) => void;
  error: any;
};

const PasswordInput: React.FC<PasswordInputProps> = ({
  label,
  name,
  value,
  onChange,
  error,
}) => (
  <FormControl
    sx={{
      marginTop: '8px',
    }}
    fullWidth={true}
  >
    <PasswordField
      name={name}
      label={label}
      value={value}
      error={!!error}
      onChange={onChange}
      data-testid={`reset-${name}-field`}
      fullWidth={true}
    />
    {error && (
      <Box>
        <FormHelperText
          data-testid={`${name}-error`}
          error={!!error}
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <Icon>Cancel</Icon> {error}
        </FormHelperText>
      </Box>
    )}
  </FormControl>
);

const PasswordView: React.FC<WelcomePasswordViewProps> = ({
  onChange,
  password,
  passwordRepeat,
  passwordCurrent,
  getError,
  onSubmit,
  onBackClick,
  currentPlatform,
  skipIntro,
  isForceReset,
  errorMessage,
  errors,
  isDirty,
}) => {
  return (
    <Box>
      <Headline isForceReset={isForceReset} />

      <ResetPasswordSubHeader
        platform={currentPlatform}
        isForceReset={isForceReset}
      />

      {isForceReset && errorMessage && (
        <Alert severity="error">
          <Translate code={errorMessage} />
        </Alert>
      )}

      <form id="password-form" onSubmit={onSubmit}>
        {isForceReset && (
          <PasswordInput
            name="currentPassword"
            label="Current password"
            value={passwordCurrent}
            onChange={onChange('passwordCurrent')}
            error={getError('passwordCurrent')}
          />
        )}

        <PasswordInput
          name="password"
          label={<Translate code="global.password_reset.password" />}
          value={password}
          onChange={onChange('password')}
          error={getError('password')}
        />

        <PasswordInput
          name="passwordRepeat"
          label={<Translate code="global.password_reset.password_repeat" />}
          value={passwordRepeat}
          onChange={onChange('passwordRepeat')}
          error={getError('passwordRepeat')}
        />
        <Button
          sx={{ marginTop: '16px' }}
          variant="contained"
          color="primary"
          data-testid="welcome-password-save-btn"
          type="submit"
          fullWidth={true}
        >
          <Translate
            code={
              isForceReset
                ? 'force_reset.password.primary_button'
                : 'global.auth.set_password.confirm'
            }
          />
        </Button>
      </form>
      {PASSWORD_REQUIREMENT_BRAND_ALLOWLIST.includes(currentPlatform.name) && (
        <Box mt="20px" data-testid="password-requirement">
          <Typography variant="secondary-500">
            <Translate code="global.auth.password_strength.info_title" />
          </Typography>
          <Box sx={{ ml: isDirty ? '0px' : '20px' }}>
            <List
              sx={{
                listStyleType: isDirty ? 'none' : 'disc',
              }}
            >
              <ListItem sx={{ display: 'list-item' }}>
                <Typography
                  variant="secondary-500"
                  color={
                    isDirty
                      ? errors.rules.isTooShort
                        ? '#E5434A'
                        : '#26AE5F'
                      : '#161616'
                  }
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  {isDirty &&
                    (errors.rules.isTooShort ? (
                      <Icon>Clear</Icon>
                    ) : (
                      <Icon>Done</Icon>
                    ))}
                  <Translate code="global.auth.password_strength.min_length_8_chars" />
                </Typography>
              </ListItem>
              <ListItem sx={{ display: 'list-item' }}>
                <Typography
                  variant="secondary-500"
                  color={
                    isDirty
                      ? errors.rules.hasMissingUppercaseLetter
                        ? '#E5434A'
                        : '#26AE5F'
                      : '#161616'
                  }
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  {isDirty &&
                    (errors.rules.hasMissingUppercaseLetter ? (
                      <Icon>Clear</Icon>
                    ) : (
                      <Icon>Done</Icon>
                    ))}
                  <Translate code="global.auth.password_strength.atleast_1_uppercase_letter" />
                </Typography>
              </ListItem>
              <ListItem sx={{ display: 'list-item' }}>
                <Typography
                  variant="secondary-500"
                  color={
                    isDirty
                      ? errors.rules.hasMissingDigit
                        ? '#E5434A'
                        : '#26AE5F'
                      : '#161616'
                  }
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  {isDirty &&
                    (errors.rules.hasMissingDigit ? (
                      <Icon>Clear</Icon>
                    ) : (
                      <Icon>Done</Icon>
                    ))}
                  <Translate code="global.auth.password_strength.atleast_1_number" />
                </Typography>
              </ListItem>
              <ListItem sx={{ display: 'list-item' }}>
                <Typography
                  variant="secondary-500"
                  color={
                    isDirty
                      ? errors.rules.hasMissingSpecialCharacter
                        ? '#E5434A'
                        : '#26AE5F'
                      : '#161616'
                  }
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  {isDirty &&
                    (errors.rules.hasMissingSpecialCharacter ? (
                      <Icon>Clear</Icon>
                    ) : (
                      <Icon>Done</Icon>
                    ))}
                  <Translate code="global.auth.password_strength.atleast_1_special_char" />
                </Typography>
              </ListItem>
              <ListItem sx={{ display: 'list-item' }}>
                <Typography
                  variant="secondary-500"
                  color={
                    isDirty
                      ? errors.rules.hasWhitespaceCharacters
                        ? '#E5434A'
                        : '#26AE5F'
                      : '#161616'
                  }
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  {isDirty &&
                    (errors.rules.hasWhitespaceCharacters ? (
                      <Icon>Clear</Icon>
                    ) : (
                      <Icon>Done</Icon>
                    ))}
                  <Translate code="global.auth.password_strength.space_not_allowed" />
                </Typography>
              </ListItem>
            </List>
          </Box>
        </Box>
      )}
      {!skipIntro && (
        <Button
          sx={{ marginTop: '16px' }}
          color="primary"
          onClick={onBackClick}
          data-testid="welcome-password-back-btn"
        >
          <Translate code="global.back" />
        </Button>
      )}
    </Box>
  );
};

export default PasswordView;
