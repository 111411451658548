import { injectable, inject } from 'inversify';
import { ENV_PROD } from '../constants';
import { IConfig } from '../config';
import { TYPES } from '../types';
import { SessionStore } from './SessionStore';
import { observable } from 'mobx';

const STG_COUNTRY = ['se', 'sa', 'sg', 'de'];

@injectable()
export class NotificationsStore {
  @observable isLoading: boolean = false;
  @observable unreadMessages: number = 0;

  @inject(TYPES.SessionStore) private sessionStore: SessionStore;
  @inject('config') private config: IConfig;

  async getUnreadMessages() {
    try {
      this.setLoading();

      const url = this.getUrl();

      if (url) {
        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${this.getToken()}`,
          },
        });
        const data = await response.json();

        this.unreadMessages = data.unreadMessages ?? 0;
      }
    } catch (e) {
      // eslint-disable-next-line
      console.error('Could not get number of unread messages');
    } finally {
      this.unsetIsLoading();
    }
  }

  private getToken = () => this.sessionStore.mainSessionInfo?.accessToken;

  private setLoading() {
    this.isLoading = true;
  }

  private unsetIsLoading() {
    this.isLoading = false;
  }

  private getUrl() {
    const country = this.sessionStore.getUserData('country');

    if (typeof country === 'string') {
      const lowerCaseCountry = country.toLowerCase();

      if (
        this.config.env !== ENV_PROD &&
        !STG_COUNTRY.includes(lowerCaseCountry)
      ) {
        return null;
      }

      const baseUrl =
        this.config.env === ENV_PROD
          ? `https://${lowerCaseCountry}.usehurrier.com`
          : `https://${lowerCaseCountry}-st.usehurrier.com`;

      return `${baseUrl}/api/tweety-inbox/v1/inbox/summary`;
    }

    return null;
  }
}
