import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

import TintedBackground from './../TintedBackground';

import { PlatformLogo } from '../SquaredPartnerLogo';
import ActionBar from './components/ActionBar';
import { IAvailableLanguage, IPlatform } from '../../config';
import { LanguageStore } from '../../stores/LanguageStore';
import StartPromptFooter from './components/StartPromptFooter';
import { getStaticPath } from '../../utils/PathHelper';

const LOGO_WIDTH = 64;

const PlatformLogoWithShadow = (props) => {
  return (
    <Box
      sx={{
        display: 'flex',
      }}
    >
      <PlatformLogo {...props} hyperlink="/dashboard" />
    </Box>
  );
};

const HeaderLogos = ({ currentPlatform }) => {
  const platform = currentPlatform.name;
  return (
    <Box
      sx={{
        margin: '-56px auto 24px',
        paddingTop: '16px',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          marginLeft: '-10px',
          '&:first-of-type': {
            marginLeft: '0',
            zIndex: 100,
          },
        }}
      >
        <PlatformLogoWithShadow width={LOGO_WIDTH} platform={platform} />
      </Box>
    </Box>
  );
};

export type StartPromptInnerProps = {
  languageStore: LanguageStore;
  availableLanguages: IAvailableLanguage[];
  platform: IPlatform;
  RegistrationView?: React.ReactNode;
};

// TODO: Remove these 2 variables below after PeYa banner is removed:
const host = window?.location?.host;
const isPeya = host?.includes('pedidosya.portal.restaurant');

export default class StartPrompt extends React.Component<
  StartPromptInnerProps,
  any
> {
  render() {
    const { platform, RegistrationView, children } = this.props;

    const languages = this.props.availableLanguages;
    const currentLanguage = this.props.languageStore.currentLanguage;
    const currentLanguageIndex = languages.findIndex(
      (lang) => lang.value === currentLanguage,
    );

    return (
      <div>
        <Box
          sx={{
            position: 'fixed',
            left: '0',
            top: '0',
            width: '100%',
            height: '100%',
            zIndex: 10,
          }}
        >
          <Box
            sx={{
              position: 'relative',
              width: '100vw',
              height: '100vh',
            }}
          >
            <TintedBackground image={getStaticPath('images/auth-bg.jpg')} />
          </Box>
        </Box>
        <Box
          sx={{
            position: 'relative',
            zIndex: 20,
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
          }}
        >
          <ActionBar
            languages={languages}
            currentLanguage={currentLanguageIndex}
            onLanguageChange={this.changeLanguage}
          />
          <Box
            sx={{
              flexGrow: 1,
              paddingTop: '32px',
              paddingBottom: '40px',
              marginLeft: '20px',
              marginRight: '20px',
            }}
          >
            <Paper
              sx={{
                margin: '4px auto',
                padding: '10px 20px 10px',
                maxWidth: '320px',
                borderRadius: '12px',
                boxShadow: '0px 2px 16px 0px rgba(22, 22, 22, 0.12)',
              }}
              elevation={24}
            >
              <HeaderLogos currentPlatform={platform} />
              <Box
                sx={{
                  paddingBottom: '8px',
                }}
              >
                {children}
              </Box>
              <StartPromptFooter phoneNumber={platform.phoneNumber} />
            </Paper>

            {RegistrationView}

            {/*
              TODO: Check with Peya when this can be deleted
            */}
            {/* eslint max-len: ["error", { "ignoreStrings": true, }] */}
            {isPeya && (
              <div style={{ textAlign: 'center', marginTop: '24px' }}>
                <a
                  id="peya-banner-link"
                  data-testid="peya-banner-link"
                  href="https://centrodesocios.pedidosya.com/articles/todo-sobre-partner-portal/agregar-fotos.html?utm_source=vendorportal&utm_medium=bannerlogin&utm_campaign=picsenero"
                  target="_blank"
                >
                  <img
                    style={{
                      width: '530px',
                      maxWidth: '100%',
                    }}
                    src="https://s3.amazonaws.com/peya.pcomm/120522-mas-fotos-mas-ventas-banner.gif"
                    alt=""
                  />
                </a>
              </div>
            )}
          </Box>
        </Box>
      </div>
    );
  }

  private changeLanguage = (index: number): void => {
    const newLanguage = this.props.availableLanguages.map((lang) => lang.value)[
      index
    ];

    this.props.languageStore.setCurrentLanguage(newLanguage);
  };
}
