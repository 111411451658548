import { withDependencies } from '@deliveryhero/react-inversify-provider';
import { withRouter } from 'react-router-dom';
import { PluginStore } from '../../stores/PluginStore';
import OnboardingContainer from './OnboardingPluginContainer';
import FwfStore from '../../stores/FwfStore';
import { PlatformStore } from '../../stores/PlatformStore';
import { TYPES } from '../../types';
import { NavStore } from '../../stores/NavStore';
import { observer } from 'mobx-react';

const OnboardingPluginContainer = withDependencies({
  pluginStore: PluginStore,
  fwfStore: FwfStore,
  platformStore: PlatformStore,
  userStore: TYPES.UserStore,
  navStore: NavStore,
  sessionStore: TYPES.SessionStore,
})(withRouter<any, typeof OnboardingContainer>(observer(OnboardingContainer)));

export default OnboardingPluginContainer;
