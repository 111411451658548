import {
  getMessageColor,
  getNeutralColor,
  getSpacing,
  getWhiteColor,
} from '@deliveryhero/vt-portal-chardonnay/cjs/core/helpers';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { forBreakpoint } from '../../utils/forBreakpoint';

export const Wrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${getSpacing('spacing-20')};
  align-items: center;
  border-bottom: 1px solid ${getNeutralColor('gray-20')};
  position: fixed;
  top: 0;
  width: 100%;
  background: ${getWhiteColor()};
  overflow: hidden;
  z-index: 9;
  box-sizing: border-box;

  ${forBreakpoint('sm')} {
    padding: ${getSpacing('spacing-24')} ${getSpacing('spacing-40')};
  }
`;

export const RightWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ProfileIconWrapper = styled(Box)`
  width: 27px;
  height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${getMessageColor('main', 'information')};
  color: white;
`;

export const ProfileIconOuterWrapper = styled(Box)`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33px;
  height: 33px;
  border-radius: 50%;
  border: 1px solid ${getNeutralColor('gray-10')};
`;

export const NavElementMargin = styled(Box)`
  margin-right: ${getSpacing('spacing-12')};
`;

export const IconDivider = styled(Box)`
  width: 1px;
  height: 16px;
  border-right: 1px solid ${getNeutralColor('gray-20')};
`;
