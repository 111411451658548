import { computed, observable } from 'mobx';
import FWFMain from '@deliveryhero/fwf-sdk-javascript';

export interface IPlugin {
  name: string;
  displayName: string;
  code: string;
  type: string;
  fallbackName?: string;
  endpoint?: string;
  options?: any;
  bundleUrl?: string;
  route?: string;
  fwfClient?: FWFMain;
  fallbackUrl?: string;
  groupCode?: string;
  version?: string;

  // NEW!!! new attributes from navbar-v3
  groupKey?: string;
  iconKey?: string;
}

export class Plugin implements IPlugin {
  @observable name: string;
  @observable displayName: string;
  @observable fallbackName: string;
  @observable code: string;
  @observable type: string;
  @observable route: string;
  @observable endpoint?: string;
  @observable options?: any;
  @observable bundleUrl?: string;
  @observable fwfClient?: FWFMain;
  @observable activeFallback?: boolean;
  @observable fallbackUrl?: string;
  @observable groupCode?: string;
  @observable version?: string;

  // NEW!!! new observables from navbar-v3
  @observable groupKey?: string;
  @observable iconKey?: string;

  constructor(props) {
    this.name = props.name;
    this.displayName = props.display_name;
    this.fallbackName = props.fallback_name;
    this.code = props.code || props.plugin_code;
    this.type = props.type;
    this.endpoint = props.endpoint;
    this.options = props.options;
    this.bundleUrl = props.bundleUrl || props.bundle_url;
    this.route = props.route;
    this.fwfClient = props.fwfClient;
    this.activeFallback = props.activeFallback;
    this.fallbackUrl = props.options?.fallbackUrl || props.fallback_url;
    this.groupCode = props.groupCode || props.group_code;
    this.version = props.version;

    // NEW!!! new prop from navbar-v3
    // group / category for plugins
    this.groupKey = props.group_key || 'global.menu.category.manage';

    // icon-key assigned from plugin config
    this.iconKey = props.icon_key || '';
  }

  /**
   * Tells if plugin is displayable (has bundle URL and a route configured)
   */
  @computed get isFrontendPlugin(): boolean {
    return !!this.bundleUrl && !!this.route;
  }
}
