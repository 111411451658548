import { withDependencies } from '@deliveryhero/react-inversify-provider';
import PasswordHandlingContainer from './PasswordHandlingContainer';
import { PlatformStore } from '../../stores/PlatformStore';
import { NavStore } from '../../stores/NavStore';
import GtmManager from '../../utils/gtm/GtmManager';
import { TYPES } from '../../types';

const PasswordContainer = withDependencies({
  platformStore: PlatformStore,
  navStore: NavStore,
  gtmManager: GtmManager,
  sessionStore: TYPES.SessionStore,
})(PasswordHandlingContainer);

export default PasswordContainer;
