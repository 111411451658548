module.exports = [
  {
    name: 'Google Chrome',
    identifier: 'Chrome',
    minimumSupportedVersion: 63,
  },
  {
    name: 'Mozilla Firefox',
    identifier: 'Firefox',
    minimumSupportedVersion: 69,
  },
  {
    name: 'Safari',
    identifier: 'Safari',
    minimumSupportedVersion: 13,
  },
  {
    name: 'Microsoft Edge',
    identifier: 'Edge',
    minimumSupportedVersion: 79,
  },
  {
    name: 'Opera Mini',
    identifier: 'Opera',
    minimumSupportedVersion: 50,
  },
  {
    name: 'Mobile Safari',
    identifier: 'Mobile Safari',
    minimumSupportedVersion: 13.3,
  },
];
