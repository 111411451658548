import { TYPES, DatadogSDK } from '../types';
import { inject, injectable, postConstruct } from 'inversify';
import {
  LoggerContext,
  LoggerContextValue,
} from '@deliveryhero/vendor-portal-sdk';
import { Logger } from './Logger';
import { IConfig } from '../config';
import { HandlerType } from '@datadog/browser-logs';

export type CreateLoggerOptions = {
  pluginCode?: string;
  context?: LoggerContext;
};

@injectable()
export class LoggerService {
  @inject(TYPES.Datadog) private datadogLogs: DatadogSDK;
  @inject('config') private config: IConfig;
  private loggers: Map<string, Logger> = new Map();

  @postConstruct() init() {
    this.createLogger('PORTAL_GLOBAL');
  }

  /**
   * Create logger with specific name (e.g. plugin code)
   * @param name name of the logger
   * @param options logger options (plugin code and default context)
   */
  createLogger(name: string, options: CreateLoggerOptions = {}) {
    if (this.loggers.has(name)) {
      return this.loggers.get(name);
    }

    const { pluginCode, context = {} } = options;
    const logger = new Logger(
      name,
      this.datadogLogs.createLogger(name, {
        level: this.config.datadog.level,
        handler: HandlerType.http,
        context: {
          ...context,
          plugin: pluginCode?.toLowerCase(),
        },
      }),
    );
    this.loggers.set(name, logger);

    return logger;
  }

  getGlobalLogger() {
    return this.loggers.get('PORTAL_GLOBAL');
  }

  debug(message: string, context?: LoggerContext) {
    this.getGlobalLogger().debug(message, context);
  }
  error(message: string, context?: LoggerContext) {
    this.getGlobalLogger().error(message, context);
  }
  info(message: string, context?: LoggerContext) {
    this.getGlobalLogger().info(message, context);
  }
  log(message: string, context?: LoggerContext) {
    this.getGlobalLogger().log(message, context);
  }
  warn(message: string, context?: LoggerContext) {
    this.getGlobalLogger().warn(message, context);
  }

  addContext(key: string, value: LoggerContextValue) {
    this.getGlobalLogger().addContext(key, value);
  }
}
