import * as React from 'react';
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { History, Location } from 'history';
import { SdkProvider } from '@deliveryhero/vendor-portal-sdk';

import Box from '@mui/material/Box';
import { CenteredCircularProgress } from '@deliveryhero/vt-portal-chardonnay/cjs/components';
import { useIsMobile } from '@deliveryhero/vt-portal-chardonnay/cjs/core/helpers';
import ServerError from '../../components/errors/ServerError';
import { SessionStore } from '../../stores/SessionStore';
import { PlatformStore } from '../../stores/PlatformStore';
import PortalSDKStore from '../../stores/PortalSDKStore';
import GtmManager from '../../utils/gtm/GtmManager';
import { NavStore } from '../../stores/NavStore';
import { PluginStore } from '../../stores/PluginStore';
import { NotificationsStore } from '../../stores/NotificationsStore';
import { LocaleStore } from '../../stores/LocaleStore';
import { MobileDrawer } from './MobileDrawer';
import { IPlugin } from '../../models/Plugin';
import { IAvailableLanguage } from '../../config';
import { VendorStore } from '../../stores/VendorStore';
import { UserStore } from '../../stores/UserStore';
import { ContactInfo } from '../../components/ContactInfo';
import { ApiStatus } from '../../models/ApiStatus';
import SideBarV2 from './SidebarV2';
import FwfStore from '../../stores/FwfStore';
import HelpCenterDefault from '../HelpCenter';
import { ContentContainer } from './Container.styles';
import { isMobileWebView } from '../../utils/isMobileWebView';

import {
  SIDEBAR_CATEGORISATION_FLAG,
  HELP_CENTER_IFRAME,
  SIDEBAR_CATEGORISATION_DISCOUNTS,
  USE_NAVBAR_V3,
} from '../../constants';
import Navbar from '../Navbar';
import useQuery from './useQuery';
import { HelpStore } from '../../stores/HelpStore';

export type MasterContainerProps = {
  sessionStore: SessionStore;
  platformStore: PlatformStore;
  vendorStore: VendorStore;
  userStore: UserStore;
  children?: any;
  currentLanguage: string;
  history: History;
  location: Location<{ menuOpen: boolean }>;
  switchLanguage: () => void;
  portalSdkStore: PortalSDKStore;
  gtmManager: GtmManager;
  navStore: NavStore;
  pluginStore: PluginStore;
  availableLanguages: IAvailableLanguage[];
  fwfStore: FwfStore;
  HelpCenter?: React.ComponentType<any>;
  notificationsStore: NotificationsStore;
  localeStore: LocaleStore;
  helpStore: HelpStore;
};

const mapPluginToNavItem = (plugin: IPlugin) => {
  const code = plugin.code.toLowerCase();

  return {
    ...plugin,
    name: code,
    label: plugin.displayName || `global.menu.${code}`,
    path: plugin.route,
  };
};

const MasterContainerComponent: React.FC<MasterContainerProps> = ({
  sessionStore,
  platformStore,
  vendorStore,
  userStore,
  children,
  history,
  location,
  portalSdkStore,
  gtmManager,
  pluginStore,
  fwfStore,
  HelpCenter = HelpCenterDefault,
  notificationsStore,
  helpStore,
}) => {
  const [isSidebarCategorisation, setIsSidebarCategorisation] =
    useState<boolean>(false);
  const [sidebarCategorisationDiscounts, setSidebarCategorisationDiscounts] =
    useState<boolean>(false);

  const [showHelpCenter, setShowHelpCenter] = useState<boolean>(false);

  const [contactInfoDialogOpen, setContactInfoDialogOpen] = useState(false);

  const isMobile = useIsMobile();

  const query = useQuery();

  const platform = platformStore.currentPlatform.name;

  useEffect(() => {
    if (fwfStore.ready) {
      const checkNonMobileFlags = async () => {
        setIsSidebarCategorisation(
          fwfStore.featureFlags.get(SIDEBAR_CATEGORISATION_FLAG),
        );
        setShowHelpCenter(fwfStore.featureFlags.get(HELP_CENTER_IFRAME));
        setSidebarCategorisationDiscounts(
          fwfStore.featureFlags.get(SIDEBAR_CATEGORISATION_DISCOUNTS),
        );
      };
      if (!isMobileWebView()) {
        checkNonMobileFlags();
      }
    }
  }, [fwfStore.ready]);

  useEffect(() => {
    const pageId = query.get('helpcenter-page-id');
    const orderId = query.get('helpcenter-order-id');

    if (pageId || orderId) {
      gtmManager.pushEvent('deeplink_triggered', {
        eventTrigger: 'external_source',
        location: location.pathname,
        query: { pageId, orderId },
        label: 'help_center_deeplink',
      });
      helpStore.toggleHelpCenter(true, { pageId, orderId });
    }
  }, []);

  const onContactInfoClick = () => {
    setContactInfoDialogOpen(true);
  };

  if (userStore.userProfileApiStatus === ApiStatus.ERROR) {
    return <ServerError />;
  }

  if (
    userStore.userProfileApiStatus === ApiStatus.LOADING ||
    pluginStore.plugins.length === 0 ||
    vendorStore.vendors.size === 0
  ) {
    return <CenteredCircularProgress />;
  }

  const sdk = portalSdkStore.getSdkForMaster();

  if (!sdk) {
    return null;
  }

  const renderMobileMenu = () => {
    const isMenuOpen = () => {
      return location.state?.menuOpen;
    };

    const closeMenu = () => {
      history.goBack();
    };

    return (
      <>
        <MobileDrawer
          platform={platform}
          open={isMenuOpen()}
          onClose={closeMenu}
          menuItems={getMenuItemsV2()}
          onContactInfoClick={onContactInfoClick}
          userName={sessionStore.getUserData('name')}
          notificationsStore={notificationsStore}
          fwfStore={fwfStore}
        />
      </>
    );
  };

  const transformFn = (prev, curr) => {
    if (isSidebarCategorisation && curr.code === 'GROWTH_CENTRE') {
      curr.label = 'sidebar_categorisation.growth_center';
    }
    if (sidebarCategorisationDiscounts) {
      if (curr.code === 'ITEM_LEVEL_DISCOUNTS_PY') {
        curr.label = 'sidebar_categorisation_py.item_level_discounts_py';
      }
      if (curr.code === 'VENDOR_DEALS') {
        curr.label = 'sidebar_categorisation_py.vendor_deals';
      }
      if (curr.code === 'DISCOUNTS_PY') {
        curr.label = 'sidebar_categorisation_py.discounts_py';
      }
    }

    const groupCode = curr.groupCode;
    if (
      (isSidebarCategorisation &&
        groupCode === 'global.menu.group.marketing') ||
      (sidebarCategorisationDiscounts &&
        groupCode === 'global.menu.group.discounts')
    ) {
      // eslint-disable-next-line no-unused-expressions
      prev[groupCode] ? prev[groupCode].push(curr) : (prev[groupCode] = [curr]);
      return prev;
    } else {
      prev[curr.name] = curr;
      return prev;
    }
  };

  const getMenuItemsV2 = () => {
    const { shown: showOnboarding, required: redirectToOnboarding } =
      userStore.userProfile?.onboarding_info || {};

    let eligiblePlugins = pluginStore.menuPlugins;
    if (isSidebarCategorisation) {
      eligiblePlugins = eligiblePlugins.filter(
        (plugin) => plugin.code !== 'GROWTH_CENTRE',
      );

      const vendorDeals = pluginStore.plugins.find(
        (p) => p.code === 'VENDOR_DEALS' && p.type.toUpperCase() === 'UNLISTED',
      );
      const midas = pluginStore.plugins.find(
        (p) =>
          p.code === 'MIDAS' &&
          p.type.toUpperCase() === 'UNLISTED' &&
          p.groupCode,
      );
      // show some plugins only in subcategories and not otherwise
      if (vendorDeals) {
        const vendorDealsIndex = pluginStore.plugins.findIndex(
          (p) => p.code === 'VENDOR_DEALS',
        );
        eligiblePlugins.splice(vendorDealsIndex, 0, vendorDeals);
      }
      if (midas) {
        const midasIndex = pluginStore.plugins.findIndex(
          (p) => p.code === 'MIDAS',
        );
        eligiblePlugins.splice(midasIndex, 0, midas);
      }
    }

    let pluginsList = eligiblePlugins
      .map(mapPluginToNavItem)
      .map((p) => ({ ...p, path: `${p.path}` }));

    if (isSidebarCategorisation || sidebarCategorisationDiscounts) {
      pluginsList = pluginsList.reduce(transformFn, {});
      pluginsList = Object.values(pluginsList);
    }

    // Filter out if Onboarding plugin is not needed
    pluginsList = showOnboarding
      ? pluginsList
      : pluginsList.filter((plugin) => plugin.name !== 'onboarding');
    const onboardingPlugin = pluginsList.find(
      (plugins) => plugins.name === 'onboarding',
    );
    const mountOnlyOnboarding = redirectToOnboarding && onboardingPlugin;
    // Mount only onboarding plugin if is is required
    return mountOnlyOnboarding ? [onboardingPlugin] : pluginsList;
  };

  const getDesktopSideBar = () => {
    const useNavbarV3 = fwfStore.featureFlags.get(USE_NAVBAR_V3);
    if (useNavbarV3) {
      return <Navbar onContactInfoClick={onContactInfoClick} />;
    }
    return (
      <SideBarV2
        platform={platform}
        menuItems={getMenuItemsV2()}
        onContactInfoClick={onContactInfoClick}
        notificationsStore={notificationsStore}
      />
    );
  };

  const renderContent = () => (
    <ContentContainer data-testid={'content-container-new'} isMobile={isMobile}>
      <ContactInfo
        open={contactInfoDialogOpen}
        setOpen={setContactInfoDialogOpen}
        userStore={userStore}
      />
      {children}
    </ContentContainer>
  );

  const renderSidebar = () => {
    if (isMobile) {
      return renderMobileMenu();
    } else {
      return getDesktopSideBar();
    }
  };
  const shouldShowSideBar = () =>
    !isMobileWebView() &&
    fwfStore.ready &&
    isSidebarCategorisation !== undefined &&
    Boolean(pluginStore.menuPlugins.length);

  return (
    <SdkProvider sdk={sdk}>
      <Box id={'sideBarV2'} display={{ xs: 'block', md: 'flex' }}>
        {shouldShowSideBar() && renderSidebar()}
        {renderContent()}
        {showHelpCenter && !isMobileWebView() && <HelpCenter />}
      </Box>
    </SdkProvider>
  );
};

const MasterContainer = observer(MasterContainerComponent);

export default MasterContainer;
