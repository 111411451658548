import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import { Datacenter, datadogLogs } from '@datadog/browser-logs';
import { addLocaleData } from 'react-intl';
import * as en from 'react-intl/locale-data/en';
import * as de from 'react-intl/locale-data/de';
import { reaction, when } from 'mobx';
import i18next from 'i18next';

import { container } from './dependencies';
import { SessionStore } from './stores/SessionStore';
import { PluginStore } from './stores/PluginStore';
import { PlatformStore } from './stores/PlatformStore';
import VersionUpdateHandler from './utils/VersionUpdateHandler';
import GtmManager from './utils/gtm/GtmManager';
import GtmVirtualPageViewListener from './utils/gtm/GtmVirtualPageViewListener';
import GtmLoginReturnedEvent from './utils/gtm/GtmLoginReturnedEvent';
import { QueryStringParser } from './utils/QueryStringParser';
import { IConfig } from './config';
import FwfStore from './stores/FwfStore';
import { VendorStore } from './stores/VendorStore';
import { TYPES } from './types';
import { ApiStore } from './stores/ApiStore';
import { BrazeManager } from './utils/BrazeManager';
import { loadQualtrics } from './utils/loadQualtrics';
import QualtricsManager from './utils/QualtricsManager';
import { TranslationsStore } from './stores/TranslationsStore';
import { initializeI18Next } from './i18n';
import { AvailableLanguagesStore } from './stores/AvailableLanguagesStore';
import { UserStore } from './stores/UserStore';
import { ApiStatus } from './models/ApiStatus';

export const init = async () => {
  const config = container.get<IConfig>('config');
  const brazeManager = container.get<BrazeManager>(TYPES.BrazeManager);
  const window = container.get<Window>('window');
  const availableLangStore = container.get<AvailableLanguagesStore>(
    AvailableLanguagesStore,
  );
  const translationStore = container.get<TranslationsStore>(TranslationsStore);

  await availableLangStore.fetchLanguageList();

  await initializeI18Next();
  await translationStore.fetchTranslationsForLocale(i18next.language);

  datadogLogs.init({
    clientToken: config.datadog.clientToken,
    datacenter: Datacenter.EU,
    forwardErrorsToLogs: true,
    sampleRate: 100,
  });

  datadogLogs.setLoggerGlobalContext({
    environment: config.env,
    region: config.region,
  });

  Sentry.init({
    environment: `${config.region}-${config.env}`,
    dsn: `https://${config.sentry.key}@sentry.telemerty.infra.euw.dh-vt-prd.net/${config.sentry.project}`,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: config.sentry.tracesSampleRate,
  });

  const platformStore = container.get<PlatformStore>(PlatformStore);
  const currentDomain = window.location.hostname;
  const urlParams = QueryStringParser.parse(window.location.search);
  const platformQueryParam = urlParams.platform;

  if (platformQueryParam) {
    platformStore.setPlatformByName(platformQueryParam);
  } else {
    platformStore.setPlatformByDomain(currentDomain);
  }

  const qualtricsProjectId =
    platformStore.currentPlatform?.qualtrics?.projectId;

  // // Add HTML <title />
  // // const currentPluginText =
  // //   portalSdkStore.currentPlugin !== null
  // //     ? t(`${portalSdkStore.currentPlugin.displayName}`)
  // //     : '';
  // const HtmlTitleFromConfig =
  //   platformStore.currentPlatform?.titlePrefix + ' | ' + '';
  // console.log(HtmlTitleFromConfig);
  // const HtmlTitleTag = document.createElement('title');
  // // const HtmlTitleText = document.createTextNode();

  // Add HTML Favicon
  const brandFavicon = platformStore.currentPlatform?.brandFavicon;
  const FaviconLinkTag = document.createElement('Link');
  FaviconLinkTag.setAttribute('rel', 'icon');
  FaviconLinkTag.setAttribute('type', 'image/png');
  FaviconLinkTag.setAttribute('href', `static/favicons/${brandFavicon}.png`);
  document.head.appendChild(FaviconLinkTag);

  if (qualtricsProjectId) {
    loadQualtrics(qualtricsProjectId);

    const qualtricsManager = container.get<QualtricsManager>(
      TYPES.QualtricsManager,
    );
    qualtricsManager.initSurvey();
  }

  const gtmManager = container.get<GtmManager>(GtmManager);
  container.get<GtmVirtualPageViewListener>(GtmVirtualPageViewListener);
  const loginReturnedEvent = container.get<GtmLoginReturnedEvent>(
    GtmLoginReturnedEvent,
  );
  const versionUpdateHandler =
    container.get<VersionUpdateHandler>(VersionUpdateHandler);
  versionUpdateHandler.init();

  addLocaleData([...en, ...de]);

  // Just initialise ApiStore to attach expiration handling
  container.get<ApiStore>(ApiStore);
  const vendorStore = container.get<VendorStore>(TYPES.VendorStore);

  // Just initialise ApiStore to attach expiration handling
  container.get<ApiStore>(ApiStore);
  const sessionStore = container.get<SessionStore>(TYPES.SessionStore);

  // Update current restaurant and available restaurants when jwt changes
  const fwfStore = container.get<FwfStore>(FwfStore);

  const userStore = container.get<UserStore>(TYPES.UserStore);
  userStore.init();

  // NOTE: clear vendorStore when user logs out
  reaction(
    () => ({ mainSession: sessionStore.getMainSession() }),
    ({ mainSession }) => {
      if (!mainSession) {
        vendorStore.clearVendors();
      }
    },
    { fireImmediately: true },
  );

  // NOTE: initialize fwfStore only when userProfile is initialized
  reaction(
    () => ({ userProfileApiStatus: userStore.userProfileApiStatus }),
    ({ userProfileApiStatus }) => {
      if (userProfileApiStatus === ApiStatus.SUCCESS) {
        fwfStore.init();
      }
    },
    { fireImmediately: true },
  );

  if (sessionStore.isLoggedIn) {
    when(
      () => vendorStore.isVendorAvailable,
      () => loginReturnedEvent.pushEvent(),
    );
  }

  when(
    () => !!vendorStore.allVendors.length,
    () => {
      const platform = vendorStore.allVendors[0].globalEntityId;
      if (config.brazeConfig[platform]?.apiKey) {
        brazeManager.init({
          apiKey: config.brazeConfig[platform].apiKey,
          baseUrl: config.brazeConfig[platform].baseUrl,
        });
      } else {
        brazeManager.initMock();
      }
    },
  );

  const pluginStore = container.get<PluginStore>(PluginStore);

  pluginStore.init();

  const performanceInMs = container.get<any>('window').performance.now();

  gtmManager.pushEvent('app.initTime', {
    eventValue: Math.round(performanceInMs),
  });
};
