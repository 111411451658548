import * as React from 'react';
import { withDependencies } from '@deliveryhero/react-inversify-provider';
import { TranslationsStore } from '../../stores/TranslationsStore';
import ErrorMessage from './ErrorMessage';

export interface ServerErrorProps {
  title?: string;
  message?: string;
  centered?: boolean;
}

const ServerError: React.FC<ServerErrorProps> = ({
  title,
  message,
  centered,
}) => (
  <ErrorMessage
    title={title || null}
    message={message || null}
    centered={centered}
  />
);

const ServerErrorContainer = withDependencies({
  translationsStore: TranslationsStore,
})((props) => (
  <ServerError
    title={props.translationsStore.translate(
      'global.error.server_error.headline',
    )}
    message={props.translationsStore.translate(
      'global.error.server_error.message',
    )}
    {...props}
  />
));

export default ServerErrorContainer;
