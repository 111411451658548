import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

export interface IResources {
  // language
  [key: string]: {
    // namespace or default 'translation'
    [key: string]: {
      // translation key-value pairs
      [key: string]: string | number;
    };
  };
}

export const initializeI18Next = async () => {
  await i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
      detection: {
        order: ['querystring', 'localStorage', 'navigator'],
        lookupQuerystring: 'locale',
        lookupLocalStorage: 'language',
        caches: ['localStorage'],
      },
      fallbackLng: 'en',
      defaultNS: 'translation',
      interpolation: {
        escapeValue: false,
        prefix: '{',
        suffix: '}',
      },
      returnEmptyString: false,
      resources: {},
      keySeparator: false,
      debug: false,
    });
};
