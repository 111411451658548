import React, { MouseEventHandler } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link, ListItem } from '@mui/material';
import { Translate } from '../../../components/Translate';
import { NavButton } from './SidebarV2.styles';

interface StyledLinkProps {
  name: string;
  label: string;
  path: string;
  onClick: MouseEventHandler;
  isNestedLink?: boolean;
}
const StyledLink = ({
  name,
  label,
  path,
  onClick,
  isNestedLink = false,
}: StyledLinkProps) => (
  <Link
    component={RouterLink}
    underline="none"
    color="black"
    data-testid={`link-${name}`}
    key={label}
    to={path}
    onClick={onClick}
  >
    <ListItem key={name}>
      <NavButton
        isNestedLink={isNestedLink}
        selected={
          isNestedLink
            ? location.pathname === path.split('?')[0]
            : location.pathname.startsWith(path.split('?')[0])
        }
        data-testid={`nav-item-${name}`}
      >
        <Translate code={label} />
      </NavButton>
    </ListItem>
  </Link>
);

export default StyledLink;
