import * as React from 'react';

import Drawer from '@mui/material/Drawer';

import { NotificationsStore } from '../../stores/NotificationsStore';
import FwfStore from '../../stores/FwfStore';

import SidebarV2 from './SidebarV2';
import Navbar from '../Navbar';

import { USE_NAVBAR_V3 } from '../../constants';

interface Props {
  platform: string;
  open: boolean;
  onClose: () => any;
  onContactInfoClick: () => any;
  userName: string;
  menuItems: Array<{
    name: string;
    label: string;
    path: string;
  }>;
  notificationsStore: NotificationsStore;
  fwfStore: FwfStore;
}

export const MobileDrawer: React.FC<Props> = ({
  platform,
  open,
  onClose,
  onContactInfoClick,
  menuItems,
  notificationsStore,
  fwfStore,
}) => {
  const getDrawerContent = () => {
    const useNavbarV3 = fwfStore.featureFlags.get(USE_NAVBAR_V3);
    if (useNavbarV3) {
      return (
        <Navbar menuItems={menuItems} onContactInfoClick={onContactInfoClick} />
      );
    }
    return (
      <SidebarV2
        menuItems={menuItems}
        platform={platform}
        isMobile={true}
        onContactInfoClick={onContactInfoClick}
        notificationsStore={notificationsStore}
      />
    );
  };

  const anchor = 'left';
  return (
    <Drawer open={open} anchor={anchor} onClose={onClose}>
      {getDrawerContent()}
    </Drawer>
  );
};
