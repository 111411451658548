import * as React from 'react';

import { useTheme } from '@mui/material/styles';
import { Box, Container, Typography } from '@mui/material';
import { PartnerLogo } from '../../../components/PartnerLogo';

const YemeksepetiPrivacy: React.FC = ({}) => {
  const theme = useTheme();

  return (
    <Container maxWidth="lg">
      <Box padding={4}>
        <Box marginBottom={2}>
          <PartnerLogo platform="yemeksepeti" width={212} height={40} />
        </Box>
        <Typography
          variant="h1"
          gutterBottom
          sx={{ color: `${theme.palette.primary.main}` }}
        >
          ÜYE İŞYERİ AYDINLATMA METNİ
        </Typography>

        <Typography variant="body1" paragraph>
          Üye İşyeri Sözleşmesi (“Sözleşme”) ile Yemeksepeti sistemine dâhil
          olan birçok işletme bulunmaktadır. Bu işletmelerden bazıları gerçek
          kişiler olabileceği gibi işletme bir tüzel kişilik olmakla birlikte
          sürdürülen ticari iletişim ilgili işletmenin sahibi olan yahut
          yetkilendirdiği gerçek kişiler (“İşletme Yetkilisi”) üzerinden
          sürdürülmektedir. İşbu Üye İşyeri Aydınlatma Metni, İşletme
          Yetkilisi’ne ait kişisel verilerinin Esentepe Mah. Dedekorkut Sk.
          No:28-01 Şişli İstanbul adresinde mukim Yemek Sepeti Elektronik
          İletişim Perakende Gıda Lojistik A.Ş. (“Yemeksepeti” ve/veya “Şirket”)
          tarafından 6698 sayılı Kişisel Verilerin Korunması Kanunu’na (“Kanun”)
          uygun olarak işlenmesine ilişkin açıklamalarda bulunmak ve İşletme
          Yetkilisi’ni bilgilendirmek amacıyla kaleme almıştır.
        </Typography>
        <Typography variant="subtitle1">1. Toplanan Kişisel Veriler</Typography>
        <Typography variant="body1" paragraph>
          Yemeksepeti ile Üye İşyeri Sözleşmesi’nin kurulması, sürdürülmesi ve
          yemeksepeti.com web ve mobil app üzerinden alınan siparişlerin
          sorunsuz olarak işletmenize aktarılması için İşletme Yetkilisine ait
          olan ad-soyad, e-mail adresi, gsm ve/veya sabit hat, iş ve/veya ev
          adresi, Vergi Kimlik Numarası/T.C. Kimlik Numarası toplanmaktadır.
        </Typography>

        <Typography variant="subtitle1">2. Tanımlar</Typography>
        <Typography variant="body1" paragraph>
          <ul>
            <li>
              Kişisel Veri: Kimliği belirli veya belirlenebilir gerçek kişiye
              ilişkin her türlü bilgi
            </li>
            <li>
              Kullanıcı: Yemek Sepeti platformu aracılığı ile sipariş veren
              gerçek kişiler
            </li>
            <li>
              İşleme: Kişisel verilerin tamamen veya kısmen otomatik olan ya da
              herhangi bir veri kayıt sisteminin parçası olmak kaydıyla otomatik
              olmayan yollarla elde edilmesi, kaydedilmesi, depolanması,
              muhafaza edilmesi, değiştirilmesi, yeniden düzenlenmesi,
              açıklanması, aktarılması, devralınması, elde edilebilir hâle
              getirilmesi, sınıflandırılması ya da kullanılmasının engellenmesi
              gibi veriler üzerinde gerçekleştirilen her türlü işlem
            </li>
          </ul>
        </Typography>

        <Typography variant="subtitle1">
          3. Kişisel Verilerin İşlenme Amacı
        </Typography>
        <Typography variant="body1" paragraph>
          Yukarıda belirtilen kişisel verileriniz aşağıdaki amaçlar
          doğrultusunda ve bunlarla sınırlı ve ölçülü olacak şekilde
          işlenecektir.
        </Typography>

        <Typography variant="subtitle1">
          i.Yemeksepeti tarafından sunulan hizmete ilişkin sözleşmenin yerine
          getirilebilmesi kapsamında;
        </Typography>
        <Typography variant="body1" paragraph>
          <ul>
            <li>Sözleşmenin ifası ve süreçlerinin takibi</li>
            <li>Fatura kesim süreçlerinin planlanması ve icrası</li>
            <li>Aylık sipariş bilgisinin paylaşılması</li>
            <li>Kullanıcı şikâyetlerinin çözümlenmesi ve takibi</li>
            <li>
              Yemeksepeti sisteminin doğru çalışması için denetim yapılması ve
              kötü niyetli işlemlerin takibi
            </li>
            <li>
              Sipariş takip portalı üzerindeki bilgi akışının sağlanması ve
              sipariş süreçlerinin takibi
            </li>
          </ul>
        </Typography>

        <Typography variant="subtitle1">
          ii. Yemeksepeti ve Yemeksepeti ile iş ilişkisi içerisinde olan ilgili
          kişilerin hukuki, teknik ve ticari iş güvenliğinin temin edilmesi
          kapsamında;
        </Typography>
        <Typography variant="body1" paragraph>
          <ul>
            <li>
              Şirket faaliyetlerinin şirket prosedürleri ve/veya ilgili mevzuata
              uygun olarak yürütülmesinin temini için gerekli operasyonel
              faaliyetlerin planlanması ve icrası
            </li>
            <li>Şirket operasyonlarının güvenliğinin temini</li>
            <li>Verilerin doğru ve güncel olmasının sağlanması</li>
            <li>Yetkili kuruluşlara mevzuattan kaynaklı bilgi verilmesi</li>
          </ul>
        </Typography>

        <Typography variant="subtitle1">
          iii.   Yemeksepeti tarafından sunulan ürün ve hizmetlerden ilgili
          kişileri faydalandırmak ve bu kullanıcıların deneyiminin arttırılması
          için gerekli çalışmaların iş birimlerimiz tarafından yapılması ve
          ilgili iş süreçlerinin yürütülmesi kapsamında;
        </Typography>
        <Typography variant="body1" paragraph>
          <ul>
            <li>
              İşletmenize yönelik yeni ürün ve/veya hizmet grupları önerilmesi
            </li>
            <li>
              Şirket içi yahut 3.kişiler aracılığı ile sağlanan iş birliği
              çalışmalarının sürdürülmesi ve takibi
            </li>
            <li>
              Gizli müşteri araması, puan ve yorum takibi, yerinde inceleme,
              sistemler üzerinden uzaktan inceleme gibi süreçlerle Üye İşyeri
              Sözleşme süreçlerine uyulup uyulmadığının denetlenmesi ve takibi
            </li>
            <li>
              Kullanıcılardan, 3.kişilerden, şirketimizden yahut sizin
              tarafınızdan gelebilecek olan hukuki taleplerin incelenmesi ve
              takibi
            </li>
          </ul>
        </Typography>

        <Typography variant="subtitle1">
          iv. Üye İşyeri Sözleşmesi’nin ifası kapsamında kullanıcıları korumaya
          yönelik ortaya çıkabilecek olan diğer işleme amaçları kapsamında;
        </Typography>
        <Typography variant="body1" paragraph>
          <ul>
            <li>
              Üye İşyeri memnuniyeti aktivitelerinin planlanması ve/veya icrası
            </li>
            <li>
              Ürün ve hizmetlerin satış ve pazarlaması için pazar araştırması
              faaliyetlerinin planlanması ve icrası
            </li>
            <li>
              Ürün ve/veya hizmetlerin satış ve pazarlama süreçlerinin
              planlanması ve icrası
            </li>
            <li>
              Ürün ve/veya hizmetlere ilişkin deneyimin geliştirilmesi ve buna
              yönelik genel/bireysel tanıtıcı faaliyetlerin yapılması
            </li>
            <li>
              Şirketin sunduğu ürün veya hizmetlerden en yüksek faydanın elde
              edilmesi ve ilgili kişileri faydalandırmak için ilgili süreçlerin
              planlanması ve icrası, raporlanması
            </li>
            <li>
              Satış sonrası destek hizmetleri aktivitelerinin planlanması
              ve/veya icrası
            </li>
          </ul>
        </Typography>

        <Typography variant="subtitle1">
          v. Şirket tarafından yürütülen ticari faaliyetlerin gerçekleştirilmesi
          için ilgili iş birimlerimiz tarafından gerekli çalışmaların yapılması
          ve buna bağlı iş süreçlerinin yürütülmesi kapsamında;
        </Typography>
        <Typography variant="body1" paragraph>
          <ul>
            <li>
              Bilgi güvenliği süreçlerinin planlanması, denetimi ve icrası
            </li>
            <li>
              Bilgi teknolojileri alt yapısının oluşturulması ve yönetilmesi
            </li>
            <li>Hukuk işlerinin takibi</li>
            <li>
              İş faaliyetlerinin etkinlik/verimlilik ve/veya yerindelik
              analizlerinin gerçekleştirilmesi faaliyetlerinin planlanması
              ve/veya icrası
            </li>
            <li>İş faaliyetlerinin planlanması ve icrası</li>
            <li>Kurumsal iletişim faaliyetlerinin planlanması ve icrası</li>
            <li>Lojistik faaliyetlerinin planlanması ve icrası</li>
            <li>Operasyon süreçlerinin planlanması ve icrası</li>
          </ul>
        </Typography>

        <Typography variant="subtitle1">
          vi. Yemeksepeti’nin ticari ve/veya iş stratejilerinin planlanması ve
          icrası kapsamında;
        </Typography>
        <Typography variant="body1" paragraph>
          <ul>
            <li>
              Üye İşyerleri, iş ortakları ve/veya tedarikçilerle olan
              ilişkilerin yönetimi
            </li>
            <li>Stratejik planlama faaliyetlerinin icrası</li>
            <li>
              Üye İşyerleri, iş ortakları ve/veya tedarikçilerin performans
              değerlendirme ve yönetimi süreçlerinin planlanması ve takibi buna
              yönelik hileli hareketlerin engellenmesi
            </li>
          </ul>
        </Typography>

        <Typography variant="subtitle1">
          4. Kişisel Verilerin Aktarılabileceği Taraflar ile Aktarım Amacı
        </Typography>
        <Typography variant="body1" paragraph>
          Yemeksepeti tarafından toplanan kişisel verileriniz yukarıda
          Aydınlatma Metni'nin 3. maddesinde detayları verilen amaçlarla ve 5.
          maddede belirtilen hukuki sebeplere dayanılarak Kanun'un 8. maddesi
          uyarınca yurt içindeki iştiraklerimiz, hissedarlarımız,
          işbirliklerimiz, iş ortaklarımız, altyapı-sistem-sunucu gibi teknik
          konulardaki iş ortaklarımız, kanunen yetkili kamu kurumları ve özel
          kişiler ile paylaşılabilecektir. Aynı amaçlar doğrultusunda ve
          bunlarla sınırlı olarak kişisel verileriniz Kanun'un 9. maddesi
          uyarınca yurtdışındaki iştiraklerimiz, hissedarlarımız,
          işbirliklerimiz, iş ortaklarımız, altyapı-sistem-sunucu gibi teknik
          konulardaki iş ortaklarımız, kanunen yetkili kamu kurumları ve özel
          kişiler ile paylaşılabilecektir.
        </Typography>

        <Typography variant="subtitle1">
          5. Kişisel Veri Toplamanın Yöntemi ve Hukuki Sebebi
        </Typography>
        <Typography variant="body1" paragraph>
          Kişisel verileriniz Yemeksepeti tarafından e-mail, ilgili internet
          siteleri ve mobil uygulama gibi kanallardan, fiziki olarak posta/kargo
          yolu ile ve/veya telefon kanalıyla toplanmaktadır. Kişisel verileriniz
          işbu Aydınlatma Metni'nin 3. maddesinde belirtilen amaçlar
          doğrultusunda Kanun'un 5. maddesinde belirtilen aşağıdaki hukuki
          sebeplere dayanılarak işlenebilecektir:
        </Typography>
        <Typography variant="body1" paragraph>
          Kanunlarda açıkça öngörülmesi: ii, iii, v
        </Typography>
        <Typography variant="body1" paragraph>
          Sizinle aramızdaki sözleşmenin kurulması veya ifasıyla doğrudan
          doğruya ilgili olması kaydıyla, size ait kişisel verilerinizi
          işlenmesinin gerekli olması: i, iii, v
        </Typography>
        <Typography variant="body1" paragraph>
          Hukuki yükümlülüklerimizi yerine getirebilmemiz için zorunlu
          olması: ii, iii, v
        </Typography>
        <Typography variant="body1" paragraph>
          Sizin tarafınızdan alenileştirilmiş olması (alenileştirme amacınızla
          bağdaştığı ölçüde):  iii
        </Typography>
        <Typography variant="body1" paragraph>
          Bir hakkın tesisi, kullanılması veya korunması için kişisel
          verilerinizi işlemenin zorunlu olması: ii, iii, v
        </Typography>
        <Typography variant="body1" paragraph>
          Temel hak ve özgürlüklerinize zarar vermemek kaydıyla, meşru
          menfaatlerimiz için zorunlu olması:  ii, iii, , iv, v
        </Typography>

        <Typography variant="subtitle1">
          6. Kişisel Veri Sahibinin Hakları
        </Typography>
        <Typography variant="body1" paragraph>
          Kanun’un 11. maddesi kapsamında kişisel veri sahibi olarak sizlere
          aşağıda belirtilen haklar tanınmıştır:
          <ul>
            <li>Kişisel verilerinizin işlenip işlenmediğini öğrenme,</li>
            <li>
              Kişisel verileriniz işlenmişse buna ilişkin bilgi talep etme,
            </li>
            <li>
              Kişisel verilerinin işlenme amacını ve bunların amacına uygun
              kullanılıp kullanılmadığını öğrenme,
            </li>
            <li>
              Yurt içinde veya yurt dışında kişisel verilerinizin aktarıldığı
              üçüncü kişileri bilme,
            </li>
            <li>
              Kişisel verilerinizin eksik veya yanlış işlenmiş olması hâlinde
              bunların düzeltilmesini isteme ve bu kapsamda yapılan işlemin
              kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini
              isteme,
            </li>
            <li>
              6698 sayılı Kanun ve ilgili diğer kanun hükümlerine uygun olarak
              işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin
              ortadan kalkması hâlinde kişisel verilerinizin silinmesini veya
              yok edilmesini isteme ve bu kapsamda yapılan işlemin kişisel
              verilerinizin aktarıldığı üçüncü kişilere bildirilmesini isteme,
            </li>
            <li>
              İşlenen verilerinizin münhasıran otomatik sistemler vasıtasıyla
              analiz edilmesi suretiyle aleyhinize bir sonucun ortaya çıkmasına
              itiraz etme,
            </li>
            <li>
              Kişisel verilerinizin kanuna aykırı olarak işlenmesi sebebiyle
              zarara uğramanız hâlinde zararın giderilmesini talep etme.
            </li>
          </ul>
        </Typography>

        <Typography variant="body1" paragraph>
          Bu kapsamındaki bilgi taleplerinizi info@yemeksepeti.com adresine
          iletebilirsiniz. Kişisel Verileri Koruma Kurumu tarafından ilgili
          mevzuat kapsamında belirlenen şartları taşımayan başvurular, şartları
          karşılaması için sizden talep edilebilecek ek bilgi tamamlanmadığı
          müddetçe, değerlendirmeye alınmayacaktır.
        </Typography>

        <Typography variant="body1" paragraph>
          Yemeksepeti talebin niteliğine göre talebi en kısa sürede ve/veya en
          geç otuz gün içinde sonuçlandıracaktır. Bununla birlikte
          taleplerinizin yerine getirilmesi nedeniyle ek bir maliyet doğması
          hâlinde Kişisel Verileri Koruma Kurulunca belirlenen tarifedeki
          ücretin talep edilebileceğini hatırlatmak isteriz. Yemeksepeti’nin iş
          bu Aydınlatma Metnini, iş gereksinimleri, kanuni gereksinimler doğması
          halinde tek taraflı olarak güncelleme hakkı saklıdır. Bu metnin en
          güncel haline ulaşmak için üye işyeri kayıt ana sayfamızı düzenli
          olarak ziyaret etmenizi öneririz.
        </Typography>
      </Box>
    </Container>
  );
};

export default YemeksepetiPrivacy;
