import React from 'react';
import { History } from 'history';
import {
  getBlackColor,
  getNeutralColor,
} from '@deliveryhero/vt-portal-chardonnay/core';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material';
import { getPathname } from '../../utils/PathHelper';
import GtmManager from '../../utils/gtm/GtmManager';
import { getGlobalVendorCount } from '../../utils/getGlobalVendorCount';
import { SessionStore } from '../../stores/SessionStore';
import { LocaleStore } from '../../stores/LocaleStore';
import { NotificationsStore } from 'src/stores/NotificationsStore';
import { observer } from 'mobx-react';
import Icon from '@mui/material/Icon';

type NotificationBellProps = {
  sessionStore: SessionStore;
  localeStore: LocaleStore;
  gtmManager: GtmManager;
  notificationsStore: NotificationsStore;
  history: History;
  isMobile?: boolean;
  isShrunk?: boolean;
};

const NotificationBell: React.FC<NotificationBellProps> = ({
  sessionStore,
  localeStore,
  gtmManager,
  notificationsStore,
  history,
  isMobile = false,
  isShrunk = false,
}) => {
  const theme = useTheme();

  const getIconButtonStyle = () => {
    if (isMobile) {
      return {
        padding: 0,
      };
    }

    return {
      padding: `${isShrunk ? '8px' : '16px'}`,
      backgroundColor: getNeutralColor('gray-05'),
      border: `1px solid ${getNeutralColor('gray-10')}`,
      borderRadius: '4px',
      marginLeft: '16px',
      ':hover': {
        backgroundColor: `${theme.palette.primary.main}1a`,
        borderColor: theme.palette.primary.main,
      },
    };
  };

  const getBadgeStyle = () => {
    if (isMobile) {
      return {
        transform: 'translate(-1px, 1px)',
        minWidth: 5,
        height: 5,
        backgroundColor: theme.palette.primary.main,
      };
    }

    return {
      transform: 'translate(17px, -19px)',
      right: 'initial',
      height: '24px',
      minWidth: '26px',
      borderRadius: '100px',
      fontSize: '16px',
      fontWeight: 700,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    };
  };

  const handleNotificationBellClick = () => {
    const pathname = getPathname(history);
    const section = `${pathname.replace('/', '')}.notifications`;
    const { platformIds, sessionPlatformVendorsIds } = sessionStore;

    history.push('/notifications');

    gtmManager.pushEvent('button_tap', {
      section,
      label: undefined,
      record_count: undefined,
      scroll_count: undefined,
      scroll_state: undefined,
      unread_count: undefined,
      user_id: sessionStore.getUserData('userId'),
      locale: localeStore.locale,
      global_vendor_count: getGlobalVendorCount(
        platformIds,
        sessionPlatformVendorsIds,
      ),
    });
  };

  return (
    <IconButton
      data-testid="notification-button"
      sx={getIconButtonStyle()}
      onClick={handleNotificationBellClick}
    >
      <Badge
        variant={isMobile ? 'dot' : 'standard'}
        sx={{
          '& .MuiBadge-badge': getBadgeStyle(),
        }}
        badgeContent={notificationsStore.unreadMessages}
      >
        <Icon sx={{ color: getBlackColor() }}>notifications</Icon>
      </Badge>
    </IconButton>
  );
};

export default observer(NotificationBell);
