import { withDependencies } from '@deliveryhero/react-inversify-provider';
import OtpSigninContainer from './OtpSigninContainer';
import { withRouter } from 'react-router-dom';
import AuthService from '../../services/AuthService';

const OtpSignin = withDependencies({
  authService: AuthService,
})(withRouter(OtpSigninContainer));

export default OtpSignin;
