import { injectable } from 'inversify';
import { action, computed, observable } from 'mobx';

import { IDialog, Dialog } from '../models/Dialog';

@injectable()
export class DialogStore {
  @observable dialogs: Dialog[] = [];

  @computed get currentDialog(): Dialog {
    if (this.dialogs.length > 0) {
      return this.dialogs[0];
    }
  }

  @action addDialog(dialogData: IDialog) {
    const dialog = new Dialog(dialogData);
    if (this.dialogs.length === 0) {
      dialog.isOpen = true;
    }

    this.dialogs.push(dialog);
  }

  @action closeCurrentDialog() {
    if (this.currentDialog) {
      if (typeof this.currentDialog.okButtonCallback === 'function') {
        this.currentDialog.okButtonCallback();
      }

      this.currentDialog.isOpen = false;
    }
  }

  next() {
    if (!this.currentDialog.isOpen) {
      this.dialogs.shift();
      if (this.currentDialog) {
        this.currentDialog.isOpen = true;
      }
    }
  }
}
