import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { SIDEBAR_WIDTH } from '../../../constants';
import Accordion from '@mui/material/Accordion';
import { ListItemButton } from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';

export interface OverlayPosition {
  top: boolean;
  bottom: boolean;
}

export const SidebarWrapper = styled(Box, {
  shouldForwardProp: (prop) =>
    !['isMobile', 'overlayPosition'].includes(prop.toString()),
})<{
  isMobile: boolean;
  overlayPosition: OverlayPosition;
}>(({ isMobile, overlayPosition }) => ({
  position: 'relative',
  marginTop: isMobile ? '12px' : '72px',
  '&::before': {
    position: 'absolute',
    content: overlayPosition.top ? '""' : 'none',
    height: '80px',
    width: '100%',
    zIndex: '1',
    pointerEvents: 'none',
    top: overlayPosition.top ? '0' : 'auto',
    background: 'linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #fff 100%)',
  },
  '&::after': {
    position: 'absolute',
    content: overlayPosition.bottom ? '""' : 'none',
    height: '80px',
    width: '100%',
    zIndex: '1',
    pointerEvents: 'none',
    bottom: overlayPosition.bottom ? '0' : 'auto',
    background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%)',
  },
}));

export const SidebarList = styled('div', {
  shouldForwardProp: (prop) => prop !== 'extraContentHeight',
})<{ extraContentHeight: string }>(({ extraContentHeight }) => ({
  overflow: 'auto',
  overflowX: 'hidden',
  height: `calc(100vh - ${extraContentHeight})`,
  '&::-webkit-scrollbar-thumb': {
    width: '5px',
    backgroundColor: '#dce5e6',
    borderRadius: '10px',
  },
  '&::-webkit-scrollbar': {
    width: '5px',
  },
}));

export const NavSidebar = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isMobile',
})<{ isMobile: boolean }>(({ isMobile }) => ({
  padding: '42px 0 36px',
  zIndex: 10,
  backgroundColor: '#fff',
  color: '#000',
  boxSizing: 'border-box',
  position: isMobile ? 'relative' : 'fixed',
  top: 0,
  fontSize: '14px',
  overflow: 'hidden',
  width: SIDEBAR_WIDTH,
  minHeight: '100vh',
  borderRight: '1px solid #dce5e6',
  alignSelf: 'flex-start',
}));
export const LogoWrapper = styled(Box)(() => ({
  marginLeft: '24px',
}));

export const AccordionWrapper = styled(Box)(() => ({
  padding: '8px 0',
}));

export const AccordionWithoutLine = styled(Accordion)(() => ({
  boxShadow: 'none !important',
  '&:before': {
    display: 'none',
  },
}));

export const NavButton = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'isNestedLink',
})<{ isNestedLink?: boolean }>(({ isNestedLink = false }) =>
  isNestedLink
    ? {
        paddingLeft: '48px',
        '&.Mui-selected': {
          paddingLeft: '48px',
        },
      }
    : {},
);

export const Title = styled(AccordionSummary)(() => ({
  paddingLeft: '24px',
  paddingRight: '16px',
}));
