import * as React from 'react';
import { styled as muiStyled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import { LogoProps } from './LogoProps';

import { DeliveryHeroSquaredPartnerLogo } from './parts/DeliveryHeroSquaredPartnerLogo';
import { eFoodSquaredPartnerLogo } from './parts/eFoodSquaredPartnerLogo';
import { FoodoraSquaredPartnerLogo } from './parts/FoodoraSquaredPartnerLogo';
import { FoodpandaSquaredPartnerLogo } from './parts/FoodpandaSquaredPartnerLogo';
import { FoodySquaredPartnerLogo } from './parts/FoodySquaredPartnerLogo';
import { TalabatSquaredPartnerLogo } from './parts/TalabatSquaredPartnerLogo';
import { PedidosYaSquaredPartnerLogo } from './parts/PedidosYaSquaredPartnerLogo';
import { HungerstationSquaredPartnerLogo } from './parts/HungerstationSquaredPartnerLogo';
import { YemeksepetiSquaredPartnerLogo } from './parts/YemeksepetiSquaredPartnerLogo';

const PLATFORMS_AND_LOGOS = [
  {
    platform: 'foodora',
    logo: FoodoraSquaredPartnerLogo,
  },
  {
    platform: 'foodpanda',
    logo: FoodpandaSquaredPartnerLogo,
  },
  {
    platform: 'hungerstation',
    logo: HungerstationSquaredPartnerLogo,
  },
  {
    platform: 'talabat',
    logo: TalabatSquaredPartnerLogo,
  },
  {
    platform: 'pedidosya',
    logo: PedidosYaSquaredPartnerLogo,
  },
  {
    platform: 'yemeksepeti',
    logo: YemeksepetiSquaredPartnerLogo,
  },
  {
    platform: 'efood',
    logo: eFoodSquaredPartnerLogo,
  },
  {
    platform: 'foody',
    logo: FoodySquaredPartnerLogo,
  },
  {
    platform: 'default',
    logo: DeliveryHeroSquaredPartnerLogo,
  },
];

const getLogoByPlatform = (platform: string): React.FC<LogoProps> => {
  const filteredLogoObj = PLATFORMS_AND_LOGOS.find(
    (p) => p.platform === platform,
  );
  return filteredLogoObj.logo;
};

const LogoWrapper = muiStyled(Box, {
  shouldForwardProp: (prop) => prop !== 'width',
})<{ width: number }>(({ width }) => ({
  width: `${width}px`,
  height: `${width}px`,
  overflow: 'hidden',
  boxShadow: '0px 2px 16px 0px rgba(22, 22, 22, 0.12)',
  borderRadius: '15px',
  borderWidth: '5px',
  borderColor: '#fff',
  borderStyle: 'solid',
}));

export type PlatformLogoProps = {
  platform: string;
  width?: number;
  hyperlink: string;
  'data-testid'?: string;
};

export const PlatformLogo: React.FC<PlatformLogoProps> = ({
  platform,
  width = 64,
  hyperlink,
  'data-testid': dataTestId,
}) => {
  const Logo = getLogoByPlatform(platform);

  if (!Logo) {
    throw new Error('Logo of platform not available.');
  }

  return (
    <LogoWrapper width={width}>
      <a data-testid={dataTestId} href={hyperlink}>
        <Logo width={width} />
      </a>
    </LogoWrapper>
  );
};
