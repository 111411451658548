import React from 'react';
import { IPlatform } from '../../config';
import { PlatformLogo } from '../SquaredPartnerLogo';
import LanguageSelectorContainer from '../../components/LanguageSelector';
import { Wrapper, RightWrapper, IconDivider, NavElementMargin } from './styles';
import { HeaderAvatar } from './HeaderAvatar';
import { getCurrentBrandColor } from '../../utils/getCurrentBrandColor';
import { useIsMobile } from '@deliveryhero/vt-portal-chardonnay/cjs/core/helpers';
import Icon from '@mui/material/Icon';

type Props = {
  currentPlatform: IPlatform;
  userName?: string;
  userRole?: string;
  navFilters?: JSX.Element[];
};

const RenderNavFilters = ({ navFilters }: { navFilters: JSX.Element[] }) => {
  return (
    <>
      {navFilters.map<any>((elem) => {
        return (
          <>
            {React.cloneElement(elem, {
              key: elem.props.name,
            })}
            <NavElementMargin>
              <IconDivider />
            </NavElementMargin>
          </>
        );
      })}
    </>
  );
};

export const Header = (props: Props) => {
  const { currentPlatform, userName, userRole, navFilters } = props;
  const brandColor = getCurrentBrandColor();
  const isMobile = useIsMobile();
  const languageIconSize = isMobile ? '20px' : '24px';
  const platformLogoSize = isMobile ? 22 : 40;
  const hasNavFilters = !!navFilters?.length;

  return (
    <Wrapper>
      <PlatformLogo
        hyperlink={'javascript:void(0);'}
        width={platformLogoSize}
        platform={currentPlatform.name}
      />
      <RightWrapper>
        {hasNavFilters && <RenderNavFilters navFilters={navFilters} />}
        <NavElementMargin>
          <LanguageSelectorContainer
            isMobileVersionEnabled
            renderComponent={(_label, onClick) => (
              <Icon
                sx={{
                  width: languageIconSize,
                  display: 'flex',
                  color: brandColor,
                  cursor: 'pointer',
                }}
                onClick={onClick}
              >
                language
              </Icon>
            )}
          />
        </NavElementMargin>
        {userName && (
          <>
            <NavElementMargin>
              <IconDivider />
            </NavElementMargin>
            <HeaderAvatar userName={userName} userRole={userRole} />
          </>
        )}
      </RightWrapper>
    </Wrapper>
  );
};
