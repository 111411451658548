import { observable } from 'mobx';

export interface IDevTools {
  debug: boolean;
}

const CONSOLE_COMMANDS: string[] = ['toggleDebugging'];

/**
 * @deprecated Looks like this is no longer used, can be deleted?
 */
export class DevTools implements IDevTools {
  @observable debug: boolean = false;

  constructor(private window: any) {
    this.exposeConsoleCommands();
  }

  protected toggleDebugging(): void {
    this.debug = !this.debug;
  }

  private exposeConsoleCommands(): void {
    this.window.devTools = CONSOLE_COMMANDS.reduce((cmdObj, cmdName) => {
      cmdObj[cmdName] = this[cmdName].bind(this);
      return cmdObj;
    }, {});
  }
}

export class NoDevTools implements IDevTools {
  readonly debug = false;
}
