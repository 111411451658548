import { injectable } from 'inversify';
import { action, observable } from 'mobx';
import { HelpCenterOptions } from '@deliveryhero/vendor-portal-sdk';

@injectable()
export class HelpStore {
  public static readonly defaultY = 40; // px
  @observable isOpen: boolean = false;
  @observable options: HelpCenterOptions = undefined;
  @observable offsetY: number = HelpStore.defaultY;
  @action setOffsetY(nextOffset: number) {
    this.offsetY = nextOffset;
  }
  @action toggleHelpCenter(isOpen: boolean, options?: HelpCenterOptions) {
    this.isOpen = isOpen;
    this.options = options;
  }
}
