import { createTheme } from '@material-ui/core/styles';

const shadowKeyUmbraOpacity = 0.16;
const shadowKeyPenumbraOpacity = 0.08;

const createShadow = (...px) => {
  return [
    `${px[0]}px ${px[1]}px ${px[2]}px ${px[3]}px rgba(0, 0, 0, ${shadowKeyUmbraOpacity})`,
    `${px[4]}px ${px[5]}px ${px[6]}px ${px[7]}px rgba(0, 0, 0, ${shadowKeyPenumbraOpacity})`,
  ].join(',');
};

const shadows = [
  'none',
  createShadow(0, 1, 3, 0, 0, 1, 1, 0, 0, 2, 1, -1),
  createShadow(0, 1, 5, 0, 0, 2, 2, 0, 0, 3, 1, -2),
  createShadow(0, 1, 8, 0, 0, 3, 4, 0, 0, 3, 3, -2),
  createShadow(0, 2, 4, -1, 0, 4, 5, 0, 0, 1, 10, 0),
  createShadow(0, 3, 5, -1, 0, 5, 8, 0, 0, 1, 14, 0),
  createShadow(0, 3, 5, -1, 0, 6, 10, 0, 0, 1, 18, 0),
  createShadow(0, 4, 5, -2, 0, 7, 10, 1, 0, 2, 16, 1),
  createShadow(0, 5, 5, -3, 0, 8, 10, 1, 0, 3, 14, 2),
  createShadow(0, 5, 6, -3, 0, 9, 12, 1, 0, 3, 16, 2),
  createShadow(0, 6, 6, -3, 0, 10, 14, 1, 0, 4, 18, 3),
  createShadow(0, 6, 7, -4, 0, 11, 15, 1, 0, 4, 20, 3),
  createShadow(0, 7, 8, -4, 0, 12, 17, 2, 0, 5, 22, 4),
  createShadow(0, 7, 8, -4, 0, 13, 19, 2, 0, 5, 24, 4),
  createShadow(0, 7, 9, -4, 0, 14, 21, 2, 0, 5, 26, 4),
  createShadow(0, 8, 9, -5, 0, 15, 22, 2, 0, 6, 28, 5),
  createShadow(0, 8, 10, -5, 0, 16, 24, 2, 0, 6, 30, 5),
  createShadow(0, 8, 11, -5, 0, 17, 26, 2, 0, 6, 32, 5),
  createShadow(0, 9, 11, -5, 0, 18, 28, 2, 0, 7, 34, 6),
  createShadow(0, 9, 12, -6, 0, 19, 29, 2, 0, 7, 36, 6),
  createShadow(0, 10, 13, -6, 0, 20, 31, 3, 0, 8, 38, 7),
  createShadow(0, 10, 13, -6, 0, 21, 33, 3, 0, 8, 40, 7),
  createShadow(0, 10, 14, -6, 0, 22, 35, 3, 0, 8, 42, 7),
  createShadow(0, 11, 14, -7, 0, 23, 36, 3, 0, 9, 44, 8),
  createShadow(0, 11, 15, -7, 0, 24, 38, 3, 0, 9, 46, 8),
];

export const defaultTheme = createTheme({
  shadows: shadows as any,
  palette: {
    action: {
      hover: '#edf0f7',
    },
    common: {
      white: 'rgba(255, 255, 255, 0.87)',
      black: '#2d3645',
    },
    primary: {
      light: 'rgb(97, 194, 255)',
      main: 'rgb(30, 145, 214)',
      dark: 'rgb(23, 109, 163)',
      contrastText: '#fff',
    },
    secondary: {
      light: 'rgb(151, 230, 48)',
      main: 'rgb(101, 178, 2)',
      dark: 'rgb(73, 128, 1)',
      contrastText: '#fff',
    },
  },
  typography: {
    caption: {
      color: 'rgba(0,0,0,0.54)',
    },
    h4: {
      color: 'rgba(0, 0, 0, 0.87)',
    },
    body1: {
      fontSize: 14,
    },
    body2: {
      color: 'rgba(0,0,0,0.54)',
      fontWeight: 'normal',
      lineHeight: 1.5,
      fontSize: 14,
    },
  },
  shape: {
    borderRadius: 2,
  },
});
