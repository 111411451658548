import * as React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';

import StartPrompt from '../../components/StartPrompt';
import StartPromptHead from '../../components/StartPrompt/StartPromptHead';
import { Translate } from '../../components/Translate';

export type Props = {
  platform: any;
};

const ResetPasswordSuccessView: React.FC<Props> = ({ platform }) => {
  const history = useHistory();

  return (
    <div data-testid="reset-password-success">
      <StartPrompt platform={platform}>
        <StartPromptHead
          title={<Translate code="login.reset_password.success.title" />}
          text={<Translate code="login.reset_password.success.text" />}
        />
        <Button
          data-testid="reset-password-back-to-login"
          fullWidth
          color="primary"
          onClick={() => {
            history.push('/login');
          }}
        >
          <Translate code="login.reset_password.success.back_to_login" />
        </Button>
      </StartPrompt>
    </div>
  );
};

export default ResetPasswordSuccessView;
