import * as React from 'react';
import i18next from 'i18next';

// dh packages
import { useIsMobile } from '@deliveryhero/vt-portal-chardonnay/cjs/core/helpers';

// stores
import { AvailableLanguagesStore } from '../../stores/AvailableLanguagesStore';
import { PlatformStore } from '../../stores/PlatformStore';
import { PluginStore } from '../../stores/PluginStore';
import { UserStore } from '../../stores/UserStore';

// components
import NavbarFooter from './NavbarFooter';
import PluginMenuWrapper from './PluginMenuWrapper';
import { PartnerLogo } from '../../components/PartnerLogo';

// styles
import { NavbarWrapper, PlatformLogoWrapper } from './Navbar.styles';

// types
type NavbarContainerProps = {
  availableLanguagesStore: AvailableLanguagesStore;
  platformStore: PlatformStore;
  pluginStore: PluginStore;
  userStore: UserStore;
  onContactInfoClick: Function;
};

const NavbarContainer: React.FC<NavbarContainerProps> = ({
  availableLanguagesStore,
  platformStore,
  pluginStore,
  userStore,
  onContactInfoClick,
}) => {
  const isMobile = useIsMobile();
  const [currentLanguageLabel, setCurrentLanguageLabel] = React.useState('');
  const { shown: showOnboarding, required: redirectToOnboarding } =
    userStore.userProfile?.onboarding_info || {};

  React.useEffect(() => {
    const languageCode = i18next.language;
    const languageLabel = availableLanguagesStore.availableLanguages.find(
      (l) => l.code === languageCode,
    ).language;
    setCurrentLanguageLabel(languageLabel);
  }, [i18next.language]);

  return (
    <NavbarWrapper
      id="navbar-wrapper"
      data-testid="navbar-wrapper"
      isMobile={isMobile}
    >
      <PlatformLogoWrapper isMobile={isMobile}>
        <PartnerLogo
          width={212}
          height={44}
          platform={platformStore.currentPlatform.name}
        />
      </PlatformLogoWrapper>

      <PluginMenuWrapper
        menuPlugins={pluginStore.menuPlugins}
        showOnboarding={showOnboarding}
        redirectToOnboarding={redirectToOnboarding}
      />

      <NavbarFooter
        onContactInfoClick={onContactInfoClick}
        currentLanguage={currentLanguageLabel}
      />
    </NavbarWrapper>
  );
};

export default NavbarContainer;
