import * as React from 'react';
import {
  Paper,
  Typography,
  Button,
  Box,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Stack,
  Drawer,
} from '@mui/material';
import { useIsMobile } from '@deliveryhero/vt-portal-chardonnay/core';

import GtmManager from '../../utils/gtm/GtmManager';
import { Translate } from '../../components/Translate';
import Icon from '@mui/material/Icon';

interface CountrySSULinkType {
  isoCode: string;
  country: string;
  link: string;
  brand: string;
}

interface RegistrationViewProps {
  countrySSULinks: Array<CountrySSULinkType>;
  gtm: GtmManager;
}

const RegistrationView: React.FC<RegistrationViewProps> = ({
  countrySSULinks,
  gtm,
}: RegistrationViewProps) => {
  const isMobile = useIsMobile();

  const [isCountrySelectorDialogOpen, setIsCountrySelectorOpen] =
    React.useState(false);

  const redirectToCountrySSUPage = (link) => {
    window.open(link, '_self');
  };

  return (
    <Paper
      id="registration-view"
      data-testid="registration-view"
      sx={{
        margin: '20px auto',
        padding: '20px',
        maxWidth: '320px',
        borderRadius: '12px',
        boxShadow: '0px 2px 16px 0px rgba(22, 22, 22, 0.12)',
      }}
    >
      <Stack direction="column" alignItems="center">
        <Typography
          variant="secondary-400"
          sx={{
            color: '#777777',
            marginBottom: '10px',
          }}
        >
          <Translate code={'global.inquire_registration'} />
        </Typography>
        <Button
          id="sign-up-btn"
          data-testid="sign-up-btn"
          variant="outlined"
          fullWidth
          onClick={() => {
            gtm.pushEvent('vp_signup', {
              section: 'performance',
              label: 'vp_signup_clicked',
            });
            setIsCountrySelectorOpen(true);
          }}
        >
          <Translate code={'global.sign_up'} />
        </Button>
      </Stack>
      <Drawer
        anchor={isMobile ? 'bottom' : 'right'}
        id="registration-country-selector-dialog"
        data-testid="registration-country-selector-dialog"
        open={isCountrySelectorDialogOpen}
        onClose={() => setIsCountrySelectorOpen(false)}
        sx={{
          '& .MuiDrawer-paper': {
            borderRadius: 0,
          },
        }}
      >
        <Box sx={{ maxWidth: '500px' }}>
          <Stack direction="row" justifyContent="end">
            <Box sx={{ padding: '20px' }}>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: 24,
                  lineHeight: 1.2,
                }}
              >
                <Translate code="global.choose_registration_country" />
              </Typography>
            </Box>
            <Box sx={{ padding: '15px' }}>
              <IconButton
                data-testid="close-ssu-dialog"
                onClick={() => setIsCountrySelectorOpen(false)}
              >
                <Icon>close</Icon>
              </IconButton>
            </Box>
          </Stack>

          <Box sx={{ padding: 2 }}>
            <List>
              {countrySSULinks.map(({ isoCode, country, link }) => {
                let flagIcon = null;
                try {
                  const file = require(`../../../public/static/country-flags/${isoCode.toLowerCase()}.svg`);
                  flagIcon = file;
                } catch (e) {
                  // eslint-disable-next-line
                  console.error(
                    `While trying to get a country flag icon file, an error occurred: ${e}`,
                  );
                }

                return (
                  <ListItem
                    divider
                    key={isoCode}
                    secondaryAction={
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        data-testid={`ssu-${isoCode.toLowerCase}-click-open`}
                      >
                        <Icon>keyboard_arrow_right</Icon>
                      </IconButton>
                    }
                    sx={{
                      marginBottom: '8px',
                      padding: '8px 5px',
                      cursor: 'pointer',
                      border: '1px solid #d3d3d3',
                      borderRadius: '12px',
                    }}
                    onClick={() => redirectToCountrySSUPage(link)}
                    data-testid={`ssu-${isoCode.toLowerCase}-open`}
                  >
                    <img
                      loading="lazy"
                      width="20"
                      style={{ marginRight: '8px' }}
                      src={flagIcon}
                      alt={`${country}-flag`}
                    />
                    <ListItemText>{country}</ListItemText>
                  </ListItem>
                );
              })}
            </List>
          </Box>
        </Box>
      </Drawer>
    </Paper>
  );
};

export default RegistrationView;
