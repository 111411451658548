import { injectable, inject, postConstruct } from 'inversify';

const DEFAULT_EVENT_DATA = {
  // Ref: https://jira.deliveryhero.com/browse/VP-8262
  // To clear the user attributes when not passed
  // so that they do not spill over to future events
  // GA4 event user attributes
  section: undefined,
  subsection: undefined,
  group: undefined,
  label: undefined,
  referred_section: undefined,

  // GA3 event user attributes
  eventLabel: undefined,
  eventValue: undefined,
  eventStatus: undefined,
  eventAction: undefined,
  eventCategory: undefined,
};

@injectable()
export default class GtmManager {
  @inject('window') private window: any;

  constructor() {}

  @postConstruct() initialize(): void {
    if (this.window['dataLayer'] === undefined) {
      this.window['dataLayer'] = [];
    }
  }
  pushEvent(event: string, data?: {}): void {
    this.window['dataLayer'].push({
      ...DEFAULT_EVENT_DATA,
      ...data,
      event: event,
    });
  }
}
