/* eslint-disable max-len */
import * as React from 'react';

export const DeliveryHeroPartnerLogo: React.FC = () => (
  <svg
    data-testid="platform-logo-svg"
    width="212"
    height="48"
    viewBox="0 0 212 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32.6692 23.158C32.6597 23.1626 32.6543 23.1666 32.6469 23.1703L28.8321 24.7269L28.7148 24.7801L27.7854 29.0288C27.7233 29.174 27.5378 29.2083 27.4123 29.0868L24.6333 25.8004L24.6196 25.7913L8.94681 32.5167C8.93342 32.5238 8.91798 32.527 8.90283 32.527C8.84481 32.527 8.79761 32.481 8.79761 32.4212C8.79761 32.3878 8.81422 32.3569 8.84049 32.3372L22.3908 22.1951L20.6755 18.2808C20.5904 18.1024 20.7481 17.9126 20.9577 17.9647H20.9594L25.1115 18.9882L28.335 16.1182V16.1191C28.4748 16.0099 28.6637 16.0876 28.6989 16.2603L29.0138 20.5714L32.7312 22.7464C32.891 22.8482 32.8681 23.0837 32.6692 23.158ZM30.7739 10.836C25.9641 8.94867 20.6738 10.3655 17.4092 13.9937L4.67007 27.6403C4.49856 27.8238 4.57773 28.0541 4.78729 28.0853L8.18027 28.2937C8.45232 28.3108 8.48605 28.5438 8.34915 28.7047L0.0531581 37.6529C-0.0911341 37.8084 0.0829382 38.0519 0.281303 37.9902L12.126 34.2479C12.3761 34.1616 12.569 34.3846 12.4633 34.5804L10.8789 37.3814C10.7969 37.5411 10.9507 37.7598 11.1497 37.7455L28.2263 33.9415C32.3068 33.2956 35.9504 30.5719 37.5696 26.4487C40.008 20.2595 36.9614 13.27 30.7739 10.836Z"
      fill="#D61F26"
    />
    <path
      d="M76.5536 31.5996H72.6624L75.707 17.3405L79.9501 15.7811L76.5536 31.5996Z"
      fill="#D61F26"
    />
    <path
      d="M68.1944 23.4318C67.263 23.4318 66.7273 24.2033 66.51 25.0976C68.3517 25.0976 68.91 24.522 68.91 24.0446C68.91 23.7091 68.61 23.4318 68.1944 23.4318ZM66.0739 27.5952C66.0527 27.6931 66.033 27.8735 66.033 27.9715C66.033 28.6067 66.4683 28.8239 67.62 28.8239C68.6503 28.8239 70.1166 28.5661 70.9705 28.2696V31.1845C69.8803 31.5806 68.1732 31.7979 66.8049 31.7979C63.5927 31.7979 62.0466 30.9249 62.0466 28.0119C62.0466 25.1525 63.3747 20.6931 68.493 20.6931C71.7432 20.6931 72.7134 22.1628 72.7134 23.7091C72.7134 25.7113 70.9903 27.4162 66.0739 27.5952Z"
      fill="#D61F26"
    />
    <path
      d="M83.6207 20.1816C82.2941 20.1816 81.4409 19.3291 81.4409 18.1588C81.4409 16.632 82.5114 15.7794 83.838 15.7794C85.1872 15.7794 86.0214 16.632 86.0214 17.7816C86.0214 19.3291 84.9699 20.1816 83.6207 20.1816Z"
      fill="#D61F26"
    />
    <path
      d="M80.8896 21.0515H84.8942L82.8696 31.4414H78.824L80.2508 24.1452"
      fill="#D61F26"
    />
    <path
      d="M96.4342 21.0515C95.0455 24.5414 93.5184 27.9515 91.5765 31.4414H86.6177C85.984 28.1484 85.7053 24.6788 85.7857 21.0515H89.8321C89.7509 22.7567 89.7721 24.5022 89.8687 26.1259C89.8899 26.5627 89.9309 26.9783 89.9689 27.3973H89.9916C90.1687 26.9783 90.3655 26.5627 90.5448 26.1259C91.2384 24.4184 91.8721 22.5595 92.3513 21.0515H96.4342Z"
      fill="#D61F26"
    />
    <path
      d="M101.848 23.4318C100.918 23.4318 100.379 24.2033 100.16 25.0976C102.007 25.0976 102.561 24.522 102.561 24.0446C102.561 23.7091 102.266 23.4318 101.848 23.4318ZM99.7275 27.5952C99.7077 27.6931 99.6858 27.8735 99.6858 27.9715C99.6858 28.6067 100.123 28.8239 101.272 28.8239C102.304 28.8239 103.772 28.5661 104.624 28.2696V31.1845C103.532 31.5806 101.827 31.7979 100.461 31.7979C97.2463 31.7979 95.7002 30.9249 95.7002 28.0119C95.7002 25.1525 97.0275 20.6931 102.145 20.6931C105.397 20.6931 106.367 22.1628 106.367 23.7091C106.367 25.7113 104.645 27.4162 99.7275 27.5952Z"
      fill="#D61F26"
    />
    <path
      d="M114.143 24.719C113.924 24.6593 113.487 24.5986 113.171 24.5986C112.277 24.5986 111.564 25.4927 111.245 27.1376L110.416 31.4424H106.367L108.393 21.0522H111.428L111.525 22.4372C112.457 21.2888 113.192 20.6931 114.322 20.6931C114.836 20.6931 115.114 20.7324 115.252 20.7729L114.143 24.719Z"
      fill="#D61F26"
    />
    <path
      d="M126.794 21.0524C125.007 25.6924 123.422 29.0829 121.835 31.4246C119.536 34.8559 117.512 35.6484 115.429 35.6484C114.813 35.6484 114.161 35.4875 113.842 35.3301L114.478 32.3352H115.866C116.561 32.3352 116.856 32.056 117.313 31.4423C116.54 28.6473 116.163 24.7406 116.202 21.0524H120.269C120.186 22.7359 120.208 24.4826 120.308 26.0683C120.329 26.5233 120.365 26.9603 120.405 27.3982H120.426C120.605 26.9792 120.802 26.5636 120.982 26.1077C121.656 24.4611 122.312 22.5773 122.745 21.0524H126.794Z"
      fill="#D61F26"
    />
    <path
      d="M142.344 31.4414H138.077L139.09 26.2048H135.479L134.466 31.4414H130.205L132.901 17.599H137.166L136.195 22.5383H139.805L140.776 17.599H145.038L142.344 31.4414Z"
      fill="#D61F26"
    />
    <path
      d="M150.747 23.4318C149.814 23.4318 149.281 24.2033 149.062 25.0976C150.907 25.0976 151.464 24.522 151.464 24.0446C151.464 23.7091 151.163 23.4318 150.747 23.4318ZM148.625 27.5952C148.605 27.6931 148.587 27.8735 148.587 27.9715C148.587 28.6067 149.024 28.8239 150.171 28.8239C151.204 28.8239 152.672 28.5661 153.524 28.2696V31.1845C152.434 31.5806 150.727 31.7979 149.358 31.7979C146.145 31.7979 144.599 30.9249 144.599 28.0119C144.599 25.1525 145.928 20.6931 151.044 20.6931C154.297 20.6931 155.271 22.1628 155.271 23.7091C155.271 25.7113 153.542 27.4162 148.625 27.5952Z"
      fill="#D61F26"
    />
    <path
      d="M163.041 24.719C162.824 24.6593 162.391 24.5986 162.072 24.5986C161.181 24.5986 160.465 25.4927 160.148 27.1376L159.314 31.4424H155.271L157.292 21.0522H160.325L160.426 22.4372C161.358 21.2888 162.09 20.6931 163.219 20.6931C163.738 20.6931 164.014 20.7324 164.154 20.7729L163.041 24.719Z"
      fill="#D61F26"
    />
    <path
      d="M169.686 23.8862C168.059 23.8862 167.623 26.3646 167.623 27.4764C167.623 28.3682 167.999 28.6246 168.736 28.6246C170.339 28.6246 170.756 26.145 170.756 25.0181C170.756 24.1451 170.399 23.8862 169.686 23.8862ZM168.177 31.7969C165.024 31.7969 163.48 30.4478 163.48 27.7522C163.48 24.7574 164.966 20.6924 170.203 20.6924C173.336 20.6924 174.901 22.0806 174.901 24.7397C174.901 27.7939 173.413 31.7969 168.177 31.7969Z"
      fill="#D61F26"
    />
    <path
      d="M53.3625 28.1491H52.5681L53.9952 20.8507H55.1076C56.7923 20.8507 57.4069 21.8464 57.4069 23.2112C57.4069 25.9692 55.878 28.1491 53.3625 28.1491ZM57.1413 21.1099L59.8369 18.5226C58.7854 17.8895 57.3418 17.5994 55.5413 17.5994H54.6301H50.3856L47.6912 31.4424H51.9319H53.0663C59.31 31.4424 61.8074 27.0796 61.8074 22.5387C61.8074 21.3594 61.5535 20.4162 61.0632 19.6831L57.1413 21.1099Z"
      fill="#D61F26"
    />
  </svg>
);
