import { inject, injectable } from 'inversify';
import { computed, observable, reaction } from 'mobx';
import { Api } from '@deliveryhero/portal-api-client';

import { SessionStore } from './SessionStore';
import GtmManager from '../utils/gtm/GtmManager';
import FwfStore from './FwfStore';

import { IPlugin, Plugin } from '../models/Plugin';
import { isMobileWebView } from '../utils/isMobileWebView';
import { ApiStatus } from '../models/ApiStatus';
import { TYPES } from '../types';
import { DISABLE_IMPERSONATER_UMP } from './../constants';

@injectable()
export class PluginStore {
  @observable plugins: Array<Plugin> = [];
  @observable pluginApiStatus: ApiStatus = ApiStatus.IDLE;

  @inject(TYPES.GlobalApiV2) private globalApiV2: Api;
  @inject(TYPES.SessionStore) private sessionStore: SessionStore;
  @inject(FwfStore) private fwfStore: FwfStore;
  @inject(GtmManager) private gtm: GtmManager;
  @inject('pluginApiUrl') private pluginApiUrl: string;
  @inject('pluginOverrides') private overrides: IPlugin | undefined;

  readonly TYPE_MENU = 'MENU';

  /**
   * Returns plugins that have all the required configuration (has route and bundle URL)
   */
  @computed get frontendPlugins() {
    return this.plugins.filter((plugin) => plugin.isFrontendPlugin);
  }

  /**
   * Plugins that can be shown in the menu
   */
  @computed get menuPlugins() {
    return this.getAllPluginsItemsForType(this.TYPE_MENU);
  }

  init() {
    // Reaction that triggers every time the vendors change (should only happen once after login)
    reaction(
      () => ({
        fwfReady: this.fwfStore.ready,
      }),
      async ({ fwfReady }) => {
        if (!fwfReady) {
          return;
        }

        /** reset all the data when vendors changes */
        this.plugins = [];
        this.pluginApiStatus = ApiStatus.LOADING;

        // NOTE: START of Plugin Api V2 fetch and handling.
        this.gtm.pushEvent('vpwebapp_load', {
          timestamp: Date.now(),
          label: 'vp_webapp_plugin_api_v2_start',
          section: 'performance',
        });

        try {
          this.plugins = await this.fetchNewPluginsList();

          // NOTE: To decide whether to use HC plugin or Bubble for current user
          await this.decideHelpCenterPluginPresence();

          this.pluginApiStatus = ApiStatus.SUCCESS;

          // NOTE: END of Plugin Api V2 fetch and handling.
          this.gtm.pushEvent('vpwebapp_load', {
            timestamp: Date.now(),
            label: 'vp_webapp_plugin_api_v2_success',
            section: 'performance',
          });

          // NOTE: END of Webapp Shell Load
          this.gtm.pushEvent('vpwebapp_load', {
            timestamp: Date.now(),
            label: 'vpwebapp_load_end',
            section: 'performance',
          });

          // For returning users, we are tracking the total time from entering url
          // till the end of successful Plugin Api call
          if (this.sessionStore.isUserReturned) {
            this.gtm.pushEvent('vpwebapp_load', {
              time_ms: window.performance.now(),
              label: 'vpwebapp_shell_load_total_time',
              section: 'performance',
            });
          }
        } catch (error) {
          this.pluginApiStatus = ApiStatus.ERROR;

          // NOTE: Plugin API Failed
          this.gtm.pushEvent('vpwebapp_load', {
            timestamp: Date.now(),
            label: 'vp_webapp_plugin_api_v2_failed',
            section: 'performance',
          });

          // NOTE: Webapp Load Failed
          this.gtm.pushEvent('vpwebapp_load', {
            timestamp: Date.now(),
            label: 'vpwebapp_load_failed',
            section: 'performance',
          });
        }
      },
      { fireImmediately: true },
    );
  }

  /**
   * Get plugin by plugin code
   * @param code Plugin code (e.g. `OPENING_TIMES_TB` or `MENU_MANAGEMENT_PANDORA`)
   */
  getByCode(code: string): Plugin | undefined {
    if (!this.isPluginAvailable(code)) {
      return undefined;
    }

    const plugins = this.plugins.filter(
      (plugin: Plugin) => plugin.code === code,
    );

    return plugins.length ? plugins[0] : undefined;
  }

  /**
   * Returns boolean value that indicates if plugin is available for user
   * @param code Plugin code
   */
  isPluginAvailable(code: string): boolean {
    if (!this.plugins) {
      return false;
    }

    return this.plugins.some((element: Plugin) => element.code === code);
  }

  /**
   * use reset store during logout
   */
  resetStore() {
    this.plugins = [];
    this.pluginApiStatus = ApiStatus.IDLE;
  }

  /**
   * Fetches list of plugins from New Plugin API V2
   */
  private async fetchNewPluginsList() {
    const userId = this.sessionStore.userId;
    const fetchUrl = `${this.pluginApiUrl}/v1/navigation/user/${userId}`;

    // Note: Overriden response for local dev using Overrides prop in portal config
    const OVERRIDEN_RESPONSE = {
      name: 'navigations/FP_SG',
      display_name: 'FP_SG',
      menu: this.overrides,
    };

    // NOTE: add pin token as a header only if it is available in session
    const pinToken = this.sessionStore.getPinToken();
    const pinTokenHeader = pinToken ? { 'X-Pin-Token': pinToken } : {};

    let { menu } =
      this.overrides !== undefined
        ? await Promise.resolve(OVERRIDEN_RESPONSE) // NOTE: for local dev using `pluginOverrides` in portal config
        : await this.globalApiV2.fetch(fetchUrl, 200, {
            method: 'GET',
            headers: {
              ...pinTokenHeader,
            },
          }); // NOTE: Actual call to Plugin API V2

    const disableImpersonaterUmp = this.fwfStore.featureFlags.get(
      DISABLE_IMPERSONATER_UMP,
    );
    if (
      disableImpersonaterUmp &&
      this.sessionStore.mainSessionInfo.isImpersonator
    ) {
      menu = menu.filter(
        (plugin) => plugin.plugin_code !== 'USER_MANAGEMENT_SYSTEM',
      );
    }

    // NOTE: Create FwF clients for each Plugin
    const plugins: Plugin[] = await Promise.all(
      menu.map(async (plugin) => {
        const fwfData = await this.getFwfPluginParameters(plugin);
        const pluginProps = { ...plugin, ...fwfData };
        return new Plugin(pluginProps);
      }),
    );

    return plugins;
  }

  /**
   * Gets the FwF client for a plugin
   * @param pluginPayload single plugin payload of the Portal API plugin endpoint
   */
  private async getFwfPluginParameters(plugin) {
    if (plugin.fwf_client_id) {
      const fwfClient = await this.fwfStore.createFwfClient(
        `plugin_${plugin.plugin_code}`,
        plugin.fwf_client_id,
      );
      return {
        fwfClient,
      };
    }
    return {};
  }

  /**
   * Filters plugins based on type (e.g. `MENU` or `UNLISTED`)
   * @param pluginType type of the plugin
   */
  private getAllPluginsItemsForType(pluginType: string): Plugin[] {
    const allPlugins = this.frontendPlugins.filter(
      (plugin, i, arr) =>
        arr.findIndex((val) => val.route === plugin.route) === i,
    );
    const plugins = this.frontendPlugins.length > 0 ? allPlugins : [];

    return plugins
      .filter(filterByType(pluginType))
      .filter(filterByUniqueRoute());
  }

  /**
   * TODO: Code that should be deleted after the helpcenter bubble integration
   * START: Remove this code piece when Help Center Bubble is integrated completely
   * This flagging code removes HC plugin from the sidebar when bubble is on.
   */
  private async decideHelpCenterPluginPresence() {
    const isHelpCenterBubbleOn = isMobileWebView()
      ? false
      : await this.fwfStore.getVariationValue('help-center-iframe', false);
    if (isHelpCenterBubbleOn) {
      this.plugins = this.plugins.filter((p) => p.code !== 'HELPCENTER');
    }
  }
}

const filterByType = (type: string) => (plugin) =>
  plugin.type.toLowerCase() === type.toLowerCase();

const filterByUniqueRoute = () => {
  const uniqueRoutes = [];
  return ({ route }: { route?: string }) => {
    const result = !uniqueRoutes.includes(route);
    uniqueRoutes.push(route);
    return result;
  };
};
