import * as React from 'react';

import { StyledReactSVG } from '../StyledReactSVG';

type PluginSvgIconProps = {
  iconKey: string;
  color: string;
};

const PluginSvgIcon: React.FC<PluginSvgIconProps> = ({ iconKey, color }) => {
  const [pluginIcon, setPluginIcon] = React.useState(null);

  React.useEffect(() => {
    try {
      let file;
      if (iconKey.startsWith('menu_management')) {
        file = require('../../../public/static/plugin-icons/menu_management.svg');
      } else {
        file = require(`../../../public/static/plugin-icons/${iconKey}.svg`);
      }
      setPluginIcon(file);
    } catch (e) {
      const noIconDefaultFile = require('../../../public/static/plugin-icons/no-icon.svg');
      setPluginIcon(noIconDefaultFile);
    }
  }, []);

  if (pluginIcon === null) {
    return null;
  }

  return <StyledReactSVG src={pluginIcon} size={24} color={color} />;
};

export default PluginSvgIcon;
