import { createBrowserHistory } from 'history';
import { IConfig } from '../config';

/**
 * Create history for `Router`
 * @param _window global `window` object
 * @param config application config
 */
export const historyFactory = (_window: Window, config: IConfig) => {
  const history = createBrowserHistory({ basename: `/${config.basePath}` });

  // Scroll to top when history changes
  history.listen(() => {
    _window.scrollTo(0, 0);
  });

  return history;
};
