import * as React from 'react';
import { styled as muiStyled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import { InfoGroup as TabletInfoGroup } from './Tablet/InfoGroup';

const ContentWrapper = muiStyled(Box)({
  maxWidth: '340px',
  margin: 'auto',
});

export const Content = () => {
  return (
    <ContentWrapper>
      <TabletInfoGroup />
    </ContentWrapper>
  );
};
