import { withDependencies } from '@deliveryhero/react-inversify-provider';
import { TYPES } from '../../types';
import { PlatformStore } from '../../stores/PlatformStore';
import { TranslationsStore } from '../../stores/TranslationsStore';
import { PhoneLogin } from './PhoneLogin';
import GtmManager from '../../utils/gtm/GtmManager';

const PhoneLoginContainer = withDependencies({
  sessionStore: TYPES.SessionStore,
  platformStore: PlatformStore,
  window: 'window',
  gtmManager: GtmManager,
  translationsStore: TranslationsStore,
  userStore: TYPES.UserStore,
})(PhoneLogin);

export default PhoneLoginContainer;
