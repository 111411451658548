import { autorun, observable } from 'mobx';

/**
 * @todo Should be merged with language store, not really sure why this is separate in the first place
 */
export class LocaleStore {
  @observable locale: string;

  constructor(private defaultLocale: string, private window: any) {}

  init() {
    const locale =
      this.window.localStorage.getItem('locale') || this.defaultLocale;

    if (!locale) {
      throw new Error('no default locale specified');
    }

    this.locale = locale;

    autorun(() => {
      this.window.localStorage.setItem('locale', this.locale);
    });
  }
}
