import { injectable, inject, postConstruct } from 'inversify';
import { autorun, computed, observable, action } from 'mobx';
import Session, {
  IAccessTokenContent,
  IKeymakerAccessTokenContent,
  ISession,
  IUserData,
} from '../models/Session';
import * as Sentry from '@sentry/browser';
import { TYPES } from '../types';
import { BrazeManager } from '../utils/BrazeManager';
import GtmManager from '../utils/gtm/GtmManager';
import { PlatformStore } from '../stores/PlatformStore';

export enum Audience {
  MASTER = 'master',
  GLOBAL = 'global',
}

@injectable()
export class SessionStore {
  @observable isUserReturned: boolean;
  @observable isDirty = false;
  @observable private session: Session = null;
  @inject('window') private window: Window;
  @inject(TYPES.BrazeManager) private braze: BrazeManager;
  @inject(GtmManager) private gtm: GtmManager;
  @inject(PlatformStore) private platformStore: PlatformStore;
  @inject('defaultOneWebUrl') private defaultOneWebUrl: string;

  @computed get platformIds(): string[] {
    const mainSession = this.getMainSession();
    if (!mainSession) {
      return [];
    }

    return mainSession.platformIds;
  }

  @computed get sessionPlatformVendorsIds(): string[] {
    const mainSession = this.getMainSession();
    if (!mainSession) {
      return [];
    }

    return mainSession.platformVendorIds;
  }

  @computed get mainSessionInfo() {
    return this.getMainSession();
  }

  // Proxy methods to current session
  @computed get jwtData(): IAccessTokenContent {
    return <IAccessTokenContent>this.proxySessionGetter('jwtData');
  }

  @computed get keymakerJwtData(): IKeymakerAccessTokenContent {
    return <IKeymakerAccessTokenContent>(
      this.proxySessionGetter('keymakerJwtData')
    );
  }

  @computed get userId(): string {
    const session = this.getMainSession();
    return session.getUserId();
  }

  @computed get isImpersonator(): boolean {
    return <boolean>this.proxySessionGetter('isImpersonator');
  }

  @computed get audience(): Audience {
    return <Audience>this.proxySessionGetter('audience');
  }

  @computed get firstVendorId(): string {
    return <string>this.proxySessionGetter('firstVendorId');
  }

  // TODO: Remove this method after keymaker migration
  @computed get hasRefreshToken(): boolean {
    return <boolean>this.proxySessionGetter('hasRefreshToken');
  }

  @computed get hasKeymakerRefreshToken(): boolean {
    return <boolean>this.proxySessionGetter('hasKeymakerRefreshToken');
  }
  // TODO: Remove this method after migration
  @computed get isLoggedIn(): boolean {
    const session = this.getMainSession();
    return !!session?.jwtData;
  }

  @computed get isLoggedInUsingKeymaker(): boolean {
    const session = this.getMainSession();
    return !!session?.keymakerJwtData;
  }

  @computed get hasSession(): boolean {
    return !!this.session;
  }

  @postConstruct() init() {
    const searchParams = new URLSearchParams(this.window.location.search);
    const isImpersonated = searchParams.get('isImpersonated') === 'true';
    if (isImpersonated) {
      this.window.localStorage.removeItem('mainSessionInfo');
      this.window.localStorage.setItem('isImpersonated', 'true');
    } else {
      this.window.localStorage.removeItem('isImpersonated');
    }
    const localStorageSessionInfo =
      this.window.localStorage.getItem('mainSessionInfo');
    const sessionInfo = JSON.parse(localStorageSessionInfo || '{}');

    // If sessionInfo is not empty, it means there is a session
    if (Object.keys(sessionInfo).length > 0) {
      this.setSessionInfo(sessionInfo);
      this.isUserReturned = this.isLoggedIn;
    }
    // Autosave sessionInfo on change
    autorun(() => {
      if (!this.isLoggedIn) {
        this.window.localStorage.removeItem('mainSessionInfo');
        this.clearSession();
      }
    });

    autorun(() => {
      if (
        this.mainSessionInfo &&
        Object.keys(this.mainSessionInfo).length > 0
      ) {
        this.window.localStorage.setItem(
          'mainSessionInfo',
          JSON.stringify(this.mainSessionInfo),
        );
      }
    });

    this.window.addEventListener('storage', (e) => {
      if (e.key === 'mainSessionInfo') {
        if (e.newValue === JSON.stringify(this.mainSessionInfo)) {
          return;
        }

        // Log out if new main session info or the new main session key is not defined
        if (!e.newValue) {
          this.clearSession();
          return;
        }

        this.setSessionInfo(JSON.parse(e.newValue || '{}'));
      }
    });
  }

  @action clearSession() {
    if (!!this.session) {
      this.session = null;
      this.isUserReturned = false;
    }
  }

  @action setSessionInfo(sessionContent: ISession) {
    if (sessionContent.isForceResetPassword) {
      this.session = new Session({
        user: sessionContent.user,
        accessTokenContent: { sub: sessionContent.accessTokenContent.sub },
        isForceResetPassword: sessionContent.isForceResetPassword,
      });
    } else {
      this.gtm.pushEvent('vpwebapp_load', {
        timestamp: Date.now(),
        label: 'vpwebapp_load_start',
        section: 'performance',
      });
      this.session = new Session(sessionContent);
      Sentry.setUser({
        id: this.session.getUserData('userId'),
        email: this.session.getUserData('email'),
        username: this.session.getUserData('name'),
      });
      this.braze.setUserData(this.session);
      this.isDirty = true;
    }
  }

  getMainSession(): Session {
    return this.session;
  }

  getUserData(prop: keyof IUserData): any {
    const session = this.getMainSession();
    if (session) {
      return session.getUserData(prop);
    }
  }

  getVendorCountry(): string {
    const session = this.getMainSession();
    if (session) {
      return session.getVendorCountry();
    }
  }

  getPinToken(): string {
    const session = this.getMainSession();
    return session?.pinToken ?? '';
  }

  getOneWebRedirectUrl(): string {
    const platformStore = this.platformStore;
    const oneWebUrl =
      platformStore.currentPlatform.oneWebUrl !== 'default'
        ? platformStore.currentPlatform.oneWebUrl
        : this.defaultOneWebUrl;

    const originalQueryParams = window.location.search;

    return (
      oneWebUrl +
      '/otp-signin?vp#' +
      this.session.keymakerAccessToken +
      '#' +
      this.session.keymakerRefreshToken +
      '#' +
      this.session.keymakerDeviceToken +
      '#' +
      location.pathname +
      '#' +
      originalQueryParams +
      '&utm_source=dashboard-vg-migration'
    );
  }

  private proxySessionGetter(key: keyof Session) {
    const session = this.getMainSession();
    if (session) {
      return session[key];
    }
  }
}
