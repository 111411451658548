import * as React from 'react';
import { FC, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';

import {
  Box,
  Typography,
  Button,
  CircularProgress,
  TextField,
  Divider,
} from '@mui/material';

import { PlatformStore } from '../../stores/PlatformStore';
import { UserStore } from '../../stores/UserStore';
import { SessionStore } from '../../stores/SessionStore';
import GtmManager from '../../utils/gtm/GtmManager';
import StartPrompt from '../../components/StartPrompt';
import { Translate } from '../../components/Translate';
import { ApiStatus } from '../../models/ApiStatus';
import { EnterPhoneForm } from '../../components/EnterPhoneForm';
import { validatePhoneNumber } from '../../utils/validatePhoneNumber';
import { VerificationCodeSnackbar } from './VerificationCodeSnackbar';
import { CaptchaHttpContextProvider } from '@deliveryhero/captcha';

const errorMsgStyle: any = {
  color: '#f44336',
  textAlign: 'center',
  marginTop: '16px',
  marginBottom: '8px',
};

const buttonStyle = {
  width: '100%',
};

const defaultButtonStyle = {
  width: '100%',
  marginTop: '16px',
};

const isNumeric = (str: string): boolean => {
  return /^\d+$/.test(str);
};

const EnterPhoneHeader: FC = () => (
  <header style={{ textAlign: 'center' }}>
    <Box>
      <Typography variant="heading3-700" gutterBottom component="div">
        <Translate code="global.enter_phone_number.form.title" />
      </Typography>
      <Box sx={{ marginTop: '12px' }}>
        <Translate code="global.enter_phone_number.form.subtitle" />
      </Box>
    </Box>
  </header>
);

const VerificationCodeHeader: FC<{ number: string }> = ({ number }) => (
  <header style={{ textAlign: 'center' }}>
    <Box>
      <Typography variant="h4" gutterBottom component="div">
        <Translate code="global.enter_verification_code.form.title" />
      </Typography>
      <Box sx={{ marginTop: '12px' }}>
        <Translate
          code="global.enter_verification_code.form.subtitle"
          params={{ number }}
        />
      </Box>
    </Box>
  </header>
);

type Props = any & {
  gtmManager: GtmManager;
  platformStore: PlatformStore;
  sessionStore: SessionStore;
  userStore: UserStore;
};

export const PhoneLoginComponent: FC<Props> = ({
  gtmManager,
  platformStore,
  sessionStore,
  userStore,
}) => {
  const [isEnterPhoneNumberView, setIsEnterPhoneNumberView] = useState(true);
  const [countryCode, setCountryCode] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [cooldownCounter, setCooldownCounter] = useState(0);
  const [showVerificationCodeSnackbar, setShowVerificationCodeSnackbar] =
    useState(false);
  const platform = platformStore.currentPlatform;
  const history = useHistory();

  const {
    postPhoneNumberToLoginApiStatus,
    postVerificationCodeApiStatus,
    postExchangeTokenApiStatus,
    postPhoneNumberToLogin,
    postVerificationCode,
    postExchangeToken,
    setPostPhoneNumberToLoginApiStatus,
    setPostVerificationCodeApiStatus,
    postPhoneNumberToLoginError,
  } = userStore;

  useEffect(() => {
    gtmManager.pushEvent(
      'onPageLoad',
      {
        eventTrigger: 'onPageLoad',
        eventCategory: 'login_with_phone_number',
        eventLabel: 'login_with_phone_number',
        eventAction: 'page_loaded',
      },
      false,
    );
  }, []);

  useEffect(() => {
    if (postPhoneNumberToLoginApiStatus === ApiStatus.SUCCESS) {
      setIsEnterPhoneNumberView(false);
      setCooldownCounter(30);
      setShowVerificationCodeSnackbar(true);

      gtmManager.pushEvent('onPageLoad', {
        eventTrigger: 'onPageLoad',
        eventCategory: 'login_with_phone_number',
        eventLabel: 'login_with_phone_number',
        eventAction: 'enter_verification_code_page_loaded',
      });
    }
  }, [postPhoneNumberToLoginApiStatus]);

  useEffect(() => {
    if (
      postVerificationCodeApiStatus === ApiStatus.ERROR ||
      postExchangeTokenApiStatus === ApiStatus.ERROR
    ) {
      gtmManager.pushEvent('onAPIResponse', {
        eventTrigger: 'onAPIResponse',
        eventCategory: 'login_with_phone_number',
        eventLabel: 'login_with_phone_number',
        eventAction: 'failed',
        errorMessage:
          'global.enter_verification_code.form.submit_error.incorrect_code',
      });
    }
  }, [postVerificationCodeApiStatus, postExchangeTokenApiStatus]);

  useEffect(() => {
    const timer =
      cooldownCounter > 0 &&
      setInterval(() => setCooldownCounter(cooldownCounter - 1), 1000);

    return () => clearInterval(timer);
  }, [cooldownCounter]);

  const onCountryCodeSelect = (event) => {
    const value = event?.target?.value || '';

    setCountryCode(value);
  };

  const onPhoneNumberInput = (event) => {
    const value = event?.target?.value || '';

    setPhoneNumber(value);
  };

  const isPhoneNumberValid = !phoneNumber
    ? true
    : validatePhoneNumber(phoneNumber);

  const onVerificationCodeInput = (event) => {
    const val = event.target.value;

    setVerificationCode(val);
  };

  const onSubmitPhoneNumberBtnClick = () => {
    const fullPhoneNumber = countryCode + phoneNumber;

    postPhoneNumberToLogin(fullPhoneNumber);
  };

  const submitPhoneNumberBtnContent =
    postPhoneNumberToLoginApiStatus === ApiStatus.LOADING ? (
      <CircularProgress size="25px" color="inherit" />
    ) : (
      <Translate code="global.enter_phone_number.form.submit" />
    );

  const onSubmitVerificationCodeBtnClick = async () => {
    const fullPhoneNumber = countryCode + phoneNumber;

    try {
      const keymakerToken = await postVerificationCode(
        fullPhoneNumber,
        verificationCode,
      );
      const oldToken = await postExchangeToken(keymakerToken.access_token);
      const tokenVendors = oldToken.accessTokenContent.vendors;
      const hasVendors =
        tokenVendors &&
        typeof tokenVendors === 'object' &&
        Object.keys(tokenVendors).length > 0;

      if (!hasVendors) {
        throw new Error(
          'A user must have at least one vendor code attached to their user account',
        );
      }

      // Vendors array in accessTokenContent is different in the response from bff than from old Auth node service
      // We need to convert it to the correct structure: can't be done in bff due to Protobuf limitations
      // `vendors: { FP_HK: { codes: ['a1', 'a2'] } }` needs to become: `vendors: { FP_HK: ['a1', 'a2'] }`
      const vendors = Object.keys(tokenVendors).reduce(
        (acc, item) => ({
          ...acc,
          [item]: tokenVendors[item].codes,
        }),
        {},
      );

      const formattedToken = {
        ...oldToken,
        accessTokenContent: {
          ...oldToken.accessTokenContent,
          vendors,
        },
        keymakerAccessToken: keymakerToken.access_token,
        keymakerRefreshToken: keymakerToken.refresh_token,
        keymakerDeviceToken: keymakerToken.device_token,
      };

      sessionStore.setSessionInfo(formattedToken);

      // this to extract the region and userId e.g. eu-34439b25-5ca8-4efb-a867-d9fb77b8d7de
      const [, , userId] = /([a-z]+)-(.*)/.exec(
        formattedToken.accessTokenContent?.sub,
      );

      gtmManager.pushEvent('onAPIResponse', {
        eventTrigger: 'onAPIResponse',
        eventCategory: 'login_with_phone_number',
        eventLabel: 'login_with_phone_number',
        eventAction: 'succeeded',
        userId,
      });

      history.push('/dashboard');
    } catch (err) {
      // eslint-disable-next-line
      console.error(
        `While trying to login with phone number and verification code, an error occurred: ${err}`,
      );
    }
  };

  const onResendVerificationCodeBtnClick = () => {
    if (!countryCode || !phoneNumber) {
      throw new Error(
        'A country code and phone number are required to resend verification code',
      );
    }

    const fullPhoneNumber = countryCode + phoneNumber;

    postPhoneNumberToLogin(fullPhoneNumber);

    gtmManager.pushEvent('onClick', {
      eventTrigger: 'onClick',
      eventCategory: 'login_with_phone_number',
      eventLabel: 'login_with_phone_number',
      eventAction: 'resend_code_button_clicked',
    });
  };

  const onRedirectBtnClick = () => {
    setPostPhoneNumberToLoginApiStatus(ApiStatus.IDLE);
    setPostVerificationCodeApiStatus(ApiStatus.IDLE);
    history.push('/login');
  };

  const submitVerificationCodeBtnContent =
    postVerificationCodeApiStatus === ApiStatus.LOADING ||
    postExchangeTokenApiStatus === ApiStatus.LOADING ? (
      <CircularProgress size="25px" color="inherit" />
    ) : (
      <Translate code="global.enter_verification_code.form.submit" />
    );

  const resendVerificationCodeBtnContent =
    postPhoneNumberToLoginApiStatus === ApiStatus.LOADING ? (
      <CircularProgress size="25px" color="inherit" />
    ) : (
      <>
        <Translate code="global.enter_verification_code.form.resend_code" />
        {cooldownCounter > 0 &&
          ` (0:${String(cooldownCounter).padStart(2, '0')})`}
      </>
    );

  const canSubmitPhoneNumber =
    countryCode &&
    phoneNumber &&
    isPhoneNumberValid &&
    postPhoneNumberToLoginApiStatus !== ApiStatus.LOADING;

  const isVerificationCodeInputValid =
    verificationCode.length === 6 && isNumeric(verificationCode);

  const canSubmitVerificationCode =
    isVerificationCodeInputValid &&
    postVerificationCodeApiStatus !== ApiStatus.LOADING &&
    postExchangeTokenApiStatus !== ApiStatus.LOADING;

  const canRequestVerificationCodeResend =
    postPhoneNumberToLoginApiStatus !== ApiStatus.LOADING &&
    postVerificationCodeApiStatus !== ApiStatus.LOADING &&
    postExchangeTokenApiStatus !== ApiStatus.LOADING &&
    cooldownCounter === 0;

  const showSubmitVerificationCodeError =
    postVerificationCodeApiStatus === ApiStatus.ERROR ||
    postExchangeTokenApiStatus === ApiStatus.ERROR;

  const showVerificationCodeInputError = verificationCode
    ? !isNumeric(verificationCode) || !(verificationCode.length <= 6)
    : false;

  const userPhoneNumberEnding = phoneNumber.substring(phoneNumber.length - 3);

  return (
    <CaptchaHttpContextProvider>
      <StartPrompt platform={platform}>
        {isEnterPhoneNumberView ? (
          <>
            <EnterPhoneHeader />
            <EnterPhoneForm
              phoneNumber={phoneNumber}
              onCountryCodeSelect={onCountryCodeSelect}
              onPhoneNumberInput={onPhoneNumberInput}
            />
            <Box sx={{ marginTop: '32px', marginBottom: '16px' }}>
              <Button
                sx={buttonStyle}
                variant="contained"
                disabled={!canSubmitPhoneNumber}
                onClick={onSubmitPhoneNumberBtnClick}
                data-testid="enter-phone-submit-button"
              >
                {submitPhoneNumberBtnContent}
              </Button>
            </Box>
            {postPhoneNumberToLoginApiStatus === ApiStatus.ERROR && (
              <Box sx={errorMsgStyle}>
                {postPhoneNumberToLoginError?.status === 404 ? (
                  <Translate code="global.phone_login.phone_number_not_found" />
                ) : (
                  <Translate code="global.contact_info.form.submit_error" />
                )}
              </Box>
            )}
          </>
        ) : (
          <>
            <VerificationCodeHeader number={userPhoneNumberEnding} />
            <Box sx={{ marginTop: '36px' }}>
              <TextField
                name="enter-verification-code-input"
                data-testid="enter-verification-code-input"
                sx={{ width: '100%' }}
                variant="standard"
                value={verificationCode}
                error={showVerificationCodeInputError}
                onChange={onVerificationCodeInput}
                autoComplete="off"
                autoFocus
              />
            </Box>
            <Box sx={{ marginTop: '32px' }}>
              <Button
                sx={buttonStyle}
                variant="contained"
                disabled={!canSubmitVerificationCode}
                onClick={onSubmitVerificationCodeBtnClick}
                data-testid="enter-verification-code-submit-button"
              >
                {submitVerificationCodeBtnContent}
              </Button>
              <Button
                sx={defaultButtonStyle}
                disabled={!canRequestVerificationCodeResend}
                onClick={onResendVerificationCodeBtnClick}
                data-testid="enter-verification-code-resend-button"
              >
                {resendVerificationCodeBtnContent}
              </Button>
            </Box>
            {showSubmitVerificationCodeError && (
              <Box sx={errorMsgStyle}>
                <Translate code="global.enter_verification_code.form.submit_error.incorrect_code" />
              </Box>
            )}
            <VerificationCodeSnackbar
              open={showVerificationCodeSnackbar}
              onClose={() => setShowVerificationCodeSnackbar(false)}
            />
          </>
        )}

        <Box sx={{ marginTop: '16px' }}>
          <Divider>
            <Translate code="global.login.divider.or" />
          </Divider>
          <Button
            sx={defaultButtonStyle}
            disabled={false}
            onClick={onRedirectBtnClick}
            data-testid="phone-login-go-back-button"
          >
            <Translate code="global.enter_phone_number.form.login_with_email" />
          </Button>
        </Box>
      </StartPrompt>
    </CaptchaHttpContextProvider>
  );
};

export const PhoneLogin = observer(PhoneLoginComponent);
