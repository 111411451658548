import { config } from '../dependencies';
import { History } from 'history';

export function getStaticPath(relativePath: string): string {
  const base = config.basePath === '' ? '' : `/${config.basePath}`;

  // Checks if the path is absolute
  if (relativePath.startsWith('https://')) {
    return relativePath;
  }

  if (relativePath.startsWith('/static/')) {
    return base + relativePath;
  }

  return `${base}/static/${relativePath}`;
}

export function getPathname(history: History): string {
  const basePath = config.basePath;
  const pathname = basePath
    ? history.location.pathname.replace(`/${basePath}/`, '/')
    : history.location.pathname;

  return pathname;
}
