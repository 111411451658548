import * as React from 'react';

import { useTheme } from '@mui/material/styles';
import {
  Box,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { PartnerLogo } from '../../../components/PartnerLogo';

const HungerStationPrivacy: React.FC = ({}) => {
  const theme = useTheme();

  return (
    <Container maxWidth="lg">
      <Box padding={4}>
        <Box marginBottom={2}>
          <PartnerLogo platform="hungerstation" width={212} height={40} />
        </Box>
        <Typography
          variant="h1"
          gutterBottom
          sx={{ color: `${theme.palette.primary.main}` }}
        >
          Privacy Statement (Vendor App)
        </Typography>

        <Typography variant="body1" paragraph>
          This Privacy Statement aims to clarify what personal data we process,
          why we process it, who receives your data and how you can exercise
          your legal rights. Accordingly, in this Privacy Statement, “personal
          data” means any information which directly identifies you as a person
          (like the combination of your full name and address), or can be used
          to identify you as a person (like a user ID connected to your
          identity). Similarly, “processing” refers to any operation performed
          on your personal data, for example the collection, storage, use,
          disclosure, or destruction of your personal data.
        </Typography>

        <Typography
          variant="h6"
          sx={{ color: `${theme.palette.primary.main}` }}
        >
          Who are we?
        </Typography>
        <Typography variant="body1" paragraph>
          We are Hungerstation LLC, part of the Delivery Hero Group
          headquartered in the official address Oranienburger Str. 70 10117
          Berlin, Germany. As regards your privacy, it’s us who decide how and
          for what purposes your personal data is processed. In data protection
          language that makes us a so-called “Data Controller” or “Controller”
          (the party responsible for how your personal data is processed. You
          can reach out to our support team for any questions related to your
          privacy on the following e-mail: help@hungerstation.com
        </Typography>

        <Typography
          variant="h6"
          sx={{ color: `${theme.palette.primary.main}` }}
        >
          What categories of personal data do we process?
        </Typography>

        <Box marginBottom={4}>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography
                      variant="subtitle1"
                      sx={{ color: theme.palette.primary.main }}
                    >
                      Data categories
                    </Typography>{' '}
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="subtitle1"
                      sx={{ color: theme.palette.primary.main }}
                    >
                      Explanation
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Company Information</TableCell>
                  <TableCell>
                    Name, Address, Tax ID, Geolocation Data, Order Data, Trading
                    Licence, Contact Data, Order History
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Proprietor Information</TableCell>
                  <TableCell>
                    Name, Surname, Address, ID Data, Contact Information
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Technical Information</TableCell>
                  <TableCell>
                    Device Data, Language Settings, Usage Data
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Order Information</TableCell>
                  <TableCell>
                    Order IDs, Product Names and Quantities, Order History,
                    Delivery Related Data
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Financial Information</TableCell>
                  <TableCell>
                    Bank Account, Tax ID, Payment Recipient Data.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Customer Support Information</TableCell>
                  <TableCell>Content of Support Chat</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        <Typography
          variant="h6"
          sx={{ color: `${theme.palette.primary.main}` }}
        >
          What do we do with your personal data?
        </Typography>
        <Typography variant="body1" paragraph>
          In order to provide services on our vendor portal, we use various
          tools and systems that are necessary for the operation of the portal.
          Accordingly, we collect, process and store the following categories of
          personal data when you use the portal:
        </Typography>
        <Typography variant="body1" paragraph>
          We process the above categories of personal data for the following
          purposes:
        </Typography>

        <Box marginBottom={4}>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography
                      variant="subtitle1"
                      sx={{ color: theme.palette.primary.main }}
                    >
                      Purpose
                    </Typography>{' '}
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="subtitle1"
                      sx={{ color: theme.palette.primary.main }}
                    >
                      Description and Legal Basis
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Account Creation and Maintenance</TableCell>
                  <TableCell>
                    <Typography variant="body1" paragraph>
                      The information we request during the account creation
                      process is necessary to take the first step in
                      establishing a business relationship with you so that we
                      can provide you with our services. We only ask for your
                      data that is necessary for this purpose.
                    </Typography>
                    <Typography variant="subtitle1">
                      Categories of personal data:
                    </Typography>
                    <ul>
                      <li>Company information</li>
                      <li>Proprietor Information</li>
                      <li>Technical Information</li>
                    </ul>
                    <Typography variant="body1" paragraph>
                      The legal basis for this processing is ‘entering into or
                      performance of a contract’ under Art. 6(1)(b) GDPR.
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Order Processing & Delivery</TableCell>
                  <TableCell>
                    <Typography variant="body1" paragraph>
                      Following information is necessary for us to conclude your
                      order and ensure the successful delivery.
                    </Typography>
                    <Typography variant="subtitle1">
                      Categories of personal data:
                    </Typography>
                    <ul>
                      <li>Order Information</li>
                      <li>Company Information</li>
                    </ul>
                    <Typography variant="body1" paragraph>
                      The legal basis for this processing is ‘entering into or
                      performance of a contract’ under Art. 6(1)(b) GDPR.
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Payment</TableCell>
                  <TableCell>
                    <Typography variant="body1" paragraph>
                      On a regular basis, your information will be shared with
                      payment providers to facilitate the payment process.
                    </Typography>
                    <Typography variant="subtitle1">
                      Categories of personal data:
                    </Typography>
                    <ul>
                      <li>Company Information</li>
                      <li>Financial Information</li>
                    </ul>
                    <Typography variant="body1" paragraph>
                      The legal basis for this processing is ‘entering into or
                      performance of a contract’ under Art. 6(1)(b) GDPR.
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Product Analytics</TableCell>
                  <TableCell>
                    <Typography variant="body1" paragraph>
                      We analyse the usage of the Vendor Portal in order to
                      ensure its security, optimization and effectiveness.
                      Accordingly, Vendor Portal generates and uses anonymous
                      information about the device you used to access.
                    </Typography>
                    <Typography variant="subtitle1">
                      Categories of personal data:
                    </Typography>
                    <ul>
                      <li>Technical Information</li>
                    </ul>
                    <Typography variant="body1" paragraph>
                      The legal basis for this processing is ‘legitimate
                      interest’ under Art. 6(1)(f) GDPR.
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Communication</TableCell>
                  <TableCell>
                    <Typography variant="body1" paragraph>
                      Different tools are used for communication between you and
                      the partnering entity. The purpose of the processing is
                      the communication of necessary information between the
                      parties involved to ensure we can adequately process your
                      order.
                    </Typography>
                    <Typography variant="subtitle1">
                      Categories of personal data:
                    </Typography>
                    <ul>
                      <li>Company Information</li>
                      <li>Order Information</li>
                    </ul>
                    <Typography variant="body1" paragraph>
                      The legal basis for this processing is ‘entering into or
                      performance of a contract’ under Art. 6(1)(b) GDPR.
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        <Typography
          variant="h6"
          sx={{ color: `${theme.palette.primary.main}` }}
        >
          Who will receive your data and under what circumstances?
        </Typography>
        <Typography variant="body1" paragraph>
          You can trust that, within our company, only those staff members will
          receive access to your personal data who need them in order to fulfill
          their professional duties, such as providing you with a great online
          experience, or looking into your support request. In certain
          scenarios, we also need to share your personal data with recipients
          outside of our company. Please be assured that your data is shared
          with these recipients only to the extent necessary for the specified
          purposes and only as we are legally permitted to do so.
        </Typography>

        <Typography variant="subtitle1">
          Delivery Hero group companies
        </Typography>
        <Typography variant="body1" paragraph>
          We are part of an international group of companies with legal entities
          in many parts of the world, including our group’s headquarters located
          with Delivery Hero SE in Berlin, Germany. In order to utilize our
          resources efficiently and ensure that our business processes function
          properly, we utilize our group-wide shared technological support
          services that sometimes necessitate sharing personal data with our
          parent company, Delivery Hero SE, or with the locations of our global
          tech hubs.
        </Typography>
        <Typography variant="body1" paragraph>
          Delivery Hero group companies are bound by strict intra-group data
          transfer agreements ascertaining compliance with data protection
          requirements whenever sharing personal data with group companies.
        </Typography>

        <Typography variant="subtitle1">Data processors</Typography>
        <Typography variant="body1" paragraph>
          We use various third-party service providers to perform our
          operations. Many of these providers process your personal data as
          so-called “data processors”. This means they are only allowed to
          process your personal data under our instructions and have no claims
          whatsoever to process your personal data for their own, independent
          purposes. Our processors are strictly monitored and we only engage
          processors who meet our high data protection standards.
        </Typography>
        <Typography variant="body1" paragraph>
          Our user platforms and databases run on cloud resources provided by
          the EU subsidiaries of Google Cloud Platform and Amazon Web Services.
          We use marketing and communications tools by companies such as
          SalesForce or Braze.
        </Typography>

        <Typography variant="subtitle1">
          Other third parties and service providers
        </Typography>
        <Typography variant="body1" paragraph>
          In addition to data processors, we also work with third parties, to
          whom we share your personal data, but who are not bound by our
          instructions and instead will process your data independently. These
          may be our consultants, lawyers or accountants who receive your data
          from us under a contract and process your personal data for legal
          reasons, or to protect our own interests. Under no circumstances will
          we sell or rent your personal information to third parties without
          your explicit, informed consent.
        </Typography>

        <Typography variant="subtitle1">
          Mergers & acquisitions, change of ownership
        </Typography>
        <Typography variant="body1" paragraph>
          In the event of a merger with, or acquisition by, another company or
          group of undertakings, we may need to disclose limited information to
          that company and their advisors who are under professional obligations
          to maintain the confidentiality of your personal data. This may occur
          in circumstances such as mutual due diligence assessments and
          regulatory disclosures.
        </Typography>
        <Typography variant="body1" paragraph>
          In any event, we will ensure that we only disclose the minimum amount
          of information necessary to conduct the transaction, while also
          carefully considering the feasibility of removing or anonymising any
          data that could identify individuals.
        </Typography>

        <Typography variant="subtitle1">
          Prosecuting authorities, courts and other public authorities
        </Typography>
        <Typography variant="body1" paragraph>
          From time to time we may be requested to disclose personal data to
          public authorities. In some circumstances, we may disclose personal
          data with public bodies in order to bring or defend legal claims, to
          protect our rights and interests, or to address security concerns.
        </Typography>
        <Typography variant="body1" paragraph>
          Examples of such situations include cooperating in the detection and
          prevention of crime, responding to legal processes such as court
          orders or subpoenas, or sharing data with tax authorities for
          tax-related purposes. The public authorities involved in these
          scenarios may include law enforcement agencies, courts, tax
          authorities, or other government officials.
        </Typography>

        <Typography
          variant="h6"
          sx={{ color: `${theme.palette.primary.main}` }}
        >
          What are your legal rights?
        </Typography>
        <Typography variant="body1" paragraph>
          Under the data protection laws, you are entitled to the following
          rights:
        </Typography>
        <Box marginBottom={4}>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Right to access</TableCell>
                  <TableCell>
                    You have the right to access your personal data and obtain
                    additional information on how we process it. You may also
                    request a copy of your personal data.
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Right to rectification</TableCell>
                  <TableCell>
                    If you notice that your personal data is incorrect, you can
                    always request that we correct it.
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Right to erasure</TableCell>
                  <TableCell>
                    You have the right to ask us to delete your personal data.
                    Please note that even if you exercise this right, we may be
                    required to retain some of your information if we process it
                    as part of our legal obligations, or in pursuit of our own
                    (or a third party’s legitimate interests) such as the
                    assertion of, or defense against, legal claims, preventing
                    fraud or protecting ourselves or others against abusive
                    behavior.
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Right to restriction of processing</TableCell>
                  <TableCell>
                    If you have requested the deletion of your personal data,
                    but we are legally prevented from immediately deleting it,
                    we will store your data in our archives and retain them for
                    the sole purpose of meeting our legal obligations. However,
                    you will not be able to use our services during this time,
                    as this would require us to de-archive your personal data.
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Right to data portability</TableCell>
                  <TableCell>
                    You can ask us to provide you or another Data Controller
                    with your personal data in a machine-readable format.
                    However, please note that this right only applies to data
                    that we process based on your consent.
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Right to object</TableCell>
                  <TableCell>
                    <Typography variant="body1" paragraph>
                      You have the right, for reasons arising from your
                      particular situation, to object at any time to any
                      processing of your personal data, which is processed on
                      the basis of our legitimate interests. If you object, we
                      will no longer process your personal data unless we can
                      prove compelling grounds for the processing that outweigh
                      your interests, rights and freedoms or the processing
                      serves to assert, exercise, or defend against legal
                      claims.
                    </Typography>
                    <Typography variant="body1" paragraph>
                      You also have the right to object at any time, without
                      giving any explanations, to the process of your personal
                      data for the purposes of direct marketing (including any
                      associated profiling).
                    </Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Right of complaint</TableCell>
                  <TableCell>
                    You can raise a complaint about our processing with the data
                    protection authority in the country of your habitual
                    residence, place of work, or the place where you think a
                    violation of data protection laws has occurred. In the case
                    of cross-border data processing, you can also lodge a
                    complaint with our lead supervisory authority in Berlin,
                    Germany.
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    Right not to be subject to a decision based solely on
                    automated processing
                  </TableCell>
                  <TableCell>
                    You have the right to object to a fully automated decision
                    (i.e. without any human intervention in the decision-making
                    process) that has legal effects or significantly affects
                    you.
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        <Typography variant="body1" paragraph>
          To exercise your rights, we encourage you to use the functions
          available in your account at any time. For example, if you would like
          to delete your data, or receive a copy of it, you can directly do so
          by following the relevant steps in your profile. These self-service
          methods are designed to expedite the process of fulfilling your
          rights. Alternatively, you can also reach out to the contact
          information given next to your Hungerstation contracting partner’s
          name and request our customer support team to assist you.
        </Typography>

        <Typography
          variant="h6"
          sx={{ color: `${theme.palette.primary.main}` }}
        >
          How long do we keep your data?
        </Typography>
        <Typography variant="body1" paragraph>
          We retain your personal data for as long as it is necessary to achieve
          the purposes we described above. The duration for which we retain your
          personal data is determined by factors such as the scope, nature and
          purposes of the personal data processing, and whether we have
          legitimate interests or legal obligations that require us to retain
          your personal data.
        </Typography>

        <Typography
          variant="h6"
          sx={{ color: `${theme.palette.primary.main}` }}
        >
          Changes to this Privacy Statement
        </Typography>
        <Typography variant="body1" paragraph>
          We may update this Privacy Statement to reflect our new processes, new
          technologies, and legal obligations. We are committed to keeping you
          informed of any changes to our privacy practices, so we encourage you
          to review this privacy statement to keep updated.
        </Typography>

        <Typography variant="overline">Last Modified: May-2024</Typography>
      </Box>
    </Container>
  );
};

export default HungerStationPrivacy;
