// TODO: Deprecated component. To be removed with PortalComponents folder
// But first we need to confirm that no active plugins use PortalComponents

import * as React from 'react';

import {
  Heatmap as __Heatmap,
  IHeatmapProps,
} from '@deliveryhero/vendor-portal-components';
import { withDependencies } from '@deliveryhero/react-inversify-provider';
import { TranslationsStore } from '../../stores/TranslationsStore';

export {
  IBarChart,
  IBarChartData,
  UnthemedBarChart,
  BarChart,
  IBaseHeatmapProps,
  IScaledHeatmapProps,
  IHeatmapProps,
  defaultColors,
  IScaledHeatmapState,
  ScaledHeatmap,
  ITableChartProps,
  TableChart,
  IHorizontalBarProps,
  HorizontalBar,
  IListProps,
  List,
} from '@deliveryhero/vendor-portal-components';

export type DependencyProps = {
  translationsStore: TranslationsStore;
};

const _Heatmap: React.FC<IHeatmapProps & DependencyProps> = (props) => (
  <__Heatmap
    noDataMessage={props.translationsStore.translate(
      'plugins.reports.errors.no_data',
    )}
    {...props}
  />
);

export const Heatmap: React.FC<IHeatmapProps> = withDependencies({
  translationsStore: TranslationsStore,
})(_Heatmap);
