import * as React from 'react';

import Typography from '@mui/material/Typography';

export type Props = {
  title: string | JSX.Element;
  text: string | JSX.Element;
};

const StartPromptHead: React.FC<Props> = ({ title, text }) => (
  <div>
    <Typography
      variant="heading3-700"
      sx={{
        textAlign: 'center',
        paddingBottom: '16px',
      }}
    >
      {title}
    </Typography>
    <Typography
      sx={{
        fontSize: '1rem',
        lineHeight: '1.5em',
        textAlign: 'center',
        paddingBottom: '16px',
      }}
    >
      {text}
    </Typography>
  </div>
);

export default StartPromptHead;
