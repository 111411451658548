import { getColorRgba } from '@deliveryhero/vt-portal-chardonnay/cjs/core/helpers';
import type {
  HexColor,
  Opacity,
} from '@deliveryhero/vt-portal-chardonnay/cjs/core/tokens/types';
import { useTheme } from '@mui/material';

export const getCurrentBrandInteractionMainColorRgba = (
  opacity: Opacity = 1,
): string => {
  const theme = useTheme();

  // As string is not assignable to HexColor / `#${string}`, here we slice the hex and re-interpolate
  const onlyHex = theme.palette.primary.main.slice(1);
  const currentBrandInteractionMainColor: HexColor = `#${onlyHex}`;

  return getColorRgba(currentBrandInteractionMainColor, opacity);
};
