import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { useIsMobile } from '@deliveryhero/vt-portal-chardonnay/cjs/core/helpers';
import { Brand } from '@deliveryhero/vt-portal-chardonnay/cjs/core/tokens/brands';

import OutletSelectorWrapper from '../OutletSelector/OutletSelectorWrapper';
import { QueryStringParser } from '../../utils/QueryStringParser';
import { History } from 'history';
import { VendorStore } from '../../stores/VendorStore';
import { Filters } from './../../components/filters/Filters';
import GtmManager from '../../utils/gtm/GtmManager';
import { SessionStore } from '../../stores/SessionStore';
import { getPathname } from '../../utils/PathHelper';
import { NotificationsStore } from '../../stores/NotificationsStore';
import { LocaleStore } from '../../stores/LocaleStore';
import NotificationBell from '../NotificationBell';
import { isMobileWebView } from '../../utils/isMobileWebView';
import { MobileTopBar } from './MobileTopBar';
import { HeaderActionsWrapper, HeaderWrapper } from './Header.styles';
import { ImpersonatorWarning } from '../../containers/MasterContainer/ImpersonatorWarning';
import { UserMigrationBanner } from '../../containers/MasterContainer/UserMigrationBanner';

import { SIDEBAR_WIDTH, USE_ONE_WEB_BANNER } from '../../constants';
import Icon from '@mui/material/Icon';
import FwfStore from '../../stores/FwfStore';
import { PlatformStore } from 'src/stores/PlatformStore';

export type HeaderProps = {
  title: string | React.ReactNode;
  subtitle: string | React.ReactNode;
  backLinkComponent?:
    | React.ComponentType<{ children: React.ReactNode }>
    | React.ForwardRefExoticComponent<{ children: React.ReactNode }>;
  navFilters: JSX.Element[];
  shouldRenderOutletSelector: boolean;
  vendorStore: VendorStore;
  platformStore: PlatformStore;
  history: History;
  isMultipleVendors: Boolean;
  isShrunk: boolean;
  shouldElevateHeader: boolean;
  ActionButtons: React.ComponentType;
  gtmManager: GtmManager;
  isNewNotifications: boolean;
  sessionStore: SessionStore;
  notificationsStore: NotificationsStore;
  localeStore: LocaleStore;
  backUrl: string;
  fwfStore: FwfStore;
};

/**
 * Adjusts the layout of the page to accommodate for the presence of banners.
 * It calculates the total height occupied by the impersonation and migration banners
 * and shifts the main content and other relevant elements accordingly to ensure
 * they are visually correct and do not overlap.
 */
function adjustLayoutForBanners(
  impersonationHeight: number,
  migrationHeight: number,
  isMobile: boolean,
) {
  // If both values are 0 then there is no need to shift the layout.
  if (impersonationHeight === 0 && migrationHeight === 0) {
    return;
  }

  const totalHeightOffset = impersonationHeight + migrationHeight;
  const elementsToAdjust = [
    { selector: '#oneweb-migration-banner', offset: impersonationHeight },
    {
      selector: '#navbar-wrapper',
      offset: totalHeightOffset,
      setMinHeight: true,
    },
    {
      selector: '#plugin-menu',
      overrideHeight: true,
    },
    { selector: '#sidebar-v2', offset: totalHeightOffset, setMinHeight: true },
    { selector: '#header-wrapper', offset: totalHeightOffset },
    {
      selector: '#master-content-wrapper',
      offset: totalHeightOffset,
      marginTop: isMobile ? 16 : totalHeightOffset,
      paddingBottom: true,
    },
  ];

  elementsToAdjust.forEach(
    ({
      selector,
      offset,
      setMinHeight,
      marginTop,
      paddingBottom,
      overrideHeight,
    }) => {
      const element = document.querySelector(selector) as HTMLElement;
      if (element) {
        element.style.translate = `0 ${offset}px`;
        if (setMinHeight) {
          element.style.minHeight = '97vh';
        }
        if (marginTop) {
          element.style.marginTop =
            Number(element.style.marginTop.replace('px', '')) +
            Number(marginTop) +
            'px';
        }
        if (paddingBottom) {
          element.style.paddingBottom = `${offset}px`;
        }
        if (overrideHeight) {
          element.style.height = 'calc(100vh - 220px)';
        }
      }
    },
  );
}

const renderFilters = (filters: JSX.Element[], isHeaderShrunk: boolean) => {
  let isLegacyFilters = false;
  const filtersChildren = filters.map<any>((elem) => {
    isLegacyFilters = isLegacyFilters || elem.props.name === 'timeframe';
    return React.cloneElement(elem, {
      key: elem.props.name,
      isHeaderShrunk,
    });
  });

  if (isLegacyFilters) {
    return <Filters children={filtersChildren} />;
  }
  return filtersChildren;
};

const Header: React.FC<HeaderProps> = ({
  title,
  backLinkComponent,
  navFilters,
  vendorStore,
  history,
  shouldRenderOutletSelector = false,
  isMultipleVendors,
  isShrunk,
  ActionButtons,
  gtmManager,
  isNewNotifications,
  sessionStore,
  platformStore,
  notificationsStore,
  localeStore,
  backUrl,
  shouldElevateHeader,
  fwfStore,
}) => {
  const isMobile = useIsMobile();

  const impersonatorBannerRef = useRef(null);
  const migrationBannerRef = useRef(null);

  const showImpersonatorMessage = (): boolean =>
    sessionStore.mainSessionInfo.isImpersonator;

  let platformName = platformStore?.currentPlatform?.name;
  const brandName = Brand[platformName] || Brand.foodpanda;

  const showOneWebMessage = (): boolean =>
    fwfStore.featureFlags.get(USE_ONE_WEB_BANNER);

  useEffect(() => {
    if (showImpersonatorMessage() || showOneWebMessage()) {
      const impersonationBannerHeight =
        impersonatorBannerRef.current?.clientHeight ?? 0;
      const migrationBannerHeight =
        migrationBannerRef.current?.clientHeight ?? 0;

      adjustLayoutForBanners(
        impersonationBannerHeight,
        migrationBannerHeight,
        isMobile,
      );
    }
  }, []);

  const handleSingleVendorFilterChange = (vendorId: string) => {
    const searchParams = QueryStringParser.parse(history.location.search);
    const pathname = getPathname(history);

    vendorStore.setCurrentVendorId(vendorId);
    history.push(
      pathname +
        QueryStringParser.stringify({
          ...searchParams,
          vendor: vendorId,
        }),
    );
  };

  const handleMultiVendorsFilterChange = (vendors: string[]) => {
    vendorStore.setSelectedVendors(vendors);
  };

  const renderOutletWrapper = (isHeaderShrunk: boolean) => {
    return (
      <OutletSelectorWrapper
        isMultipleVendors={isMultipleVendors}
        onChange={handleOutletSelectorChange}
        hideContentDescription={isMobile ? false : isHeaderShrunk}
        selectedOutlets={
          isMultipleVendors
            ? vendorStore.selectedVendorIds
            : [vendorStore.currentVendorId]
        }
      />
    );
  };

  const handleOutletSelectorChange = isMultipleVendors
    ? handleMultiVendorsFilterChange
    : (outletArray: string[]) => handleSingleVendorFilterChange(outletArray[0]);

  const renderIcon = () => {
    if (!backLinkComponent || isMobile) {
      return null;
    }
    return (
      <IconButton
        data-testid="back-icon"
        color="inherit"
        sx={{ transform: 'translateX(-12px)', padding: '12px' }}
        component={backLinkComponent}
      >
        <Icon>arrow_back</Icon>
      </IconButton>
    );
  };
  const width = !isMobile ? `calc(100% - ${SIDEBAR_WIDTH})` : '100%';

  const getTitle = () => {
    if (isShrunk && isMobile) {
      return null;
    }
    return (
      <Typography variant={isShrunk ? 'heading5-700' : 'heading3-700'}>
        {title}
      </Typography>
    );
  };

  const location = useLocation();

  const showNotificationBell =
    !isMobileWebView() &&
    !isMobile &&
    isNewNotifications &&
    !history.location.pathname.match(/onboarding|notifications/);

  const isMenuOpen = () => {
    // @ts-ignore
    return location.state?.menuOpen;
  };

  const toggleMenu = () => {
    const menuIsCurrentlyClosed = !isMenuOpen();

    if (menuIsCurrentlyClosed) {
      // Push new location state to open the menu
      history.push({
        ...location,
        state: {
          menuOpen: true,
        },
      });
    } else {
      // Go Back to old closed menu state
      history.goBack();
    }
  };

  const hasHeaderActionRow =
    (shouldRenderOutletSelector || navFilters.length) &&
    isMobile &&
    !isMobileWebView();

  const getMarginTop = () => (hasHeaderActionRow ? '20px' : 0);
  const redirectToOneWeb = () => {
    window.open(sessionStore.getOneWebRedirectUrl(), '_links');
  };
  return (
    <>
      {showImpersonatorMessage() && (
        <ImpersonatorWarning ref={impersonatorBannerRef} />
      )}
      {showOneWebMessage() && (
        <UserMigrationBanner
          brandName={brandName}
          onClick={redirectToOneWeb}
          gtmManager={gtmManager}
          ref={migrationBannerRef}
        />
      )}
      <HeaderWrapper
        isShrunk={isShrunk}
        isMobile={isMobile}
        shouldElevateHeader={shouldElevateHeader}
        container
        id={'header-wrapper'}
        data-testid={'header-wrapper'}
        sx={{ width: `${width}` }}
      >
        {isMobile && !isMobileWebView() && (
          <MobileTopBar
            backUrl={backUrl}
            toggleMenu={toggleMenu}
            isNewNotifications={isNewNotifications}
            gtmManager={gtmManager}
            sessionStore={sessionStore}
            notificationsStore={notificationsStore}
            localeStore={localeStore}
            pluginName={
              <Box>
                <Typography
                  variant={'heading5-500'}
                  sx={{ lineHeight: '1.5rem' }}
                >
                  {title}
                </Typography>
              </Box>
            }
          />
        )}
        <Grid item md={12} xs={12} marginTop={getMarginTop()}>
          {/* Main Content */}
          <Grid container justifyContent="space-between" alignItems="center">
            {!isMobileWebView() && !isMobile && (
              <Grid item md={4} xs={12} display="flex" alignItems="center">
                {renderIcon()}
                <Box>{getTitle()}</Box>
              </Grid>
            )}
            <Grid item md={8} xs={12} display="flex" alignItems="center">
              <HeaderActionsWrapper isMobile={isMobile}>
                {renderFilters(navFilters, isShrunk)}
                {Boolean(navFilters.length) && (
                  <Box display="inline" marginRight="16px" />
                )}
                {shouldRenderOutletSelector && renderOutletWrapper(isShrunk)}
                {ActionButtons && <ActionButtons data-testid="action-btns" />}
                {showNotificationBell && (
                  <NotificationBell
                    sessionStore={sessionStore}
                    localeStore={localeStore}
                    notificationsStore={notificationsStore}
                    history={history}
                    gtmManager={gtmManager}
                    isShrunk={isShrunk}
                  />
                )}
              </HeaderActionsWrapper>
            </Grid>
          </Grid>
        </Grid>
      </HeaderWrapper>
    </>
  );
};

export default Header;
