import { observable } from 'mobx';

export interface IDialog {
  messageCode: string;
  okButtonCode: string;
  okButtonCallback?: any;
  isCancelable?: boolean;
  cancelButtonCode?: string;
  isModal?: boolean;
  titleCode?: string;
  isOpen?: boolean;
}

const okButtonCallbackDefault = () => {};

export class Dialog implements IDialog {
  @observable messageCode: string;
  @observable okButtonCode: string;
  @observable isCancelable?: boolean;
  @observable cancelButtonCode?: string;
  @observable isModal?: boolean;
  @observable titleCode?: string;
  @observable isOpen: boolean;
  @observable okButtonCallback: Function;

  constructor(props: IDialog) {
    this.messageCode = props.messageCode;
    this.okButtonCode = props.okButtonCode;
    this.isCancelable = props.isCancelable;
    this.cancelButtonCode = props.cancelButtonCode;
    this.isModal = props.isModal;
    this.titleCode = props.titleCode;
    this.isOpen = props.isOpen || false;
    this.okButtonCallback = props.okButtonCallback || okButtonCallbackDefault;
  }
}
