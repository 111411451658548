import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { Box, Typography, Button, Divider, Icon } from '@mui/material';

import RegistrationView from './RegistrationView';
import { Translate } from '../../components/Translate';
import StartPrompt from '../../components/StartPrompt';
import LoginForm from '../../components/LoginForm';
import { IPlatform } from '../../config';

import { SELF_SIGN_UP_LINK_PER_COUNTRY } from './SELF_SIGN_UP_LINK_PER_COUNTRY';
import { Stack } from '@mui/material';
import GtmManager from '../../utils/gtm/GtmManager';

const SELF_SIGNUP_BLOCKED_COUNTRIES = [
  'hungerstation',
  'foodora',
  'foody',
  'efood',
];

interface Props {
  hasError: boolean;
  errorMessage: string;
  onChange: (event: any) => void;
  onSubmit: () => void;
  email: string;
  password: string;
  isLoading: boolean;
  getError: (key: string) => JSX.Element | string;
  isLoggedOut: boolean;
  platform: IPlatform;
  feedbackEmail: string;
  gtm: GtmManager;
}

export const LoginView: React.FC<Props> = ({
  hasError,
  errorMessage,
  onChange,
  onSubmit,
  email,
  password,
  isLoading,
  getError,
  isLoggedOut,
  platform,
  feedbackEmail,
  gtm,
}) => {
  const history = useHistory();
  const platformName = platform.name;
  const titleTranslationCode = `global.login.welcome_title.${platformName}`;
  const messageTranslationCode = 'global.login.welcome_subtitle';

  const onPhoneLoginButtonClick = () => {
    history.push('/phone-login');
  };

  const onForgotPasswordButtonClick = () => {
    history.push('/reset-password');
  };

  return (
    <div>
      <StartPrompt
        platform={platform as any}
        RegistrationView={
          !!!SELF_SIGNUP_BLOCKED_COUNTRIES.includes(platform.name) ? (
            <RegistrationView
              countrySSULinks={SELF_SIGN_UP_LINK_PER_COUNTRY.filter(
                (i) => i.brand === platform.name || platform.name === 'default',
              )}
              gtm={gtm}
            />
          ) : null
        }
      >
        <Box sx={{ textAlign: 'center' }}>
          <Typography
            variant="heading3-700"
            sx={{
              marginBottom: '28px',
            }}
          >
            <Translate code={titleTranslationCode} />
          </Typography>

          <Typography
            variant="secondary-500"
            sx={{
              color: '#6B6B6B',
              marginBottom: '12px',
            }}
          >
            <Translate code={messageTranslationCode} />
          </Typography>
        </Box>
        {hasError ? (
          <Box sx={{ color: 'red' }} id="login-error" data-testid="login-error">
            {errorMessage}
          </Box>
        ) : null}
        {isLoggedOut && !hasError ? (
          <Box sx={{ color: 'red' }} id="login-logout-error">
            <Translate code="global.login.logout_out" />
          </Box>
        ) : null}

        <LoginForm
          email={email}
          password={password}
          isSubmitting={isLoading}
          onChange={onChange}
          onSubmit={onSubmit}
          getError={getError}
        />

        <Stack direction="row" justifyContent="end">
          <Button
            sx={{
              marginTop: '4px',
              marginBottom: '4px',
            }}
            id="button_forgot_password"
            data-testid="login-forgotten-password"
            onClick={onForgotPasswordButtonClick}
          >
            <Translate code="global.login.forgot_password" />
          </Button>
        </Stack>

        {feedbackEmail && (
          <Box
            sx={{
              fontSize: '0.9em',
              color: '#aaaaaa',
              width: '300px',
              margin: '5px auto 30px',
              lineHeight: '1.45em',
            }}
          >
            <Translate code="global.login.feedback" />{' '}
            <a
              style={{ color: '#aaaaaa' }}
              href={`mailto:${feedbackEmail}`}
              data-testid="login-feedback-link"
            >
              {feedbackEmail}
            </a>
          </Box>
        )}
        <div style={{ textAlign: 'center', marginTop: '12px' }}>
          <Divider>
            <Translate code="global.login.divider.or" />
          </Divider>
          <Button
            color="primary"
            variant="outlined"
            data-testid="login-with-phone-number-button"
            onClick={onPhoneLoginButtonClick}
            style={{ width: '100%', marginTop: '8px' }}
          >
            <Icon sx={{ marginRight: '8px' }}>call</Icon>
            <Translate code="global.login.login_with_phone" />
          </Button>
        </div>
      </StartPrompt>
    </div>
  );
};
LoginView.displayName = 'Login';

export default LoginView;
