import * as React from 'react';
import { useState } from 'react';
import { Snackbar, SnackbarContent } from '@mui/material';

import ResetPasswordView from './ResetPasswordView';
import ResetPasswordSuccessView from './ResetPasswordSuccessView';
import { PlatformStore } from '../../stores/PlatformStore';
import { Translate } from '../../components/Translate';
import AuthService from '../../services/AuthService';
import { CaptchaHttpContextProvider } from '@deliveryhero/captcha';

export type Props = {
  platformStore: PlatformStore;
  authService: AuthService;
};

export type State = {
  email: string;
  loading: boolean;
  snackbarMessage: string;
  requestSuccess: boolean;
};

export const ResetPasswordContainer: React.FC<Props> = ({
  platformStore,
  authService,
}) => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [requestSuccess, setRequestSuccess] = useState(false);

  const currentPlatform = platformStore.currentPlatform;
  const snackbarOpen = snackbarMessage !== '';

  const resetPassword = (emailAddress: string): Promise<void> => {
    return authService.requestMasterPasswordReset(emailAddress);
  };

  const onEmailChange = (event) => {
    const value = event.target.value;

    setEmail(value);
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);

    try {
      await resetPassword(email);
      setSnackbarMessage('login.reset_password.request_succeeded');
      setRequestSuccess(true);
      setLoading(false);
    } catch (err) {
      setSnackbarMessage('login.reset_password.request_failed');
      setRequestSuccess(false);
      setLoading(false);
    }
  };

  const closeSnackbar = () => {
    setSnackbarMessage('');
  };

  return (
    <CaptchaHttpContextProvider>
      {requestSuccess ? (
        <ResetPasswordSuccessView platform={currentPlatform} />
      ) : (
        <ResetPasswordView
          platform={currentPlatform}
          email={email}
          onEmailChange={onEmailChange}
          onSubmit={onSubmit}
          loading={loading}
        />
      )}

      <Snackbar
        open={snackbarOpen}
        onClose={closeSnackbar}
        autoHideDuration={3000}
      >
        <SnackbarContent
          message={<Translate code={snackbarMessage} />}
          data-testid="reset-password-snackbar-content"
        />
      </Snackbar>
    </CaptchaHttpContextProvider>
  );
};
