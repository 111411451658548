import { withDependencies } from '@deliveryhero/react-inversify-provider';
import GtmManager from '../../utils/gtm/GtmManager';
import { TYPES } from '../../types';
import { PlatformStore } from '../../stores/PlatformStore';
import { TranslationsStore } from '../../stores/TranslationsStore';
import { AddPhone } from './AddPhone';

const AddPhoneContainer = withDependencies({
  sessionStore: TYPES.SessionStore,
  platformStore: PlatformStore,
  window: 'window',
  gtmManager: GtmManager,
  translationsStore: TranslationsStore,
  userStore: TYPES.UserStore,
})(AddPhone);

export default AddPhoneContainer;
