import * as React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { defaultTheme } from '../../theme';

/* TODO: this HOC must be removed after full migration to Chardonnay and mui v5*/
const withThemeProviders = (Container) => (props) =>
  (
    <ThemeProvider theme={defaultTheme}>
      <StyledThemeProvider theme={defaultTheme}>
        <Container {...props} />
      </StyledThemeProvider>
    </ThemeProvider>
  );

export default withThemeProviders;
