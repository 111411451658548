import React from 'react';
import { FormControl, FormHelperText } from '@mui/material';
import { PasswordField } from '../../components/PasswordField';

type PasswordInputProps = {
  name: string;
  value: string;
  label: JSX.Element | string;
  onChange: (event: any) => void;
  error: any;
};

export const PasswordInput: React.FC<PasswordInputProps> = ({
  label,
  name,
  value,
  onChange,
  error,
}) => (
  <FormControl fullWidth={true}>
    <PasswordField
      name={name}
      label={label}
      value={value}
      error={!!error}
      onChange={onChange}
      data-testid={`reset-${name}-field`}
      fullWidth={true}
    />
    {!!error && (
      <FormHelperText data-testid={`${name}-error`} error={!!error}>
        {error}
      </FormHelperText>
    )}
  </FormControl>
);
