module.exports = {
  AP_PA: {},
  CD_CO: {},
  DJ_CZ: {},
  FO_CA: {},
  FO_NO: {
    baseUrl: 'https://sdk.iad-01.braze.com',
    apiKey: '012247f0-6577-4ef0-a2e6-c312337f2e10',
  },
  FP_BD: {
    baseUrl: 'https://sdk.iad-01.braze.com',
    apiKey: '0f8728a9-66d9-4385-a7f5-6a85db26a36c',
  },
  FP_BG: {
    baseUrl: 'https://sdk.iad-01.braze.com',
    apiKey: '0f8728a9-66d9-4385-a7f5-6a85db26a36c',
  },
  FP_DE: {
    baseUrl: 'https://sdk.iad-01.braze.com',
    apiKey: '0f8728a9-66d9-4385-a7f5-6a85db26a36c',
  },
  FP_HK: {
    baseUrl: 'https://sdk.iad-01.braze.com',
    apiKey: '0f8728a9-66d9-4385-a7f5-6a85db26a36c',
  },
  NP_HU: {
    baseUrl: 'https://sdk.iad-01.braze.com',
    apiKey: '0b7ab39e-f6b1-499c-9932-1a1a2e0aacf2',
  },
  FP_JP: {
    baseUrl: 'https://sdk.iad-01.braze.com',
    apiKey: '0f8728a9-66d9-4385-a7f5-6a85db26a36c',
  },
  FP_KH: {
    baseUrl: 'https://sdk.iad-01.braze.com',
    apiKey: '0f8728a9-66d9-4385-a7f5-6a85db26a36c',
  },
  FP_LA: {
    baseUrl: 'https://sdk.iad-01.braze.com',
    apiKey: '0f8728a9-66d9-4385-a7f5-6a85db26a36c',
  },
  FP_MM: {
    baseUrl: 'https://sdk.iad-01.braze.com',
    apiKey: '0f8728a9-66d9-4385-a7f5-6a85db26a36c',
  },
  FP_MY: {
    baseUrl: 'https://sdk.iad-01.braze.com',
    apiKey: '0f8728a9-66d9-4385-a7f5-6a85db26a36c',
  },
  FP_PH: {
    baseUrl: 'https://sdk.iad-01.braze.com',
    apiKey: '0f8728a9-66d9-4385-a7f5-6a85db26a36c',
  },
  FP_PK: {
    baseUrl: 'https://sdk.iad-01.braze.com',
    apiKey: '0f8728a9-66d9-4385-a7f5-6a85db26a36c',
  },
  FP_RO: {},
  FP_SG: {
    baseUrl: 'https://sdk.iad-01.braze.com',
    apiKey: '0f8728a9-66d9-4385-a7f5-6a85db26a36c',
  },
  FP_SK: {
    baseUrl: 'https://sdk.iad-01.braze.com',
    apiKey: '0f8728a9-66d9-4385-a7f5-6a85db26a36c',
  },
  FP_TH: {
    baseUrl: 'https://sdk.iad-01.braze.com',
    apiKey: '0f8728a9-66d9-4385-a7f5-6a85db26a36c',
  },
  FP_TW: {
    baseUrl: 'https://sdk.iad-01.braze.com',
    apiKey: '0f8728a9-66d9-4385-a7f5-6a85db26a36c',
  },
  HF_EG: {
    baseUrl: 'https://sdk.iad-01.braze.com',
    apiKey: '7d1ce153-1dd9-4db3-9796-86b4c6936e7d',
  },
  HS_BH: {},
  HS_SA: {
    baseUrl: 'https://sdk.iad-01.braze.com',
    apiKey: 'e641bf81-6d6f-405c-be59-22bbf165026e',
  },
  MJM_AT: {
    baseUrl: 'https://sdk.iad-01.braze.com',
    apiKey: '0f8728a9-66d9-4385-a7f5-6a85db26a36c',
  },
  OP_SE: {},
  HN_DK: {
    baseUrl: 'https://sdk.iad-01.braze.com',
    apiKey: '58e9ce66-4dde-4c36-99f3-392ea9b058ed',
  },
  PO_FI: {
    baseUrl: 'https://sdk.iad-01.braze.com',
    apiKey: 'cf985e30-cbb6-4d18-9d42-2d6b6a91b3ca',
  },
  PY_AR: {
    baseUrl: 'https://sdk.iad-01.braze.com',
    apiKey: 'cf985e30-cbb6-4d18-9d42-2d6b6a91b3ca',
  },
  PY_BO: {
    baseUrl: 'https://sdk.iad-01.braze.com',
    apiKey: 'cf985e30-cbb6-4d18-9d42-2d6b6a91b3ca',
  },
  PY_CL: {
    baseUrl: 'https://sdk.iad-01.braze.com',
    apiKey: 'cf985e30-cbb6-4d18-9d42-2d6b6a91b3ca',
  },
  PY_CR: {
    baseUrl: 'https://sdk.iad-01.braze.com',
    apiKey: 'cf985e30-cbb6-4d18-9d42-2d6b6a91b3ca',
  },
  PY_DO: {
    baseUrl: 'https://sdk.iad-01.braze.com',
    apiKey: 'cf985e30-cbb6-4d18-9d42-2d6b6a91b3ca',
  },
  PY_EC: {
    baseUrl: 'https://sdk.iad-01.braze.com',
    apiKey: 'cf985e30-cbb6-4d18-9d42-2d6b6a91b3ca',
  },
  PY_GT: {
    baseUrl: 'https://sdk.iad-01.braze.com',
    apiKey: 'cf985e30-cbb6-4d18-9d42-2d6b6a91b3ca',
  },
  PY_HN: {
    baseUrl: 'https://sdk.iad-01.braze.com',
    apiKey: 'cf985e30-cbb6-4d18-9d42-2d6b6a91b3ca',
  },
  PY_MX: {
    baseUrl: 'https://sdk.iad-01.braze.com',
    apiKey: 'cf985e30-cbb6-4d18-9d42-2d6b6a91b3ca',
  },
  PY_NI: {
    baseUrl: 'https://sdk.iad-01.braze.com',
    apiKey: 'cf985e30-cbb6-4d18-9d42-2d6b6a91b3ca',
  },
  PY_PA: {
    baseUrl: 'https://sdk.iad-01.braze.com',
    apiKey: 'cf985e30-cbb6-4d18-9d42-2d6b6a91b3ca',
  },
  PY_PE: {
    baseUrl: 'https://sdk.iad-01.braze.com',
    apiKey: 'cf985e30-cbb6-4d18-9d42-2d6b6a91b3ca',
  },
  PY_PR: {
    baseUrl: 'https://sdk.iad-01.braze.com',
    apiKey: 'cf985e30-cbb6-4d18-9d42-2d6b6a91b3ca',
  },
  PY_PY: {
    baseUrl: 'https://sdk.iad-01.braze.com',
    apiKey: 'cf985e30-cbb6-4d18-9d42-2d6b6a91b3ca',
  },
  PY_SV: {
    baseUrl: 'https://sdk.iad-01.braze.com',
    apiKey: 'cf985e30-cbb6-4d18-9d42-2d6b6a91b3ca',
  },
  PY_UY: {
    baseUrl: 'https://sdk.iad-01.braze.com',
    apiKey: 'cf985e30-cbb6-4d18-9d42-2d6b6a91b3ca',
  },
  PY_VE: {
    baseUrl: 'https://sdk.iad-01.braze.com',
    apiKey: 'cf985e30-cbb6-4d18-9d42-2d6b6a91b3ca',
  },
  TB_AE: {
    baseUrl: 'https://sdk.iad-01.braze.com',
    apiKey: 'd3a9fb22-aa0f-4086-9d75-c0eae4fd8ebb',
  },
  HF_EG: {
    baseUrl: 'https://sdk.iad-01.braze.com',
    apiKey: 'd3a9fb22-aa0f-4086-9d75-c0eae4fd8ebb',
  },
  TB_BH: {
    baseUrl: 'https://sdk.iad-01.braze.com',
    apiKey: 'd3a9fb22-aa0f-4086-9d75-c0eae4fd8ebb',
  },
  TB_IQ: {
    baseUrl: 'https://sdk.iad-01.braze.com',
    apiKey: 'd3a9fb22-aa0f-4086-9d75-c0eae4fd8ebb',
  },
  TB_JO: {
    baseUrl: 'https://sdk.iad-01.braze.com',
    apiKey: 'd3a9fb22-aa0f-4086-9d75-c0eae4fd8ebb',
  },
  TB_KW: {
    baseUrl: 'https://sdk.iad-01.braze.com',
    apiKey: 'd3a9fb22-aa0f-4086-9d75-c0eae4fd8ebb',
  },
  TB_OM: {
    baseUrl: 'https://sdk.iad-01.braze.com',
    apiKey: 'd3a9fb22-aa0f-4086-9d75-c0eae4fd8ebb',
  },
  TB_OT: {
    baseUrl: 'https://sdk.iad-01.braze.com',
    apiKey: 'd3a9fb22-aa0f-4086-9d75-c0eae4fd8ebb',
  },
  TB_QA: {
    baseUrl: 'https://sdk.iad-01.braze.com',
    apiKey: 'd3a9fb22-aa0f-4086-9d75-c0eae4fd8ebb',
  },
  TB_SA: {
    baseUrl: 'https://sdk.iad-01.braze.com',
    apiKey: 'd3a9fb22-aa0f-4086-9d75-c0eae4fd8ebb',
  },
  YS_TR: {
    baseUrl: 'https://sdk.iad-01.braze.com',
    apiKey: 'a1ab619c-4d95-4818-8993-b75bb085e1df',
  },
};
