/* eslint-disable max-len */
import * as React from 'react';

import { LogoProps } from '../LogoProps';

export const YemeksepetiSquaredPartnerLogo: React.FC<LogoProps> = ({
  width = 64,
}) => (
  <svg
    data-testid="platform-logo-svg"
    width={width}
    height={width}
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="64" height="64" fill="#FA0050" />
    <path
      d="M9.93415 30.9697H41.1115L39.1941 42.1263H8L9.93415 30.9697Z"
      fill="white"
    />
    <path
      d="M19.7188 35.4178C19.7489 35.2691 19.6588 35.1229 19.5127 35.0822C19.1477 34.9882 18.7721 34.9381 18.3951 34.9328C16.3783 34.9328 15.5823 36.506 15.5676 37.8731C15.5676 38.7304 16.006 39.3115 16.7145 39.3162C17.0828 39.3108 17.4317 39.1494 17.6753 38.8725C17.6692 38.9692 17.7139 39.062 17.7927 39.1174C17.9868 39.2435 18.209 39.3195 18.4398 39.3382C18.7534 39.3649 18.9449 39.2895 19.013 38.9512L19.4053 36.9297L19.7188 35.4178ZM17.3196 38.1846C17.0748 38.1846 16.9547 37.9618 16.9594 37.5969C16.9594 37.0211 17.3737 35.9749 18.0108 35.9769C18.0929 35.9783 18.175 35.9876 18.2557 36.0036L18.0549 36.9837C18.0549 37.0104 17.9962 37.2433 17.9962 37.2433C17.81 37.9538 17.5452 38.184 17.3196 38.184V38.1846Z"
      fill="#FA0050"
    />
    <path
      d="M13.9626 34.7261C13.6984 34.7281 13.4389 34.7968 13.2087 34.9263C13.0039 35.0364 12.8277 35.1925 12.6936 35.382L12.715 35.2725C12.7497 35.1224 12.6896 34.9663 12.5622 34.8789C12.3994 34.7842 12.2179 34.7254 12.0304 34.7074C11.9043 34.6921 11.7769 34.6921 11.6515 34.7074C11.4633 34.7435 11.3913 34.8502 11.3486 35.0697C11.3412 35.0864 10.2918 40.4906 10.2918 40.4906C10.2371 40.7841 10.3325 40.8675 10.4919 40.9462H10.5059C10.7561 41.0643 11.0337 41.1137 11.3099 41.089C11.6268 41.053 11.7415 41.0103 11.8296 40.5953C11.8296 40.586 12.0678 39.3197 12.0678 39.3197H12.0872C12.1619 39.363 12.2419 39.3971 12.3253 39.4197C12.4815 39.4584 12.6416 39.4784 12.8024 39.4791C14.3289 39.4818 15.2996 37.974 15.2996 36.4027V36.3981C15.3023 35.5014 14.9013 34.7141 13.9619 34.7261H13.9626ZM13.7191 36.5575C13.7191 36.5575 13.7191 36.5562 13.7191 36.5555C13.7184 37.3021 13.3061 38.3396 12.715 38.3442C12.6516 38.3442 12.5882 38.3362 12.5268 38.3202C12.4381 38.2902 12.3567 38.2415 12.2886 38.1774L12.4868 37.0686C12.5242 36.695 12.6929 36.3467 12.9638 36.0858C13.0599 36.0091 13.1793 35.9657 13.3028 35.9644C13.6263 35.9644 13.7197 36.2613 13.7197 36.5562C13.7197 36.5555 13.7197 36.5542 13.7197 36.5535V36.5582L13.7191 36.5575Z"
      fill="#FA0050"
    />
    <path
      d="M34.8967 36.2458C34.914 35.4191 34.3122 34.8514 33.4049 34.8227C31.7976 34.7713 30.8542 36.1524 30.8109 37.4901C30.7742 38.6396 31.3893 39.3522 32.647 39.3922C33.1707 39.4089 33.6417 39.3422 34.1528 39.1714C34.373 39.098 34.395 38.9946 34.3856 38.8645C34.3696 38.6463 34.2862 38.4208 34.2095 38.3214C34.0847 38.1579 34.018 38.1159 33.8132 38.1706C33.6524 38.2133 33.3842 38.2793 33.0599 38.272C32.7277 38.2647 32.4882 38.1639 32.3394 38.0245C32.2527 37.9317 32.2106 37.8223 32.1966 37.7142C33.8472 37.8217 34.8587 37.3953 34.896 36.2458H34.8967ZM32.3661 36.6501C32.4321 36.2925 32.7143 35.8882 33.1994 35.9035C33.4329 35.9109 33.5543 36.0496 33.5483 36.2298V36.2384C33.5383 36.544 33.1827 36.6941 32.3661 36.6501Z"
      fill="#FA0050"
    />
    <path
      d="M25.7822 38.2935C25.7221 38.1553 25.6374 38.0813 25.472 38.1033C25.3819 38.1153 25.1911 38.1687 25.1123 38.1013C25.0496 38.0473 25.0049 37.9765 25.0009 37.8711C24.9976 37.7871 25.051 37.5349 25.0777 37.4014L25.3532 35.9823H25.993C26.0724 35.983 26.2365 35.8943 26.2752 35.7095C26.3273 35.4613 26.346 35.3432 26.3586 35.2191C26.376 35.0443 26.1985 34.9068 26.0224 34.9068L25.5627 34.9048C25.5627 34.9048 25.7081 34.1883 25.7081 34.1676C25.7402 33.9308 25.6521 33.8507 25.5053 33.7886C25.2711 33.6899 25.0036 33.6405 24.7794 33.6472C24.4799 33.6565 24.3611 33.7199 24.279 34.1122L24.1249 34.9048H23.836C23.6993 34.9048 23.5785 35.0009 23.5525 35.1344C23.5291 35.2544 23.4931 35.4286 23.4577 35.6134C23.4364 35.7268 23.3944 35.9623 23.7379 35.9803H23.9188L23.5965 37.6176C23.5338 37.9112 23.5024 38.1894 23.5111 38.3715C23.5405 38.976 23.9194 39.379 24.72 39.379C25.0583 39.379 25.2985 39.2956 25.4866 39.2008C25.7989 39.044 25.8369 38.97 25.8536 38.7311C25.8609 38.6284 25.8449 38.4349 25.7835 38.2928L25.7822 38.2935Z"
      fill="#FA0050"
    />
    <path
      d="M22.7044 34.7335C22.5163 34.7355 22.3008 34.8576 22.134 34.983C21.9218 35.1418 21.7324 35.368 21.5749 35.6482H21.5602L21.6043 35.0958C21.6169 34.8429 21.4201 34.6895 21.1105 34.7248C20.8737 34.7522 20.5861 34.8843 20.4253 35.0624C20.3039 35.1965 20.2979 35.3653 20.2766 35.5655C20.2432 35.873 20.1925 36.2106 20.1331 36.5302C20.1331 36.5302 19.8256 38.1768 19.7141 38.7273C19.6521 39.0328 19.7361 39.1262 19.915 39.2123C20.1471 39.3237 20.4233 39.3671 20.6569 39.3484C20.9371 39.3257 21.0311 39.2803 21.1052 38.9101C21.1179 38.846 21.3581 37.6938 21.4221 37.3862C21.6003 36.7004 21.9519 36.1666 22.623 36.188C22.8025 36.1933 22.9233 36.1186 23.008 35.9751C23.1388 35.7536 23.2155 35.5094 23.2068 35.2532C23.1955 34.919 23.0721 34.7302 22.7038 34.7348L22.7044 34.7335Z"
      fill="#FA0050"
    />
    <path
      d="M37.9161 34.7335C37.728 34.7355 37.5125 34.8576 37.3457 34.983C37.1335 35.1418 36.944 35.368 36.7866 35.6482H36.7719L36.8159 35.0958C36.8286 34.8429 36.6318 34.6895 36.3222 34.7248C36.0854 34.7522 35.7978 34.8843 35.637 35.0624C35.5156 35.1965 35.5096 35.3653 35.4882 35.5655C35.4549 35.873 35.4042 36.2106 35.3448 36.5302C35.3448 36.5302 35.0372 38.1768 34.9258 38.7273C34.8638 39.0328 34.9478 39.1262 35.1266 39.2123C35.3588 39.3237 35.635 39.3671 35.8685 39.3484C36.1487 39.3257 36.2428 39.2803 36.3169 38.9101C36.3295 38.846 36.5697 37.6938 36.6338 37.3862C36.8119 36.7004 37.1635 36.1666 37.8347 36.188C38.0142 36.1933 38.1349 36.1186 38.2197 35.9751C38.3504 35.7536 38.4272 35.5094 38.4185 35.2532C38.4072 34.919 38.2837 34.7302 37.9154 34.7348L37.9161 34.7335Z"
      fill="#FA0050"
    />
    <path
      d="M29.5782 34.8368C29.1192 34.8368 28.6835 35.0303 28.3453 35.3686V35.3445C28.3933 35.1751 28.3213 35.0063 28.2005 34.9095C27.983 34.7888 27.7415 34.7161 27.4993 34.7161C27.0403 34.692 27.0156 34.8368 26.9435 35.1751L26.267 38.7765C26.1943 39.0908 26.291 39.1875 26.4605 39.2602C26.702 39.381 26.9442 39.4297 27.2097 39.405C27.5 39.381 27.5967 39.3323 27.6688 38.97C27.6928 38.8973 28.007 37.3508 28.007 37.3508C28.1765 36.5048 28.4907 36.0211 28.805 36.0211C29.0225 36.0211 29.1192 36.1658 29.1192 36.3833C29.1192 36.5768 29.0952 36.7456 29.0465 36.9391L28.6842 38.7518C28.6362 38.8966 28.6842 39.0661 28.805 39.1628C28.9985 39.3076 29.24 39.3803 29.4815 39.4043C29.8198 39.4284 30.0132 39.3563 30.086 38.9934L30.521 36.7937C30.569 36.5275 30.5937 36.2619 30.6177 35.9957C30.5937 35.2945 30.2794 34.8355 29.5782 34.8355"
      fill="#FA0050"
    />
    <path
      d="M32.1591 25.5122C32.1591 25.5122 32.9198 27.2285 33.0245 27.4659C33.1194 27.6768 33.0245 27.8194 32.7566 27.9162C32.4602 28.0196 32.1432 28.0521 31.8322 28.011C31.6962 27.9951 31.5808 27.9029 31.5358 27.7743L31.0072 26.3518L30.7015 26.6197L30.5165 27.568C30.4382 27.9546 30.2983 28.0017 30.0065 28.0256C29.7412 28.0481 29.474 28.0024 29.2313 27.893C29.0443 27.8028 28.9421 27.7033 29.0224 27.3883L30.106 21.8244C30.1723 21.4875 30.2267 21.3502 30.7108 21.3502C30.9721 21.3515 31.228 21.4218 31.4529 21.5538C31.5928 21.6612 31.6525 21.8443 31.6021 22.014C31.4648 22.725 31.0331 24.9373 31.0331 24.9373C31.0331 24.9373 32.1094 23.8609 32.313 23.6739C32.5166 23.4869 32.6545 23.3775 32.7871 23.3729C33.0511 23.3729 33.3084 23.4558 33.5219 23.6096C33.7733 23.8019 33.8064 24.0526 33.6194 24.2283L32.1591 25.5135V25.5122Z"
      fill="white"
    />
    <path
      d="M41.5165 24.8219C41.5331 23.9711 40.9144 23.3875 39.9806 23.357C38.326 23.3046 37.3565 24.7251 37.3114 26.1025C37.2802 27.2856 37.9136 28.0203 39.208 28.0608C39.7353 28.0774 40.2605 27.9971 40.7585 27.8241C40.9813 27.7458 40.9953 27.6437 40.9953 27.509C40.9793 27.3134 40.9157 27.1251 40.8102 26.9593C40.6796 26.7908 40.6133 26.7484 40.4024 26.8054C40.1504 26.8777 39.8891 26.9129 39.6272 26.9096C39.3573 26.9195 39.0927 26.8286 38.8851 26.6556C38.8035 26.5674 38.7518 26.4553 38.7379 26.3353C40.4375 26.444 41.478 26.0083 41.5165 24.8226V24.8219ZM38.9156 25.2298C38.9739 24.8 39.338 24.4777 39.7717 24.4711C39.7876 24.4691 39.8029 24.4685 39.8188 24.4691C39.9979 24.4758 40.1385 24.6256 40.1318 24.8053C40.1225 25.1203 39.7571 25.2749 38.9156 25.2298Z"
      fill="white"
    />
    <path
      d="M29.1372 24.8219C29.1537 23.9711 28.535 23.3875 27.6006 23.357C25.946 23.3046 24.9765 24.7251 24.9314 26.1025C24.9002 27.2856 25.5335 28.0203 26.828 28.0608C27.3552 28.0774 27.8805 27.9971 28.3785 27.8241C28.6013 27.7458 28.6153 27.6437 28.6153 27.509C28.5987 27.3134 28.5343 27.1244 28.4282 26.9593C28.3003 26.7908 28.2313 26.7484 28.0204 26.8054C27.7684 26.8777 27.5071 26.9129 27.2452 26.9096C26.9759 26.9188 26.7126 26.8286 26.5057 26.6556C26.4242 26.5674 26.3724 26.4553 26.3585 26.3353C28.0555 26.444 29.0987 26.0083 29.1372 24.8226V24.8219ZM26.5369 25.2298C26.5953 24.7994 26.9607 24.4764 27.395 24.4711C27.6344 24.4784 27.7531 24.6203 27.7531 24.8053C27.7439 25.1203 27.3785 25.2749 26.5369 25.2298Z"
      fill="white"
    />
    <path
      d="M17.9001 24.8219C17.9193 23.9711 17.2979 23.3875 16.3662 23.357C14.7116 23.3046 13.7394 24.7251 13.6943 26.1025C13.6638 27.2856 14.2991 28.0203 15.5909 28.0608C16.1181 28.0774 16.6434 27.9971 17.1414 27.8241C17.3781 27.7458 17.3781 27.6437 17.3781 27.509C17.3616 27.3134 17.2986 27.1251 17.1931 26.9593C17.0651 26.7908 16.9962 26.7484 16.7853 26.8054C16.5333 26.8777 16.272 26.9129 16.01 26.9096C15.7401 26.9182 15.4762 26.828 15.268 26.6556C15.1871 26.5667 15.136 26.4546 15.1207 26.3353C16.8204 26.444 17.8609 26.0083 17.8994 24.8226L17.9001 24.8219ZM15.3018 25.2298H15.3044C15.3621 24.8007 15.7249 24.4777 16.1579 24.4711C16.4 24.4784 16.518 24.6203 16.518 24.8053C16.5087 25.1203 16.1413 25.2749 15.3018 25.2298Z"
      fill="white"
    />
    <path
      d="M50.5914 24.8219C50.608 23.9711 49.9892 23.3875 49.0548 23.357C47.4003 23.3046 46.4307 24.7251 46.3856 26.1025C46.3544 27.2856 46.9878 28.0203 48.2823 28.0608C48.8095 28.0774 49.3347 27.9971 49.8327 27.8241C50.0556 27.7458 50.0695 27.6437 50.0695 27.509H50.0715C50.0536 27.3128 49.9886 27.1244 49.8818 26.9593C49.7538 26.7908 49.6848 26.7484 49.474 26.8054C49.222 26.8777 48.9607 26.9129 48.6987 26.9096C48.4295 26.9188 48.1662 26.8286 47.9593 26.6556C47.8777 26.5674 47.826 26.4553 47.8121 26.3353C49.5091 26.444 50.5523 26.0083 50.5907 24.8226L50.5914 24.8219ZM47.9911 25.2298H47.9931C48.0515 24.8 48.4156 24.4777 48.8493 24.4711C49.0887 24.4784 49.2074 24.6203 49.2074 24.8053C49.1981 25.1203 48.8327 25.2749 47.9911 25.2298Z"
      fill="white"
    />
    <path
      d="M23.6611 23.3336C23.0842 23.3382 22.5457 23.6227 22.2174 24.0969C22.1385 23.6227 21.7074 23.2898 21.2286 23.3336C20.6497 23.3283 20.1072 23.6167 19.7876 24.0995C19.7876 24.0995 19.8207 23.9457 19.83 23.8933C19.8771 23.7308 19.8035 23.5577 19.6543 23.4781C19.454 23.38 19.2325 23.3303 19.0097 23.3336C18.7729 23.3336 18.5853 23.3528 18.5216 23.6844L17.8107 27.359C17.7656 27.5957 17.7941 27.6839 17.9029 27.7761C18.1051 27.9187 18.3412 28.0062 18.5879 28.0294C18.8651 28.0626 19.1616 28.0102 19.2066 27.7927L19.572 25.9126C19.7425 25.0352 20.0959 24.5545 20.4302 24.5545C20.6338 24.5545 20.7173 24.7202 20.7173 24.9623C20.712 25.146 20.6901 25.3284 20.651 25.5074L20.279 27.3921C20.2226 27.54 20.2723 27.7065 20.3997 27.8C20.6066 27.9352 20.8433 28.0162 21.0894 28.0367C21.476 28.0699 21.6438 27.975 21.7127 27.6216L22.0443 25.9146C22.2147 25.0326 22.5185 24.5564 22.862 24.5564C23.0755 24.5564 23.175 24.7129 23.175 24.9358C23.1697 25.1287 23.1485 25.3204 23.1107 25.5094L22.7453 27.3729C22.6889 27.5214 22.7327 27.6899 22.854 27.7927C23.0662 27.9326 23.3116 28.0148 23.5649 28.0294C23.9038 28.0599 24.1127 27.977 24.1863 27.6004L24.6247 25.3456C24.6731 25.0704 24.7043 24.7925 24.7169 24.5133C24.7049 23.8117 24.3826 23.3303 23.6618 23.3303L23.6591 23.3329L23.6611 23.3336Z"
      fill="white"
    />
    <path
      d="M55.2803 21.5862C54.8592 21.6001 54.529 21.9536 54.5429 22.3747C54.5429 22.3873 54.5442 22.4006 54.5449 22.4132C54.5442 22.7706 54.8334 23.0611 55.1908 23.0617C55.2061 23.0617 55.222 23.0617 55.2372 23.0604C55.6524 23.0664 55.9939 22.7355 55.9999 22.3203C55.9999 22.2905 55.9992 22.2606 55.9959 22.2308C55.9893 21.8667 55.6888 21.5769 55.3254 21.5835C55.3102 21.5835 55.2949 21.5849 55.2803 21.5862Z"
      fill="white"
    />
    <path
      d="M14.9159 22.2713C15.0267 22.1142 15.0313 21.9053 14.9279 21.7428C14.7813 21.4775 14.5631 21.26 14.2972 21.1148C13.949 20.9298 13.6831 20.9463 13.4696 21.3283C13.2209 21.7766 12.9503 22.2269 12.685 22.6772L11.941 23.9405C11.8912 23.8099 11.6233 22.5724 11.4807 21.9776C11.4463 21.825 11.3726 21.6838 11.2672 21.5677C11.0192 21.3071 10.6783 21.1559 10.3189 21.146C9.87788 21.1433 9.75918 21.2786 9.79234 21.6486C9.80626 21.7839 9.82549 21.9166 9.85136 22.0492C10.0059 22.835 10.2446 23.6016 10.5623 24.3371C10.6942 24.6335 10.8527 24.918 11.0364 25.1859C11.1027 25.2615 11.114 25.371 11.0649 25.4585C10.8282 25.9705 10.5696 26.4804 10.354 27.0017C10.1445 27.4745 10.0443 27.9878 10.0603 28.5044C10.0675 28.7067 10.1683 28.8937 10.3328 29.0117C10.5722 29.2001 10.8826 29.2737 11.1817 29.2133C11.4602 29.1941 11.6943 28.9965 11.7599 28.7252C12.1253 27.0972 12.7666 25.5427 13.6566 24.1308C14.1022 23.4763 14.5028 22.8887 14.9153 22.272L14.9159 22.2713Z"
      fill="white"
    />
    <path
      d="M55.598 23.5182C55.3725 23.4101 55.1245 23.3571 54.8751 23.3644C54.4839 23.3644 54.401 23.4665 54.342 23.7364C54.342 23.7483 53.8041 27.5032 53.8041 27.5032C53.7537 27.6796 53.8559 27.8633 54.0323 27.913C54.0349 27.9137 54.0382 27.9143 54.0409 27.9156C54.2955 28.0237 54.5741 28.0649 54.8493 28.0344C55.1265 27.9985 55.2618 27.9515 55.3234 27.539L55.8307 23.9831C55.8739 23.7928 55.7764 23.5985 55.5986 23.5182H55.598Z"
      fill="white"
    />
    <path
      d="M53.2862 26.92C53.2471 26.7814 53.1078 26.6959 52.9659 26.723C52.8738 26.735 52.6768 26.792 52.5959 26.723C52.5243 26.6647 52.4818 26.5785 52.4798 26.4863C52.4964 26.3265 52.5229 26.168 52.5607 26.0121L52.8452 24.5519H53.5018C53.649 24.5286 53.765 24.4159 53.7936 24.27C53.846 24.014 53.8645 23.8933 53.8791 23.7653C53.8804 23.585 53.7359 23.4371 53.5548 23.4351C53.5475 23.4351 53.5402 23.4351 53.5329 23.4351H53.0588C53.0588 23.4351 53.208 22.6977 53.208 22.6764C53.2604 22.5146 53.1715 22.3409 53.0097 22.2885C53.0064 22.2872 53.0031 22.2865 52.9998 22.2852C52.7637 22.1857 52.509 22.1366 52.253 22.1406C51.9447 22.1406 51.8213 22.2142 51.7384 22.6148L51.5793 23.4351H51.2782C51.1369 23.4351 51.0149 23.5339 50.9864 23.6718C50.9625 23.7952 50.9247 23.9756 50.8889 24.1652C50.8677 24.2839 50.8246 24.5253 51.1781 24.5446H51.3631L51.0408 26.225C50.9811 26.4757 50.9493 26.733 50.9459 26.991C50.9765 27.6123 51.3677 28.0268 52.1907 28.0268C52.4646 28.0308 52.7358 27.9685 52.9799 27.8445C53.3022 27.6833 53.34 27.6077 53.3565 27.3703C53.3658 27.2164 53.3419 27.0632 53.2856 26.92H53.2862Z"
      fill="white"
    />
    <path
      d="M37.0243 24.5495C37.1762 24.4268 37.2882 24.2617 37.3466 24.0753C37.4202 23.8386 37.4248 23.7126 37.0575 23.5256C36.6609 23.3677 36.2345 23.2981 35.8081 23.322C34.5965 23.3578 33.8027 24.0329 33.8597 24.9978C33.8902 25.5336 34.3789 25.9176 34.8956 26.1828C35.2868 26.3752 35.4241 26.5005 35.4334 26.6762C35.4427 26.852 35.3127 26.9939 35.007 27.0032C34.6827 27.0072 34.3604 26.9475 34.0586 26.8274C33.8451 26.7233 33.7529 26.7804 33.672 26.8845C33.5414 27.0383 33.4591 27.2273 33.4353 27.4276C33.414 27.6643 33.4638 27.7758 33.7291 27.9018C34.1144 28.0503 34.5255 28.1206 34.938 28.108C36.1967 28.0795 37.0336 27.4727 36.9719 26.4203C36.9388 25.8486 36.3837 25.4719 35.8485 25.1854C35.5355 25.0382 35.3744 24.9149 35.3744 24.7756C35.3604 24.5647 35.5594 24.446 35.8724 24.4367C36.1197 24.4374 36.3664 24.4719 36.6052 24.5389L36.6695 24.5581C36.9063 24.6364 36.9281 24.6224 37.025 24.5462V24.5508L37.0243 24.5495Z"
      fill="white"
    />
    <path
      d="M44.7667 23.3763C44.5041 23.3782 44.2461 23.4466 44.0173 23.5752C43.8137 23.6846 43.6386 23.8398 43.5053 24.0281L43.5266 23.9194C43.5611 23.7702 43.5014 23.615 43.3747 23.5281C43.2129 23.434 43.0325 23.3756 42.8462 23.3577C42.7208 23.3424 42.5942 23.3424 42.4695 23.3577C42.2825 23.3935 42.2109 23.4996 42.1684 23.7178C42.1611 23.7344 41.118 29.106 41.118 29.106C41.0636 29.3978 41.1584 29.4807 41.3169 29.5589H41.3308C41.5795 29.6763 41.8554 29.7254 42.13 29.7008C42.445 29.665 42.559 29.6226 42.6466 29.2101C42.6466 29.2008 42.8833 27.9421 42.8833 27.9421H42.9025C42.9768 27.9852 43.0564 28.0191 43.1393 28.0416C43.2945 28.0801 43.4536 28.1 43.6134 28.1006C45.1308 28.1033 46.0957 26.6045 46.0957 25.0428V25.0381C46.0983 24.1469 45.6997 23.3643 44.766 23.3763H44.7667ZM44.5246 25.1966C44.5246 25.1966 44.5246 25.1953 44.5246 25.1946C44.524 25.9367 44.1141 26.9679 43.5266 26.9726C43.4636 26.9726 43.4006 26.9646 43.3396 26.9487C43.2514 26.9189 43.1704 26.8705 43.1028 26.8068L43.2998 25.7046C43.3369 25.3332 43.5047 24.9871 43.7739 24.7278C43.8694 24.6515 43.9881 24.6084 44.1108 24.6071C44.4324 24.6071 44.5253 24.9022 44.5253 25.1953C44.5253 25.1946 44.5253 25.1933 44.5253 25.1927V25.1973L44.5246 25.1966Z"
      fill="white"
    />
  </svg>
);
