import 'reflect-metadata';
import 'normalize.css';
import * as React from 'react';
import * as ReactDOM from 'react-dom';

import { container } from './dependencies';
import { DependencyProvider } from '@deliveryhero/react-inversify-provider';
import { init } from './init';
import App from './containers/App';
import { PerimeterXTag } from './components/PerimeterX';
import { IConfig } from './config';

const config: IConfig = require('config');

const render = async (Component) => {
  // Initialize the entire app
  await init();

  ReactDOM.render(
    <DependencyProvider value={container}>
      <PerimeterXTag appId={config.pxAppId} shouldLoad={true} />
      <Component />
    </DependencyProvider>,
    document.getElementById('root'),
  );
};

render(App);
