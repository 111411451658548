import React, { FunctionComponent } from 'react';

export const PerimeterXTag: FunctionComponent<{
  appId: string;
  shouldLoad: boolean;
}> = ({ appId, shouldLoad }) => {
  React.useEffect(() => {
    if (shouldLoad) {
      (window as any)._pxAppId = `PX${appId}`;
      let p = document.getElementsByTagName('script')[0],
        s = document.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = `//client.px-cloud.net/${appId}/main.min.js`;
      p.parentNode.insertBefore(s, p);
    }
  }, []);

  return null;
};
