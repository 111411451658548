import * as React from 'react';
import { observer } from 'mobx-react';
import { StaticContext } from 'react-router';
import { Redirect, RouteComponentProps } from 'react-router-dom';

import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';

import LoginView from './LoginView';
import { QueryStringParser } from '../../utils/QueryStringParser';
import { Translate } from '../../components/Translate';
import { SessionStore } from '../../stores/SessionStore';
import { DialogStore } from '../../stores/DialogStore';
import GtmLoginSucceededEvent from '../../utils/gtm/GtmLoginSucceededEvent';
import { PlatformStore } from '../../stores/PlatformStore';
import { NavStore } from '../../stores/NavStore';
import AuthService from '../../services/AuthService';
import { TranslationsStore } from '../../stores/TranslationsStore';
import GtmManager from '../../utils/gtm/GtmManager';
import { UserStore } from '../../stores/UserStore';
import { ApiStatus } from '../../models/ApiStatus';
import { CaptchaHttpContextProvider } from '@deliveryhero/captcha';

type LocationState = {
  isLoggedOut: boolean;
};

export type LoginContainerProps = RouteComponentProps<
  {},
  StaticContext,
  LocationState
> & {
  gtmManager: GtmManager;
  translationsStore: TranslationsStore;
  authService: AuthService;
  sessionStore: SessionStore;
  userStore: UserStore;
  dialogStore: DialogStore;
  loginSucceededEvent: GtmLoginSucceededEvent;
  platformStore: PlatformStore;
  navStore: NavStore;
  window: Window;
};

export type LoginContainerState = {
  email?: string;
  password?: string;
  snackbarMessage: string | JSX.Element;
  isLoading: boolean;
  hasError: boolean;
  redirectPath: string;
  hasSucceeded: boolean;
  errorMessage?: string;
  errors?: any[];
};

const MESSAGES: Map<string, string> = new Map();
MESSAGES.set('resetSuccess', 'login.reset_success');

const getMessage = (message: string) =>
  MESSAGES.has(message) ? <Translate code={MESSAGES.get(message)} /> : '';

@observer
export class LoginContainer extends React.Component<
  LoginContainerProps,
  LoginContainerState
> {
  constructor(props, context) {
    super(props, context);

    const urlParams = QueryStringParser.parse(
      this.props.window.location.search,
    );

    const message = urlParams.message ? getMessage(urlParams.message) : '';

    this.state = {
      errors: [],
      hasError: false,
      hasSucceeded: false,
      isLoading: false,
      redirectPath: urlParams.redirect
        ? decodeURIComponent(urlParams.redirect)
        : '/',
      email:
        props.location.search.email || this.props.authService.lastLoginEmail,
      password: props.location.search.password || '',
      snackbarMessage: message,
    };
  }

  componentDidMount() {
    this.props.navStore.setNewNavigation({
      title: null,
      name: 'login',
      showAppBar: false,
      showBottomNav: false,
    });
    if (this.props.platformStore.currentPlatform.loginRedirect) {
      this.props.window.location.href =
        this.props.platformStore.currentPlatform.loginRedirect;
    }
  }

  /**
   * Reset Qualtrics on login, to prevent mixed up data
   * in case the user was not logging out naturally (e.g. session expired)
   */
  resetQualtrics() {
    const _window: Window & { QSI?: any } = window;

    if (_window.QSI) {
      _window.QSI.API.unload();
    }
  }

  handleSubmit = async (event?: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!this.state.email?.length || !this.state.password?.length) {
      this.setState({
        errorMessage: this.props.translationsStore.translate(
          'global.error.email_or_password_empty',
        ),
        hasError: true,
      });
      return;
    }
    this.setState({
      hasError: false,
      isLoading: true,
    });

    this.resetQualtrics();

    const { authService } = this.props;

    try {
      const response = await authService.loginMaster(
        this.state.email,
        this.state.password,
      );

      this.setState({ isLoading: false });

      this.handleSubmitSuccess(response);
    } catch (e) {
      this.handleSubmitError(e);
    }
  };

  getRedirectParts(redirectPath: string, hasPhone: boolean) {
    const [redirectPathname, redirectSearchString] = redirectPath.split('?');
    const redirectSearchParsed = QueryStringParser.parse(
      '?' + redirectSearchString,
    );

    let search = '';
    if (redirectSearchParsed) {
      search = QueryStringParser.stringify(redirectSearchParsed);
    }
    if (!hasPhone) {
      if (redirectPathname !== '/') {
        search = '?redirect=' + redirectPathname + search;
      }
    }

    let pathname = '';
    if (!hasPhone) {
      pathname = '/add-phone';
    } else {
      pathname = redirectPathname;
    }

    return [pathname, search];
  }

  render() {
    const { sessionStore, userStore } = this.props;

    if (
      sessionStore.isLoggedIn &&
      userStore.userProfileApiStatus === ApiStatus.SUCCESS
    ) {
      const isCurrentUser = !!userStore.userProfile;
      const hasPhone = !(
        isCurrentUser && !userStore.userProfile.user.phone_number
      );

      const [pathname, search] = this.getRedirectParts(
        this.state.redirectPath,
        hasPhone,
      );

      return (
        <Redirect
          to={{
            pathname: pathname,
            search: search,
          }}
        />
      );
    }

    const { snackbarMessage } = this.state;
    const snackbarOpen = snackbarMessage !== '';

    const platform = this.props.platformStore.currentPlatform;
    const feedbackEmailTranslation = this.props.translationsStore.translate(
      'global.login.feedback_email',
    );
    const feedbackEmail =
      !!feedbackEmailTranslation &&
      feedbackEmailTranslation !== 'global.login.feedback_email'
        ? feedbackEmailTranslation
        : null;

    return (
      <CaptchaHttpContextProvider>
        <Box>
          <LoginView
            hasError={this.state.hasError}
            errorMessage={this.state.errorMessage}
            email={this.state.email}
            password={this.state.password}
            isLoading={
              this.state.isLoading &&
              userStore.userProfileApiStatus !== ApiStatus.SUCCESS
            }
            onChange={this.handleInputChange}
            onSubmit={this.handleSubmit}
            getError={this.getError}
            isLoggedOut={
              !!this.props.location.state &&
              !!this.props.location.state.isLoggedOut
            }
            platform={platform}
            feedbackEmail={feedbackEmail}
            gtm={this.props.gtmManager}
          />
        </Box>

        <Snackbar
          open={snackbarOpen}
          onClose={this.closeSnackbar.bind(this)}
          autoHideDuration={3000}
        >
          <SnackbarContent
            data-testid="login-snackbar"
            message={snackbarMessage}
          />
        </Snackbar>
      </CaptchaHttpContextProvider>
    );
  }

  getError = (fieldName: string): JSX.Element | string => {
    const foundError =
      (this.state.errors as any[]).filter((error) => {
        return error.field === fieldName;
      })[0] || {};
    const errorCode = foundError.code;
    const errorMessage = foundError.message;

    if (errorCode) {
      return this.props.translationsStore.translate(
        `global.error.${errorCode.toLowerCase()}`,
      );
    }

    if (errorMessage) {
      return errorMessage;
    }
  };

  private handleInputChange = (event: any): void => {
    const target: any = event.target;
    this.setState({ [target.name]: target.value } as any);
  };

  private handleSubmitSuccess = (response) => {
    if (response.accessToken) {
      this.props.loginSucceededEvent.pushEvent();
      return;
    }
    this.setState({
      errors: [],
      hasError: false,
      hasSucceeded: true,
      isLoading: false,
    });
  };

  private handleSubmitError = (err) => {
    if (!err.response) {
      this.props.gtmManager.pushEvent('error_message.shown', {
        errorMessage: err.message,
      });
      this.setState({
        errorMessage: this.props.translationsStore.translate(
          'global.error.error_occurred',
        ),
        errors: [],
        hasError: true,
        isLoading: false,
      });
      return;
    }

    const errorCode = err.response.code;
    const errorMessage = errorCode
      ? this.props.translationsStore.translate(
          `global.error.${errorCode.toLowerCase()}`,
        )
      : err.response.message;

    this.props.gtmManager.pushEvent('error_message.shown', {
      errorMessage: errorMessage,
    });

    if (err.status === 400) {
      this.setState({
        errorMessage,
        errors: err.response.errors || [],
        hasError: true,
        isLoading: false,
      });
    } else {
      this.setState({
        errorMessage,
        hasError: true,
        isLoading: false,
      });
    }

    this.props.gtmManager.pushEvent('login.failed');
  };

  private closeSnackbar() {
    this.setState({ snackbarMessage: '' });
  }
}
