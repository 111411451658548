import * as React from 'react';
import { StaticContext } from 'react-router';
import { Redirect, RouteComponentProps } from 'react-router-dom';

// stores
import { SessionStore } from '../../stores/SessionStore';
import { PluginStore } from '../../stores/PluginStore';
import { UserStore } from '../../stores/UserStore';
import FwfStore from '../../stores/FwfStore';
import AuthService from '../../services/AuthService';

// utils
import GtmManager from '../../utils/gtm/GtmManager';
import { QueryStringParser } from '../../utils/QueryStringParser';

type LocationState = {
  redirect: string;
};

export type LogoutProps = RouteComponentProps<
  {},
  StaticContext,
  LocationState
> & {
  sessionStore: SessionStore;
  fwfStore: FwfStore;
  gtmManager: GtmManager;
  window: Window;
  authService: AuthService;
  pluginStore: PluginStore;
  userStore: UserStore;
};

export default class Logout extends React.Component<LogoutProps> {
  private userId: string;

  constructor(props) {
    super(props);
    this.userId = this.props.sessionStore.getUserData('userId');
  }

  resetQualtricsData() {
    const _window: Window & { userInfo?: Object; QSI?: any } = window;
    _window.userInfo = {};
  }

  componentDidMount() {
    this.props.authService.logout();
    this.props.userStore.resetStore();
    this.props.pluginStore.resetStore();

    this.resetQualtricsData();

    // This is to clear runtime app data after logout
    setTimeout(() => {
      this.props.window.location.reload();
    });
  }

  render() {
    this.props.gtmManager.pushEvent('logout.submitted', {
      userId: this.userId,
    });
    const redirect = this.props.location.state?.redirect || '/';

    return (
      <Redirect
        to={{
          pathname: '/login',
          search: QueryStringParser.stringify({ redirect }),
          state: { isLoggedOut: true },
        }}
      />
    );
  }
}
