/* eslint-disable max-len */
import * as React from 'react';

import { LogoProps } from '../LogoProps';

export const eFoodSquaredPartnerLogo: React.FC<LogoProps> = ({
  width = 64,
}) => (
  <svg
    data-testid="platform-logo-svg"
    width={width}
    height={width}
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="64" height="64" fill="#ED2E2E" />
    <path d="M8 43.5273L10.3733 29.9401H56L53.644 43.5273H8Z" fill="white" />
    <path
      d="M10.3692 42.5696L11.9578 34.3122H13.8672L13.747 34.9372H13.7715C14.2924 34.4564 14.9407 34.204 15.6139 34.204C17.1684 34.204 18.1029 35.454 17.7561 37.257C17.4069 39.0719 15.996 40.2979 14.3436 40.2979C13.6581 40.2979 13.1781 40.0575 12.8719 39.6128L12.8451 39.6248L12.2786 42.5696H10.3692ZM14.2832 38.6392C15.0176 38.6392 15.6626 38.0863 15.8221 37.257C15.984 36.4156 15.5396 35.8627 14.8052 35.8627C14.0341 35.8627 13.3699 36.4517 13.215 37.257C13.0531 38.0983 13.5244 38.6392 14.2832 38.6392Z"
      fill="#ED2E2E"
    />
    <path
      d="M20.7024 40.2979C19.0623 40.2979 18.1109 39.0719 18.46 37.257C18.8069 35.454 20.2347 34.204 21.7769 34.204C22.4134 34.204 22.9646 34.4564 23.2883 34.9372L23.3151 34.9252L23.433 34.3122H25.3547L24.2239 40.1897H22.4001L22.5181 39.5768L22.4959 39.5647C22.0094 40.0575 21.4246 40.2979 20.7024 40.2979ZM21.4255 38.6392C22.1109 38.6392 22.7903 38.0983 22.9521 37.257C23.1071 36.4517 22.6696 35.8627 21.9596 35.8627C21.2252 35.8627 20.5681 36.4156 20.4062 37.257C20.2466 38.0863 20.6666 38.6392 21.4255 38.6392Z"
      fill="#ED2E2E"
    />
    <path
      d="M25.5055 40.1897L26.6362 34.3122H28.5457L28.3884 35.1295H28.4129C28.9692 34.5285 29.6558 34.204 30.3168 34.204H30.3902L29.9994 36.2353C29.8182 36.2233 29.6491 36.2113 29.5512 36.2113C28.6454 36.2113 28.0716 36.7762 27.9236 37.5454L27.4149 40.1897H25.5055Z"
      fill="#ED2E2E"
    />
    <path
      d="M31.9969 40.2979C30.9687 40.2979 30.3546 39.6729 30.6113 38.3387L31.0946 35.8266H30.4214L30.7127 34.3122H31.3859L31.749 32.4251L33.7077 32.2328L33.3076 34.3122H34.7519L34.4606 35.8266H33.0162L32.6 37.9902C32.526 38.3748 32.5585 38.6512 32.9257 38.6512C33.0603 38.6512 33.3121 38.6152 33.5387 38.519L33.7501 39.9013C33.29 40.1297 32.8292 40.2979 31.9969 40.2979Z"
      fill="#ED2E2E"
    />
    <path
      d="M34.5659 40.1897L35.6967 34.3122H37.6061L37.4743 34.9973H37.4988C38.0657 34.4684 38.753 34.204 39.4507 34.204C40.687 34.204 41.4771 34.805 41.1187 36.668L40.4411 40.1897H38.5317L39.1214 37.1248C39.3087 36.1512 39.0314 35.8747 38.4071 35.8747C37.7462 35.8747 37.22 36.3194 37.0442 37.2329L36.4754 40.1897H34.5659Z"
      fill="#ED2E2E"
    />
    <path
      d="M47.9842 37.1849C47.9518 37.3531 47.8741 37.6296 47.8388 37.7498H43.7629C43.7558 38.4229 44.264 38.7714 44.9984 38.7714C45.4903 38.7594 46.0268 38.5791 46.5163 38.1344L47.4051 39.2402C46.7854 39.8532 45.8675 40.2979 44.5089 40.2979C42.6973 40.2979 41.6113 39.0719 41.9628 37.245C42.305 35.4661 43.8208 34.204 45.6323 34.204C47.3214 34.204 48.3195 35.442 47.9842 37.1849ZM45.3211 35.6944C44.7336 35.6944 44.2259 36.043 43.9901 36.632H46.2301C46.2187 36.055 45.8964 35.6944 45.3211 35.6944Z"
      fill="#ED2E2E"
    />
    <path
      d="M48.4196 40.1897L49.5504 34.3122H51.4598L51.3026 35.1295H51.3271C51.8833 34.5285 52.57 34.204 53.2309 34.204H53.3044L52.9136 36.2353C52.7323 36.2233 52.5633 36.2113 52.4653 36.2113C51.5596 36.2113 50.9858 36.7762 50.8378 37.5454L50.329 40.1897H48.4196Z"
      fill="#ED2E2E"
    />
    <path
      d="M29.9467 24.8558C29.932 24.8558 29.8778 24.8557 29.8778 24.8557C29.8778 24.8557 29.899 24.8091 29.9063 24.7936C30.1272 24.3216 30.5916 23.9969 31.1284 23.9975C31.6651 23.9981 32.1287 24.3238 32.3485 24.7963C32.3552 24.8108 32.3762 24.8584 32.3762 24.8584C32.3762 24.8584 32.3238 24.8585 32.3079 24.8584L29.9467 24.8558ZM33.9181 26.0455C33.9493 25.8713 33.9654 25.6916 33.9657 25.5079C33.9659 25.3167 33.9487 25.1301 33.9152 24.9489C33.6665 23.5816 32.5145 22.5472 31.1297 22.5457C29.5621 22.5439 28.2898 23.8675 28.288 25.5016C28.2861 27.1365 29.5554 28.4629 31.123 28.4647C32.0604 28.4657 32.7195 28.0324 33.2922 27.4701C33.318 27.4448 33.3973 27.3664 33.3973 27.3664C33.3973 27.3664 33.303 27.3127 33.2702 27.294L32.0859 26.6146C32.0655 26.6029 32.0182 26.577 32.0182 26.577C32.0182 26.577 31.977 26.6119 31.9587 26.6268C31.7285 26.8135 31.4392 26.9245 31.1252 26.9241C30.7834 26.9237 30.4706 26.7917 30.2323 26.5736C30.0746 26.4292 29.9487 26.2474 29.8688 26.041L33.9181 26.0455Z"
      fill="white"
    />
    <path
      d="M38.2703 22.6414L37.2812 24.2592L36.4507 24.2593L36.4459 28.4706L34.8232 28.4688L34.828 24.2576L34.0508 24.2567L34.0526 22.6376L34.8298 22.6385L34.8308 21.7993C34.8326 20.184 36.2178 19.32 37.5151 19.6887C37.956 19.814 38.277 20.0142 38.644 20.3641C38.6832 20.4014 38.7876 20.5001 38.7876 20.5001L37.5093 21.4403C37.5093 21.4403 37.4569 21.4136 37.4384 21.4041C36.8724 21.1127 36.3889 21.4752 36.4499 22.5978C36.4506 22.6105 36.4525 22.6399 36.4525 22.6399L38.2703 22.6414Z"
      fill="white"
    />
    <path
      d="M40.6703 26.8417C39.7955 26.951 39.0611 26.2144 39.1722 25.3398C39.248 24.7442 39.7297 24.2634 40.3256 24.1891C41.2005 24.0798 41.9355 24.8157 41.8242 25.6904C41.7485 26.2864 41.2665 26.7674 40.6703 26.8417ZM40.6952 22.562C38.9084 22.4462 37.4333 23.9176 37.5444 25.7047C37.6361 27.1774 38.8296 28.3739 40.3022 28.4687C42.0878 28.5838 43.562 27.114 43.4521 25.3278C43.3616 23.8546 42.1682 22.6576 40.6952 22.562Z"
      fill="white"
    />
    <path
      d="M46.905 26.8487C46.0302 26.958 45.2958 26.2214 45.4069 25.3468C45.4827 24.7511 45.9644 24.2704 46.5603 24.1961C47.4352 24.0868 48.1701 24.8227 48.0589 25.6974C47.9831 26.2934 47.5012 26.7744 46.905 26.8487ZM46.9299 22.5691C45.1431 22.4532 43.668 23.9246 43.7791 25.7117C43.8708 27.1844 45.0642 28.3809 46.5369 28.4757C48.3225 28.5908 49.7968 27.121 49.6869 25.3348C49.5963 23.8617 48.4028 22.6646 46.9299 22.5691Z"
      fill="white"
    />
    <path
      d="M53.4111 26.8005C52.4385 27.1024 51.552 26.3262 51.6552 25.3791C51.722 24.7649 52.2184 24.268 52.8327 24.2006C53.639 24.1124 54.3209 24.7408 54.3212 25.5286V25.5328C54.3205 26.1067 53.9591 26.6303 53.4111 26.8005ZM54.3268 20.5671L54.3242 22.8199C54.3242 22.8353 54.3239 22.8889 54.3239 22.8889C54.3239 22.8889 54.2763 22.8669 54.2607 22.8593C53.7607 22.6197 53.1836 22.5155 52.5759 22.5972C51.1075 22.7947 50.01 24.0811 50.0246 25.5628C50.0405 27.1807 51.2306 28.4872 52.8529 28.489C53.3282 28.4895 53.9056 28.2506 54.2444 27.9326C54.2586 27.9194 54.3198 27.8576 54.3198 27.8576C54.3198 27.8576 54.3185 27.9461 54.3184 27.9654L54.3179 28.4907L55.9405 28.4925L55.9503 19.7889C55.9504 19.7287 55.9503 19.5 55.9503 19.5C55.9503 19.5 55.7594 19.6103 55.7003 19.6444L54.4374 20.3754C54.4032 20.3952 54.3283 20.4394 54.3283 20.4394C54.3283 20.4394 54.3268 20.5276 54.3268 20.5671Z"
      fill="white"
    />
  </svg>
);
