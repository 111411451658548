import { withDependencies } from '@deliveryhero/react-inversify-provider';
import LanguageSelector from './LanguageSelector';
import { LanguageStore } from '../../stores/LanguageStore';
import { TranslationsStore } from '../../stores/TranslationsStore';

const LanguageSelectorContainer = withDependencies({
  languageStore: LanguageStore,
  translationsStore: TranslationsStore,
  availableLanguages: 'availableLanguages',
})(LanguageSelector);

export default LanguageSelectorContainer;
