import * as React from 'react';
import PasswordHandlingContainer from '../PasswordHandlingContainer';
import { PasswordHandlingContainerProps } from '../PasswordHandlingContainer/PasswordHandlingContainer';
import AuthService from '../../services/AuthService';
import { CaptchaHttpContextProvider } from '@deliveryhero/captcha';

export type Props = PasswordHandlingContainerProps & {
  authService: AuthService;
};

const resetPassword =
  (authService: AuthService) => (token: string, newPassword: string) =>
    authService.resetPassword(token, newPassword);

const WelcomeContainer: React.FC<Props> = (props) => (
  <CaptchaHttpContextProvider>
    <PasswordHandlingContainer
      translationPrefix="welcome"
      submit={resetPassword(props.authService)}
      {...props}
    />
  </CaptchaHttpContextProvider>
);

export default WelcomeContainer;
