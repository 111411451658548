import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import { SIDEBAR_WIDTH } from '../../constants';

export const NavbarWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isMobile',
})<{ isMobile: boolean }>(({ isMobile }) => ({
  zIndex: 10,
  backgroundColor: '#fff',
  color: '#000',
  boxSizing: 'border-box',
  position: isMobile ? 'relative' : 'fixed',
  top: 0,
  fontSize: '14px',
  overflow: 'hidden',
  width: SIDEBAR_WIDTH,
  minHeight: '100vh',
  borderRight: '1px solid #dce5e6',
  alignSelf: 'flex-start',
}));

export const PlatformLogoWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isMobile',
})<{ isMobile: boolean }>(({ isMobile }) => ({
  margin: isMobile ? '16px 24px' : '36px 24px 24px',
}));
