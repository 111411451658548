import * as React from 'react';
import ErrorMessage from '../ErrorMessage';
import { INotFound, TranslateFn } from './interfaces';

const PageNotFound = ({
  translate,
  centered,
}: {
  translate: TranslateFn;
  centered?: boolean;
}) => (
  <ErrorMessage
    centered={centered}
    title={translate('global.error.page_not_found.headline')}
    message={translate('global.error.page_not_found.message')}
  />
);

const NotFoundPage = (props: INotFound) => {
  return (
    <PageNotFound
      translate={props.translationsStore.translate}
      centered={props.centered}
    />
  );
};

export default NotFoundPage;
