import { ReactSVG } from 'react-svg';
import { styled as muiStyled } from '@mui/material/styles';

export const StyledReactSVG = muiStyled(ReactSVG, {
  shouldForwardProp: (prop) => !['size', 'color'].includes(String(prop)),
})<{ size: number; color: string }>(({ size, color = 'black' }) => ({
  width: `${size}px`,
  height: `${size}px`,

  svg: {
    width: `${size}px`,
    height: `${size}px`,
    fill: 'currentcolor',
    color: color,
  },

  path: {
    fill: 'currentcolor',
  },
}));
