import {
  action,
  computed,
  IObservableArray,
  observable,
  ObservableMap,
  reaction,
  toJS,
} from 'mobx';
import { inject, injectable, postConstruct } from 'inversify';
import { History } from 'history';
import {
  IVendorData,
  SelectedVendorsObserverCallback,
  VendorObserverCallback,
} from '@deliveryhero/vendor-portal-sdk';

import { makeVendorId, Vendor } from '../models/Vendor';
import { TYPES } from '../types';
import { DialogStore } from './DialogStore';
import { SessionStore } from './SessionStore';
import { UserProfileAccount } from './UserStore';
import { UtilsDateStore } from './UtilsDateStore';

type SymMap<T> = Map<Symbol, T>;

@injectable()
export class VendorStore {
  @observable vendors: ObservableMap<string, IVendorData> = observable.map<
    string,
    IVendorData
  >();
  @observable selectedVendorIds: IObservableArray<string> =
    observable.array<string>() as any;
  @observable isSingleVendorSelect: boolean = false;
  @observable private _currentVendorId: string | undefined = undefined;

  @inject(TYPES.UtilsDateStore) private utilsDateStore: UtilsDateStore;
  @inject(DialogStore) private dialogStore: DialogStore;
  @inject(TYPES.SessionStore) private sessionStore: SessionStore;
  @inject('window') private window: Window;
  @inject('history') private history: History;

  private observerCallbacks: SymMap<VendorObserverCallback> = new Map();
  private selectedObserverCallbacks: SymMap<SelectedVendorsObserverCallback> =
    new Map();
  private _candidateVendorId: string | undefined = undefined;

  /**
   * Respect the _currentVendorId;
   * But if it's undefined, try to fallback to first vendor's id;
   * Can still be undefined if there's no vendors data
   */
  @computed get currentVendorId(): string | undefined {
    return this._currentVendorId || this.allVendors[0]?.id;
  }

  @computed get currentVendor(): IVendorData {
    return this.vendors.get(this.currentVendorId);
  }

  @computed get selectedVendors(): IVendorData[] {
    const vendorIds = toJS(this.selectedVendorIds);

    return vendorIds
      .map((vendorId) => this.vendors.get(vendorId))
      .filter((restaurant) => !!restaurant);
  }

  @computed get allVendors(): IVendorData[] {
    return Array.from(this.vendors.values()).sort(sortVendorsByName);
  }

  @computed get allPlatforms(): string[] {
    const platforms = Array.from(this.vendors.values()).map(
      (vendor) => vendor.globalEntityId,
    );

    return [...new Set(platforms)];
  }

  @computed get isVendorAvailable(): boolean {
    return !!(
      this.vendors.get(this.currentVendorId) ||
      Array.from(this.vendors.values())[0]
    );
  }

  /**
   * The return value is "true" if at least one vendor is a keyAccount
   */
  @computed get hasKeyAccount(): boolean {
    return this.allVendors.some((vendor) => vendor.keyAccount);
  }

  /**
   * Get unique vertical types among all vendors
   */
  @computed get verticalTypes(): string[] {
    return Array.from(
      new Set(this.allVendors.map((vendor) => vendor.verticalType)),
    );
  }

  /**
   * Get unique delivery types among all vendors
   */
  @computed get deliveryTypes(): string[] {
    return Array.from(
      new Set(this.allVendors.flatMap((vendor) => vendor.deliveryTypes)),
    );
  }

  @postConstruct() init() {
    reaction(
      () => this.currentVendor,
      async (vendor) => {
        Array.from(this.observerCallbacks.values()).forEach((cb) => cb(vendor));
      },
    );

    reaction(
      () => this.selectedVendors,
      (vendors) => {
        Array.from(this.selectedObserverCallbacks.values()).forEach((cb) =>
          cb(vendors),
        );
      },
    );
  }

  /**
   * Vendors data is expected to be empty when calling this function,
   * because we wanted to assign the id before the data is fetched;
   * So we blindly accept the id, but will validate it after the data is fetched
   * if vendors is already fetched, it can do the setCurrentVendorId already
   * @param vendorId arbitrary string
   */
  @action queueCurrentVendorId(vendorId: string): void {
    this._candidateVendorId = vendorId;

    if (this.vendors.size > 0) {
      this.setCurrentVendorId(this._candidateVendorId);
    }
  }

  /**
   * Accept the input vendor id if it appears in current vendors data;
   * Else if it doesn't appear, rewrite the current vendor id to the first vendor;
   * Else if there no vendors data, the current vendor id becomes undefined
   * @param vendorId arbitrary string
   */
  @action setCurrentVendorId(vendorId: string): void {
    if (this.vendors.get(vendorId)) {
      this._currentVendorId = vendorId;
    } else {
      this._currentVendorId = this.allVendors[0]?.id;
    }
  }

  @action
  private handleVendorFetch(vendorIds: string[], response: any) {
    this.vendors = observable.map(
      response.data.map((vendorData: IVendorData | UserProfileAccount) => {
        const vendor = new Vendor(vendorData);
        return [vendor.id, vendor];
      }),
    );
    if (this._candidateVendorId) {
      this.setCurrentVendorId(this._candidateVendorId);
      this._candidateVendorId = undefined;
    }

    const storedSelectVendorIds = JSON.parse(
      this.window.localStorage.getItem('selectedVendorIds') || '[]',
    ) as string[];

    // Get overlap of stored and set fetched vendor ids
    const overlappingStoredSelectVendorIds = storedSelectVendorIds.filter(
      (id) => !!this.vendors.get(id),
    );

    // If stored restaurants (partially) overlap with fetched vendor use stored ones, otherwise all fetched
    this.setSelectedVendors(
      overlappingStoredSelectVendorIds.length > 0
        ? overlappingStoredSelectVendorIds
        : this.allVendors.map((vendor) => vendor.id),
    );
  }

  setIsSingleVendorSelect(flag: boolean): void {
    this.isSingleVendorSelect = flag;
  }

  setSelectedVendors(vendorIds: string[]) {
    this.selectedVendorIds.replace(
      Array.from(new Set(vendorIds)).filter((id) =>
        // Filter out platforms that are not available
        this.vendors.get(id),
      ),
    );

    const writableVendorIds =
      this.selectedVendorIds.length === this.vendors.size
        ? this.allVendors.map((vendor) => vendor.id)
        : this.selectedVendorIds;

    this.window.localStorage.setItem(
      'selectedVendorIds',
      JSON.stringify(writableVendorIds),
    );
  }

  clearVendors(): void {
    this.vendors = observable.map();
    this._candidateVendorId = undefined;
    this._currentVendorId = undefined;
  }

  // accounts are fetched as a part of userProfile in UserStore (fetchUserProfile)
  setVendors = (accounts: UserProfileAccount[]) => {
    const vendorIds = accounts.map((account) =>
      makeVendorId({
        global_entity_id: account.global_entity_id,
        vendor_id: account.global_vendor_id,
      }),
    );

    this.selectedVendorIds.clear();

    const timezone = accounts[0].timezone;

    if (timezone) {
      this.utilsDateStore.setAdjustDateWithTimeZone(timezone);
    }

    this.handleVendorFetch(vendorIds, { data: accounts });
  };

  setVendorsError = (err) => {
    const mainSession = this.sessionStore.getMainSession();
    const vendorIds = mainSession.platformVendorIds;

    return this.handleVendorFetchFailed(vendorIds)(err);
  };

  addVendorObserver(cb: VendorObserverCallback): () => void {
    const cbSymbol = Symbol('callback');
    this.observerCallbacks.set(cbSymbol, cb);
    cb(this.currentVendor);
    return () => {
      this.observerCallbacks.delete(cbSymbol);
    };
  }

  addSelectedVendorsObserver(cb: SelectedVendorsObserverCallback): () => void {
    const cbSymbol = Symbol('callback');
    this.selectedObserverCallbacks.set(cbSymbol, cb);
    cb(this.selectedVendors);
    return () => {
      this.selectedObserverCallbacks.delete(cbSymbol);
    };
  }

  private handleVendorFetchFailed = (vendorIds: string[]) => (err) => {
    if (err?.status === 403 || err?.status === 404) {
      vendorIds.forEach((id) => this.vendors.delete(id));
      this._currentVendorId = undefined;
      this.dialogStore.addDialog({
        titleCode: 'global.login.error.no_restaurant.title',
        messageCode: 'global.login.error.no_restaurant.message',
        okButtonCode: 'global.login.error.no_restaurant.close_button',
        okButtonCallback: () => {
          this.history.push('/logout');
        },
        isCancelable: false,
        isModal: true,
      });
    }

    if (err?.status === 401) {
      this.history.push('/logout');
    }

    return Promise.reject(err);
  };
}

function sortVendorsByName(a: IVendorData, b: IVendorData) {
  const aName = a.name.toLowerCase();
  const bName = b.name.toLowerCase();
  if (aName < bName) {
    return -1;
  }

  if (aName > bName) {
    return 1;
  }

  return 0;
}
