import React, { FC } from 'react';
import { PasswordFormErrors } from '../PasswordHandlingContainer/parts/Password/PasswordContainer';
import { Icon, Stack, Typography } from '@mui/material';
import { Translate } from '../../components/Translate';

export type PasswordValidationViewProps = {
  errors: PasswordFormErrors;
  isDirty: boolean;
};

const ValidationField: FC<{
  code: string;
  isInvalid: boolean;
  isDirty: boolean;
}> = ({ code, isInvalid, isDirty }) => {
  return (
    <Stack
      flexDirection={'row'}
      gap={1}
      alignItems={'center'}
      typography={'secondary-500'}
      color={!isDirty ? 'inherit' : isInvalid ? '#E5434A' : '#26AE5F'}
    >
      {isInvalid ? <Icon>Clear</Icon> : <Icon>Done</Icon>}
      <Translate code={code} />
    </Stack>
  );
};

const PasswordValidationView: React.FC<PasswordValidationViewProps> = ({
  errors,
  isDirty,
}) => {
  return (
    <Stack spacing={1}>
      <Typography variant="secondary-500">
        <Translate code="global.auth.password_strength.info_title" />
      </Typography>
      <Stack>
        <ValidationField
          isDirty={isDirty}
          code="global.auth.password_strength.min_length_8_chars"
          isInvalid={errors.rules.isTooShort}
        />
        <ValidationField
          isDirty={isDirty}
          code="global.auth.password_strength.atleast_1_uppercase_letter"
          isInvalid={errors.rules.hasMissingUppercaseLetter}
        />
        <ValidationField
          isDirty={isDirty}
          code="global.auth.password_strength.atleast_1_number"
          isInvalid={errors.rules.hasMissingDigit}
        />
        <ValidationField
          isDirty={isDirty}
          code="global.auth.password_strength.atleast_1_special_char"
          isInvalid={errors.rules.hasMissingSpecialCharacter}
        />
        <ValidationField
          isDirty={isDirty}
          code="global.auth.password_strength.space_not_allowed"
          isInvalid={errors.rules.hasWhitespaceCharacters}
        />
      </Stack>
    </Stack>
  );
};

export default PasswordValidationView;
