import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { styled as muiStyled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';

import StartPrompt from '../../components/StartPrompt';
import StartPromptHead from '../../components/StartPrompt/StartPromptHead';
import { Translate } from '../../components/Translate';

export type Props = {
  platform: any;
  email: string;
  loading: boolean;
  onEmailChange;
  onSubmit;
};

const validateEmail = (email: string): boolean => {
  const regex = /\S+@\S+\.\S+/;

  return regex.test(String(email).toLowerCase());
};

const InputWrapper = muiStyled(Box)({
  padding: '0 0 24px',
});

const ButtonWrapper = muiStyled(Box)({
  paddingBottom: '8px',
});

const LoadingWrapper = muiStyled(Box)({
  margin: 'auto',
  textAlign: 'center',
  padding: '16px 0',
});

const Form = muiStyled('form')({
  marginTop: '16px',
});

const ResetPasswordView: React.FC<Props> = ({
  platform,
  email,
  onEmailChange,
  onSubmit,
  loading,
}) => {
  const history = useHistory();

  const onGoBackButtonClick = () => {
    history.push('/');
  };

  return (
    <StartPrompt platform={platform}>
      <StartPromptHead
        title={<Translate code="login.reset_password.title" />}
        text={<Translate code="login.reset_password.subtitle" />}
      />

      {loading && (
        <LoadingWrapper>
          <CircularProgress />
        </LoadingWrapper>
      )}

      {!loading && (
        <>
          <Form onSubmit={onSubmit}>
            <InputWrapper>
              <TextField
                name="email"
                value={email}
                onChange={onEmailChange}
                fullWidth={true}
                type="text"
                label={<Translate code="global.login.email" />}
                data-testid="reset-password-email-input"
              />
            </InputWrapper>

            <ButtonWrapper data-testid="reset-password-submit-button-wrapper">
              <Button
                fullWidth={true}
                color="primary"
                type="submit"
                disabled={!validateEmail(email)}
                data-testid="reset-password-submit-button"
              >
                <Translate code="login.reset_password.submit" />
              </Button>
            </ButtonWrapper>
          </Form>

          <ButtonWrapper data-testid="reset-password-go-back-button-wrapper">
            <Button
              fullWidth={true}
              color="primary"
              data-testid="reset-password-go-back-button"
              onClick={onGoBackButtonClick}
            >
              <Translate code="login.reset_password.back" />
            </Button>
          </ButtonWrapper>
        </>
      )}
    </StartPrompt>
  );
};

export default ResetPasswordView;
