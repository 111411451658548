import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export interface OverlayPosition {
  top: boolean;
  bottom: boolean;
}

export const StyledPluginMenuWrapper = styled(Box, {
  shouldForwardProp: (prop) => !['overlayPosition'].includes(prop.toString()),
})<{ overlayPosition: OverlayPosition }>(({ overlayPosition }) => ({
  position: 'relative',
  '&::before': {
    position: 'absolute',
    content: overlayPosition.top ? '""' : 'none',
    height: '80px',
    width: '100%',
    zIndex: '1',
    pointerEvents: 'none',
    top: overlayPosition.top ? '0' : 'auto',
    background: 'linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #fff 100%)',
  },
  '&::after': {
    position: 'absolute',
    content: overlayPosition.bottom ? '""' : 'none',
    height: '32px',
    width: '100%',
    zIndex: '1',
    pointerEvents: 'none',
    bottom: overlayPosition.bottom ? '0' : 'auto',
    background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%)',
  },
}));

export const PluginMenu = styled(Box, {
  shouldForwardProp: (prop) => !['isMobile'].includes(prop.toString()),
})<{ isMobile: boolean }>(({ isMobile }) => {
  const bottomClipHeight = isMobile ? 150 : 180;
  return {
    overflow: 'auto',
    overflowX: 'hidden',
    height: `calc(100vh - ${bottomClipHeight}px)`,
    '&::-webkit-scrollbar-thumb': {
      width: '5px',
      backgroundColor: '#dce5e6',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar': {
      width: '5px',
    },
  };
});
