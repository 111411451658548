import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';

const getPadding = (isMobile, isShrunk) => {
  if (isMobile) {
    return '16px';
  }
  if (isShrunk) {
    return '12px 56px';
  }
  return '32px 56px 32px';
};

const getHeight = (isMobile, isShrunk) => {
  if (!isMobile) {
    return isShrunk ? '72px' : '120px';
  }
  return 'unset';
};

export const HeaderWrapper = styled(Grid, {
  shouldForwardProp: (prop) =>
    !['isMobile', 'isShrunk', 'shouldElevateHeader'].includes(prop.toString()),
})<{
  isMobile: boolean;
  isShrunk: boolean;
  shouldElevateHeader: boolean;
}>(({ isMobile, isShrunk, shouldElevateHeader }) => ({
  padding: getPadding(isMobile, isShrunk),
  background: '#fff',
  transition: 'all .2s ease-in',
  alignItems: 'center',
  minHeight: getHeight(isMobile, isShrunk),
  position: 'fixed',
  boxShadow: `${
    isShrunk || (isMobile && shouldElevateHeader)
      ? '0px 0px 16px rgba(134, 147, 148, 0.24)'
      : 'none'
  }`,
  top: 0,
  zIndex: 9,
  transform: isMobile && isShrunk ? 'translateY(-140px)' : 'translateY(0px)',
}));

export const HeaderActionsWrapper = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'isMobile',
})<{
  isMobile: boolean;
}>(({ isMobile }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: isMobile ? 'flex-end' : 'flex-end',
  flex: isMobile ? 'auto' : 'auto',
}));
