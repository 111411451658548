import * as React from 'react';

import { useTheme } from '@mui/material/styles';
import {
  Box,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { PartnerLogo } from '../../../components/PartnerLogo';

const PedidosYaEcuadorPrivacy: React.FC = ({}) => {
  const theme = useTheme();

  return (
    <Container maxWidth="lg">
      <Box padding={4}>
        <Box marginBottom={2}>
          <PartnerLogo platform="pedidosya" width={212} height={40} />
        </Box>
        <Typography
          variant="h1"
          gutterBottom
          sx={{ color: `${theme.palette.primary.main}` }}
        >
          POLÍTICA DE PROTECCIÓN DE DATOS ECUADOR PARTNERS
        </Typography>

        <Typography variant="overline">Última Revisión 26/06/2023</Typography>

        <Typography
          variant="h6"
          sx={{ color: `${theme.palette.primary.main}` }}
        >
          1. ANTECEDENTES PRELIMINARES
        </Typography>
        <Typography variant="body1" paragraph>
          Al utilizar nuestra página web (https://www.pedidosya.com.ec)o nuestra
          Aplicación “PeYa Mobile Admin” para dispositivos móviles (en adelante,
          el “Portal”), usted, como cliente, nos confía información de su
          compañía y de aplicar, de algunas personas integrantes de su compañía,
          según se indica en los Términos y Condiciones disponibles en:
          www.portal.pedidosya.com (en adelante, el cliente de PedidosYa así
          como sus integrantes, referidos en el presente como el “Cliente”).
        </Typography>
        <Typography variant="body1" paragraph>
          Mediante la presente política de privacidad (la “Política”),
          informamos a nuestros Clientes cómo tratamos su información personal.
        </Typography>
        <Typography variant="body1" paragraph>
          Si nuestro Portal contiene enlaces que conducen a la página web de
          otro proveedor, nuestra Política no se aplica a la página web a la que
          se le remite.
        </Typography>
        <Typography variant="body1" paragraph>
          Esta Política aplica a todos las personas que utilicen el Portal.
        </Typography>
        <Typography variant="body1" paragraph>
          Los Clientes garantizan y responden, en cualquier caso, de la
          veracidad, exactitud, vigencia y autenticidad de los datos personales
          facilitados, y se comprometen a mantenerlos debidamente actualizados.
        </Typography>
        <Typography variant="body1" paragraph>
          Por favor, tómese el tiempo suficiente para leer esta Política
          detenidamente.
        </Typography>
        <Typography variant="body1" paragraph>
          Si usted tiene cualquier pregunta sobre esta Políticas o nuestro trato
          a sus datos personales, por favor escribanos un email a
          protecciondedatos.ecuador@pedidosya.com
        </Typography>

        <Typography
          variant="h6"
          sx={{ color: `${theme.palette.primary.main}` }}
        >
          2. PRINCIPIOS QUE ORIENTAN LA POLÍTICA
        </Typography>
        <Typography variant="body1" paragraph>
          PedidosYa y sus sociedades relacionadas por vínculos de subordinación
          o control en Ecuador o en una jurisdicción o territorio diferente (en
          adelante las "Sociedades Relacionadas"), en el tratamiento de la
          información de sus usuarios, respeta y respetará sus derechos,
          aplicando y garantizando los siguientes principios orientadores de la
          Política:
        </Typography>
        <ul>
          <li>
            Principio de Juridicidad: En el tratamiento de datos personales de
            los usuarios se dará aplicación a las disposiciones vigentes y
            aplicables, incluyendo las disposiciones contractuales pactadas con
            los usuarios.
          </li>
          <li>
            Principio de Libertad: El tratamiento y transferencia de datos
            personales sólo se llevará a cabo con el consentimiento, libre,
            previo, informado, específico e inequívoco del usuario. Los datos
            personales que no tengan el carácter de datos públicos en los
            términos de la Normativa, no podrán ser obtenidos o divulgados sin
            previa autorización escrita de su titular, o en ausencia de mandato
            legal o judicial que releve el consentimiento.
          </li>
          <li>
            Principio de Finalidad: El tratamiento de datos personales a los que
            tenga acceso y sean almacenados y recogidos por PedidosYa, estarán
            subordinados y atenderán una finalidad determinadas, explícitas,
            legítimas y comunicadas al titular
          </li>
          <li>
            Principio de Calidad y Exactitud: La información protegida sujeta a
            tratamiento debe ser exactos, íntegros, precisos, completos,
            comprobables, claros; y, de ser el caso, debidamente actualizados
            PedidosYa no será responsable frente al usuario cuando sean objeto
            de tratamiento, datos o información parcial, incompleta o
            fraccionada o que induzca a error, que hubiere sido proporcionada
            por el usuario sin que existiera la forma de ser verificada la
            veracidad o calidad de la misma por parte de PedidosYa o si la misma
            hubiere sido entregada o divulgada por el usuario declarando o
            garantizando, de cualquier forma, su veracidad o calidad. El usuario
            tendrá derecho a solicitar en cualquier momento que se corrijan o
            actualicen los datos que fueren inexactos, incompletos, erróneos,
            falsos, incorrectos o imprecisos.
          </li>
          <li>
            Principio de Transparencia: El tratamiento de datos personales
            deberá ser transparente, por lo que toda información o comunicación
            relativa a este tratamiento deberá ser fácilmente accesible y fácil
            de entender y se deberá utilizar un lenguaje sencillo y claro
          </li>
          <li>
            Principio de Seguridad: Los datos personales protegidos bajo la
            Política sujetos a tratamiento por PedidosYa, serán objeto de
            protección de conformidad a los estándares normales de la industria,
            a través de la adopción de medidas tecnológicas de protección,
            protocolos, y medidas administrativas que sean necesarias para
            otorgar seguridad a los registros y repositorios electrónicos
            evitando su adulteración, modificación, pérdida, consulta, y, en
            general, en contra de cualquier uso, acceso o tratamiento no
            autorizado.
          </li>
          <li>
            Principio de Confidencialidad: El tratamiento de datos personales
            debe concebirse sobre la base del debido sigilo y secreto, es decir,
            no debe tratarse o comunicarse para un fin distinto para el cual
            fueron recogidos, a menos que concurra una de las causales que
            habiliten un nuevo tratamiento conforme los supuestos de tratamiento
            legítimo señalados la Ley.
          </li>
          <li>
            Principio de Conservación: Los datos personales serán conservados
            durante un tiempo no mayor al necesario para cumplir con la
            finalidad de su tratamiento
          </li>
          <li>
            Principio de Lealtad: El tratamiento de datos personales deberá ser
            leal, por lo que para los titulares debe quedar claro que se están
            recogiendo, utilizando, consultando o tratando de otra manera, datos
            personales que les conciernen, así como las formas en que dichos
            datos son o serán tratados
          </li>
          <li>
            Principio de Pertinencia y minimización de datos personales: Los
            datos personales deben ser pertinentes y estar limitados a lo
            estrictamente necesario para el cumplimiento de la finalidad del
            tratamiento.
          </li>
          <li>
            Principio de Proporcionalidad: El tratamiento debe ser adecuado,
            necesario, oportuno, relevante y no excesivo con relación a las
            finalidades para las cuales hayan sido recogidos o a la naturaleza
            misma, de las categorías especiales de datos
          </li>
        </ul>
        <Typography
          variant="h6"
          sx={{ color: `${theme.palette.primary.main}` }}
        >
          3. INFORMACIÓN QUE PEDIDOSYA RECOPILA Y USO DE LA INFORMACIÓN
        </Typography>
        <Typography variant="body1" paragraph>
          Informamos a nuestros Clientes que el responsable de la base de datos
          DELIVERY HERO DH E-COMMERCE ECUADOR S.A.S, con domicilio en Fernando
          Ayarza N13-16 y Carlos Montúfar, parroquia Iñaquito, Ciudad de Quito
          (Provincia de Pichincha, Ecuador, y que los datos de carácter personal
          proporcionados a través del Portal, así como los que pudiera facilitar
          en el futuro en el marco de su relación con nosotros, son transmitidos
          a nuestros servidores ubicados en Estados Unidos para ser conservados
          bajo seguridad de los mismos.
        </Typography>
        <Typography variant="body1" paragraph>
          Los datos personales recogidos y tratados podrán corresponder a las
          personas naturales que interactúen a través de nuestro Portal por
          cuenta propia o por designación del cliente para la utilización del
          mismo, derivada de la relación que mantiene con DELIVERY HERO DH
          E-COMMERCE ECUADOR S.A.S,
        </Typography>
        <Typography variant="body1" paragraph>
          A continuación, informamos a nuestros Clientes qué datos recolectamos,
          para qué propósitos y bajo qué circunstancias los compartimos con
          otros.
        </Typography>
        <Typography variant="body1" paragraph>
          a. Datos personales que recolectamos cuando visita nuestro Portal
        </Typography>
        <Typography variant="body1" paragraph>
          Los datos personales son información con la que podemos relacionar a
          nuestros Clientes directa o indirectamente, tales como su nombre y
          apellidos, dirección, número de identidad fiscal, razón social, nombre
          fantasía, número de teléfono, datos de contacto o dirección de correo
          electrónico y todo otro dato que usted nos provea.
        </Typography>
        <Typography variant="body1" paragraph>
          A continuación, le mostramos qué datos recolectamos:
        </Typography>

        <Box marginBottom={4}>
          <Typography variant="body1" paragraph>
            <ul>
              <li>
                Cuando usted visita nuestro Portal para realizar su gestión:
              </li>
            </ul>
          </Typography>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography
                      variant="subtitle1"
                      sx={{ color: theme.palette.primary.main }}
                    >
                      CATEGORÍA DE INFORMACIÓN
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="subtitle1"
                      sx={{ color: theme.palette.primary.main }}
                    >
                      EXPLICACIÓN
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    Información del dispositivo desde el que visita nuestro
                    sitio web:
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1" paragraph>
                      Identificación del dispositivo, sistema operativo y/u
                      otros identificadores del dispositivo, hardware.
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Información de conexión:</TableCell>
                  <TableCell>
                    <Typography variant="body1" paragraph>
                      Hora, fecha, duración de uso del sitio web y origen.
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Información de acceso:</TableCell>
                  <TableCell>
                    <Typography variant="body1" paragraph>
                      Nombre de usuario, contraseña
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Información de comunicación:</TableCell>
                  <TableCell>
                    <Typography variant="body1" paragraph>
                      Información estadística de los pedidos recibidos y
                      facturación.
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Datos de publicación:</TableCell>
                  <TableCell>
                    <Typography variant="body1" paragraph>
                      Productos integrantes de su oferta en la aplicación
                      PedidosYa o la web así como sus ingredientes, precios,
                      fotografías, y cualquier otro tipo de contenido; que
                      describan sus productos. Horarios de apertura y cierre del
                      comercio.
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        <Box marginBottom={4}>
          <Typography variant="body1" paragraph>
            <ul>
              <li>
                Datos personales que recogemos al visitar nuestra página web, al
                registrarse:
              </li>
            </ul>
            <Typography variant="body1" paragraph>
              Para utilizar el Portal, el cliente debe de iniciar sesión y
              realizar su gestión de las publicaciones. Los campos son
              necesarios para la publicación y gestión de productos.
            </Typography>
          </Typography>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography
                      variant="subtitle1"
                      sx={{ color: theme.palette.primary.main }}
                    >
                      CATEGORÍA DE INFORMACIÓN
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="subtitle1"
                      sx={{ color: theme.palette.primary.main }}
                    >
                      EXPLICACIÓN
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Información de cuenta:</TableCell>
                  <TableCell>
                    <Typography variant="body1" paragraph>
                      Esta información puede incluir el nombre completo,
                      domicilio fiscal, número de identidad fiscal, datos de
                      cuenta bancaria, razón social, nombre fantasía, número
                      telefónico, email, información de pago (incluida
                      información de verificación de pago vinculada), documento
                      de identidad o número de documentos de identificación ante
                      organismos públicos, número de socio en programas de
                      promociones asociados a PedidosYa, foto de perfil.
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Información de acceso:</TableCell>
                  <TableCell>
                    <Typography variant="body1" paragraph>
                      Mail y contraseña.
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Información del dispositivo desde el que visita nuestro
                    sitio web:
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1" paragraph>
                      Identificación del dispositivo, sistema operativo y/u
                      otros identificadores del dispositivo, hardware.
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Información de conexión:</TableCell>
                  <TableCell>
                    <Typography variant="body1" paragraph>
                      Hora, fecha, duración de uso del sitio web y origen.
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Otra información:</TableCell>
                  <TableCell>
                    <Typography variant="body1" paragraph>
                      Información estadística sobre ventas y facturación.
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        <Typography variant="body1" paragraph>
          En cualquiera de los casos antes indicados, por favor tenga presente
          que toda información que usted nos provea según lo antes descrito,
          PedidosYa la considera información personal suya y por tanto si nos
          provee información personal de otra persona, incluidas personas
          integrantes de su empresa, PedidosYa no será responsable de obtener el
          consentimiento previo de esa otra persona para tratar dichos datos
          personales según estas Políticas de Privacidad, carga que le será
          imputable a usted de forma exclusiva por hacer uso y tratamiento de
          datos personales de terceros, entendiéndose en todo momento que,
          respecto de dichos datos, es usted es el Responsable del Tratamiento.
        </Typography>
        <Typography variant="body1" paragraph>
          b. Uso de los datos personales de los clientes
        </Typography>
        <Typography variant="body1" paragraph>
          Solo recolectamos sus datos personales cuando es necesario y la
          finalidad es lícita y previamente definida. PedidosYa recopila y usa
          su información personal para los propósitos especificados en esta
          Política o en las secciones relevantes de nuestro Portal.
        </Typography>

        <Box marginBottom={4}>
          <Typography variant="body1" paragraph>
            PedidosYa utiliza su información para distintos fines:
          </Typography>
          <TableContainer>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell>Prestación de servicios</TableCell>
                  <TableCell>
                    <Typography variant="body1" paragraph>
                      Utilizamos su información para ofrecer, personalizar,
                      mantener y mejorar la prestación de los servicios que les
                      ofrecemos a nuestros Clientes, así como realizar las
                      siguientes actividades:
                    </Typography>
                    <ul>
                      <li>
                        Llevar procesos internos de evaluación y estudio para la
                        posibilidad de contratarle como proveedor de PedidosYa.
                      </li>
                      <li>
                        Gestionar pagos, retenciones, descuentos y otras
                        acciones relativas y correspondientes a los servicios
                        prestados.
                      </li>
                      <li>Administrar el Portal.</li>
                      <li>Actualizar su cuenta.</li>
                      <li>Verificar su perfil.</li>
                      <li>
                        Mejorar la experiencia de navegación tanto en el Partner
                        Portal como en la web y/o aplicación móvil.
                      </li>
                      <li>
                        Habilitarlo a usted para el uso de los servicios
                        disponibles en el Portal.
                      </li>
                      <li>Activar funciones para personalizar su cuenta.</li>
                      <li>Mejorar iniciativas comerciales y promocionales.</li>
                    </ul>
                    <Typography variant="body1" paragraph>
                      Para estas actividades tomamos como base de legitimación
                      la ejecución de obligaciones contractuales
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Servicio de Atención al Cliente</TableCell>
                  <TableCell>
                    <Typography variant="body1" paragraph>
                      Cuando el cliente se pone en contacto con nuestro Servicio
                      de Atención al Cliente para obtener información o para
                      presentar un reclamo técnico, almacenamos la información
                      que el Cliente nos proporciona. Dependiendo del motivo del
                      contacto, los datos personales pueden variar.
                    </Typography>
                    <Typography variant="body1" paragraph>
                      Para esta actividad tomamos como base de legitimación la
                      ejecución de obligaciones contractuales
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Envío de información</TableCell>
                  <TableCell>
                    <Typography variant="body1" paragraph>
                      Enviarle información que pueda ser de su interés respecto
                      de nuestros servicios y/o invitarle a eventos y
                      actividades de cualquier índole y naturaleza, por
                      cualquier canal, incluido pero no limitado, por vía
                      electrónica.
                    </Typography>
                    <Typography variant="body1" paragraph>
                      Para esta actividad tomamos como base de legitimación tu
                      consentimiento
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Protección y seguridad:</TableCell>
                  <TableCell>
                    <Typography variant="body1" paragraph>
                      Utilizamos sus datos personales para mantener la
                      protección, seguridad e integridad de nuestros servicios y
                      Clientes. Asimismo, utilizamos la información para
                      prevenir fraudes. Para estas actividades tomamos como base
                      de legitimación la ejecución de obligaciones contractuales
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Investigación y desarrollo:</TableCell>
                  <TableCell>
                    <Typography variant="body1" paragraph>
                      Podemos utilizar la información que recopilamos para
                      testear, investigar, analizar y desarrollar productos.
                      Esto permite que podamos mejorar en términos de seguridad
                      y que podamos desarrollar nuevas funciones o servicios.
                    </Typography>
                    <Typography variant="body1" paragraph>
                      Para estas actividades tomamos como base de legitimación
                      tu consentimiento y nuestro interés legítimo.
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Publicidad</TableCell>
                  <TableCell>
                    <Typography variant="body1" paragraph>
                      Podemos enviarte correos electrónicos, SMS, push
                      notifications o realizar llamadas para ofrecerte
                      recomendaciones de cómo utilizar el portal, información
                      sobre nuevos productos, realizar encuestas y sondeos de
                      opinión para mejorar nuestro servicio
                    </Typography>
                    <Typography variant="body1" paragraph>
                      Para estas actividades tomamos como base de legitimación
                      tu consentimiento
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Procedimientos judiciales o requerimientos administrativos
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1" paragraph>
                      Podremos utilizar tus datos personales en caso que nos sea
                      requerido por orden judicial o resolución administrativa,
                      regulatoria, arbitral, administrativa, y en general cuando
                      sea requerido por cualquier autoridad gubernamental.
                      También podremos utilizar su información para registrarlo
                      ante registros de deudores por incumplimientos de pagos.
                    </Typography>
                    <Typography variant="body1" paragraph>
                      Para estas actividades tomamos como base de legitimación
                      el cumplimiento de una obligación legal o disposición de
                      autoridad.
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        <Typography
          variant="h6"
          sx={{ color: `${theme.palette.primary.main}` }}
        >
          4. ACTUALIZACIÓN DE LA INFORMACIÓN
        </Typography>
        <Typography variant="body1" paragraph>
          Si su información cambia o ha cambiado, el cliente es responsable de
          modificarla en el Portal. Esto permitirá el correcto funcionamiento
          del sitio.
        </Typography>
        <Typography variant="body1" paragraph>
          Si usted actualiza y/o modifica sus datos personales, “PedidosYa”
          conservará la información personal anterior por motivos de seguridad y
          control del fraude.
        </Typography>

        <Typography
          variant="h6"
          sx={{ color: `${theme.palette.primary.main}` }}
        >
          5. CON QUIÉN COMPARTIMOS SUS DATOS PERSONALES
        </Typography>
        <Typography variant="body1" paragraph>
          En el evento que PedidosYa, en el ejercicio de sus actividades
          propias, utilice, transmita o transfiera internacionalmente datos
          personales garantizará el cumplimiento de los principios aplicables
          establecidos en la Sección 2 de la presente Política, así como la
          normativa aplicable.
        </Typography>
        <Typography variant="body1" paragraph>
          Concretamente PedidosYa, en el giro ordinario de sus negocios podrá
          incorporar la información que Ud. nos provea dentro de sus sistemas de
          información. PedidosYa, como responsable del tratamiento garantiza que
          los sistemas de información cumplen en su integridad la Política y la
          Normativa.
        </Typography>
        <Typography variant="body1" paragraph>
          En la siguiente sección le indicamos a quién le informamos sus datos y
          bajo qué condiciones, así como a qué países los transferimos.
        </Typography>
        <Typography variant="body1" paragraph>
          a. Qué terceros tienen acceso a los datos personales
        </Typography>
        <Typography variant="body1" paragraph>
          <ul>
            <li>Empresas relacionadas a PedidosYa</li>
          </ul>
        </Typography>
        <Typography variant="body1" paragraph>
          Podremos compartir datos con nuestras empresas relacionadas para
          facilitar y mejorar el procesamiento de nuestros datos sobre
          servicios. Por ejemplo, en el Espacio Económico Europeo (EEE) y
          Uruguay, PedidosYa procesa y almacena información en nombre de sus
          filiales internacionales.
        </Typography>
        <Typography variant="body1" paragraph>
          <ul>
            <li>Proveedores de servicios y comercios adheridos a PedidosYa</li>
          </ul>
        </Typography>
        <Typography variant="body1" paragraph>
          PedidosYa puede facilitar información a sus proveedores, consultores,
          socios de marketing, empresas de investigación y otros proveedores de
          servicios o socios comerciales. A modo de ejemplo PedidosYa podrá
          facilitar información a:
          <ul>
            <li>Procesadores y facilitadores de pagos.</li>
            <li>Proveedores de almacenamiento en la nube.</li>
            <li>
              Socios de marketing y proveedores de plataformas de marketing.
            </li>
            <li>Proveedores de análisis de datos.</li>
            <li>
              Proveedores de investigación, incluidos los que implementan
              estudios o proyectos de investigación en colaboración con
              PedidosYa o en su nombre.
            </li>
            <li>Consultores y otros proveedores de servicios profesionales.</li>
            <li>Registros de deudores.</li>
          </ul>
        </Typography>
        <Typography variant="body1" paragraph>
          <ul>
            <li>Entidades del sector público</li>
          </ul>
        </Typography>
        <Typography variant="body1" paragraph>
          Entidades del sector público competentes y autoridades de control que
          tengan la facultad de solicitar información sobre tus datos tales como
          Unidad de Análisis Financiero y Económico, Servicio de Rentas
          Internas, Jueces y Tribunales, Fiscalía General del Estado, Policía
          Nacional, Superintendencia de Protección de Datos, entre otras.
        </Typography>
        <Typography variant="body1" paragraph>
          <ul>
            <li>Otros</li>
          </ul>
        </Typography>
        <Typography variant="body1" paragraph>
          El Cliente reconoce con la aceptación de la Política que, de
          presentarse una venta, fusión, consolidación, cambio de control
          societario, transferencia sustancial de activos, reorganización o
          liquidación de PedidosYa, PedidosYa podrá transferir, enajenar o
          asignar sus datos personales a una o más partes relevantes, incluidas
          Sociedades Relacionadas o terceros. Asimismo, PedidosYa puede
          compartir su información de otra manera que no sea la descrita en esta
          política si así se lo notificamos y lo acepta expresamente.
        </Typography>
        <Typography variant="body1" paragraph>
          Finalmente le informamos que PedidosYa utiliza los servicios de
          terceras empresas para poder prestar sus servicios, para lo cual
          dichos terceros podrán tener que acceder a datos personales. Dichas
          empresas proveedoras podrán clasificarse en las siguientes categorías:
          proveedores de servicios tecnológicos e informáticos, empresas de
          seguridad, empresas de mensajería, servicios de atención y emisión de
          llamadas telefónicas, empresas de gestión y mantenimiento de sistemas,
          infraestructuras e inmuebles, servicios logísticos, servicios de
          tasación, servicios de gestión de cobro y recuperación de cartera por
          cuenta de terceros, proveedores de servicio de medios de pago,
          entidades bancarias o financieras, servicios de asesoramiento jurídico
          y tributario, agencias de publicidad y de servicios de marketing y
          comunicación así como servicios profesionales generales. La relación
          anterior se facilita a título de ejemplo pudiendo PedidosYa utilizar
          servicios de empresas pertenecientes a otros sectores de actividad
          para gestionar la relación con proveedores y prestadores médicos.
          PedidosYa velará por el correcto tratamiento de los datos personales
          por parte de estos.
        </Typography>
        <Typography variant="body1" paragraph>
          Toda transferencia de los datos personales fuera del Ecuador, se
          realizará conforme a la normativa vigente y a las disposiciones que en
          su momento emita la Superintendencia de Protección de Datos.
        </Typography>

        <Typography
          variant="h6"
          sx={{ color: `${theme.palette.primary.main}` }}
        >
          6. A QUÉ PAÍSES TRANSFERIMOS SUS DATOS
        </Typography>
        <Typography variant="body1" paragraph>
          Podremos compartir datos con nuestras empresas relacionadas para
          facilitar y mejorar el procesamiento de nuestros datos sobre
          servicios. Por ejemplo, en el Espacio Económico Europeo (EEE) y
          Uruguay, PedidosYa procesa y almacena información en nombre de sus
          filiales internacionales. Así también podemos compartir sus datos
          personales en otros países en los que PedidosYa cuenta con
          operaciones, cuando sea necesaria para prestar nuestros servicios.
        </Typography>

        <Typography
          variant="h6"
          sx={{ color: `${theme.palette.primary.main}` }}
        >
          7. SUS DERECHOS
        </Typography>
        <Typography variant="body1" paragraph>
          El Titular de los datos personales, y del derecho a la protección de
          los mismos, podrá ejercer los siguientes derechos en relación con el
          tratamiento de sus datos personales:
        </Typography>
        <Typography variant="body1" paragraph>
          <ul>
            <li>
              El derecho de acceso a sus datos contenidos en los
              ficheros/bancos/bases de datos de PedidosYa
            </li>
            <li>
              El derecho de rectificación y actualización de aquellos datos que
              puedan ser incorrectos o incompletos.
            </li>
            <li>
              El derecho de eliminación, en los casos previstos en la Ley
              Orgánica de Protección de Datos Personales.
            </li>
            <li>
              El derecho de oposición que le permite solicitar que se cese en
              todos o algunos de los tratamientos, como el envío de
              comunicaciones comerciales, que llevamos a cabo con sus datos
              personales.
            </li>
            <li>
              El derecho suspensión del tratamiento de datos, durante un período
              de tiempo limitado y en los casos que establece la normativa
              vigente.
            </li>
            <li>
              El derecho a la portabilidad, que le permitirá solicitar que le
              remitamos sus datos personales en un formato compatible,
              actualizado, estructurado, común, inter-operable y de lectura
              mecánica, preservando sus características, bien directamente a
              usted o bien a otra entidad, en su nombre, conforme a los
              requerimientos establecidos en la normativa vigente.
            </li>
            <li>
              El derecho a no ser objeto de una decisión basada única o
              parcialmente en valoraciones automatizadas incluida la elaboración
              de perfiles, que produzcan efectos jurídicos en él o que atenten
              contra sus derechos y libertades fundamentales
            </li>
          </ul>
        </Typography>
        <Typography variant="body1" paragraph>
          Asimismo, podrá solicitar la revocación del consentimiento otorgado
          para el tratamiento de sus datos.
        </Typography>
        <Typography variant="body1" paragraph>
          Para ejercer los indicados derechos o plantear cualquier cuestión
          relativa al tratamiento de sus datos personales, el titular debe
          dirigirse a PedidosYa cuando este sea identificado como responsable
          del tratamiento, por solicitud dirigida a PedidosYa, Delegado de
          Protección de datos mediante correo electrónico dirigido a
          protecciondedatos.ecuador@pedidosya.com, acreditando su identidad.
        </Typography>

        <Typography
          variant="h6"
          sx={{ color: `${theme.palette.primary.main}` }}
        >
          8. INFORMACIÓN SOBRE COOKIES
        </Typography>
        <Typography variant="body1" paragraph>
          Una cookie consiste en información enviada por un servidor web a un
          navegador web, y almacenada por el navegador. La información es luego
          enviada de nuevo al servidor en cada momento que el navegador solicita
          una página del servidor. Esto habilita al servidor a identificar y
          rastrear el navegador web. Nosotros usaremos tanto cookies de sesión
          como cookies persistentes únicamente en nuestro sitio web.
        </Typography>
        <Typography variant="body1" paragraph>
          Usaremos las cookies de sesión para estar al tanto de usted mientras
          utiliza en el Portal.
        </Typography>
        <Typography variant="body1" paragraph>
          Por su parte, usaremos cookies persistentes para habilitar a nuestro
          sitio web que lo reconozca a usted cuando visita nuestro Portal. Las
          cookies de sesión serán eliminadas de su computadora cuando usted
          cierra el navegador web. Las cookies persistentes se mantendrán
          almacenadas en su computadora hasta que sean eliminadas, o hasta que
          lleguen a una fecha de expiración especificada.
        </Typography>
        <Typography variant="body1" paragraph>
          Utilizamos Google Analytics para analizar el uso de este sitio web.
          Google Analytics genera información estadística y de otro tipo sobre
          el uso de sitios web mediante cookies, las cuales son almacenadas en
          la computadora del Cliente. La información generada en relación con
          nuestro sitio web es utilizada para crear reportes sobre el uso de
          nuestro sitio. Google almacenará esta información.
        </Typography>
        <Typography variant="body1" paragraph>
          Las políticas de privacidad de Google se encuentran disponibles en:
          http://www.google.com/privacypolicy.html
        </Typography>
        <Typography variant="body1" paragraph>
          La mayoría de los navegadores web lo autorizan a usted a rechazar la
          aceptación de cookies. Esto se logra a través de la configuración de
          cada navegador en particular. Esto sin embargo, tendrá un impacto
          negativo con respecto a la usabilidad de varios sitios web incluyendo
          este.
        </Typography>

        <Typography
          variant="h6"
          sx={{ color: `${theme.palette.primary.main}` }}
        >
          9. SEGURIDAD DE SU INFORMACIÓN PERSONAL Y SERVIDORES
        </Typography>
        <Typography variant="body1" paragraph>
          Toda información recabada en el Portal será tratada en cumplimiento de
          la Normativa y los datos serán utilizados únicamente para los fines
          aquí consentidos o los que consienta oportunamente. PedidosYa expresa
          su compromiso de proteger la seguridad de la información personal de
          los Clientes. Con ese fin, PedidosYa usa una amplia variedad de
          tecnologías y procedimientos de seguridad así como medidas
          administrativas, y jurídicas para proteger la información personal
          contra un acceso, uso, modificación o una divulgación no autorizados.
          Estas medidas han sido implementadas para proteger su Información
          Personal contra el acceso, revelación, uso y modificación no
          autorizados y son revisadas, probadas periódicamente.
        </Typography>
        <Typography variant="body1" paragraph>
          Sin embargo, la transmisión de datos a través de internet es
          inherentemente insegura, y nosotros no podemos garantizar la seguridad
          de los datos enviados a través de internet. PedidosYa no tiene control
          alguno sobre la privacidad de las comunicaciones mientras estén en
          tránsito hacia ella. Por lo anterior, le recomendamos que no incluya
          información confidencial, secreta, comprometedora, datos sensibles o
          información personal delicada que usted no desea revelar en estas
          comunicaciones (incluyendo correos electrónicos). Asimismo, usted es
          responsable de mantener su contraseña y detalles de Cliente
          confidencialmente.
        </Typography>
        <Typography variant="body1" paragraph>
          Usted reconoce y acepta que a pesar de todos nuestros esfuerzos,
          ninguna medida de seguridad de datos puede garantizar por completo la
          protección en todo momento.
        </Typography>

        <Typography
          variant="h6"
          sx={{ color: `${theme.palette.primary.main}` }}
        >
          10. ¿QUIÉN CONTROLA O SUPERVISA SU CUMPLIMIENTO?
        </Typography>
        <Typography variant="body1" paragraph>
          El titular podrá presentar una reclamación ante la Autoridad de
          Protección de Datos a través de los medios que ésta haya habilitado
          para el efecto.
        </Typography>

        <Typography
          variant="h6"
          sx={{ color: `${theme.palette.primary.main}` }}
        >
          11. PLAZOS DE CONSERVACIÓN
        </Typography>
        <Typography variant="body1" paragraph>
          PedidosYa considera distintos plazos de conservación según el tipo de
          tratamiento de datos personales que se refiera y que la norma que lo
          regule.
        </Typography>
        <Typography variant="body1" paragraph>
          PedidosYa considera distintos plazos de conservación según el tipo de
          tratamiento de datos personales que se refiera y que la norma que lo
          regule.
        </Typography>
        <Typography variant="body1" paragraph>
          Los datos personales tratados se conservarán mientras sean necesarios
          para cumplir las finalidades de uso, el cumplimiento de las
          obligaciones contractuales o mientras el titular no retire su
          consentimiento.
        </Typography>
        <Typography variant="body1" paragraph>
          En nuestros canales digitales, sitios web o aplicativos móviles, los
          plazos adicionales podrán estar determinados en las Políticas de
          Cookies, por lo que lo instamos a revisarlos de manera singularizada.
        </Typography>
        <Typography variant="body1" paragraph>
          Transcurridos dichos plazos, cesará el tratamiento y se procederá
          conforme a los establecido en la Ley Orgánica de Protección de Datos
          Personales.
        </Typography>

        <Typography
          variant="h6"
          sx={{ color: `${theme.palette.primary.main}` }}
        >
          12. CAMBIOS EN ESTA DECLARACIÓN DE PRIVACIDAD
        </Typography>
        <Typography variant="body1" paragraph>
          PedidosYa se reserva el derecho de realizar las modificaciones que
          estime oportunas en esta Política de Privacidad. Si se realizan
          cambios materiales en esta Política, se lo informaremos mediante una
          comunicación electrónica y requerimos su aceptación expresa respecto
          del nuevo texto de esta Política. Consulte con frecuencia esta
          declaración para estar informado, en forma permanente y actualizada,
          de cómo PedidosYa protege la privacidad de su información.
        </Typography>

        <Typography
          variant="h6"
          sx={{ color: `${theme.palette.primary.main}` }}
        >
          13. DISPOSICIONES FINALES
        </Typography>
        <Typography variant="body1" paragraph>
          El Portal puede contener enlaces con referencias a otros sitios web
          más allá de nuestro control. Por favor recuerde que no tenemos ningún
          control sobre estos sitios web y que nuestra Política no es aplicable
          a estos sitios. Le recomendamos que lea las políticas de privacidad y
          los términos y condiciones de los sitios web conectados o
          referenciados en los que usted entre. Ninguna parte de esta Política
          supone la creación o adición de algún derecho o reclamación (ya sea
          legal, equitativo o de otro tipo) que cualquier individuo o persona
          pueda tener según la ley, o de alguna otra forma, en contra de la
          empresa, terceros, o sus directores respectivos, delegados, empleados,
          agentes o representantes; ni la existencia de la presente Política o
          su aplicación impondrá o agregará obligación alguna o responsabilidad
          a ninguno de los nombrados, que no tenga actualmente según la ley
          Ecuatoriana.
        </Typography>

        <Typography
          variant="h6"
          sx={{ color: `${theme.palette.primary.main}` }}
        >
          14. JURISDICCIÓN Y LEY APLICABLE
        </Typography>
        <Typography variant="body1" paragraph>
          La presente Política se encuentra regida sin excepción y en todos sus
          términos por las leyes de la República del Ecuador y será interpretada
          de acuerdo a ellas. Cualquier controversia derivada de la presente
          Política, su existencia, validez, interpretación, alcance o
          cumplimiento será sometida a los tribunales ordinarios de Justicia con
          jurisdicción en Ecuador, renunciándose en forma expresa a cualquier
          otro fuero o jurisdicción.
        </Typography>

        <Typography
          variant="h6"
          sx={{ color: `${theme.palette.primary.main}` }}
        >
          14. CONSENTIMIENTO
        </Typography>
        <Typography variant="body1" paragraph>
          Presto mi consentimiento para que mis datos personales, sean tratados
          y transmitidos por encargados de tratamiento ubicados en EE. UU., en
          el Espacio Económico Europeo (EEE) y Uruguay, para que los utilicen
          con los fines indicados en esta Política, incluida la posibilidad de
          que me hagan llegar vía correo electrónico u otro medio de
          comunicación, información sobre promociones y novedades relacionadas
          con PedidosYa
        </Typography>
      </Box>
    </Container>
  );
};

export default PedidosYaEcuadorPrivacy;
