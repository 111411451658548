import { injectable, inject } from 'inversify';
import { History } from 'history';
import MarketingUserInfo from './MarketingUserInfo';

@injectable()
export default class QualtricsManager {
  @inject(MarketingUserInfo) protected marketingUserInfo: MarketingUserInfo;
  @inject('history') private history: History;

  private currentPage: string;
  private _window: Window & { userInfo?: Object; QSI?: any } = window;

  async initSurvey(): Promise<void> {
    const userInfo = await this.marketingUserInfo.getData();
    if (userInfo) {
      // expose userInfo globally for qualtrics
      this._window.userInfo = userInfo;

      // wait for qsi api init
      const interceptLoaded = () => {
        if (this._window.QSI.API) {
          this.runSurvey();

          // listen and fire surveys also while navigating between pages
          this.history.listen(this.listenToLocationChange.bind(this));
        }
      };
      if (typeof this._window.QSI !== 'undefined') {
        interceptLoaded();
      } else {
        this._window.addEventListener('qsi_js_loaded', interceptLoaded, false);
      }
    }
  }

  private runSurvey(): void {
    this._window.QSI.API.load().then(this._window.QSI.API.run());
  }

  private listenToLocationChange(location): void {
    const { pathname } = location;
    const isDifferentPage = pathname !== this.currentPage;

    if (this._window.QSI.API && isDifferentPage) {
      this.currentPage = pathname;
      this.runSurvey();
    }
  }
}
