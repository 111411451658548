// Custom react-router implementations that attach base route at beginning of the route.
// This is used to make plugins only live in their route context.
import React, { Ref } from 'react';
import {
  Route,
  RouteProps,
  LinkProps,
  Link,
  RedirectProps,
  Redirect,
  withRouter,
  RouteComponentProps,
  Switch,
  SwitchProps,
} from 'react-router-dom';
import * as History from 'history';
import { QueryStringParser } from './QueryStringParser';

const context = React.createContext('');

const KEEP_SEARCH_PARAMS = ['vendor'];

export const RouterProvider = ({ baseurl, children }) => (
  <context.Provider value={baseurl} children={children} />
);

/**
 * Wraps `react-router`'s `Switch` component and prepends plugin's base route to the URL if not already there
 */
export const PluginSwitch: React.ComponentType<SwitchProps> = withRouter(
  (props) => {
    const baseurl = React.useContext(context);
    const baseUrlRegexp = new RegExp(`^${baseurl}`);
    const pathname = props.location.pathname.replace(baseUrlRegexp, '');

    const newLocation = {
      ...props.location,
      pathname,
    };

    return <Switch {...props} location={newLocation} />;
  },
);

/**
 * Wraps `react-router`'s `Route` component and prepends plugin's base route to the URL if not already there
 */
export const PluginRoute: React.ComponentType<RouteProps> = (props) => {
  const baseurl = React.useContext(context);
  const path = Array.isArray(props.path)
    ? props.path.map<string>(prefixPathname.bind(null, baseurl))
    : prefixPathname(baseurl, String(props.path));

  return <Route {...props} path={path} />;
};

/**
 * Wraps `react-router`'s `Link` component and prepends plugin's base route to the URL if not already there
 */
export const PluginLink: React.ComponentType<LinkProps> = React.forwardRef(
  (props, ref: Ref<HTMLAnchorElement>) => {
    const baseurl = React.useContext(context);
    const to = getLinkTo(baseurl, props.to);
    return <Link {...props} to={to} ref={ref} />;
  },
);

/**
 * Wraps `react-router`'s `Redirect` component and prepends plugin's base route to the URL if not already there
 */
export const PluginRedirect = withRouter<
  RouteComponentProps<any> & RedirectProps,
  React.ComponentType<RouteComponentProps<any> & RedirectProps>
>(({ location, ...props }) => {
  const baseurl = React.useContext(context);
  const to = prefixLocationDescriptor(baseurl, props.to, location);
  return <Redirect {...props} to={to} />;
});

/**
 * Prefixes a URL descriptor with the `baseurl` if it's not already there, supports functions and objects as well,
 * the same way `react-router` does.
 * @param baseurl base URL to prefix
 * @param to URL to prefix
 */
function getLinkTo<S>(
  baseurl: string,
  to:
    | History.LocationDescriptor<S>
    | ((location: History.Location<S>) => History.LocationDescriptor<S>),
): (location: History.Location<S>) => History.LocationDescriptor<S> {
  if (typeof to === 'function') {
    return (location: History.Location<S>) =>
      prefixLocationDescriptor(baseurl, to(location), location);
  }

  return (location: History.Location<S>) =>
    prefixLocationDescriptor(baseurl, to, location);
}

/**
 * Prefix location descriptor (string or object)
 * @param baseurl base URL to prefix
 * @param to URL to prefix
 * @param location current location that was passed from the wrapped link (see `getLinkTo` function)
 */
function prefixLocationDescriptor<S>(
  baseurl: string,
  to: History.LocationDescriptor<S>,
  location: History.Location<S>,
): History.LocationDescriptor<S> {
  if (typeof to === 'string') {
    const [pathnameAndSearch, hash] = to.split('#');
    const [pathname, search] = pathnameAndSearch.split('?');
    return {
      hash: hash && `#${hash}`,
      search: stripKeepSearchParams(location.search, search && `?${search}`),
      pathname: prefixPathname(baseurl, pathname),
    };
  }

  return {
    ...to,
    search: stripKeepSearchParams(location.search, to.search),
    pathname: prefixPathname(baseurl, to.pathname),
  };
}

/**
 * Actually prefix the pathname (string)
 * @param baseurl base URL to prefix
 * @param pathname pathname to prefix
 */
function prefixPathname(baseurl: string, pathname: string): string {
  const strippedBaseurl = baseurl.replace(/(\/$)/, '');
  const strippedPathname = `/${pathname.replace(/^\//, '')}`;

  if (
    strippedPathname === strippedBaseurl ||
    strippedPathname.startsWith(`${strippedBaseurl}/`)
  ) {
    return strippedPathname;
  }

  return strippedBaseurl + strippedPathname;
}

/**
 * Merge search parameters that should be kept (e.g. ?vendors=123) with new ones.
 * Kept search parameters can be found in `KEEP_SEARCH_PARAMS` constant.
 * New search parameters can overwrite parameters that should be kept. If the new search parameters
 * don't include kept parameters, the ones from the current location are used.
 * @param search Current search (e.g. from current location)
 * @param mergeSearch New search parameters
 */
function stripKeepSearchParams(search: string, mergeSearch?: string) {
  // Get serialized merge search parameters
  const serializedMerge = mergeSearch
    ? QueryStringParser.parse(mergeSearch)
    : {};
  // Get serialised current search parameters
  const serialized = QueryStringParser.parse(search);

  // Filter out search parameters from current URL that should also be used in resulting search string
  const filteredSerialized = Object.entries(serialized)
    .filter(([key]) => KEEP_SEARCH_PARAMS.includes(key))
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

  // Create new search string, where filtered parameters from previous URL are taken over, but new
  // ones can overwrite previous ones
  return QueryStringParser.stringify({
    ...filteredSerialized,
    ...serializedMerge,
  });
}
