import * as React from 'react';
import { styled as muiStyled } from '@mui/material/styles';
import { Box } from '@mui/material';
import transparentize from 'polished/lib/color/transparentize';

export type TintedBackgroundProps = {
  children?: JSX.Element | string | JSX.Element[] | string[];
  image: string;
};

const Wrapper: any = muiStyled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  backgroundColor: theme.palette.primary.main,
}));

const TintedBackgroundImage = muiStyled(Box, {
  shouldForwardProp: (prop) => prop !== 'image',
})<{ image: string }>(({ image }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  backgroundImage: `linear-gradient(to bottom, ${transparentize(
    0.7,
    '#ffffff',
  )}, #ffffff), url(${image})`,
  backgroundSize: '100% 100%, cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'top',
}));

const TintedBackgroundContent = muiStyled(Box)({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
});

const TintedBackground = ({
  children,
  image,
  ...props
}: TintedBackgroundProps) => (
  <Wrapper {...props}>
    <TintedBackgroundImage image={image} />
    <TintedBackgroundContent>{children}</TintedBackgroundContent>
  </Wrapper>
);

export default TintedBackground;
