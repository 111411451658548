import * as React from 'react';
import FWFMain from '@deliveryhero/fwf-sdk-javascript';

export type FwfProviderProps = {
  client: FWFMain;
  children: React.ReactNode;
};

export type WithFwfProps = {
  fwfClient: FWFMain;
};

const { Provider, Consumer } = React.createContext(undefined);

export const FwfProvider = ({ client, children }: FwfProviderProps) => (
  <Provider value={client}>{children}</Provider>
);

export function withFwf<T>(
  Component: React.ComponentType<WithFwfProps>,
): React.ComponentType<T> {
  return (props: T) => (
    <Consumer>
      {(fwfClient: FWFMain) => {
        if (!fwfClient) {
          return null;
        }

        return <Component fwfClient={fwfClient} {...props} />;
      }}
    </Consumer>
  );
}
