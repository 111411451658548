import * as React from 'react';
import FrontendPluginContainer from '../FrontendPluginContainer';
import { PluginStore } from '../../stores/PluginStore';
import NotFoundPage from '../../components/errors/NotFoundPage';
import { ApiStatus } from '../../models/ApiStatus';
import FwfStore from '../../stores/FwfStore';
import { PlatformStore } from '../../stores/PlatformStore';
import Header from '../../components/OnboardingHeader';
import { UserStore } from '../../stores/UserStore';
import { styled } from '@mui/material/styles';
import { Box, CircularProgress } from '@mui/material';
import { forBreakpoint } from '../../utils/forBreakpoint';
import { ONBOARDING_TIMELINE_PLUGIN_CODE } from '../../constants';
import { NavStore } from '../../stores/NavStore';
import { SessionStore } from '../../stores/SessionStore';
import { getLoginRedirectUrlParts } from '../../utils/getLoginRedirectUrlParts';
import { Redirect } from 'react-router-dom';
import { Location } from 'history';
import { QueryStringParser } from '../../utils/QueryStringParser';

export type OnboardingContainerProps = {
  pluginStore: PluginStore;
  fwfStore: FwfStore;
  platformStore: PlatformStore;
  userStore: UserStore;
  navStore: NavStore;
  sessionStore: SessionStore;
  location: Location;
};

const mobileHeaderHeight = 70;
const desktopHeaderHeight = 89;

const PluginWrapper = styled(Box)`
  margin-top: ${mobileHeaderHeight + 32}px;

  ${forBreakpoint('sm')} {
    margin-top: ${desktopHeaderHeight + 64}px;
  }
`;

const LoaderWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const OnboardingContainer = (props: OnboardingContainerProps) => {
  const {
    pluginStore,
    fwfStore,
    platformStore,
    userStore,
    navStore,
    sessionStore,
    location,
  } = props;
  const { currentPlatform } = platformStore;
  const { userProfile } = userStore;
  const isLoggedIn = sessionStore.isLoggedIn;

  const onboardingPlugin = pluginStore.frontendPlugins.find(
    (plugin) => plugin.code === ONBOARDING_TIMELINE_PLUGIN_CODE,
  );

  if (!isLoggedIn) {
    const { redirectQueryParameter, excludedQueryParameters } =
      getLoginRedirectUrlParts(location);
    return (
      <Redirect
        to={{
          pathname: '/login',
          search: QueryStringParser.stringify({
            ...excludedQueryParameters,
            redirect: redirectQueryParameter,
          }),
        }}
      />
    );
  }

  const renderPage = () => {
    if (onboardingPlugin) {
      return (
        <>
          <Header
            currentPlatform={currentPlatform}
            userName={userProfile.user.name}
            userRole={userProfile.user.role}
            navFilters={navStore.filters}
          />
          <PluginWrapper>
            <FrontendPluginContainer
              pluginName={onboardingPlugin.code}
              bundleUrl={onboardingPlugin.bundleUrl}
            />
          </PluginWrapper>
        </>
      );
    }

    const arePluginsLoaded = pluginStore.pluginApiStatus === ApiStatus.SUCCESS;

    if (arePluginsLoaded && fwfStore.ready && !onboardingPlugin) {
      return <NotFoundPage />;
    }

    return (
      <LoaderWrapper>
        <CircularProgress size={80} />
      </LoaderWrapper>
    );
  };

  return renderPage();
};

export default OnboardingContainer;
