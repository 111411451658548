export type LangTagSeparator = '_' | '-';

// get a language with a subtag for complex language codes
// For example: zh-Hant-HK (zh_Hant_HK) -> zh-Hant (zh_Hant)
const buildSubtag = (lng: string, separator: LangTagSeparator): string => {
  if (lng.includes(separator)) {
    const splittedArr = lng.split(separator);

    return splittedArr.length > 2
      ? `${splittedArr[0]}${separator}${splittedArr[1]}`
      : '';
  }

  return '';
};

export const getSubtagLang = (lng: string): string =>
  lng.includes('_') ? buildSubtag(lng, '_') : buildSubtag(lng, '-');
