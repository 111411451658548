import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material';
import { useIsBelowBreakpoint } from '@deliveryhero/vt-portal-chardonnay/core';

import {
  HeaderHelpCenter,
  IconWrapper,
  IframeWrapper,
  MinimizeIcon,
  FloatingBubble,
  UnreadMessagesCountBadge,
} from './HelpCenterContainer.styles';
import AuthService from '../../services/AuthService';
import useInterval from '../../hooks/useInterval';
import useAttachmentDownload from './useAttachmentDownload';
import { Translate } from '../../components/Translate';
import GtmManager from '../../utils/gtm/GtmManager';
import { HelpStore } from '../../stores/HelpStore';
import { SessionStore } from 'src/stores/SessionStore';
import Icon from '@mui/material/Icon';

const POLLING_INTERVALS = [100, 10000]; // 100ms at the start, and 10s there after

type HelpCenterContainerProps = {
  authService: AuthService;
  gtmManager: GtmManager;
  helpStore: HelpStore;
  sessionStore: SessionStore;
};

export enum BridgeMessageType {
  HELPCENTER_INIT_WILL_START = 'HELPCENTER_INIT_WILL_START',
  HELPCENTER_ASKS_FOR_NEW_ACCESS_TOKEN = 'HELPCENTER_ASKS_FOR_NEW_ACCESS_TOKEN',
  HELPCENTER_INIT_STARTED = 'HELPCENTER_INIT_STARTED',
  HELPCENTER_INIT_FAILED = 'HELPCENTER_INIT_FAILED',
  HELPCENTER_INIT_IS_DONE = 'HELPCENTER_INIT_IS_DONE',
  HOST_IS_READY = 'HOST_IS_READY',
  HELPCENTER_ASKS_FOR_FILE_DOWNLOAD = 'HELPCENTER_ASKS_FOR_FILE_DOWNLOAD',
  HOST_ALLOWED_FILE_DOWNLOAD = 'HOST_ALLOWED_FILE_DOWNLOAD',
  HOST_DENIED_FILE_DOWNLOAD = 'HOST_DENIED_FILE_DOWNLOAD',
  HOST_FILE_DOWNLOAD_SUCCESS = 'HOST_FILE_DOWNLOAD_SUCCESS',
  HOST_FILE_DOWNLOAD_FAIL = 'HOST_FILE_DOWNLOAD_FAIL',
  HELPCENTER_CHAT_STARTED = 'HELPCENTER_CHAT_STARTED',
  HELPCENTER_CHAT_ENDED = 'HELPCENTER_CHAT_ENDED',
  HOST_ASKS_FOR_SHUTDOWN = 'HOST_ASKS_FOR_SHUTDOWN',
  HOST_SENDS_NEW_ACCESS_TOKEN = 'HOST_SENDS_NEW_ACCESS_TOKEN',
  HOST_DENIES_NEW_ACCESS_TOKEN = 'HOST_DENIES_NEW_ACCESS_TOKEN',
  HELPCENTER_ASKS_FOR_SHUTDOWN = 'HELPCENTER_ASKS_FOR_SHUTDOWN',
  HELPCENTER_SHUTDOWN_READY = 'HELPCENTER_SHUTDOWN_READY',
  HELPCENTER_DEEPLINK_CLICKED = 'HELPCENTER_DEEPLINK_CLICKED',
}

const ackBridgeMessages: BridgeMessageType[] = [
  BridgeMessageType.HELPCENTER_ASKS_FOR_SHUTDOWN,
  BridgeMessageType.HELPCENTER_ASKS_FOR_FILE_DOWNLOAD,
];

export interface BridgeMessage {
  type: BridgeMessageType;
  id: string;
  payload?: Record<string, any>;
}

const HelpCenterContainer: React.FC<HelpCenterContainerProps> = ({
  authService,
  gtmManager: gtm,
  helpStore,
  sessionStore,
}) => {
  const { pathname: location } = useLocation();
  const history = useHistory();
  const isMobile: boolean = useIsBelowBreakpoint('sm');
  const theme = useTheme();
  const [messagePort, setMessagePort] = React.useState<MessagePort | null>(
    null,
  );
  const [url, setUrl] = React.useState<string>('');
  const [unreadMessagesCount, setUnreadMessagesCount] =
    React.useState<number>(0);
  // assumes no count fetch till hc is opened & closed first time after pageload
  const [isPolling, setIsPolling] = React.useState<boolean>(false);
  const [pollingInterval, setPollingInterval] = React.useState<number>(
    POLLING_INTERVALS[0], // initally 100ms, for quick feedback, changes to 10s in the first polled cycle
  );
  const [isDirty, setIsDirty] = React.useState<boolean>(false); // to check if HC was opened atleast once
  const getCurrentTimestamp = (): number => new Date().getTime();

  const toggleHelpCenter = () => {
    if (helpStore.isOpen) {
      if (messagePort) {
        // eslint-disable-next-line no-console
        console.log('host asks for shutdown');
        messagePort.postMessage({
          type: BridgeMessageType.HOST_ASKS_FOR_SHUTDOWN,
          id: String(new Date().getTime()),
        });
      }
    } else {
      // eslint-disable-next-line no-console
      console.log('host opens helpcenter');
      helpStore.toggleHelpCenter(true, helpStore.options); // open HC if it is closed already
    }
    gtm.pushEvent('button_tap', {
      location,
      label: 'help_center_bubble',
    });
  };

  const closeHelpCenter = () => {
    helpStore.toggleHelpCenter(false);
    setIsDirty(true);
  };

  React.useEffect(() => {
    if (helpStore.isOpen) {
      gtm.pushEvent('modal_open', {
        location,
        label: 'help_center_bubble',
      });
      setIsPolling(false); // stop polling as hc is opened
      setUnreadMessagesCount(0); // clear the count as hc is opened
      authService
        .getHelpCenterUrl(helpStore.options)
        .then((res) => {
          setUrl(res.url);
          // TODO: this GA event firing does not really tell if the iframe has loaded successfully
          gtm.pushEvent('request_return', {
            location,
            label: 'help_center_bubble',
          });
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log('Error while fetching helpcenter URL.', err);
        });
    } else if (!helpStore.isOpen && isDirty) {
      gtm.pushEvent('modal_close', {
        location,
        label: 'help_center_bubble',
      });
      setUrl(''); // clear hc iframe
    }

    // when portal is reloaded with a chat in progress, we need chat count numbers to be shown
    const hcChatStatus = window.localStorage.getItem('help-center-chat-status');
    if (!helpStore.isOpen && hcChatStatus === 'started') {
      setIsPolling(true);
    }
  }, [helpStore.isOpen, isDirty]);

  useInterval(
    async () => {
      try {
        setPollingInterval(POLLING_INTERVALS[1]);
        const response = await authService.getHelpCenterChatMessagesCount();
        if (response?.chat?.active) {
          const unreadCount = response?.chat?.unread_message_count;
          setUnreadMessagesCount(unreadCount); // display count on bubble when hc is closed
        } else {
          window.localStorage.removeItem('help-center-chat-status');
          setUnreadMessagesCount(0); // clear count when no active chats
          setIsPolling(false); // kill polling if there are no active chats
        }
      } catch (err) {
        if (err.status === 404) {
          setIsPolling(false); // kill polling if api responds with 404 error
        }
      }
    },
    isPolling ? pollingInterval : null,
  );

  React.useEffect(() => {
    window.addEventListener('message', (event: MessageEvent<BridgeMessage>) => {
      const {
        ports: [port],
      } = event;
      if (event.data.type === BridgeMessageType.HELPCENTER_INIT_STARTED) {
        port.postMessage({
          type: BridgeMessageType.HOST_IS_READY,
          id: String(new Date().getTime()),
          payload: {
            supportedMessageTypes: Object.values(BridgeMessageType),
          },
        });
        setMessagePort(port);
      }
    });
    gtm.pushEvent('bubble_display', {
      location,
      label: 'help_center_bubble',
    });
  }, []);

  const [sendFileDownloadRequest] = useAttachmentDownload({
    onFileDownloadSuccess: (requestId) => {
      // eslint-disable-next-line no-unused-expressions
      messagePort?.postMessage({
        type: BridgeMessageType.HOST_FILE_DOWNLOAD_SUCCESS,
        id: String(new Date().getTime()),
        correlationId: requestId,
      });
    },
    onFileDownloadError: (requestId, error) => {
      // eslint-disable-next-line no-unused-expressions
      messagePort?.postMessage({
        type: BridgeMessageType.HOST_FILE_DOWNLOAD_FAIL,
        id: String(new Date().getTime()),
        correlationId: requestId,
        description: error?.toString(),
      });
    },
  });

  React.useEffect(() => {
    if (messagePort) {
      if (!sessionStore.isLoggedIn && helpStore.isOpen) {
        messagePort.postMessage({
          type: BridgeMessageType.HOST_DENIES_NEW_ACCESS_TOKEN,
        });
      }

      messagePort.onmessage = async (event): Promise<void> => {
        const { data: message } = event as { data?: BridgeMessage };

        if (message) {
          if (ackBridgeMessages.includes(message.type)) {
            messagePort.postMessage({
              type: `${message.type}_ACK`,
              id: message.id,
            });
          }

          switch (message.type) {
            case BridgeMessageType.HELPCENTER_ASKS_FOR_NEW_ACCESS_TOKEN:
              await authService.refreshToken();

              const { accessToken } = authService.getAuthToken();

              messagePort.postMessage({
                type: BridgeMessageType.HOST_SENDS_NEW_ACCESS_TOKEN,
                payload: {
                  accessToken,
                },
              });
              break;
            case BridgeMessageType.HELPCENTER_ASKS_FOR_FILE_DOWNLOAD: {
              const { id, payload } = message;

              messagePort.postMessage({
                type: BridgeMessageType.HOST_ALLOWED_FILE_DOWNLOAD,
                id: String(getCurrentTimestamp),
                correlationId: id,
              });

              sendFileDownloadRequest({ url: payload.url, id });
              break;
            }
            case BridgeMessageType.HELPCENTER_CHAT_STARTED:
              window.localStorage.setItem('help-center-chat-status', 'started');
              break;
            case BridgeMessageType.HELPCENTER_SHUTDOWN_READY:
              // eslint-disable-next-line no-console
              console.log('hc shutdown ready');
              closeHelpCenter();
              break;
            case BridgeMessageType.HELPCENTER_DEEPLINK_CLICKED:
              const {
                payload: { link },
              } = message;

              closeHelpCenter();

              gtm.pushEvent('deeplink_clicked', {
                link,
                label: 'help_center_deeplink',
              });

              // eslint-disable-next-line no-unused-expressions
              link.startsWith('https://')
                ? window.open(link)
                : history.push(link);

              break;
            default:
              break;
          }
        }
      };
    }
  }, [messagePort]);

  return (
    <IconWrapper
      id="help-center-bubble"
      style={{ bottom: `${helpStore.offsetY}px` }}
    >
      <IframeWrapper
        isHelpCenterOpen={helpStore.isOpen}
        data-testid="help-center-iframe"
      >
        <HeaderHelpCenter>
          <p style={{ color: theme.palette.primary.contrastText }}>
            <Translate code={'global.menu.helpcenter'} />
          </p>
          <div style={{ position: 'absolute', right: '20px' }}>
            <MinimizeIcon
              style={{ color: theme.palette.primary.contrastText }}
              onClick={toggleHelpCenter}
              data-testid="minimize-icon"
              color="inherit"
            >
              <Icon>keyboard_arrow_down</Icon>
            </MinimizeIcon>
          </div>
        </HeaderHelpCenter>
        <iframe
          width={'100%'}
          height={isMobile ? '95%' : '100%'}
          src={url}
          frameBorder="0"
        />
      </IframeWrapper>

      <UnreadMessagesCountBadge
        badgeContent={unreadMessagesCount}
        overlap="circular"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <FloatingBubble
          style={{ color: theme.palette.primary.contrastText }}
          onClick={toggleHelpCenter}
          data-testid="help-center-icon"
        >
          {helpStore.isOpen ? (
            <Icon sx={{ fontSize: '24px' }}>close</Icon>
          ) : (
            <Icon sx={{ fontSize: '24px' }}>help</Icon>
          )}
        </FloatingBubble>
      </UnreadMessagesCountBadge>
    </IconWrapper>
  );
};

export default HelpCenterContainer;
