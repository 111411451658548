import * as React from 'react';

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Link,
} from '@mui/material';
import { ISupportedBrowser } from '../../config';
import { Translate } from '../Translate';
import Icon from '@mui/material/Icon';

type Props = {
  supportedBrowsers: ISupportedBrowser[];
  show: boolean;
  close: (flag: boolean) => void;
};

const UnsupportedBrowserWarning: React.FC<Props> = ({
  show,
  close,
  supportedBrowsers,
}: Props): React.ReactElement => {
  return (
    <Dialog
      id="unsupported-browser-warning"
      data-testid="unsupported-browser-warning"
      open={show}
      onClose={() => close(false)}
    >
      <DialogTitle sx={{ verticalAlign: 'middle', display: 'inline-flex' }}>
        <Icon sx={{ color: 'red', paddingRight: 2 }}>warning</Icon>{' '}
        <Translate code="portal.browser_warning.title" />
      </DialogTitle>
      <DialogContent>
        <Box sx={{ paddingTop: 2 }}>
          <Typography variant="paragraph-500">
            <Translate code="portal.browser_warning.warning_info" />
          </Typography>
        </Box>
        <Box sx={{ paddingTop: 2 }}>
          <Typography variant="paragraph-500">
            <Translate code="portal.browser_warning.recommendation" />
          </Typography>
          <List sx={{ paddingTop: 2 }}>
            {supportedBrowsers.map(
              ({ name, identifier, minimumSupportedVersion }) => (
                <ListItem key={identifier}>
                  <ListItemIcon>
                    <Icon fontSize="small">arrow_right</Icon>
                  </ListItemIcon>
                  <ListItemText>
                    <Typography variant="paragraph-500">
                      <Translate
                        code="portal.browser_warning.min_version_supported"
                        params={{
                          browserName: name,
                          minimumSupportedVersion,
                        }}
                      />
                    </Typography>
                  </ListItemText>
                </ListItem>
              ),
            )}
          </List>
        </Box>
        <Box sx={{ paddingTop: 2 }}>
          <Link
            data-testid="update-browser-help-link"
            href="https://www.whatismybrowser.com/guides/how-to-update-your-browser/"
            target="blank"
          >
            <Translate code="portal.browser_warning.help_link" />
          </Link>
          <Typography variant="paragraph-500">
            <Translate code="portal.browser_warning.consult_tech_desk" />
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button data-testid="close-warning-action" onClick={() => close(false)}>
          <Translate code="portal.browser_warning.close" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UnsupportedBrowserWarning;
