import AuthService from '../../services/AuthService';
import GtmManager from '../../utils/gtm/GtmManager';
import FwfStore from '../../stores/FwfStore';
import { withDependencies } from '@deliveryhero/react-inversify-provider';
import Logout from './Logout';
import { TYPES } from '../../types';
import { PluginStore } from '../../stores/PluginStore';

export default withDependencies({
  sessionStore: TYPES.SessionStore,
  fwfStore: FwfStore,
  gtmManager: GtmManager,
  window: 'window',
  authService: AuthService,
  pluginStore: PluginStore,
  userStore: TYPES.UserStore,
})(Logout);
