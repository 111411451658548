import * as React from 'react';
import { withDependencies } from '@deliveryhero/react-inversify-provider';
import { TranslationsStore } from '../../stores/TranslationsStore';
import ErrorMessage from './ErrorMessage';

export interface ForbiddenErrorProps {
  title?: string;
  message?: string;
  centered?: boolean;
}

export const ForbiddenError: React.FC<ForbiddenErrorProps> = ({
  title,
  message,
  centered,
}) => (
  <ErrorMessage
    title={title || null}
    message={message || null}
    centered={centered}
  />
);

const Forbidden = withDependencies({
  translationsStore: TranslationsStore,
})((props) => (
  <ForbiddenError
    title={props.translationsStore.translate('global.error.forbidden.headline')}
    message={props.translationsStore.translate(
      'global.error.forbidden.message',
    )}
    {...props}
  />
));

export default Forbidden;
