import { useSdk } from '@deliveryhero/vendor-portal-sdk';
import {
  HeaderButton,
  OutletSelector,
} from '@deliveryhero/vt-portal-chardonnay/components';
import Box from '@mui/material/Box';
import * as React from 'react';
import { VendorName } from './OutletSelectorWrapper.styles';
import { formatVendors } from './ultils';
import { translate } from '../Translate';

const OutletSelectorWrapper = ({
  isMultipleVendors,
  selectedOutlets,
  onChange,
  hideContentDescription,
}) => {
  const [showOutletSelector, setShowOutletSelector] =
    React.useState<boolean>(false);
  const [vendorsList, setVendorsList] = React.useState([]);
  const sdk = useSdk();

  const getSelectedOutletText = () => {
    if (!isMultipleVendors) {
      const { vendorName, vendorCode } = vendorsList.find(({ value }) =>
        selectedOutlets.includes(value),
      );
      return (
        <Box display={'flex'}>
          <VendorName>{vendorName}</VendorName>
          <span>({vendorCode})</span>
        </Box>
      );
    } else {
      const outletsSelected = `${selectedOutlets.length}/ ${vendorsList.length}`;
      return selectedOutlets.length === vendorsList.length
        ? `${translate({ code: 'global.all' })} (${outletsSelected})`
        : outletsSelected;
    }
  };

  React.useEffect(() => {
    sdk.getAllVendors().then((v) => {
      const formattedVendors = formatVendors(v);
      setVendorsList(formattedVendors);
    });
  }, []);

  if (vendorsList.length < 1) {
    return null;
  }

  return (
    <>
      <HeaderButton
        data-testid={'outlet-button'}
        onClick={() => setShowOutletSelector(!showOutletSelector)}
        selectedContent={getSelectedOutletText()}
        hideDescription={hideContentDescription}
        shouldTrimText={true}
        contentDescription={translate({
          code: 'outlet_selector.header_title',
        })}
      />
      <OutletSelector
        outlets={vendorsList}
        selectedOutlets={selectedOutlets}
        isMultiSelect={isMultipleVendors}
        onPrimaryButtonClick={onChange}
        onClose={() => setShowOutletSelector(false)}
        isOpen={showOutletSelector}
      />
    </>
  );
};

export default OutletSelectorWrapper;
