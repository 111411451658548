// TODO: Deprecated component. To be removed
// Also, remove it from PluginModuleDependencyStore.ts
// But first we need to confirm that no active plugins use it
// So we should keep it for now

import * as portalComponents from '@deliveryhero/vendor-portal-components';

import * as ChartComponents from './charts';
import ErrorMessage from '../errors/ErrorMessage';
import Forbidden from '../errors/Forbidden';
import NotFound from '../errors/NotFoundPage/NotFound';
import ServerError from '../errors/ServerError';

export default {
  ...portalComponents,
  ...ChartComponents,
  ErrorMessage,
  Forbidden,
  NotFound,
  ServerError,
};
