import { withDependencies } from '@deliveryhero/react-inversify-provider';
import { PluginStore } from '../../../stores/PluginStore';
import Routes from './Routes';

const RoutesContainer = withDependencies({
  pluginStore: PluginStore,
  config: 'config',
})(Routes);

export default RoutesContainer;
