import * as React from 'react';
import { withDependencies } from '@deliveryhero/react-inversify-provider';

import LanguageSelector from './LanguageSelector';
import { RenderType } from './LanguageSelector/LanguageSelector';
import { LanguageStore } from '../stores/LanguageStore';

const withLanguageSwitch = (Component) => {
  class HOC extends React.Component<any, any> {
    constructor(props) {
      super(props);

      this.state = {
        open: false,
      };
    }

    render() {
      const { open } = this.state;

      return (
        <>
          <LanguageSelector
            renderType={RenderType.DIALOG}
            open={open}
            closeDialog={() => this.setOpen(false)}
          />

          <Component
            {...this.props}
            switchLanguage={() => this.setOpen(true)}
            currentLanguage={this.props.languageStore.currentLanguage}
          />
        </>
      );
    }

    private setOpen(open: boolean) {
      this.setState({ open });
    }
  }

  return withDependencies({
    languageStore: LanguageStore,
  })(HOC);
};

export default withLanguageSwitch;
