import { Container, interfaces } from 'inversify';
import { LocaleStore } from './stores/LocaleStore';
import { TranslationsStore } from './stores/TranslationsStore';
import { AvailableLanguagesStore } from './stores/AvailableLanguagesStore';

import {
  IConfig,
  IPlatform,
  IAvailableLanguage,
  ISupportedBrowser,
} from './config';
import * as Sentry from '@sentry/browser';

// Import stores
import { DialogStore } from './stores/DialogStore';
import { PlatformStore } from './stores/PlatformStore';
import { LanguageStore } from './stores/LanguageStore';
import { NavStore } from './stores/NavStore';
import { PluginStore } from './stores/PluginStore';
import { SessionStore } from './stores/SessionStore';
import PluginModuleStore from './stores/PluginModuleStore';
import PluginModuleDependencyStore from './stores/PluginModuleDependencyStore';
import { datadogLogs } from '@datadog/browser-logs';

// Import services
import AuthService from './services/AuthService';
import IntervalService from './services/IntervalService';

// Import utils
import tokenExpiredCondition from './utils/tokenExpiredCondition';
import { Api } from '@deliveryhero/portal-api-client';
import { IDevTools, DevTools, NoDevTools } from './utils/DevTools';
import { historyFactory } from './utils/history';
import PageVisibilityManager from './utils/PageVisibilityManager';
import VersionUpdateHandler from './utils/VersionUpdateHandler';
import MarketingUserInfo from './utils/MarketingUserInfo';

// GTM
import GtmLoginSucceededEvent from './utils/gtm/GtmLoginSucceededEvent';
import GtmVirtualPageViewEvent from './utils/gtm/GtmVirtualPageViewEvent';
import GtmManager from './utils/gtm/GtmManager';
import GtmLoginRenewedEvent from './utils/gtm/GtmLoginRenewedEvent';
import GtmLoginReturnedEvent from './utils/gtm/GtmLoginReturnedEvent';
import GtmVirtualPageViewListener from './utils/gtm/GtmVirtualPageViewListener';
import PortalSDKStore from './stores/PortalSDKStore';
import PluginTranslationsStore from './stores/PluginTranslationsStore';
import { SentrySDK, TYPES, DatadogSDK, BrazeSDK } from './types';

import MasterDateStore from './stores/MasterDateStore';
import FwfStore from './stores/FwfStore';
import FWFMain from '@deliveryhero/fwf-sdk-javascript';
import GtmPerformanceTiming from './utils/gtm/GtmPerformanceTiming';
import { ApiStore } from './stores/ApiStore';
import { LoggerService } from './services/LoggerService';
import { MasterSdkStore } from './stores/MasterSdkStore';
import { VendorStore } from './stores/VendorStore';
import { BrazeManager } from './utils/BrazeManager';
import { UtilsDateStore } from './stores/UtilsDateStore';
import QualtricsManager from './utils/QualtricsManager';
import { UserStore } from './stores/UserStore';
import { IPlugin } from './models/Plugin';
import { NotificationsStore } from './stores/NotificationsStore';
import { HelpStore } from './stores/HelpStore';

// Config
export const config: IConfig = require('config');

// Stores
export const container = new Container({ defaultScope: 'Singleton' });

// Creating instances of external packages
const localeStore = new LocaleStore('en', window);

// Bind dependencies to container
container.bind<IConfig>('config').toConstantValue(config);
container.bind<any>('authServiceUrl').toConstantValue(config.authServiceUrl);
container.bind<any>('apiUrl').toConstantValue(config.apiUrl);
container.bind<any>('bffApiUrl').toConstantValue(config.bffApiUrl);
container
  .bind<any>('defaultOneWebUrl')
  .toConstantValue(config.defaultOneWebUrl);
container.bind<any>('helpCenterUrl').toConstantValue(config.helpCenterUrl);
container.bind<any>('pluginApiUrl').toConstantValue(config.pluginApiUrl);
container
  .bind<IPlugin[] | undefined>('pluginOverrides')
  .toConstantValue(config.pluginOverrides);
container.bind<IPlatform[]>('platforms').toConstantValue(config.platforms);
container
  .bind<IAvailableLanguage[]>('availableLanguages')
  .toConstantValue(config.availableLanguages);
container
  .bind<ISupportedBrowser[]>('supportedBrowsers')
  .toConstantValue(config.supportedBrowsers);
container.bind<any>('window').toConstantValue(window);
container.bind<any>('document').toConstantValue(document);
container
  .bind<any>('history')
  .toDynamicValue((context) =>
    historyFactory(
      context.container.get('window'),
      context.container.get('config'),
    ),
  );

// Services
container.bind<AuthService>(AuthService).toSelf();
container.bind<IntervalService>(IntervalService).toSelf();

// Stores
container.bind<DialogStore>(DialogStore).to(DialogStore);
container.bind<PlatformStore>(PlatformStore).to(PlatformStore);
container.bind<LanguageStore>(LanguageStore).to(LanguageStore);
container.bind<LocaleStore>(LocaleStore).toConstantValue(localeStore);
container.bind<NavStore>(NavStore).to(NavStore);
container.bind<PluginStore>(PluginStore).to(PluginStore);
container.bind<VendorStore>(TYPES.VendorStore).to(VendorStore);
container.bind<MasterDateStore>(MasterDateStore).toSelf();
container.bind<FwfStore>(FwfStore).toSelf();
container.bind<SessionStore>(TYPES.SessionStore).to(SessionStore);
container.bind<UserStore>(TYPES.UserStore).to(UserStore);

container.bind<AvailableLanguagesStore>(AvailableLanguagesStore).toSelf();
container.bind<TranslationsStore>(TranslationsStore).toSelf();
container.bind<PluginTranslationsStore>(PluginTranslationsStore).toSelf();
container.bind<PluginModuleStore>(PluginModuleStore).to(PluginModuleStore);
container
  .bind<PluginModuleDependencyStore>(PluginModuleDependencyStore)
  .to(PluginModuleDependencyStore);
container.bind<PortalSDKStore>(PortalSDKStore).to(PortalSDKStore);
container.bind<NotificationsStore>(NotificationsStore).to(NotificationsStore);

// Utils
container
  .bind<Api>(TYPES.GlobalApi)
  .toConstantValue(new Api(tokenExpiredCondition));

container
  .bind<Api>(TYPES.GlobalApiV2)
  .toConstantValue(new Api(tokenExpiredCondition));

container.bind<ApiStore>(ApiStore).toSelf();

container
  .bind<IDevTools>('devTools')
  .toConstantValue(config.devTools ? new DevTools(window) : new NoDevTools());
container
  .bind<PageVisibilityManager>(PageVisibilityManager)
  .to(PageVisibilityManager);
container
  .bind<VersionUpdateHandler>(VersionUpdateHandler)
  .to(VersionUpdateHandler);
// GTM
container.bind<GtmManager>(GtmManager).to(GtmManager);
container
  .bind<GtmLoginSucceededEvent>(GtmLoginSucceededEvent)
  .to(GtmLoginSucceededEvent);
container
  .bind<GtmLoginSucceededEvent>(GtmLoginRenewedEvent)
  .to(GtmLoginRenewedEvent);
container
  .bind<GtmLoginReturnedEvent>(GtmLoginReturnedEvent)
  .to(GtmLoginReturnedEvent);
container
  .bind<GtmPerformanceTiming>(GtmPerformanceTiming)
  .to(GtmPerformanceTiming);
container
  .bind<GtmVirtualPageViewEvent>(GtmVirtualPageViewEvent)
  .to(GtmVirtualPageViewEvent);
container
  .bind<GtmVirtualPageViewListener>(GtmVirtualPageViewListener)
  .to(GtmVirtualPageViewListener);

// Newables
container
  .bind<interfaces.Newable<FWFMain>>('Newable<FWFMain>')
  .toConstructor<FWFMain>(FWFMain);

container.bind<SentrySDK>(TYPES.Sentry).toConstantValue(Sentry);
container.bind<BrazeSDK>(TYPES.BrazeSDK).toProvider(() => {
  return () =>
    import(/* webpackChunkName: "brazeSdk" */ '@braze/web-sdk').then(
      (module) => module.default,
    );
});
container.bind<DatadogSDK>(TYPES.Datadog).toConstantValue(datadogLogs);
container.bind<LoggerService>(TYPES.LoggerService).to(LoggerService);
container.bind<MasterSdkStore>(TYPES.MasterSdk).to(MasterSdkStore);
container.bind<BrazeManager>(TYPES.BrazeManager).to(BrazeManager);
container.bind<UtilsDateStore>(TYPES.UtilsDateStore).to(UtilsDateStore);
container.bind<MarketingUserInfo>(MarketingUserInfo).to(MarketingUserInfo);
container.bind<QualtricsManager>(TYPES.QualtricsManager).to(QualtricsManager);
container.bind<HelpStore>(TYPES.HelpStore).to(HelpStore);
