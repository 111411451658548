import * as React from 'react';
import SubHeaderText from '../../components/SubHeaderText';
import { SubHeader as TabletSubHeader } from './Tablet/ResetSubHeader';
import { IPlatform } from '../../../../config';

interface Props {
  platform: IPlatform;
  isForceReset?: boolean;
}

export const Header: React.FC<Props> = ({ isForceReset }) => {
  return (
    <SubHeaderText>
      <TabletSubHeader isForceReset={isForceReset} />
    </SubHeaderText>
  );
};
