export const SCROLLABLE_AREA_OFFSET = 32;
export const OVERLAY_OFFSET = 30;
export const NO_TOP_OVERLAY = 'no-top-overlay';

interface GetSidebarOverlayPositionArgs {
  scrollableArea: number;
  scrolledArea: number;
  condition?: string;
}

interface GetSidebarOverlayPositionReturnType {
  top: boolean;
  bottom: boolean;
}

const updateTopOverlay = (
  scrolledArea: number,
  overlayPosition: GetSidebarOverlayPositionReturnType,
): GetSidebarOverlayPositionReturnType => {
  if (scrolledArea > OVERLAY_OFFSET) {
    overlayPosition = { ...overlayPosition, top: true };
  } else {
    overlayPosition = { ...overlayPosition, top: false };
  }
  return overlayPosition;
};

const updateBottomOverlay = (
  unScrolledArea: number,
  overlayPosition: GetSidebarOverlayPositionReturnType,
): GetSidebarOverlayPositionReturnType => {
  if (unScrolledArea <= OVERLAY_OFFSET) {
    overlayPosition = { ...overlayPosition, bottom: false };
  } else {
    overlayPosition = { ...overlayPosition, bottom: true };
  }
  return overlayPosition;
};

export const getSidebarOverlayPosition = ({
  scrollableArea,
  scrolledArea,
  condition = 'none',
}: GetSidebarOverlayPositionArgs): GetSidebarOverlayPositionReturnType => {
  const unScrolledArea = scrollableArea - scrolledArea;
  let overlayPosition = {
    top: false,
    bottom: false,
  };

  if (!(condition === 'no-top-overlay')) {
    overlayPosition = updateTopOverlay(scrolledArea, overlayPosition);
  }

  overlayPosition = updateBottomOverlay(unScrolledArea, overlayPosition);
  return overlayPosition;
};
