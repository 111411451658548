import * as React from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Translate } from '@deliveryhero/vendor-portal-components';
import { forwardRef } from 'react';
// import { useIsMobile } from '../../hooks/breakpoint';

const ImpersonatorWarningWrapper = styled(Box)(({}) => ({
  backgroundColor: '#d61f26',
  color: '#ffffff',
  width: '100%',
  position: 'fixed',
  top: 0,
  boxSizing: 'border-box',
  padding: '8px 4px',
  display: 'flex',
  justifyContent: 'center',
  left: 0,
  zIndex: 11,
}));

export const ImpersonatorWarning = forwardRef((props, ref) => {
  return (
    <ImpersonatorWarningWrapper data-testid="impersonator-message" ref={ref}>
      <Typography variant="subtitle1" letterSpacing="unset">
        <Translate code="global.impersonator_message" />
      </Typography>
    </ImpersonatorWarningWrapper>
  );
});
