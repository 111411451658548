/* eslint-disable max-len */
import * as React from 'react';

export const YemeksepetiPartnerLogo: React.FC = () => (
  <svg
    data-testid="platform-logo-svg"
    width="212"
    height="48"
    viewBox="0 0 212 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M105.014 12H172.083L167.958 36H100.854L105.014 12Z"
      fill="#FA0050"
    />
    <path
      d="M126.063 21.5688C126.128 21.2487 125.934 20.9344 125.62 20.8469C124.835 20.6445 124.027 20.5369 123.216 20.5254C118.877 20.5254 117.165 23.9097 117.133 26.8505C117.133 28.6947 118.076 29.9448 119.6 29.9549C120.393 29.9434 121.143 29.5961 121.667 29.0005C121.654 29.2086 121.75 29.4081 121.92 29.5272C122.337 29.7984 122.815 29.9621 123.312 30.0022C123.987 30.0597 124.398 29.8975 124.545 29.1698L125.389 24.8211L126.063 21.5688ZM120.902 27.5207C120.375 27.5207 120.117 27.0414 120.127 26.2563C120.127 25.0177 121.018 22.7672 122.389 22.7715C122.566 22.7744 122.742 22.7945 122.916 22.8289L122.484 24.9373C122.484 24.9947 122.358 25.4956 122.358 25.4956C121.957 27.0241 121.387 27.5193 120.902 27.5193V27.5207Z"
      fill="white"
    />
    <path
      d="M113.68 20.0806C113.112 20.0849 112.553 20.2327 112.058 20.5111C111.618 20.748 111.239 21.0838 110.95 21.4914L110.996 21.256C111.071 20.9331 110.942 20.5973 110.667 20.4092C110.317 20.2054 109.927 20.0791 109.524 20.0404C109.252 20.0074 108.978 20.0074 108.708 20.0404C108.304 20.1179 108.149 20.3475 108.057 20.8197C108.041 20.8556 105.783 32.481 105.783 32.481C105.666 33.1125 105.871 33.2919 106.214 33.4613H106.244C106.782 33.7153 107.379 33.8215 107.974 33.7684C108.655 33.6909 108.902 33.5991 109.092 32.7063C109.092 32.6862 109.604 29.9622 109.604 29.9622H109.646C109.806 30.0555 109.979 30.1287 110.158 30.1775C110.494 30.2607 110.838 30.3037 111.184 30.3052C114.468 30.3109 116.556 27.0673 116.556 23.6873V23.6773C116.562 21.7483 115.699 20.0547 113.679 20.0806H113.68ZM113.156 24.0203C113.156 24.0203 113.156 24.0174 113.156 24.016C113.155 25.622 112.268 27.8538 110.996 27.8639C110.86 27.8639 110.723 27.8466 110.591 27.8122C110.401 27.7476 110.225 27.6428 110.079 27.505L110.505 25.1197C110.586 24.3159 110.949 23.5668 111.531 23.0056C111.738 22.8405 111.995 22.7472 112.261 22.7444C112.957 22.7444 113.158 23.383 113.158 24.0174C113.158 24.016 113.158 24.0131 113.158 24.0117V24.0217L113.156 24.0203Z"
      fill="white"
    />
    <path
      d="M158.713 23.35C158.751 21.5717 157.456 20.3504 155.504 20.2886C152.047 20.1781 150.017 23.1491 149.924 26.0267C149.845 28.4996 151.168 30.0324 153.874 30.1186C155 30.1544 156.014 30.0109 157.113 29.6435C157.587 29.4856 157.634 29.2632 157.614 28.9833C157.58 28.514 157.4 28.0289 157.235 27.815C156.967 27.4634 156.823 27.373 156.383 27.4906C156.037 27.5825 155.46 27.7246 154.762 27.7088C154.047 27.693 153.532 27.4763 153.212 27.1763C153.026 26.9768 152.935 26.7415 152.905 26.5089C156.456 26.74 158.632 25.8229 158.712 23.35H158.713ZM153.27 24.2197C153.412 23.4505 154.019 22.5807 155.062 22.6137C155.564 22.6295 155.826 22.928 155.813 23.3155V23.3342C155.791 23.9915 155.026 24.3145 153.27 24.2197Z"
      fill="white"
    />
    <path
      d="M139.107 27.7546C138.977 27.4575 138.795 27.2982 138.439 27.3455C138.246 27.3714 137.835 27.4862 137.666 27.3412C137.531 27.225 137.435 27.0728 137.426 26.8461C137.419 26.6652 137.534 26.1227 137.591 25.8357L138.184 22.7829H139.56C139.731 22.7843 140.084 22.5935 140.167 22.1959C140.279 21.662 140.319 21.408 140.347 21.141C140.384 20.765 140.002 20.4693 139.623 20.4693L138.634 20.465C138.634 20.465 138.947 18.9236 138.947 18.8791C139.016 18.3696 138.827 18.1973 138.511 18.0639C138.007 17.8514 137.432 17.7452 136.95 17.7596C136.305 17.7797 136.05 17.916 135.873 18.7599L135.542 20.465H134.92C134.626 20.465 134.366 20.6717 134.31 20.9587C134.26 21.2171 134.182 21.5917 134.106 21.9892C134.06 22.2332 133.97 22.7399 134.709 22.7786H135.098L134.405 26.3007C134.27 26.9322 134.202 27.5307 134.221 27.9225C134.284 29.2228 135.099 30.0897 136.822 30.0897C137.549 30.0897 138.066 29.9103 138.471 29.7065C139.143 29.3692 139.224 29.2099 139.26 28.6961C139.276 28.475 139.242 28.0588 139.11 27.7531L139.107 27.7546Z"
      fill="white"
    />
    <path
      d="M132.486 20.0959C132.081 20.1002 131.618 20.3629 131.259 20.6327C130.802 20.9743 130.395 21.4608 130.056 22.0636H130.024L130.119 20.8752C130.146 20.3313 129.723 20.0012 129.057 20.0773C128.548 20.1361 127.929 20.4203 127.583 20.8035C127.322 21.092 127.309 21.4551 127.263 21.8857C127.191 22.5473 127.082 23.2735 126.954 23.961C126.954 23.961 126.293 27.5032 126.053 28.6872C125.92 29.3446 126.101 29.5455 126.485 29.7306C126.985 29.9703 127.579 30.0636 128.081 30.0234C128.684 29.9746 128.886 29.877 129.046 29.0805C129.073 28.9427 129.59 26.4641 129.727 25.8024C130.111 24.327 130.867 23.1788 132.311 23.2247C132.697 23.2362 132.957 23.0755 133.139 22.7669C133.42 22.2904 133.585 21.7651 133.567 21.214C133.542 20.4949 133.277 20.0887 132.484 20.0988L132.486 20.0959Z"
      fill="white"
    />
    <path
      d="M165.209 20.0959C164.805 20.1002 164.341 20.3629 163.982 20.6327C163.526 20.9743 163.118 21.4608 162.779 22.0636H162.748L162.843 20.8752C162.87 20.3313 162.446 20.0012 161.78 20.0773C161.271 20.1361 160.652 20.4203 160.307 20.8035C160.045 21.092 160.032 21.4551 159.986 21.8857C159.915 22.5473 159.806 23.2735 159.678 23.961C159.678 23.961 159.016 27.5032 158.777 28.6872C158.643 29.3446 158.824 29.5455 159.209 29.7306C159.708 29.9703 160.302 30.0636 160.805 30.0234C161.407 29.9746 161.61 29.877 161.769 29.0805C161.796 28.9427 162.313 26.4641 162.451 25.8024C162.834 24.327 163.59 23.1788 165.034 23.2247C165.42 23.2362 165.68 23.0755 165.862 22.7669C166.144 22.2904 166.309 21.7651 166.29 21.214C166.266 20.4949 166 20.0887 165.208 20.0988L165.209 20.0959Z"
      fill="white"
    />
    <path
      d="M147.273 20.3185C146.286 20.3185 145.348 20.7347 144.621 21.4624V21.4107C144.724 21.0462 144.569 20.683 144.309 20.4749C143.841 20.2152 143.322 20.0587 142.801 20.0587C141.813 20.007 141.76 20.3185 141.605 21.0462L140.15 28.7936C139.994 29.4696 140.202 29.6777 140.566 29.8341C141.086 30.0939 141.607 30.1987 142.178 30.1456C142.802 30.0939 143.01 29.9891 143.165 29.2098C143.217 29.0533 143.893 25.7265 143.893 25.7265C144.258 23.9066 144.934 22.866 145.61 22.866C146.078 22.866 146.286 23.1775 146.286 23.6454C146.286 24.0616 146.234 24.4247 146.129 24.8409L145.35 28.7405C145.247 29.0519 145.35 29.4165 145.61 29.6246C146.026 29.936 146.545 30.0924 147.065 30.1441C147.793 30.1958 148.209 30.0408 148.365 29.26L149.301 24.528C149.404 23.9554 149.458 23.3842 149.509 22.8115C149.458 21.3031 148.782 20.3156 147.273 20.3156"
      fill="white"
    />
    <path
      d="M48.419 24.7028C48.419 24.7028 50.0652 28.4172 50.292 28.931C50.4972 29.3875 50.292 29.696 49.7121 29.9056C49.0706 30.1295 48.3846 30.1998 47.7114 30.1108C47.4172 30.0764 47.1675 29.8769 47.0699 29.5984L45.926 26.5199L45.2644 27.0997L44.8639 29.1521C44.6946 29.9888 44.3917 30.0907 43.7602 30.1424C43.1861 30.1912 42.6077 30.0922 42.0824 29.8553C41.6777 29.6601 41.4567 29.4449 41.6303 28.7631L43.9755 16.7215C44.119 15.9924 44.2367 15.6953 45.2844 15.6953C45.8499 15.6982 46.4039 15.8503 46.8905 16.1359C47.1933 16.3684 47.3225 16.7646 47.2134 17.132C46.9163 18.6706 45.982 23.4585 45.982 23.4585C45.982 23.4585 48.3114 21.1291 48.752 20.7244C49.1926 20.3196 49.4911 20.0828 49.7782 20.0728C50.3494 20.0728 50.9063 20.2522 51.3684 20.5852C51.9124 21.0014 51.9841 21.5439 51.5794 21.9242L48.419 24.7057V24.7028Z"
      fill="#FA0050"
    />
    <path
      d="M68.6701 23.2091C68.706 21.3677 67.3669 20.1047 65.3461 20.0387C61.7652 19.9253 59.6669 22.9996 59.5693 25.9806C59.5018 28.541 60.8725 30.1313 63.674 30.2188C64.8151 30.2547 65.9518 30.081 67.0296 29.7064C67.5119 29.5371 67.542 29.3161 67.542 29.0247C67.5076 28.6013 67.3698 28.1937 67.1416 27.8349C66.8588 27.4703 66.7153 27.3785 66.2589 27.5019C65.7135 27.6584 65.148 27.7344 64.5811 27.7272C63.997 27.7488 63.4243 27.5521 62.9751 27.1776C62.7986 26.9867 62.6866 26.7441 62.6565 26.4843C66.335 26.7197 68.5869 25.7768 68.6701 23.2106V23.2091ZM63.0411 24.0918C63.1674 23.1618 63.9554 22.4642 64.894 22.4499C64.9284 22.4456 64.9614 22.4441 64.9959 22.4456C65.3834 22.4599 65.6877 22.7843 65.6733 23.1732C65.6532 23.855 64.8624 24.1894 63.0411 24.0918Z"
      fill="#FA0050"
    />
    <path
      d="M41.8786 23.2091C41.9144 21.3677 40.5754 20.1047 38.5531 20.0387C34.9722 19.9253 32.8739 22.9996 32.7763 25.9806C32.7088 28.541 34.0795 30.1313 36.8811 30.2188C38.0221 30.2547 39.1588 30.081 40.2366 29.7064C40.7189 29.5371 40.749 29.3161 40.749 29.0247C40.7131 28.6013 40.5739 28.1923 40.3443 27.8349C40.0673 27.4703 39.918 27.3785 39.4616 27.5019C38.9162 27.6584 38.3508 27.7344 37.7838 27.7272C37.2011 27.7473 36.6313 27.5521 36.1835 27.1776C36.007 26.9867 35.8951 26.7441 35.8649 26.4843C39.5377 26.7197 41.7953 25.7768 41.8786 23.2106V23.2091ZM36.251 24.0918C36.3773 23.1603 37.1681 22.4614 38.1082 22.4499C38.6263 22.4657 38.8832 22.7728 38.8832 23.1732C38.8631 23.855 38.0723 24.1894 36.251 24.0918Z"
      fill="#FA0050"
    />
    <path
      d="M17.5586 23.2091C17.6003 21.3677 16.2554 20.1047 14.2389 20.0387C10.658 19.9253 8.55397 22.9996 8.45637 25.9806C8.39035 28.541 9.7653 30.1313 12.5611 30.2188C13.7022 30.2547 14.8389 30.081 15.9167 29.7064C16.4291 29.5371 16.4291 29.3161 16.4291 29.0247C16.3932 28.6013 16.2569 28.1937 16.0287 27.8349C15.7517 27.4703 15.6024 27.3785 15.146 27.5019C14.6006 27.6584 14.0351 27.7344 13.4682 27.7272C12.8841 27.7459 12.3128 27.5507 11.8622 27.1776C11.6871 26.9852 11.5766 26.7427 11.5436 26.4843C15.2221 26.7197 17.4739 25.7768 17.5572 23.2106L17.5586 23.2091ZM11.9354 24.0918H11.9411C12.066 23.1632 12.8511 22.4642 13.7883 22.4499C14.3121 22.4657 14.5676 22.7728 14.5676 23.1732C14.5475 23.855 13.7524 24.1894 11.9354 24.0918Z"
      fill="#FA0050"
    />
    <path
      d="M88.31 23.2091C88.3458 21.3677 87.0068 20.1047 84.9845 20.0387C81.4036 19.9253 79.3053 22.9996 79.2077 25.9806C79.1402 28.541 80.5109 30.1313 83.3125 30.2188C84.4535 30.2547 85.5902 30.081 86.668 29.7064C87.1503 29.5371 87.1804 29.3161 87.1804 29.0247H87.1847C87.146 28.5999 87.0053 28.1923 86.7743 27.8349C86.4973 27.4703 86.348 27.3785 85.8916 27.5019C85.3462 27.6584 84.7807 27.7344 84.2138 27.7272C83.6311 27.7473 83.0613 27.5521 82.6135 27.1776C82.437 26.9867 82.325 26.7441 82.2949 26.4843C85.9677 26.7197 88.2253 25.7768 88.3085 23.2106L88.31 23.2091ZM82.6824 24.0918H82.6867C82.813 23.1618 83.601 22.4642 84.5396 22.4499C85.0577 22.4657 85.3146 22.7728 85.3146 23.1732C85.2945 23.855 84.5037 24.1894 82.6824 24.0918Z"
      fill="#FA0050"
    />
    <path
      d="M30.0264 19.9883C28.7777 19.9984 27.6123 20.6141 26.9019 21.6403C26.7311 20.6141 25.7982 19.8936 24.762 19.9883C23.509 19.9769 22.335 20.6012 21.6432 21.646C21.6432 21.646 21.7149 21.3131 21.735 21.1997C21.8369 20.848 21.6776 20.4734 21.3547 20.3012C20.9213 20.0888 20.4419 19.9812 19.9597 19.9883C19.4473 19.9883 19.0411 20.03 18.9033 20.7476L17.3647 28.7002C17.2672 29.2126 17.3289 29.4035 17.5642 29.603C18.002 29.9116 18.5129 30.101 19.0468 30.1512C19.6468 30.223 20.2883 30.1096 20.3859 29.6389L21.1767 25.57C21.5456 23.6712 22.3106 22.6306 23.0339 22.6306C23.4745 22.6306 23.6554 22.9894 23.6554 23.5133C23.6439 23.9108 23.5965 24.3055 23.5119 24.693L22.7067 28.772C22.5847 29.092 22.6923 29.4523 22.9679 29.6546C23.4157 29.9474 23.9281 30.1225 24.4606 30.167C25.2973 30.2388 25.6604 30.0335 25.8097 29.2686L26.5273 25.5743C26.8961 23.6654 27.5535 22.6349 28.2969 22.6349C28.7591 22.6349 28.9744 22.9736 28.9744 23.4559C28.9629 23.8735 28.917 24.2883 28.8351 24.6973L28.0443 28.7304C27.9223 29.0518 28.0171 29.4164 28.2797 29.6389C28.739 29.9417 29.27 30.1197 29.8183 30.1512C30.5517 30.2173 31.0038 30.0379 31.1631 29.2226L32.1118 24.3428C32.2166 23.7472 32.284 23.1459 32.3113 22.5416C32.2855 21.0231 31.5879 19.9812 30.0278 19.9812L30.0221 19.9869L30.0264 19.9883Z"
      fill="#FA0050"
    />
    <path
      d="M98.4585 16.2062C97.5471 16.2363 96.8324 17.0013 96.8625 17.9127C96.8625 17.9399 96.8654 17.9686 96.8668 17.9959C96.8654 18.7695 97.4911 19.3981 98.2647 19.3996C98.2977 19.3996 98.3322 19.3996 98.3652 19.3967C99.2636 19.4096 100.003 18.6934 100.016 17.795C100.016 17.7304 100.014 17.6658 100.007 17.6012C99.9927 16.8133 99.3426 16.1861 98.5561 16.2004C98.5231 16.2004 98.49 16.2033 98.4585 16.2062Z"
      fill="#FA0050"
    />
    <path
      d="M11.0999 17.689C11.3396 17.3488 11.3497 16.8967 11.1258 16.5451C10.8086 15.971 10.3364 15.5003 9.76087 15.1859C9.00737 14.7855 8.43184 14.8214 7.96969 15.6481C7.43148 16.6183 6.8459 17.5928 6.27181 18.5673L4.66148 21.3015C4.55383 21.0187 3.974 18.3406 3.66542 17.0532C3.59079 16.7231 3.43148 16.4174 3.20328 16.1662C2.6665 15.6022 1.92879 15.2749 1.15089 15.2534C0.196456 15.2477 -0.0604509 15.5404 0.0113108 16.3413C0.0414507 16.6341 0.0830747 16.9211 0.139049 17.2082C0.473459 18.9089 0.990143 20.5681 1.67762 22.1597C1.96323 22.8013 2.30625 23.417 2.70381 23.9968C2.84734 24.1605 2.87173 24.3973 2.76553 24.5867C2.25315 25.6947 1.69341 26.7984 1.22695 27.9265C0.773421 28.9498 0.556701 30.0607 0.591147 31.1788C0.606935 31.6165 0.825092 32.0212 1.18103 32.2767C1.69915 32.6843 2.37084 32.8436 3.01813 32.713C3.62093 32.6714 4.12757 32.2437 4.26965 31.6567C5.06047 28.1332 6.44834 24.769 8.37443 21.7134C9.33891 20.2968 10.2058 19.0252 11.0985 17.6904L11.0999 17.689Z"
      fill="#FA0050"
    />
    <path
      d="M99.146 20.3871C98.658 20.1532 98.1212 20.0384 97.5816 20.0542C96.7348 20.0542 96.5554 20.2752 96.4277 20.8593C96.4277 20.8852 95.2637 29.0115 95.2637 29.0115C95.1546 29.3932 95.3756 29.7908 95.7574 29.8984C95.7632 29.8999 95.7703 29.9013 95.7761 29.9042C96.3272 30.1381 96.93 30.2271 97.5256 30.1611C98.1255 30.0836 98.4183 29.9817 98.5518 29.089L99.6498 21.3932C99.7431 20.9813 99.5321 20.5608 99.1474 20.3871H99.146Z"
      fill="#FA0050"
    />
    <path
      d="M94.1428 27.75C94.0581 27.45 93.7567 27.2649 93.4496 27.3237C93.2501 27.3496 92.8238 27.473 92.6487 27.3237C92.4937 27.1974 92.4018 27.0108 92.3975 26.8113C92.4334 26.4655 92.4908 26.1224 92.5726 25.7851L93.1883 22.6248H94.6092C94.9279 22.5745 95.179 22.3305 95.2407 22.0148C95.3541 21.4608 95.3943 21.1996 95.4259 20.9226C95.4288 20.5322 95.1159 20.2121 94.7241 20.2078C94.7083 20.2078 94.6925 20.2078 94.6767 20.2078H93.6505C93.6505 20.2078 93.9734 18.6118 93.9734 18.5659C94.0868 18.2157 93.8945 17.8397 93.5443 17.7263C93.5371 17.7234 93.5299 17.722 93.5228 17.7191C93.0118 17.5038 92.4607 17.3976 91.9067 17.4062C91.2393 17.4062 90.9723 17.5656 90.7929 18.4324L90.4485 20.2078H89.7969C89.4912 20.2078 89.2271 20.4217 89.1654 20.7202C89.1137 20.9872 89.0319 21.3775 88.9544 21.788C88.9085 22.0449 88.8152 22.5674 89.5802 22.609H89.9806L89.2831 26.2459C89.1539 26.7884 89.085 27.3453 89.0778 27.9036C89.1439 29.2484 89.9906 30.1454 91.7718 30.1454C92.3645 30.154 92.9515 30.0191 93.4797 29.7507C94.1772 29.4019 94.259 29.2383 94.2949 28.7245C94.315 28.3915 94.2633 28.06 94.1413 27.75H94.1428Z"
      fill="#FA0050"
    />
    <path
      d="M58.9478 22.6192C59.2765 22.3537 59.5191 21.9963 59.6454 21.593C59.8047 21.0806 59.8147 20.8079 59.0196 20.4032C58.1613 20.0616 57.2385 19.9109 56.3156 19.9626C53.6934 20.0401 51.9755 21.5011 52.0989 23.5894C52.1649 24.7491 53.2227 25.5801 54.3407 26.1542C55.1875 26.5704 55.4846 26.8416 55.5047 27.222C55.5248 27.6023 55.2435 27.9095 54.5818 27.9295C53.88 27.9382 53.1825 27.809 52.5295 27.5492C52.0673 27.3239 51.8678 27.4473 51.6927 27.6726C51.41 28.0056 51.232 28.4147 51.1803 28.8481C51.1344 29.3605 51.2421 29.6016 51.8162 29.8743C52.65 30.1958 53.5399 30.3479 54.4326 30.3206C57.1567 30.2589 58.9679 28.9457 58.8344 26.668C58.7627 25.4308 57.5614 24.6156 56.4032 23.9956C55.7257 23.6769 55.377 23.41 55.377 23.1086C55.3468 22.6522 55.7774 22.3953 56.4548 22.3752C56.9902 22.3766 57.5241 22.4513 58.0408 22.5962L58.18 22.6378C58.6924 22.8072 58.7397 22.7771 58.9493 22.612V22.622L58.9478 22.6192Z"
      fill="#FA0050"
    />
    <path
      d="M75.7042 20.0801C75.1359 20.0844 74.5776 20.2322 74.0824 20.5107C73.6418 20.7475 73.2629 21.0833 72.9744 21.4909L73.0203 21.2555C73.095 20.9326 72.9658 20.5968 72.6917 20.4087C72.3415 20.2049 71.9511 20.0786 71.5478 20.0399C71.2765 20.0069 71.0024 20.0069 70.7326 20.0399C70.3278 20.1174 70.1728 20.347 70.081 20.8192C70.0652 20.8551 67.8076 32.4805 67.8076 32.4805C67.6899 33.112 67.8951 33.2914 68.2381 33.4608H68.2683C68.8065 33.7148 69.4035 33.821 69.9977 33.7679C70.6795 33.6904 70.9263 33.5986 71.1158 32.7058C71.1158 32.6858 71.6282 29.9617 71.6282 29.9617H71.6698C71.8305 30.055 72.0027 30.1282 72.1822 30.177C72.518 30.2602 72.8625 30.3033 73.2083 30.3047C76.4922 30.3104 78.5804 27.0668 78.5804 23.6868V23.6768C78.5862 21.7478 77.7236 20.0542 75.7028 20.0801H75.7042ZM75.1804 24.0198C75.1804 24.0198 75.1804 24.0169 75.1804 24.0155C75.1789 25.6215 74.292 27.8533 73.0203 27.8634C72.884 27.8634 72.7476 27.8461 72.6156 27.8117C72.4247 27.7471 72.2496 27.6423 72.1032 27.5046L72.5295 25.1192C72.6098 24.3155 72.973 23.5663 73.5557 23.0051C73.7623 22.84 74.0193 22.7467 74.2848 22.7439C74.9809 22.7439 75.1818 23.3826 75.1818 24.0169C75.1818 24.0155 75.1818 24.0126 75.1818 24.0112V24.0212L75.1804 24.0198Z"
      fill="#FA0050"
    />
  </svg>
);
