import React from 'react';
import {
  getNeutralColor,
  getSpacing,
  getWhiteColor,
} from '@deliveryhero/vt-portal-chardonnay/cjs/core/helpers';
import {
  Box,
  Drawer,
  DrawerProps,
  IconButton,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Icon from '@mui/material/Icon';

export type MobileBottomDrawerProps = Omit<DrawerProps, 'title' | 'onClose'> & {
  title: React.ReactNode;
  action?: React.ReactNode;
  footerAction?: React.ReactNode;
  onClose?: () => void;
};

const FooterActionWrapper = styled(Box)`
  background: ${getWhiteColor()};
  width: 100%;
  box-sizing: border-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  margin-top: 16px;
  padding: 16px;
  position: sticky;
  bottom: 0px;
`;

const DrawerScrollableContent = styled(Box)({
  overflow: 'scroll',
});

const Bar = styled(Box)({
  display: 'flex',
  flexShrink: 0,
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: `1px solid ${getNeutralColor('gray-20')}`,
});

const BarTitle = styled(
  (props) => <Typography variant="heading3-500" color="inherit" {...props} />,
  {
    shouldForwardProp: (prop) => prop !== 'hasActionButton',
  },
)<{ hasActionButton: boolean }>(({ hasActionButton }) => ({
  flexGrow: 1,
  textAlign: hasActionButton ? 'center' : 'left',
}));

export const MobileBottomDrawer = ({
  title,
  onClose,
  action,
  children,
  footerAction,
  ...drawerProps
}: MobileBottomDrawerProps) => {
  return (
    <Drawer
      PaperProps={{
        sx: {
          borderRadius: '20px 20px 0 0',
        },
      }}
      anchor="bottom"
      onClose={onClose}
      {...drawerProps}
    >
      <Bar>
        <IconButton
          data-testid="close-filters-btn"
          color="inherit"
          aria-label="close filters"
          onClick={onClose}
          sx={{
            padding: `${getSpacing('spacing-20')} ${getSpacing(
              'spacing-20',
            )} ${getSpacing('spacing-16')}`,
          }}
        >
          <Icon>close</Icon>
        </IconButton>
        <BarTitle hasActionButton={!!action}>{title}</BarTitle>
        {action}
      </Bar>
      <DrawerScrollableContent>{children}</DrawerScrollableContent>
      {!!footerAction && (
        <FooterActionWrapper>{footerAction}</FooterActionWrapper>
      )}
    </Drawer>
  );
};
