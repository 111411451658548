import { RESET_PASSWORD_ERROR_MESSAGES } from '../../constants';

export const resolveErrorMessage = (error) => {
  if (
    error?.response?.message ===
    RESET_PASSWORD_ERROR_MESSAGES.CURRENT_PASSWORD_NOT_CORRECT
  ) {
    return 'login.reset_password.current_password_not_correct';
  }

  if (
    error?.response?.message ===
    RESET_PASSWORD_ERROR_MESSAGES.CURRENT_AND_NEW_PASSWORD_CANNOT_BE_SAME
  ) {
    return 'login.reset_password.current_and_new_password_cannot_be_same';
  }

  if (error?.response?.code === 'INVALID_TOKEN') {
    return 'login.set_password.invalid_token';
  }

  if (
    error?.response.message === RESET_PASSWORD_ERROR_MESSAGES.AN_ERROR_OCCURRED
  ) {
    return RESET_PASSWORD_ERROR_MESSAGES.AN_ERROR_OCCURRED;
  }

  return 'login.set_password.failed';
};

export const validateUrl = (url: string): boolean => {
  const regex =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;

  return regex.test(String(url).toLowerCase());
};
