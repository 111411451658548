import * as React from 'react';
import { container } from '../dependencies';
import { TranslationsStore } from '../stores/TranslationsStore';

interface ITranslateProps {
  code: string;
  params?: Record<string, string | number>;
  showKeyWhenUndefined?: boolean;
  component?: React.ComponentType<any> | string;
}

export const Translate: React.FC<ITranslateProps> = ({
  code,
  params,
  showKeyWhenUndefined = false,
  component = React.Fragment,
}) => {
  const translationStore = container.get<TranslationsStore>(TranslationsStore);
  const Component = component;
  const text = translationStore.translate(code, params);

  if (!showKeyWhenUndefined && (!text || text === code)) {
    return null;
  }

  return <Component>{text}</Component>;
};

interface ITranslateFnProps {
  code: string;
  params?: Record<string, string | number>;
}

export const translate = ({ code, params }: ITranslateFnProps): string => {
  const translationStore = container.get<TranslationsStore>(TranslationsStore);
  const text = translationStore.translate(code, params);
  return text;
};

Translate.displayName = 'Translate';
