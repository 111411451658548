import * as React from 'react';
import { Redirect, RouteComponentProps } from 'react-router';
import { Route, Switch } from 'react-router-dom';

import { IConfig } from '../../../config';
import { PluginStore } from '../../../stores/PluginStore';
import WelcomeContainer from '../../Welcome';
import Onboarding from '../../OnboardingPluginContainer';
import SetPasswordContainer from '../../SetPassword';
import routeComponentFactory from '../../../utils/routeComponentFactory';
import LoginContainer from '../../Login';
import Logout from '../../Logout';
import AddPhone from '../../AddPhone';
import PhoneLogin from '../../PhoneLogin';
import MasterRoutes from '../MasterRoutes';
import ResetPasswordContainer from '../../ResetPassword';
import OtpSignin from '../../OtpSignin';
import Privacy from '../../Privacy';
import OnboardingSetPasswordContainer from '../../OnboardingSetPasswordContainer';

const loginContainer = routeComponentFactory(LoginContainer);
const logoutContainer = routeComponentFactory(Logout);
const masterResetPasswordContainer = routeComponentFactory(
  ResetPasswordContainer,
);
const privacyContainer = routeComponentFactory(Privacy);

export type RoutesProps = RouteComponentProps & {
  pluginStore: PluginStore;
  config: IConfig;
};

const SetMasterPasswordContainer = (props) => (
  <SetPasswordContainer skipIntro={true} {...props} />
);
const ForceSetMasterPasswordContainer = (props) => (
  <SetPasswordContainer skipIntro={true} isForceReset={true} {...props} />
);

const Routes: React.FC<RoutesProps> = (props) => {
  const firstPluginRoute = props.pluginStore.frontendPlugins[0]?.route;

  return (
    <Switch>
      <Route
        path="/index\.html"
        exact={true}
        component={({ location }: any) => (
          <Redirect
            to={{
              pathname: props.config.basePath,
              search: location.search,
            }}
          />
        )}
      />
      <Route path="/login" exact={true} component={loginContainer} />
      <Route path="/privacy" exact={true} component={privacyContainer} />
      <Route
        path="/set-password"
        exact={true}
        component={SetMasterPasswordContainer as any}
      />
      <Route
        path="/request-set-password"
        exact={true}
        component={ForceSetMasterPasswordContainer}
      />
      <Route path="/logout" exact={true} component={logoutContainer} />
      <Route
        path="/reset-password"
        exact={true}
        component={masterResetPasswordContainer}
      />
      <Route path="/add-phone" exact={true} component={AddPhone} />
      <Route path="/phone-login" exact={true} component={PhoneLogin} />
      <Route path="/welcome" exact={true} component={WelcomeContainer as any} />
      <Route path="/onboarding-timeline" component={Onboarding} />
      <Route
        path="/onboarding-set-password"
        exact={true}
        component={OnboardingSetPasswordContainer}
      />

      <Route path="/otp-signin" component={OtpSignin} />

      <Route
        path="/master/:path+"
        component={({ match }) => <Redirect to={`/${match.params.path}`} />}
      />
      {firstPluginRoute ? (
        <Route
          path="/"
          component={() => <Redirect to={firstPluginRoute} />}
          exact
        />
      ) : null}
      <Route component={MasterRoutes} />
    </Switch>
  );
};
export default Routes;
