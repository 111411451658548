import * as React from 'react';
import { useEffect } from 'react';
import { formatISO } from 'date-fns';
import { reaction } from 'mobx';
import { observer } from 'mobx-react';
import { History } from 'history';
import { IntlProvider } from 'react-intl';
import { Router, Switch } from 'react-router-dom';
import { browserName, browserVersion } from 'react-device-detect';
import { Breakpoint } from '@mui/material';

// Todo: Regarding old material-ui StylesProvider:
//  Only remove it when Webapp migration and Plugins migration to Chardonnay and @mui v.5 is fully completed
// But before removing it: also update or remove withThemeProviders.tsx first
// If we remove them now, the styles of plugins will break.
// Important: we need to wait until plugins will migrate and keep the old material-ui StylesProvider here
// If removed now: things will break partly because of styled-components: so it needs to be addressed too
import {
  createGenerateClassName,
  StylesProvider,
} from '@material-ui/core/styles';

// dh internal libraries
import {
  Brand,
  ChardonnayThemeProvider,
} from '@deliveryhero/vt-portal-chardonnay/core';
import { SdkProvider } from '@deliveryhero/vendor-portal-sdk';

// routes
import Routes from './Routes';

// stores
import { SessionStore } from '../../stores/SessionStore';
import { PluginStore } from '../../stores/PluginStore';
import { LanguageStore } from '../../stores/LanguageStore';
import { PlatformStore } from '../../stores/PlatformStore';
import { LocaleStore } from '../../stores/LocaleStore';
import FwfStore from '../../stores/FwfStore';
import { VendorStore } from '../../stores/VendorStore';
import { NavStore } from '../../stores/NavStore';
import { UserStore } from '../../stores/UserStore';
import PortalSDKStore from '../../stores/PortalSDKStore';

// components
import { FwfProvider } from '../../components/fwf';
import { translate, Translate } from '../../components/Translate';
import ErrorMessage from '../../components/errors/ErrorMessage';
import AppDialog from '../../components/AppDialog';
import ApplicationDownPage from '../../components/errors/NotFoundPage/ApplicationDown';
import UnsupportedBrowserWarning from '../../components/UnsupportedBrowserWarning';

// utils
import { QueryStringParser } from '../../utils/QueryStringParser';
import { IDevTools } from '../../utils/DevTools';
import { isRTL } from '../../utils/LangUtlis';
import { ApiStatus } from '../../models/ApiStatus';
import { IConfig } from '../../config';
import {
  ONBOARDING_TIMELINE_PLUGIN_CODE,
  ONBOARDING_PLUGIN_CODE,
  FORCE_REDIRECT_ONEWEB_AFTER_LOGIN,
} from '../../constants';
import { isMobileWebView } from '../../utils/isMobileWebView';

let DevTools;
if (process.env.NODE_ENV !== 'production') {
  DevTools = require('mobx-react-devtools');
}

type AppProps = {
  width: Breakpoint;
  sessionStore: SessionStore;
  localeStore: LocaleStore;
  pluginStore: PluginStore;
  platformStore: PlatformStore;
  history: History;
  devTools: IDevTools;
  config: IConfig;
  fwfStore: FwfStore;
  languageStore: LanguageStore;
  vendorStore: VendorStore;
  navStore: NavStore;
  userStore: UserStore;
  portalSdkStore: PortalSDKStore;
};

const AppComponent: React.FC<AppProps> = ({
  sessionStore,
  localeStore,
  pluginStore,
  platformStore,
  history,
  devTools,
  config,
  fwfStore,
  languageStore,
  vendorStore,
  navStore,
  userStore,
  portalSdkStore,
}) => {
  const locale = languageStore.currentLanguage.replace('_', '-');
  let platformName = platformStore?.currentPlatform?.name;
  const currentBrand = Brand[platformName] || Brand.foodpanda;
  const sdk = portalSdkStore.getSdkForMaster();

  const initLocale = () => {
    const searchParams = QueryStringParser.parse(window.location.search);

    if (searchParams.locale) {
      localeStore.locale = searchParams.locale;
    }
  };

  const setLocaleByJwt = () => {
    const localeStr = sessionStore.getUserData('locale');

    if (localeStr) {
      localeStore.locale = locale.substring(0, 2);
    }
  };

  useEffect(() => {
    const dir = isRTL() ? 'rtl' : 'ltr';
    document.body.dir = dir;
    document.dir = dir;
  }, []);

  const isBrowserSupported = () => {
    const supportedBrowsers = config.supportedBrowsers;
    const supportedCurrentBrowserVersion = supportedBrowsers.find(
      (b) => b.identifier === browserName,
    )?.minimumSupportedVersion;
    const supportedBrowserIdentifiers = supportedBrowsers.map(
      (b) => b.identifier,
    );
    if (supportedBrowserIdentifiers.includes(browserName)) {
      if (Number(browserVersion) >= supportedCurrentBrowserVersion) {
        return true;
      } else {
        return false;
      }
    }
    // if a browser is not considered here, safely return back true.
    // the browser warning is added for certain known unsupported browsers only.
    return true;
  };

  const getBlockableContent = () => {
    const [isBrowserAlertOpen, setIsBrowserAlertOpen] = React.useState(false);

    useEffect(() => {
      if (!isBrowserSupported()) {
        setIsBrowserAlertOpen(true);
      }
    }, [sessionStore.isLoggedIn]);

    return (
      <>
        <Switch>
          <Routes />
        </Switch>
        {/* Plugin API calls returns no plugins*/}
        {pluginStore.pluginApiStatus === ApiStatus.SUCCESS &&
        pluginStore.frontendPlugins.length === 0 ? (
          <ErrorMessage
            title={<Translate code="global.error.no_plugins.headline" />}
            message={<Translate code="global.error.no_plugins.message" />}
          />
        ) : null}

        {/* Plugin API call fails */}
        {pluginStore.pluginApiStatus === ApiStatus.ERROR &&
        pluginStore.frontendPlugins.length === 0 ? (
          <ApplicationDownPage translate={translate} />
        ) : null}

        {/* Show unsupported browser warning */}
        <UnsupportedBrowserWarning
          supportedBrowsers={config.supportedBrowsers}
          show={isBrowserAlertOpen}
          close={setIsBrowserAlertOpen}
        />
      </>
    );
  };

  useEffect(() => {
    localeStore.init();
    initLocale();

    reaction(
      () => sessionStore.jwtData,
      () => setLocaleByJwt(),
      { fireImmediately: true },
    );
  }, []);

  useEffect(() => {
    const userRole = sessionStore?.mainSessionInfo?.role;

    const createdAtDate = sessionStore?.mainSessionInfo?.user?.createdAt;

    const createdAt = createdAtDate ? formatISO(new Date(createdAtDate)) : '';

    let globalEntityIds;
    if (sessionStore?.mainSessionInfo?.accessTokenContent?.vendors) {
      globalEntityIds = Object.keys(
        sessionStore.mainSessionInfo.accessTokenContent.vendors,
      ).join();
    }

    let verticalTypes;
    if (vendorStore?.verticalTypes) {
      verticalTypes = vendorStore.verticalTypes.join();
    }

    let vendorIds = '';
    if (vendorStore?.vendors) {
      vendorIds = Array.from(vendorStore.vendors.keys()).join();
    }

    let selectedVendorIds = '';
    if (vendorStore?.selectedVendors) {
      selectedVendorIds = vendorStore.selectedVendorIds.join(',');
    }

    const userId = sessionStore?.mainSessionInfo?.user?.userId;

    if (
      userId &&
      userRole &&
      createdAt &&
      globalEntityIds &&
      verticalTypes &&
      vendorIds
    ) {
      callAppcues(
        userId,
        userRole,
        createdAt,
        globalEntityIds,
        verticalTypes,
        vendorIds,
        selectedVendorIds,
      );
    }
  }, [
    sessionStore?.mainSessionInfo?.user,
    vendorStore?.verticalTypes,
    vendorStore?.vendors,
  ]);

  useEffect(() => {
    if (sessionStore.isLoggedIn) {
      navStore.setNewNavigation({
        title: null,
        name: 'master',
        showAppBar: false,
        showBottomNav: true,
      });
      userStore.fetchUserProfile();
    }
  }, [sessionStore.isLoggedIn]);

  const checkOnboardingStatus = async () => {
    const flowVersion = vendorStore?.currentVendor.ssuFlowVersion;
    const isFlowVersionString = typeof flowVersion === 'string';
    const shouldShowNewOnboardingFlow = isFlowVersionString
      ? ['v4', 'v3'].includes(flowVersion.toLowerCase())
      : false;
    const plugins = pluginStore.plugins;
    const onboardingPluginToCheck = shouldShowNewOnboardingFlow
      ? ONBOARDING_TIMELINE_PLUGIN_CODE
      : ONBOARDING_PLUGIN_CODE;
    const onboardingMenuItem = plugins.find(
      (navItem) => navItem.code === onboardingPluginToCheck,
    );

    const onboardingRequired = userStore.userProfile.onboarding_info?.required;
    const showOnlyOnboarding = onboardingRequired && onboardingMenuItem;

    const { pathname } = window.location;
    const isPathLoadedAlready = pathname.includes(
      'onboarding', // works both with `onboarding` and `onboarding-timeline` plugins
    );

    // only redirecting the user, if they are not already at the onboarding page
    if (showOnlyOnboarding && !isPathLoadedAlready) {
      const redirectUrl = onboardingMenuItem.route;
      history.push(redirectUrl);
    }
  };

  const rederictSessionToOW = async () => {
    const forceRedirect = await fwfStore.getVariationValue(
      FORCE_REDIRECT_ONEWEB_AFTER_LOGIN,
      false,
    );
    if (forceRedirect && !isMobileWebView()) {
      window.open(sessionStore.getOneWebRedirectUrl(), '_self');
    }
  };

  useEffect(() => {
    const { userProfileApiStatus, userProfile } = userStore;
    const { pluginApiStatus } = pluginStore;
    const isProfileLoaded = userProfileApiStatus === ApiStatus.SUCCESS;
    const arePluginsLoaded = pluginApiStatus === ApiStatus.SUCCESS;

    if (
      isProfileLoaded &&
      arePluginsLoaded &&
      !!userProfile &&
      fwfStore.ready
    ) {
      checkOnboardingStatus();
      rederictSessionToOW();
    }
  }, [
    userStore.userProfileApiStatus,
    pluginStore.pluginApiStatus,
    fwfStore.ready,
  ]);

  const callAppcues = (
    userId,
    role,
    createdAt,
    globalEntityIds,
    verticalTypes,
    vendorIds,
    selectedVendorIds,
  ) => {
    try {
      if (window && window['Appcues']) {
        window['Appcues'].identify(userId, {
          role,
          createdAt,
          globalEntityIds,
          verticalTypes,
          vendorIds,
          selectedVendorIds,
        });
      }
    } catch (err) {
      // eslint-disable-next-line
      console.error('Appcues tag: no active user session ');
    }
  };

  const generateClassName = createGenerateClassName({
    disableGlobal: false,
    seed: 'webapp_global',
  });

  return (
    <StylesProvider generateClassName={generateClassName} injectFirst>
      <ChardonnayThemeProvider
        brand={currentBrand}
        languageDirection={isRTL() ? 'rtl' : 'ltr'}
        /* "wa" is for WebApp, shortening to reduce the bundle size */
        /* This should prevent potential CSS class naming collisions with plugins using same provider */
        cssClassSuffix="wa"
      >
        <IntlProvider locale={locale}>
          <FwfProvider client={fwfStore.mainFwfClient}>
            <SdkProvider sdk={sdk}>
              <Router history={history}>
                <div>
                  {getBlockableContent()}
                  <AppDialog />
                  {DevTools && devTools.debug ? <DevTools /> : null}
                </div>
              </Router>
            </SdkProvider>
          </FwfProvider>
        </IntlProvider>
      </ChardonnayThemeProvider>
    </StylesProvider>
  );
};

const App = observer(AppComponent);

export default App;
