import { withDependencies } from '@deliveryhero/react-inversify-provider';
import App from './App';
import withThemeProviders from './withThemeProviders';
import { LocaleStore } from '../../stores/LocaleStore';
import { PluginStore } from '../../stores/PluginStore';
import { LanguageStore } from '../../stores/LanguageStore';
import { PlatformStore } from '../../stores/PlatformStore';
import FwfStore from '../../stores/FwfStore';
import { TYPES } from '../../types';
import { NavStore } from '../../stores/NavStore';
import PortalSDKStore from '../../stores/PortalSDKStore';

const AppContainer = withThemeProviders(
  withDependencies({
    sessionStore: TYPES.SessionStore,
    localeStore: LocaleStore,
    pluginStore: PluginStore,
    navStore: NavStore,
    userStore: TYPES.UserStore,
    vendorStore: TYPES.VendorStore,
    languageStore: LanguageStore,
    platformStore: PlatformStore,
    portalSdkStore: PortalSDKStore,
    fwfStore: FwfStore,
    history: 'history',
    devTools: 'devTools',
    config: 'config',
  })(App),
);

export default AppContainer;
