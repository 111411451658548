import * as React from 'react';
import { useState } from 'react';
import DialogMenu, {
  Option as DialogMenuOption,
  Option,
} from '../../components/DialogMenu';
import { LanguageStore } from '../../stores/LanguageStore';
import { TranslationsStore } from '../../stores/TranslationsStore';
import { IAvailableLanguage } from '../../config';
import { useIsMobile } from '@deliveryhero/vt-portal-chardonnay/cjs/core/helpers';
import { MobileBottomDrawer } from '../MobileBottomDrawer';
import { Button, List, ListItem, ListItemText, Radio } from '@mui/material';

export enum RenderType {
  TRIGGER,
  DIALOG,
}

export type LanguageSelectorProps = {
  languageStore: LanguageStore;
  translationsStore: TranslationsStore;
  availableLanguages: IAvailableLanguage[];
  renderComponent?: (labelText: string, onClick: Function) => JSX.Element;
  renderType?: RenderType;
  open?: boolean;
  closeDialog?: () => void;
  isMobileVersionEnabled?: boolean;
};

const toLanguageOption =
  (activeLanguageIndex: number) =>
  (language: IAvailableLanguage, i: number): DialogMenuOption => ({
    label: language.label,
    initiallySelected: activeLanguageIndex === i,
  });

const languageByValueFind = (value: string) => (language: IAvailableLanguage) =>
  value === language.value;

const valueEquals =
  (valueMatch: string) =>
  ({ value }: IAvailableLanguage): boolean =>
    valueMatch === value;

const LanguageSelector: React.FC<LanguageSelectorProps> = ({
  languageStore,
  translationsStore,
  availableLanguages,
  renderComponent,
  renderType,
  open,
  closeDialog,
  isMobileVersionEnabled = false,
}) => {
  const [languageDialogOpen, setLanguageDialogOpen] = useState(false);
  const isMobile = useIsMobile();
  const shouldShowMobile = isMobileVersionEnabled && isMobile;
  const dialogTitle = translationsStore.translate(
    'sidebar.language_dialog.title',
  );
  const [isMobileDrawerOpen, setIsMobileDrawerOpen] = useState(false);

  const activeLanguageIndex = availableLanguages.findIndex(
    valueEquals(languageStore.currentLanguage),
  );
  const languageOptions = availableLanguages.map(
    toLanguageOption(activeLanguageIndex),
  );
  const initiallySelected = languageOptions.findIndex(
    (x) => !!x.initiallySelected,
  );
  const [selectedMobileLanguage, setSelectedMobileLanguage] =
    useState(initiallySelected);

  const onLanguageSelected = (index: number) => {
    const selectedLanguage = availableLanguages[index];
    const currentLanguageValue = languageStore.currentLanguage;

    if (selectedLanguage.value !== currentLanguageValue) {
      languageStore.setCurrentLanguage(selectedLanguage.value);
    }

    if (shouldShowMobile) {
      setIsMobileDrawerOpen(false);
    } else {
      setLanguageDialogOpen(false);
    }
  };

  const currentLanguage =
    availableLanguages.find(
      languageByValueFind(languageStore.currentLanguage),
    ) || availableLanguages[0];

  if (renderType === RenderType.DIALOG) {
    return (
      <DialogMenu
        open={open}
        onClose={closeDialog}
        options={languageOptions}
        onSelect={onLanguageSelected}
        title={dialogTitle}
      />
    );
  }

  const renderDialog = () => {
    return (
      <DialogMenu
        open={languageDialogOpen}
        onClose={() => setLanguageDialogOpen(false)}
        options={languageOptions}
        onSelect={onLanguageSelected}
        title={dialogTitle}
      />
    );
  };

  const renderMobileDrawer = () => {
    return (
      <MobileBottomDrawer
        title={dialogTitle}
        open={isMobileDrawerOpen}
        onClose={() => {
          setIsMobileDrawerOpen(false);
        }}
        footerAction={
          <Button
            data-testid="mobile-language-selector-save-btn"
            fullWidth
            variant="contained"
            onClick={() => onLanguageSelected(selectedMobileLanguage)}
            disabled={selectedMobileLanguage === -1}
          >
            {translationsStore.translate('global.save')}
          </Button>
        }
      >
        <List
          sx={{ maxHeight: 500 }}
          data-testid="language-selector-mobile-drawer-list"
        >
          {languageOptions.map((option: Option, i: number) => {
            const { label, Icon } = option;

            return (
              <ListItem
                key={`mobile-drawer-menu-${i}`}
                onClick={() => setSelectedMobileLanguage(i)}
                data-testid={`mobile-dialog-menu-option-${i}`}
                sx={{ textAlign: 'left' }}
              >
                <Radio checked={selectedMobileLanguage === i} color="primary" />
                <ListItemText primary={label} />
                {Icon || null}
              </ListItem>
            );
          })}
        </List>
      </MobileBottomDrawer>
    );
  };

  return (
    <>
      {renderComponent(currentLanguage.label, () =>
        shouldShowMobile
          ? setIsMobileDrawerOpen(true)
          : setLanguageDialogOpen(true),
      )}
      {shouldShowMobile ? renderMobileDrawer() : renderDialog()}
    </>
  );
};

export default LanguageSelector;
