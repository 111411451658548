import { Logger as DDLogger } from '@datadog/browser-logs';
import {
  LoggerContext,
  LoggerContextValue,
} from '@deliveryhero/vendor-portal-sdk';

export class Logger {
  constructor(public name: string, private datadogLogger: DDLogger) {}

  debug(message: string, context?: LoggerContext) {
    this.datadogLogger.debug(message, context);
  }
  error(message: string, context?: LoggerContext) {
    this.datadogLogger.error(message, context);
  }
  info(message: string, context?: LoggerContext) {
    this.datadogLogger.info(message, context);
  }
  log(message: string, context?: LoggerContext) {
    this.datadogLogger.log(message, context);
  }
  warn(message: string, context?: LoggerContext) {
    this.datadogLogger.warn(message, context);
  }

  addContext(key: string, value: LoggerContextValue) {
    this.datadogLogger.addContext(key, value);
  }
}
