export function getGlobalVendorCount(
  platformIds: string[],
  platformVendorIds: string[],
) {
  return platformIds.map((id) => {
    const numberOfVendors = platformVendorIds.filter((vendorId) =>
      vendorId.includes(id),
    ).length;
    return `${id};${numberOfVendors}`;
  });
}
