import * as React from 'react';
import { FC } from 'react';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import { Translate } from '../Translate';
import { countries } from '../../containers/AddPhone/countries';
import { validatePhoneNumber } from '../../utils/validatePhoneNumber';

interface Props {
  phoneNumber: string;
  onCountryCodeSelect: (event: any) => void;
  onPhoneNumberInput: (event: any) => void;
}

export const EnterPhoneForm: FC<Props> = ({
  onCountryCodeSelect,
  onPhoneNumberInput,
  phoneNumber,
}) => {
  const countryCodeLabel = (
    <Translate code="global.contact_info.form.country_code" />
  );

  const phoneNumberLabel = (
    <Translate code="global.contact_info.form.phone_number" />
  );

  const isPhoneNumberValid = !phoneNumber
    ? true
    : validatePhoneNumber(phoneNumber);

  const phoneNumberHelperText = isPhoneNumberValid ? (
    ''
  ) : (
    <Translate code="global.contact_info.form.invalid_phone_number_error" />
  );

  return (
    <Box
      sx={{
        display: 'flex',
        marginTop: '24px',
      }}
    >
      <Autocomplete
        id="country-code"
        data-testid="enter-phone-number-country-code-container"
        sx={{ marginRight: '20px' }}
        options={countries}
        autoHighlight={true}
        getOptionLabel={(option) => option.dialCode}
        renderOption={(props, option) => {
          let flagIcon = null;

          try {
            const file = require(`../../../public/static/country-flags/${option.isoCode.toLowerCase()}.svg`);

            flagIcon = file;
          } catch (e) {
            // eslint-disable-next-line
            console.error(
              `While trying to get a country flag icon file, an error occurred: ${e}`,
            );
          }

          return (
            <Box
              {...props}
              component="li"
              key={option.isoCode}
              data-testid={`enter-phone-number-country-code-input-option-${option.isoCode}`}
            >
              <img
                loading="lazy"
                width="20"
                style={{ marginRight: '8px' }}
                src={flagIcon}
                alt=""
              />
              {option.dialCode}
            </Box>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{ width: 130 }}
            data-testid="enter-phone-number-country-code-input"
            label={countryCodeLabel}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'off',
            }}
          />
        )}
        onSelect={onCountryCodeSelect}
      />
      <TextField
        name="enter-phone-number-input"
        data-testid="enter-phone-number-input"
        label={phoneNumberLabel}
        value={phoneNumber}
        error={!isPhoneNumberValid}
        helperText={phoneNumberHelperText}
        onChange={onPhoneNumberInput}
      />
    </Box>
  );
};
