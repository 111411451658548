import * as React from 'react';
import { observer } from 'mobx-react';

import { Redirect } from 'react-router-dom';

import { styled as muiStyled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';

import PasswordView from './PasswordView';
import { Translate } from '../../../../components/Translate';
import { PasswordHandlingContainerComponentProps } from '../../PasswordHandlingContainer';
import { getPasswordErrors } from './getPasswordErrors';
import { RESET_PASSWORD_ERROR_MESSAGES } from '../../../../constants';

export type PasswordProps = PasswordHandlingContainerComponentProps;

export type PasswordFormErrors = {
  fields: Record<string, string>;
  rules: {
    isTooShort: boolean;
    hasMissingUppercaseLetter: boolean;
    hasMissingDigit: boolean;
    hasMissingSpecialCharacter: boolean;
    hasWhitespaceCharacters: boolean;
  };
};

export type PasswordState = {
  passwordCurrent: string;
  password: string;
  passwordRepeat: string;
  isDirty: boolean;
  wasSent: boolean;
  isLoading: boolean;
  errors: PasswordFormErrors;
  snackbarMessage: string;
  errorMessage: string;
  isForceReset: boolean;
};

const ProgressWrapper = muiStyled(Box)({
  textAlign: 'center',
  padding: '32px',
});

export const PASSWORD_REQUIREMENT_BRAND_ALLOWLIST = [
  'default',
  'pedidosya',
  'efood',
  'foodpanda',
  'talabat',
  'hungerstation',
  'foody',
  'foodora',
  'yemeksepeti',
];

@observer
export default class PasswordContainer extends React.Component<
  PasswordProps,
  PasswordState
> {
  constructor(props, context) {
    super(props, context);

    this.state = {
      passwordCurrent: '',
      password: '',
      passwordRepeat: '',
      isDirty: false,
      wasSent: false,
      isLoading: false,
      errors: {
        fields: {},
        rules: {
          isTooShort: false,
          hasMissingUppercaseLetter: false,
          hasMissingDigit: false,
          hasMissingSpecialCharacter: false,
          hasWhitespaceCharacters: false,
        },
      },
      snackbarMessage: '',
      isForceReset: props.isForceReset,
      errorMessage: '',
    };
  }

  eventLabel = () =>
    this.props.isForceReset ? 'force_change_password' : 'reset_change_password';

  eventCategory = () =>
    this.props.isForceReset ? 'force_reset_password' : 'reset_password';

  actionStartEvent = () => {
    this.props.gtmManager.pushEvent('onClick', {
      eventLabel: this.eventLabel(),
      eventCategory: this.eventCategory(),
      eventAction: 'set_new_password_button_clicked',
      userId: this.props.sessionStore.getUserData('userId'),
    });
  };

  actionFailedEvent = () => {
    this.props.gtmManager.pushEvent('onAPIResponse', {
      eventLabel: this.eventLabel(),
      eventCategory: this.eventCategory(),
      eventAction: 'failed',
      userId: this.props.sessionStore.getUserData('userId'),
    });
  };

  actionSucceededEvent = () => {
    this.props.gtmManager.pushEvent('onAPIResponse', {
      eventLabel: this.eventLabel(),
      eventCategory: this.eventCategory(),
      eventAction: 'succeeded',
      userId: this.props.sessionStore.getUserData('userId'),
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ errorMessage: '', isDirty: true });

    const shouldNewPasswordStrengthRequirementsBeApplied =
      this.showNewPasswordStrengthRequirements();

    const isValid = this.validate(
      shouldNewPasswordStrengthRequirementsBeApplied,
    );

    if (isValid) {
      this.setState({ isLoading: true });
      if (this.props.isForceReset) {
        this.actionStartEvent();
        this.props.submit(this.state.passwordCurrent, this.state.password).then(
          () => {
            this.actionSucceededEvent();
            this.handleSubmitSuccess();
          },
          (err) => {
            this.actionFailedEvent();
            this.handleSubmitError(err);
          },
        );
      } else {
        this.actionStartEvent();
        this.props.submit(this.props.token, this.state.password).then(
          () => {
            this.actionSucceededEvent();
            this.handleSubmitSuccess();
          },
          (err) => {
            this.actionFailedEvent();
            this.handleSubmitError(err);
          },
        );
      }
    }
  };

  render() {
    const { snackbarMessage, errorMessage } = this.state;

    const snackbarOpen = snackbarMessage !== '';

    if (this.state.wasSent) {
      return <Redirect to="/login?message=resetSuccess" />;
    }

    if (this.state.isLoading) {
      return (
        <ProgressWrapper>
          <CircularProgress />
        </ProgressWrapper>
      );
    }
    return (
      <>
        <PasswordView
          passwordCurrent={this.state.passwordCurrent}
          password={this.state.password}
          passwordRepeat={this.state.passwordRepeat}
          onChange={this.handleChange}
          getError={this.getError}
          onSubmit={this.handleSubmit}
          onBackClick={this.props.onBackClick}
          currentPlatform={this.props.currentPlatform}
          skipIntro={this.props.skipIntro}
          isForceReset={this.props.isForceReset}
          errorMessage={errorMessage}
          errors={this.state.errors}
          isDirty={this.state.isDirty}
        />

        <Snackbar
          data-testid={'password-snackbar-error'}
          open={snackbarOpen}
          onClose={this.closeSnackbar.bind(this)}
          autoHideDuration={3000}
        >
          <SnackbarContent message={<Translate code={snackbarMessage} />} />
        </Snackbar>
      </>
    );
  }

  protected handleSubmitSuccess(): void {
    this.setState({
      wasSent: true,
      isLoading: false,
    });
  }

  protected handleSubmitError(error) {
    const errorMessage = this.resolveErrorMessage(error);

    this.setState({
      isLoading: false,
      snackbarMessage: errorMessage,
      errorMessage,
    });
  }

  private resolveErrorMessage(error): string {
    if (
      error &&
      error.response &&
      error.response?.message ===
        RESET_PASSWORD_ERROR_MESSAGES.CURRENT_PASSWORD_NOT_CORRECT
    ) {
      return 'login.reset_password.current_password_not_correct';
    }

    if (
      error &&
      error.response &&
      error.response?.message ===
        RESET_PASSWORD_ERROR_MESSAGES.CURRENT_AND_NEW_PASSWORD_CANNOT_BE_SAME
    ) {
      return 'login.reset_password.current_and_new_password_cannot_be_same';
    }

    if (error && error.response && error.response.code === 'INVALID_TOKEN') {
      return 'login.set_password.invalid_token';
    }

    if (this.hasError(error) && this.isKnownError(error)) {
      return RESET_PASSWORD_ERROR_MESSAGES[this.getErrorCodes(error)[0]];
    }

    return 'login.set_password.failed';
  }

  private hasError(errorResponse): boolean {
    return (
      errorResponse.response &&
      errorResponse.response.errors &&
      errorResponse.response.errors.length > 0
    );
  }

  private isKnownError(errorResponse): boolean {
    return RESET_PASSWORD_ERROR_MESSAGES[this.getErrorCodes(errorResponse)[0]];
  }

  private getErrorCodes(errorResponse): string[] {
    return errorResponse.response.errors.map((error) => error.code);
  }

  private validate(
    shouldNewPasswordStrengthRequirementsBeApplied: boolean,
    shouldShowFieldErrors?: boolean,
  ): boolean {
    const errors = getPasswordErrors({
      passwordCurrent: this.state.passwordCurrent,
      password: this.state.password,
      passwordRepeat: this.state.passwordRepeat,
      isForceReset: this.state.isForceReset,
      shouldNewPasswordStrengthRequirementsBeApplied,
      shouldShowFieldErrors,
    });
    this.setState({ errors });
    return Object.keys(errors.fields).length === 0;
  }

  private showNewPasswordStrengthRequirements() {
    return PASSWORD_REQUIREMENT_BRAND_ALLOWLIST.includes(
      this.props.currentPlatform.name,
    );
  }

  private handleChange = (field) => (event) => {
    const shouldNewPasswordStrengthRequirementsBeApplied =
      this.showNewPasswordStrengthRequirements();

    this.setState(
      {
        [field]: event.target.value,
        isDirty: true,
      } as any,
      () => {
        // Field errors should not show on change event
        this.validate(shouldNewPasswordStrengthRequirementsBeApplied, false);
      },
    );
  };

  private getError = (key) => {
    const error = this.state.errors.fields[key];
    if (error) {
      return <Translate code={error} />;
    }
  };

  private closeSnackbar() {
    this.setState({ snackbarMessage: '' });
  }
}
