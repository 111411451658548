import { observable } from 'mobx';

import { Currency } from './Currency';
import { Address } from './Address';
import { IJoker } from '@deliveryhero/vendor-portal-sdk';
import { Transmission } from '../stores/UserStore';

export function makeVendorId(props: any): string {
  return props.global_entity_id
    ? `${props.global_entity_id};${props.vendor_id}`
    : props.id
    ? props.id
    : '';
}

export class Vendor {
  @observable id: string;
  @observable vendorId: string;
  @observable globalEntityId: string;
  @observable globalVendorId: string;
  @observable chainId: string;
  @observable chainName: string;
  @observable billingParentId: string;
  @observable creationDate: string;
  @observable businessType: string;
  @observable verticalType: string;
  @observable keyAccount: boolean;
  @observable deliveryTypes: string[];
  @observable joker: IJoker;
  @observable name: string;
  @observable timezone: string;

  @observable address: Address;
  @observable currency: Currency;
  @observable accountType: string;
  @observable isConcept: boolean;
  @observable transmission: Transmission;

  /** check with BE about usability of these props */
  @observable isOpen: boolean;
  @observable isOnline: boolean;

  @observable ssuFlowVersion: string;
  @observable offboarding: boolean;

  constructor(props) {
    this.id = makeVendorId(props);
    this.vendorId = props.vendorId || props.vendor_id;
    this.globalEntityId = props.globalEntityId || props.global_entity_id;
    this.globalVendorId = props.globalVendorId || props.global_vendor_id;
    this.chainId = props.chainId || props.chain_id || '';
    this.chainName = props.chainName || props.chain_name;
    this.billingParentId =
      props.billingParentId || props.billing_parent_id || ''; // missing in new GET Profile API
    this.creationDate = props.creationDate || props.creation_date || ''; // missing in new GET Profile API
    this.businessType = props.businessType || props.business_type || ''; // missing in new GET Profile API
    this.verticalType = props.verticalType || props.vertical_type || '';
    this.keyAccount = props.keyAccount || props.key_account || false; // missing in new GET Profile API
    this.deliveryTypes = props.deliveryTypes || props.delivery_types || [];
    this.joker = props.joker; // missing in new GET Profile API
    this.name = props.name;
    this.timezone = props.timezone;
    this.address = {
      country: {
        name: props.address?.country?.name || '',
        code: props.address?.country?.code || '',
      },
      city: {
        name: props.address?.city?.name || '',
      },
    };
    this.currency = {
      code: props.currency?.code || 'USD',
      symbol: props.currency?.symbol || '$',
    };
    this.accountType = props.account_type || '';
    this.isConcept = props.is_concept;
    this.transmission = props.transmission;
    this.isOpen = props.isOpen || false;
    this.isOnline = props.isOnline || false;
    this.ssuFlowVersion = props.ssuFlowVersion || props.ssu_flow_version || '';
    this.offboarding = props.offboarding;
  }
}
