import { inject, injectable } from 'inversify';
import i18next from 'i18next';
import { toJS } from 'mobx';

import { SessionStore } from './SessionStore';
import { PluginStore } from './PluginStore';
import { NavStore } from './NavStore';
import { Api } from '@deliveryhero/portal-api-client';
import {
  IAuthToken,
  IDateParts,
  IVendorData,
  IGroup,
  VariationMap,
  ObserverDisposer,
  RestaurantSelectorOptions,
  SelectedVendorsObserverCallback,
  Subject,
  useCurrentVendor,
  useSdk,
  useSelectedVendors,
  VendorObserverCallback,
  withSdk,
  HelpCenterOptions,
} from '@deliveryhero/vendor-portal-sdk';
import { IConfig } from '../config';

import PluginTranslationsStore from './PluginTranslationsStore';
import { LanguageStore } from './LanguageStore';
import AuthService from '../services/AuthService';
import { TranslationsStore } from './TranslationsStore';
import FwfStore from './FwfStore';
import { IPlugin } from '../models/Plugin';
import { ApiStore } from './ApiStore';
import { LoggerService } from '../services/LoggerService';
import { SentrySDK, TYPES } from '../types';
import { VendorStore } from './VendorStore';
import { IUserData } from '../models/Session';
import { PlatformStore } from './PlatformStore';
import { UserStore } from './UserStore';
import getPushPluginEvents from '../../src/utils/gtm/getPushPluginEvents';
import { isLTR, isRTL } from '../../src/utils/LangUtlis';
import GtmManager from '../utils/gtm/GtmManager';
import { HelpStore } from './HelpStore';
import { Severity } from '@sentry/browser';

interface ISdkAuth {
  tokenType: string;
  accessToken: string;
}

@injectable()
export default class PortalSDKStore {
  @inject(ApiStore) private apiStore: ApiStore;
  @inject(TYPES.VendorStore) private vendorStore: VendorStore;
  @inject(PlatformStore) private platformStore: PlatformStore;
  @inject(LanguageStore) private languageStore: LanguageStore;
  @inject(AuthService) private authService: AuthService;
  @inject(TYPES.SessionStore) private sessionStore: SessionStore;
  @inject(PluginStore) private pluginStore: PluginStore;
  @inject(NavStore) private navStore: NavStore;
  @inject(TYPES.LoggerService) private loggerService: LoggerService;
  @inject(GtmManager)
  private gtmManager: GtmManager;
  @inject(PluginTranslationsStore)
  private pluginTranslationStore: PluginTranslationsStore;
  @inject(TranslationsStore)
  private translationsStore: TranslationsStore;
  @inject(FwfStore) private fwfStore: FwfStore;
  @inject(TYPES.UserStore) private userStore: UserStore;
  @inject(TYPES.Sentry) private sentry: SentrySDK;
  @inject('config') private config: IConfig;
  @inject(TYPES.HelpStore) private helpStore: HelpStore;

  /**
   * Get SDK for the frame. This has to be used when the webapp is using the SDK (outside of a plugin)
   * @todo we should rename this to `getSdkForWebapp` or something similar,
   * `master` was the old concept we had for chains in the past
   */
  getSdkForMaster() {
    const baseSdk = this.getBaseSdk();
    const gtmManager = this.gtmManager;

    return {
      ...baseSdk,
      getBaseRoute() {
        return '/';
      },
      // Noop for master
      setTranslation: async () => {},
      getFwfClient: () => this.fwfStore.mainFwfClient,
      t: i18next.t.bind(i18next),
      pushGA4Event(event: string, data?: {}): void {
        gtmManager.pushEvent(event, data);
      },
      setNewNavigation: this.navStore.setNewNavigation.bind(this.navStore),
      initNavigation: this.navStore.initNavigation.bind(this.navStore),
      pushNavigation: this.navStore.pushNavigation.bind(this.navStore),
      popNavigation: this.navStore.popNavigation.bind(this.navStore),
    };
  }

  /**
   * Get the SDK for a plugin, does custom things for plugins like:
   * - get the base route for the plugin (e.g. /dashboard)
   * - create fwf client for plugin if configured
   * - prefix translation keys with plugin code
   * - prefix GTM events with plugin code
   * - give plugin based defaults to setNewNavigation
   * @param pluginCode code of the plugin to create the SDK for
   */
  getSdkForPlugin(pluginCode: string) {
    const plugin: IPlugin = toJS(this.pluginStore.getByCode(pluginCode));
    const navStore = this.navStore;
    const userStore = this.userStore;
    const vendorStore = this.vendorStore;
    const environment = this.config.env;
    const sessionStore = this.sessionStore;

    const getBaseRoute = (): string => {
      return `${plugin.route}`;
    };

    const baseSdk = this.getBaseSdk(pluginCode);
    const gtmManager = this.gtmManager;

    return {
      ...baseSdk,
      setTranslation: this.pluginTranslationStore.setTranslationForPlugin.bind(
        this.pluginTranslationStore,
        pluginCode,
      ),
      t: this.pluginTranslationStore.translateForPlugin.bind(
        this.pluginTranslationStore,
        pluginCode,
      ),
      getBaseRoute,
      getFwfClient: () => plugin.fwfClient,
      /**
       * @deprecated Please use pushGA4Event insetad.
       */
      pushGtmEvent(event: string, data?: {}): void {
        const prefix = `${pluginCode.toLowerCase()}_`;
        const prefixedEvent = event.startsWith(prefix) ? event : prefix + event;
        const selectedGlobalVendorIds = vendorStore.isSingleVendorSelect
          ? vendorStore.currentVendorId
          : vendorStore.selectedVendorIds.join();
        const userId = sessionStore.getMainSession().isImpersonator
          ? `impersonator-${sessionStore.getUserData('email')}`
          : `master-${sessionStore.getUserData('userId')}`;
        const shopId = sessionStore.firstVendorId;
        gtmManager.pushEvent(prefixedEvent, {
          ...data,
          userId, // to override if data includes the common attributes
          shopId,
          selectedGlobalVendorIds,
        });
      },
      /**
       * @deprecated Please use pushGA4Event insetad.
       */
      pushGtmEventV2(event: string, data?: {}): void {
        const selectedGlobalVendorIds = vendorStore.isSingleVendorSelect
          ? vendorStore.currentVendorId
          : vendorStore.selectedVendorIds.join();
        const userId = sessionStore.getMainSession().isImpersonator
          ? `impersonator-${sessionStore.getUserData('email')}`
          : `master-${sessionStore.getUserData('userId')}`;
        const shopId = sessionStore.firstVendorId;
        gtmManager.pushEvent(event, {
          ...data,
          userId, // to override if data includes the common attributes
          shopId,
          selectedGlobalVendorIds,
        });
      },
      ...getPushPluginEvents({
        pluginCode,
        pluginName: this.translationsStore.translate(
          `global.menu.${pluginCode.toLocaleLowerCase()}`,
        ),
        userStore,
        vendorStore,
        gtmManager,
        environment,
        sentry: this.sentry,
      }),
      setNewNavigation(navigationOptions): void {
        const options = { ...navigationOptions };

        if (
          options.backUrl &&
          !options.backUrl.startsWith(plugin.route) &&
          !options.backUrl.startsWith(getBaseRoute())
        ) {
          options.backUrl = `${getBaseRoute()}${options.backUrl}`;
        }

        if (!options.backUrl && plugin?.fallbackUrl) {
          options.backUrl = plugin.fallbackUrl;
          options.hasBackButton = true;
        }

        navStore.setNewNavigation(options);
      },
      initNavigation(navigationOptions): void {
        navStore.initNavigation(navigationOptions);
      },
      pushNavigation(navigationOptionsPartial): void {
        navStore.pushNavigation(navigationOptionsPartial);
      },
      popNavigation(): void {
        navStore.popNavigation();
      },
      sentry: {
        withScope: this.sentry.withScope,
        captureException: this.sentry.captureException,
        setTag: this.sentry.setTag,
      },
    };
  }

  /**
   * Get the generic parts of the SDK, that are not custom to plugins (used in webapp and plugin)
   * @param pluginCode code of the plugin to create the SDK for
   */
  private getBaseSdk(pluginCode?: string) {
    const locale = this.languageStore.currentLanguage;
    const getRestaurantDateParts = this.getRestaurantDateParts;
    const sessionStore = this.sessionStore;
    const authService = this.authService;
    const getAllVendors = this.getAllVendors.bind(this);
    const vendorStore = this.vendorStore;
    const platformStore = this.platformStore;
    const apiStore = this.apiStore;
    const pluginStore = this.pluginStore;
    const fwfStore = this.fwfStore;
    const userStore = this.userStore;
    const helpStore = this.helpStore;
    const logger = this.getPluginLogger(pluginCode);
    const i18nextDir = i18next.dir;
    const getRegion = (): string => this.config.region;
    const getEnv = (): string => this.config.env;
    const sentryInstance = this.sentry;

    return {
      RestaurantSelectorOptions,
      Subject,
      logger,
      getPlugins() {
        return pluginStore.frontendPlugins.map(({ code, route, type }) => ({
          code,
          route,
          type,
        }));
      },
      createGlobalApi(tokenExpiredCondition): Api {
        return apiStore.createGlobalApi(
          tokenExpiredCondition,
          logger,
          pluginCode,
        );
      },
      createGlobalApiV2(tokenExpiredCondition): Api {
        return apiStore.createGlobalApiV2(
          tokenExpiredCondition,
          logger,
          pluginCode,
        );
      },
      isUserLoggedIn(): boolean {
        return sessionStore.isLoggedIn;
      },
      getPlatform() {
        const { name, nameReadable } = platformStore.currentPlatform;
        return {
          name,
          nameReadable,
        };
      },
      getCurrentVendor(): IVendorData {
        return vendorStore.currentVendor;
      },
      applyRestaurantTimezone(date: Date): Date {
        // NO-OP as the job of this SDK method should be taken over by react-intl's Provider
        return date;
      },
      getRestaurantDateString(date: Date): string {
        const { year, month, day } = getRestaurantDateParts(
          date,
          vendorStore.currentVendor.timezone,
        );

        return `${year}-${month.toString().padStart(2, '0')}-${day
          .toString()
          .padStart(2, '0')}`;
      },
      getRestaurantTimeString(date: Date): string {
        const { hours, minutes } = getRestaurantDateParts(
          date,
          vendorStore.currentVendor.timezone,
        );
        const exposedTimeParts = [
          hours.toString().padStart(2, '0'),
          minutes.toString().padStart(2, '0'),
        ];
        return exposedTimeParts.join(':');
      },
      getRestaurantTimeStringWithSeconds(date: Date): string {
        const { hours, minutes, seconds } = getRestaurantDateParts(
          date,
          vendorStore.currentVendor.timezone,
        );
        const exposedTimeParts = [
          hours.toString().padStart(2, '0'),
          minutes.toString().padStart(2, '0'),
          seconds.toString().padStart(2, '0'),
        ];
        return exposedTimeParts.join(':');
      },
      getRestaurantDateParts(date: Date): IDateParts {
        return getRestaurantDateParts(date, vendorStore.currentVendor.timezone);
      },
      getLocale(): string {
        return locale;
      },
      getAuth(): ISdkAuth {
        const { tokenType, accessToken } = sessionStore.mainSessionInfo;
        return {
          tokenType,
          accessToken,
        };
      },
      getAuthToken(): IAuthToken {
        return authService.getAuthToken();
      },
      subscribeToRefreshStatus(
        cb: (isRefreshing: boolean) => void,
      ): () => void {
        return authService.subscribeToRefreshStatus(cb);
      },
      refreshToken(): Promise<any> {
        return authService.refreshToken();
      },
      getUserData(prop: keyof IUserData): any {
        return sessionStore.getUserData(prop);
      },
      getAllVendors,
      getSelectedVendors(): IVendorData[] {
        return vendorStore.selectedVendors;
      },
      addVendorObserver(cb: VendorObserverCallback): ObserverDisposer {
        return vendorStore.addVendorObserver(cb);
      },
      addSelectedVendorsObserver(
        cb: SelectedVendorsObserverCallback,
      ): ObserverDisposer {
        return vendorStore.addSelectedVendorsObserver(cb);
      },
      getSubject(): Subject {
        return sessionStore.mainSessionInfo.isImpersonator
          ? Subject.IMPERSONATOR
          : Subject.USER;
      },
      withSdk,
      useSdk,
      useCurrentVendor,
      useSelectedVendors,
      helpCenterTranslateY: (newOffsetY: number) =>
        helpStore.setOffsetY(newOffsetY),
      helpCenterResetY: () => helpStore.setOffsetY(HelpStore.defaultY),
      helpCenterToggle: (isOpen: boolean, options?: HelpCenterOptions) =>
        helpStore.toggleHelpCenter(isOpen, options),
      getPortalFwfVariation<T extends string>(
        name: T,
        fallbackValue: boolean | string = false,
        forced: boolean = false,
      ): Promise<VariationMap<T>> {
        return fwfStore.getVariation(name, fallbackValue, forced);
      },
      getPortalFwfVariationValue<T extends string>(
        name: T,
        fallBackValue?: boolean | string,
        forced?: boolean,
      ): Promise<boolean | string> {
        return fwfStore.getVariationValue(name, fallBackValue, forced);
      },
      getPortalFwfVariations<T extends string>(
        flags: T[],
      ): Promise<VariationMap> {
        return fwfStore.getVariations(flags);
      },
      getOwnedGroups(): Promise<IGroup[]> {
        sentryInstance.withScope(function (scope) {
          scope.setLevel(Severity.Info);
          sentryInstance.captureMessage(
            `getOwnedGroups() VP SDK Method has been invoked by plugin ${pluginCode}.`,
          );
        });
        return Promise.resolve(userStore.userOwnedGroups);
      },
      i18nextDir,
      isRTL,
      isLTR,
      getRegion,
      getEnv,
    };
  }

  private async getAllVendors() {
    return this.vendorStore.allVendors;
  }

  private getRestaurantDateParts(date: Date, timeZone: string) {
    const options: any = {
      timeZone,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    };
    const formatter = new Intl.DateTimeFormat('en-US', options);

    const transformedDateParts: {
      [key: string]: number;
    } = formatter
      .formatToParts(date)
      .reduce((a, p) => ({ ...a, [p.type]: Number(p.value) }), {});

    return {
      day: transformedDateParts.day,
      month: transformedDateParts.month,
      year: transformedDateParts.year,
      hours: transformedDateParts.hour,
      minutes: transformedDateParts.minute,
      seconds: transformedDateParts.second,
    };
  }

  private getPluginLogger(pluginCode?: string) {
    return this.loggerService.createLogger(
      pluginCode
        ? `PORTAL_PLUGIN_${pluginCode.toUpperCase()}`
        : 'PORTAL_GLOBAL',
      {
        pluginCode,
      },
    );
  }
}
