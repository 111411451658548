import { withDependencies } from '@deliveryhero/react-inversify-provider';
import { PlatformStore } from '../../stores/PlatformStore';
import Container from './Container';
import withLanguageSwitch from '../../components/withLanguageSwitch';
import PortalSDKStore from '../../stores/PortalSDKStore';
import { withRouter } from 'react-router-dom';
import GtmManager from '../../utils/gtm/GtmManager';
import { NavStore } from '../../stores/NavStore';
import { PluginStore } from '../../stores/PluginStore';
import { TYPES } from '../../types';
import FwfStore from '../../stores/FwfStore';
import { NotificationsStore } from '../../stores/NotificationsStore';
import { LocaleStore } from '../../stores/LocaleStore';

const MasterContainer = withDependencies({
  platformStore: PlatformStore,
  vendorStore: TYPES.VendorStore,
  sessionStore: TYPES.SessionStore,
  portalSdkStore: PortalSDKStore,
  navStore: NavStore,
  history: 'history',
  availableLanguages: 'availableLanguages',
  gtmManager: GtmManager,
  pluginStore: PluginStore,
  userStore: TYPES.UserStore,
  fwfStore: FwfStore,
  notificationsStore: NotificationsStore,
  localeStore: LocaleStore,
  helpStore: TYPES.HelpStore,
})(withRouter(withLanguageSwitch(Container)));

export default MasterContainer;
