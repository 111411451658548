import * as React from 'react';
import { RenderContext, RenderContextComponentProps } from './BaseFilter';
import { Box, Grid } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useTheme, styled as muiStyled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import FullScreenDrawer, { FullScreenDrawerAction } from '../FullScreenDrawer';
import { getSpacing } from '@deliveryhero/vt-portal-chardonnay/core';
import { Translate } from '../Translate';
import Icon from '@mui/material/Icon';

type FilterElement = React.ClassicElement<RenderContextComponentProps>;

export type FiltersProps = {
  children: FilterElement | FilterElement[];
};

const DesktopGrid = muiStyled(Grid)({
  width: 'auto',
});

export function InternalFilters({ children }: FiltersProps) {
  const values = createChildrenArray(children).map(
    (child) => child.props.value,
  );

  const theme = useTheme();
  if (useMediaQuery(theme.breakpoints.up('md'))) {
    return <DesktopFilters values={values}>{children}</DesktopFilters>;
  }

  return <MobileFilters values={values}>{children}</MobileFilters>;
}

export const Filters = React.memo(InternalFilters);

function createChildrenArray(children) {
  return Array.isArray(children) ? children : [children];
}

function DesktopFilters({ children, values }) {
  const childrenArray = Array.isArray(children) ? children : [children];
  const renderChildren = childrenArray.map((child, i) => {
    const desktopChild = React.cloneElement(child, {
      renderContext: RenderContext.DESKTOP,
      value: values[i],
    });

    return (
      <Grid item key={child.props.name}>
        {desktopChild}
      </Grid>
    );
  });
  return (
    <DesktopGrid container spacing={4}>
      {renderChildren}
    </DesktopGrid>
  );
}

const EditChildWrapper = muiStyled(Box)({
  margin: `${getSpacing('spacing-24')} ${getSpacing('spacing-16')} ${getSpacing(
    'spacing-40',
  )}`,
});

const MobileFiltersPreviewWrapper = muiStyled(Box)({
  cursor: 'pointer',
});

function MobileFilters({ children, values }) {
  const [filtersPanelOpen, setFiltersPanelOpen] = React.useState(false);
  const [usedFilterChanges, setUsedFilterChanges] = React.useState(
    new Map<string, any>(),
  );

  const [filterValidity, setFilterValidity] = React.useState(
    new Map<string, any>(),
  );

  function areFiltersValid() {
    const validityArray = Array.from(filterValidity.values());
    return (
      usedFilterChanges.size > 0 &&
      validityArray.reduce((agg, val) => agg && val, true)
    );
  }

  // Every time the filter panel open state changes, reset used filter changes
  React.useEffect(() => {
    setUsedFilterChanges(new Map());
    setFilterValidity(new Map());
  }, [filtersPanelOpen]);

  // Apply the filters in batches:
  // This approach is needed as we have one unified "APPLY" button on mobile
  // That needs to apply all filter changes
  function batchApplyFilters() {
    Array.from(usedFilterChanges.entries()).forEach(([key, changes]) => {
      const internalChildren = Array.isArray(children) ? children : [children];
      internalChildren
        .find((element) => element.props.name === key)
        .props.onChange(changes);
    });
    setFiltersPanelOpen(false);
  }

  const childrenArray = Array.isArray(children) ? children : [children];

  // Children for preview on mobile devices (left of the filter button)
  const previewChildren = childrenArray.map((child, i) => {
    const previewChild = React.cloneElement(child, {
      value: values[i],
      renderContext: RenderContext.MOBILE_PREVIEW,
    });

    return (
      <Grid item key={child.props.name}>
        {previewChild}
      </Grid>
    );
  });

  // Children for edit Drawer
  const editChildren = childrenArray.map((child, i) =>
    React.cloneElement(child, {
      ...child.props,
      value: usedFilterChanges.get(child.props.name) || values[i],
      renderContext: RenderContext.MOBILE_EDIT,
      // Instead of calling the original onChange, save the value for later
      onChange: (value) => {
        const newUsedFilterChanges = new Map(usedFilterChanges);
        newUsedFilterChanges.set(child.props.name, value);
        setUsedFilterChanges(newUsedFilterChanges);
      },
      setValidity: (value) => {
        const newFilterValidity = new Map(filterValidity);
        newFilterValidity.set(child.props.name, value);
        setFilterValidity(newFilterValidity);
      },
    }),
  );

  return (
    <>
      <MobileFiltersPreviewWrapper
        data-testid="filters-mobile-preview"
        onClick={() => setFiltersPanelOpen(true)}
      >
        <Box display="flex" justifyContent="space-between" mx={-1}>
          <Box mx={1}>
            <Grid container spacing={4}>
              {previewChildren}
            </Grid>
          </Box>
          <Box mx={1}>
            <IconButton data-testid="filter-icon">
              <Icon>filter_alt</Icon>
            </IconButton>
          </Box>
        </Box>
      </MobileFiltersPreviewWrapper>
      <FullScreenDrawer
        open={filtersPanelOpen}
        title={<Translate code="global.filter" />}
        action={
          <FullScreenDrawerAction
            label={<Translate code="global.apply" />}
            data-testid="filters-mobile-apply"
            onClick={() => batchApplyFilters()}
            disabled={!areFiltersValid()}
          />
        }
        onClose={() => setFiltersPanelOpen(false)}
      >
        {editChildren.map((child) => (
          <EditChildWrapper key={child.props.name}>{child}</EditChildWrapper>
        ))}
      </FullScreenDrawer>
    </>
  );
}

InternalFilters.displayName = 'Filters';
