import { IVendorData } from '@deliveryhero/vendor-portal-sdk';
import Typography from '@mui/material/Typography';
import { Translate } from '../Translate';
import * as React from 'react';

export const formatVendors = (vendors: IVendorData[]) =>
  vendors.map((vendor) => ({
    value: vendor.id.toString(),
    vendorName: vendor.name,
    vendorCode: vendor.vendorId,
    labelForSearch: vendor.name + vendor.id,
    label: (
      <>
        {vendor.name} (
        <Typography variant="caption">{vendor.vendorId}</Typography>)
        {vendor.deliveryTypes.indexOf('platform_delivery') > -1 && (
          <>
            &nbsp;
            <Translate code="list_filter.vendors.delivery_type.od" />
          </>
        )}
        {vendor.deliveryTypes.indexOf('vendor_delivery') > -1 && (
          <>
            &nbsp;
            <Translate code="list_filter.vendors.delivery_type.vd" />
          </>
        )}
      </>
    ),
  }));
