import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { SIDEBAR_WIDTH } from '../../constants';
import { isMobileWebView } from '../../utils/isMobileWebView';

export const ContentContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isMobile',
})<{ isMobile: boolean }>(({ isMobile }) => ({
  marginLeft: isMobileWebView() || isMobile ? 0 : SIDEBAR_WIDTH,
  flexDirection: 'column',
  flex: '1',
  overflow: 'hidden',
}));
