import * as Sentry from '@sentry/browser';
import { datadogLogs } from '@datadog/browser-logs';
import braze from '@braze/web-sdk';

export type SentrySDK = typeof Sentry;
export type BrazeSDK = typeof braze;
export type DatadogSDK = typeof datadogLogs;

export const TYPES = {
  GlobalApi: Symbol('GlobalApi'),
  GlobalApiV2: Symbol('GlobalApiV2'),
  Sentry: Symbol('Sentry'),
  BrazeManager: Symbol('Braze'),
  BrazeSDK: Symbol('BrazeSDK'),
  Datadog: Symbol('Datadog'),
  LoggerService: Symbol('LoggerService'),
  MasterSdk: Symbol('MasterSdk'),
  VendorStore: Symbol('VendorStore'),
  UtilsDateStore: Symbol('UtilsDateStore'),
  UserStore: Symbol('UserStore'),
  SessionStore: Symbol('SessionStore'),
  QualtricsManager: Symbol('QualtricsManager'),
  HelpStore: Symbol('HelpStore'),
};
