/**
 * getGA4VendorsCountString() returns back a string which tells the number of vendors per entity.
 * Although portal does not recommend having vendors from more than 1 entity,
 * there can be still users which were created for testing purposes.
 * @param vendors - it is an array of string values of vendors in format of entityId;vendorId.
 * eg: ['FP_SG;abcd', 'FP_SG;wxyz', 'FP_BD;bdpf']
 * @returns a string with count of vendors per entity.
 * eg: 'FP_SG;2,FP_BD;1'
 */
export const getGA4VendorsCountString = (vendors: Array<string>): string => {
  const vendorCountMap: Map<string, number> = new Map();
  for (const geid of vendors) {
    const [entityId] = geid.split(';');
    let inMapValue = vendorCountMap.get(entityId);
    if (inMapValue) {
      vendorCountMap.set(entityId, ++inMapValue);
    } else {
      vendorCountMap.set(entityId, 1);
    }
  }
  return Array.from(vendorCountMap.entries())
    .map(([k, v]) => `${k};${v}`)
    .join();
};
