import { QueryStringParser } from './QueryStringParser';

export const isMobileWebView = () => {
  if (localStorage.getItem('mode') === 'webview') {
    return true;
  } else if (
    QueryStringParser.parse(window.location.search).mode === 'webview'
  ) {
    localStorage.setItem('mode', 'webview');
    return true;
  }
  return false;
};
