import * as React from 'react';
import { DrawerProps } from '@mui/material/Drawer';
import { styled as muiStyled } from '@mui/material/styles';
import Div100vh from 'react-div-100vh';
import { Typography, Button, IconButton, Drawer, Box } from '@mui/material';
import { getSpacing } from '@deliveryhero/vt-portal-chardonnay/core';
import Icon from '@mui/material/Icon';

export type FullScreenDrawerProps = Omit<DrawerProps, 'title' | 'onClose'> & {
  title: React.ReactNode;
  action?: React.ReactNode;
  onClose?: () => void;
};

const FullHeightDrawerContent = muiStyled(Div100vh)({
  display: 'flex',
  flexDirection: 'column',
});

const DrawerScrollableContent = muiStyled(Box)({
  overflow: 'scroll',
});

const Bar = muiStyled(Box)({
  display: 'flex',
  flexShrink: 0,
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: '#000',
  color: '#fff',
  height: '63px',
  paddingRight: getSpacing('spacing-8'),
});

const BarTitle = muiStyled((props) => (
  <Typography variant="heading3-500" color="inherit" {...props} />
))({
  flexGrow: 1,
});

export default function FullScreenDrawer({
  title,
  onClose,
  action,
  children,
  ...drawerProps
}: FullScreenDrawerProps) {
  return (
    <Drawer anchor="bottom" onClose={onClose} {...drawerProps}>
      <FullHeightDrawerContent>
        <Bar>
          <IconButton
            data-testid="close-filters-btn"
            color="inherit"
            aria-label="close filters"
            onClick={onClose}
          >
            <Icon>close</Icon>
          </IconButton>
          <BarTitle>{title}</BarTitle>
          {action}
        </Bar>
        <DrawerScrollableContent>{children}</DrawerScrollableContent>
      </FullHeightDrawerContent>
    </Drawer>
  );
}

export type FullScreenDrawerActionProps = {
  label: React.ReactNode;
  onClick: () => void;
  disabled?: boolean;
  'data-testid'?: string;
};

const StyledButton = muiStyled(Button)({
  '&:disabled': {
    opacity: 0.3,
    backgroundColor: '#000000',
    color: '#ffffff',
  },
});

export function FullScreenDrawerAction({
  label,
  onClick,
  disabled,
  'data-testid': dataTestId,
}: FullScreenDrawerActionProps) {
  return (
    <StyledButton
      color="primary"
      variant="contained"
      onClick={onClick}
      disabled={disabled}
      data-testId={dataTestId}
    >
      {label}
    </StyledButton>
  );
}
