import React, { useMemo, useState } from 'react';
import OnboardingHeader from '../../components/OnboardingHeader';
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import { Translate } from '../../components/Translate';
import { styled } from '@mui/system';
import {
  getSpacing,
  useIsMobile,
} from '@deliveryhero/vt-portal-chardonnay/cjs/core/helpers';
import { QueryStringParser } from '../../utils/QueryStringParser';
import { useLocation } from 'react-router-dom';
import { getPasswordErrors } from '../PasswordHandlingContainer/parts/Password/getPasswordErrors';
import ErrorMessage from '../../components/errors/ErrorMessage';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import { resolveErrorMessage, validateUrl } from './utils';
import { PasswordInput } from './PasswordInput';
import { getStaticPath } from '../../utils/PathHelper';
import { OnboardingSetPasswordContainerProps, PasswordViewType } from './types';
import { RESET_PASSWORD_ERROR_MESSAGES } from '../../constants';
import { CaptchaHttpContextProvider } from '@deliveryhero/captcha';
import PasswordValidationView from './PasswordValidationView';

const RightSide = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f8f8f8;
`;

const ErrorLabel: React.FC<{ code: string }> = ({ code }) => {
  return <Translate code={code} />;
};

const PasswordForm = ({
  onSubmit,
  password,
  passwordRepeat,
  setPasswordRepeat,
  setPassword,
  isMobile,
  errors,
  isValidPassword,
  isDirty,
}: PasswordViewType & { isMobile: boolean }) => {
  return (
    <form
      style={{
        width: isMobile ? '100%' : '70%',
        maxWidth: '526px',
      }}
      id="password-form"
      onSubmit={onSubmit}
    >
      <Typography
        variant="heading2-700"
        sx={{ marginBottom: getSpacing('spacing-32') }}
      >
        <Translate code="onboarding.set_password.page_title" />
      </Typography>
      <Box mb={getSpacing('spacing-20')}>
        <PasswordInput
          name="password"
          label={<Translate code="global.password_reset.password" />}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          error={
            errors.fields?.['password'] &&
            isDirty && <ErrorLabel code={errors.fields?.['password']} />
          }
        />
      </Box>
      <Box mb={getSpacing('spacing-20')}>
        <PasswordInput
          name="passwordRepeat"
          label={<Translate code="global.password_reset.password_repeat" />}
          value={passwordRepeat}
          onChange={(e) => setPasswordRepeat(e.target.value)}
          error={
            errors.fields?.['passwordRepeat'] &&
            isDirty && <ErrorLabel code={errors.fields?.['passwordRepeat']} />
          }
        />
      </Box>
      <Box mb={getSpacing('spacing-20')}>
        <Button
          data-testid="onboarding-set-password-submit-btn"
          variant={'contained'}
          disabled={!isValidPassword}
          size="large"
          fullWidth
          type="submit"
        >
          <Translate code="onboarding.set_password.submit_btn" />
        </Button>
      </Box>
      <Box>
        <PasswordValidationView errors={errors} isDirty={isDirty} />
      </Box>
    </form>
  );
};

const OnboardingSetPasswordMobile = (props: PasswordViewType) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '121px',
        padding: `0 ${getSpacing('spacing-16')} 0 ${getSpacing('spacing-16')}`,
      }}
    >
      <PasswordForm {...props} isMobile />
    </Box>
  );
};

const OnboardingSetPasswordDesktop = (props: PasswordViewType) => {
  return (
    <Grid container>
      <Grid
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          height: '100vh',
          paddingRight: '94px',
        }}
        xs={7}
      >
        <PasswordForm {...props} isMobile={false} />
      </Grid>
      <RightSide xs={5}>
        <img
          src={getStaticPath('images/onboarding_set_password.png')}
          width={293}
        />
      </RightSide>
    </Grid>
  );
};

export const OnboardingSetPasswordContainer = (
  props: OnboardingSetPasswordContainerProps,
) => {
  const { platformStore, authService, userStore } = props;
  const { currentPlatform } = platformStore;
  const [password, setPasswordValue] = useState('');
  const [passwordRepeat, setPasswordRepeatValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const location = useLocation();
  const searchParams = QueryStringParser.parse(location.search);
  const snackbarOpen = snackbarMessage !== '';
  const isMobile = useIsMobile();
  const [isDirty, setIsDirty] = useState(false);

  const setPassword = (value: string) => {
    setIsDirty(true);
    setPasswordValue(value);
  };

  const setPasswordRepeat = (value: string) => {
    setIsDirty(true);
    setPasswordRepeatValue(value);
  };

  const passwordValidationErrors = useMemo(
    () => getPasswordErrors({ password, passwordRepeat, isForceReset: false }),
    [password, passwordRepeat],
  );

  const isValid = useMemo(() => {
    const errorKeys = Object.values(passwordValidationErrors.rules).filter(
      (condition) => condition,
    );
    return (
      errorKeys.length === 0 &&
      Object.keys(passwordValidationErrors.fields).length === 0
    );
  }, [passwordValidationErrors]);

  const handleSuccess = (redirectUrl: string) => {
    const isValidUrl = validateUrl(redirectUrl);

    if (isValidUrl) {
      window.location.replace(redirectUrl);
    }
  };

  const handleError = (error) => {
    const errorMessage = resolveErrorMessage(error);

    setSnackbarMessage(errorMessage);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (isValid) {
      try {
        setIsLoading(true);

        await authService.resetPassword(searchParams?.token, password);
        const resp = await userStore.fetchSignupRedirectUrl();

        if (resp?.redirect_link) {
          handleSuccess(resp?.redirect_link);
        }
      } catch {
        handleError({
          response: {
            message: RESET_PASSWORD_ERROR_MESSAGES.AN_ERROR_OCCURRED,
          },
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  if (!searchParams.token) {
    return (
      <ErrorMessage
        title={<Translate code="password_handling.error.no_token.title" />}
        message={
          <Translate code="password_handling.error.no_token.description" />
        }
      />
    );
  }

  return (
    <CaptchaHttpContextProvider>
      <OnboardingHeader currentPlatform={currentPlatform} />
      <>
        {isLoading ? (
          <Box
            sx={{ margin: '0px auto', textAlign: 'center', marginTop: '200px' }}
          >
            <CircularProgress />
          </Box>
        ) : isMobile ? (
          <OnboardingSetPasswordMobile
            onSubmit={onSubmit}
            password={password}
            passwordRepeat={passwordRepeat}
            setPasswordRepeat={setPasswordRepeat}
            setPassword={setPassword}
            errors={passwordValidationErrors}
            isValidPassword={isValid}
            isDirty={isDirty}
          />
        ) : (
          <OnboardingSetPasswordDesktop
            onSubmit={onSubmit}
            password={password}
            passwordRepeat={passwordRepeat}
            setPasswordRepeat={setPasswordRepeat}
            setPassword={setPassword}
            errors={passwordValidationErrors}
            isValidPassword={isValid}
            isDirty={isDirty}
          />
        )}
      </>
      <Snackbar
        data-testid={'password-snackbar-error'}
        open={snackbarOpen}
        onClose={() => setSnackbarMessage('')}
        autoHideDuration={3000}
      >
        <SnackbarContent message={<Translate code={snackbarMessage} />} />
      </Snackbar>
    </CaptchaHttpContextProvider>
  );
};
