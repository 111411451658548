import * as React from 'react';
import { FC, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { Box, Typography, Button, CircularProgress } from '@mui/material';

import StartPrompt from '../../components/StartPrompt';
import { Translate } from '../../components/Translate';
import { ApiStatus } from '../../models/ApiStatus';
import { PlatformStore } from '../../stores/PlatformStore';
import { UserStore } from '../../stores/UserStore';
import { SessionStore } from '../../stores/SessionStore';
import { EnterPhoneForm } from '../../components/EnterPhoneForm';
import { validatePhoneNumber } from '../../utils/validatePhoneNumber';
import GtmManager from '../../utils/gtm/GtmManager';
import { TranslationsStore } from '../../stores/TranslationsStore';
import { QueryStringParser } from '../../utils/QueryStringParser';
import { CaptchaHttpContextProvider } from '@deliveryhero/captcha';

type Props = {
  platformStore: PlatformStore;
  userStore: UserStore;
  sessionStore: SessionStore;
  gtmManager: GtmManager;
  translationsStore: TranslationsStore;
  window: Window;
};

export const AddPhoneComponent: FC<Props> = ({
  platformStore,
  userStore,
  sessionStore,
  window,
}) => {
  const [countryCode, setCountryCode] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const platform = platformStore.currentPlatform;
  const { updatePhoneNumberApiStatus, updatePhoneNumber } = userStore;
  const history = useHistory();

  const onCountryCodeSelect = (event) => {
    const value = event?.target?.value || '';

    setCountryCode(value);
  };

  const onPhoneNumberInput = (event) => {
    const value = event?.target?.value || '';

    setPhoneNumber(value);
  };

  const onSubmitBtnClick = async () => {
    const number = countryCode + phoneNumber;

    await updatePhoneNumber(number);
  };

  const continueNavigation = () => {
    const urlParams = QueryStringParser.parseLocationParams(
      window.location.toString(),
    );
    if (urlParams.redirect) {
      history.push(decodeURIComponent(urlParams.redirect));
    } else {
      history.push('/');
    }
  };

  const onCancelBtnClick = () => {
    continueNavigation();
  };

  useEffect(() => {
    if (!sessionStore.isLoggedIn) {
      history.push('/login');
    }
  }, []);

  useEffect(() => {
    if (updatePhoneNumberApiStatus === ApiStatus.SUCCESS) {
      continueNavigation();
    }
  }, [updatePhoneNumberApiStatus]);

  const isPhoneNumberValid = !phoneNumber
    ? true
    : validatePhoneNumber(phoneNumber);

  const canSubmit =
    countryCode &&
    phoneNumber &&
    isPhoneNumberValid &&
    updatePhoneNumberApiStatus !== ApiStatus.LOADING;

  const submitBtnContent =
    updatePhoneNumberApiStatus === ApiStatus.LOADING ? (
      <CircularProgress size="25px" color="inherit" />
    ) : (
      <Translate code="global.add_phone_number.form.submit" />
    );

  return (
    <CaptchaHttpContextProvider>
      <StartPrompt platform={platform as any}>
        <header style={{ textAlign: 'center' }}>
          <Box>
            <Typography variant="h4" gutterBottom component="div">
              <Translate code="global.add_phone_number.form.title" />
            </Typography>
            <Box sx={{ marginTop: '12px' }}>
              <Translate code="global.add_phone_number.form.subtitle" />
            </Box>
          </Box>
        </header>
        <EnterPhoneForm
          phoneNumber={phoneNumber}
          onCountryCodeSelect={onCountryCodeSelect}
          onPhoneNumberInput={onPhoneNumberInput}
        />
        <Box sx={{ marginTop: '32px' }}>
          <Button
            sx={{
              width: '100%',
            }}
            variant="contained"
            disabled={!canSubmit}
            onClick={onSubmitBtnClick}
            data-testid="add-phone-submit-button"
          >
            {submitBtnContent}
          </Button>
          <Button
            sx={{
              width: '100%',
              marginTop: '16px',
            }}
            onClick={onCancelBtnClick}
            data-testid="add-phone-cancel-button"
          >
            <Translate code="global.add_phone_number.form.cancel" />
          </Button>
        </Box>
        {updatePhoneNumberApiStatus === ApiStatus.ERROR && (
          <Box
            sx={{
              color: '#f44336',
              textAlign: 'center',
              marginTop: '16px',
              marginBottom: '8px',
            }}
            data-testid="add-phone-number-submit-error-msg"
          >
            <Translate code="global.contact_info.form.submit_error" />
          </Box>
        )}
      </StartPrompt>
    </CaptchaHttpContextProvider>
  );
};

export const AddPhone = observer(AddPhoneComponent);
