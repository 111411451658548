import { withDependencies } from '@deliveryhero/react-inversify-provider';

import { PluginStore } from '../../stores/PluginStore';
import { PlatformStore } from '../../stores/PlatformStore';
import { AvailableLanguagesStore } from '../../stores/AvailableLanguagesStore';

import NavbarContainer from './Navbar';

import { TYPES } from '../../types';

const Navbar = withDependencies({
  availableLanguagesStore: AvailableLanguagesStore,
  platformStore: PlatformStore,
  pluginStore: PluginStore,
  userStore: TYPES.UserStore,
})(NavbarContainer);

export default Navbar;
