import * as React from 'react';
import { Translate } from '../../../../../components/Translate';

interface Props {
  isForceReset: true;
}

export const Headline: React.FC<Props> = ({ isForceReset }) =>
  isForceReset ? (
    <Translate code="force_reset.password.headline" />
  ) : (
    <Translate code="global.auth.set_password.headline" />
  );
