type SendFileDownloadRequest = (config: {
  url: string;
  id: string;
}) => Promise<void>;

type UseAttachmentDownload = (handlers: {
  onFileDownloadSuccess: (requestId: string) => void;
  onFileDownloadError: (requestId: string, error: any) => void;
}) => Array<SendFileDownloadRequest>;

const isValidAttachmentUrl = (url: string): boolean => {
  try {
    const newUrl = new URL(url);
    return newUrl.protocol === 'https:';
  } catch {
    return false;
  }
};

const requestFileDownload = async (url: string): Promise<void> => {
  const anchor = document.createElement('a');
  anchor.style.display = 'none';
  anchor.href = url;
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
};

const useAttachmentDownload: UseAttachmentDownload = ({
  onFileDownloadSuccess,
  onFileDownloadError,
}) => {
  const sendFileDownloadRequest: SendFileDownloadRequest = async ({
    url,
    id,
  }): Promise<void> => {
    try {
      if (!isValidAttachmentUrl(url)) {
        new Error(
          `Invalid URL has been provided for file download: ${url}, request ID: ${id}`,
        );
      }
      await requestFileDownload(url);
      onFileDownloadSuccess(id);
    } catch (error) {
      onFileDownloadError(id, error);
    }
  };

  return [sendFileDownloadRequest];
};

export default useAttachmentDownload;
