import * as React from 'react';

import { styled as muiStyled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { Translate } from '../../../../components/Translate';
import { PasswordHandlingContainerComponentProps } from '../../PasswordHandlingContainer';
import { Content, StartSubHeader } from '../Content';

const ActionsWrapper = muiStyled(Box)({
  paddingBottom: '24px',
});

const getPlatformReadable = (platform) => {
  if (platform === 'Default') {
    return 'Restaurant Portal';
  }

  return platform;
};

const StartView: React.FC<PasswordHandlingContainerComponentProps> = ({
  onNextClick,
  currentPlatform,
}) => (
  <>
    <Typography variant="h5" gutterBottom align="center">
      <Translate
        code="set_password.header"
        params={{
          platform: getPlatformReadable(currentPlatform.nameReadable),
        }}
      />
    </Typography>

    <StartSubHeader />
    <Content />

    <ActionsWrapper>
      <Button
        color="primary"
        variant="contained"
        onClick={onNextClick}
        fullWidth={true}
        data-testid="welcome-start-next-btn"
      >
        <Translate code="set_password.next" />
      </Button>
    </ActionsWrapper>
  </>
);

export default StartView;
