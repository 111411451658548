import * as React from 'react';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import { useIsBelowBreakpoint } from '@deliveryhero/vt-portal-chardonnay/core';

export const DesktopIframeWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isHelpCenterOpen',
})<{
  isHelpCenterOpen: boolean;
}>(({ isHelpCenterOpen }) => ({
  zIndex: 1000,
  position: 'absolute',
  bottom: 70,
  right: 0,
  boxShadow: '0px 0px 16px rgba(134, 147, 148, 0.24)',
  background: '#fff',
  height: 'calc(100vh - 175px)',
  transformOrigin: 'bottom right',
  transform: isHelpCenterOpen ? 'scale(1)' : 'scale(0)',
  width: '360px',
  borderRadius: '6px',
  transition: 'all .2s ease-in',
  paddingBottom: 50,
}));

export const MobileIframeWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isHelpCenterOpen',
})<{ isHelpCenterOpen: boolean }>(({ isHelpCenterOpen }) => ({
  // set at 1000 as VP Mobile Top Bar has zIndex: 99
  zIndex: 1000,
  // remove when helpcenter is closed, or else hamburger button does not work
  display: isHelpCenterOpen ? 'block' : 'none',
  position: 'fixed',
  bottom: 0,
  right: 0,
  background: '#fff',
  height: '100%',
  width: '100%',
  transition: 'all .2s ease-in',
}));

export const IframeWrapper = ({ isHelpCenterOpen, ...props }) => {
  const isMobile = useIsBelowBreakpoint('sm');
  if (isMobile) {
    return (
      <MobileIframeWrapper isHelpCenterOpen={isHelpCenterOpen} {...props} />
    );
  }
  return (
    <DesktopIframeWrapper isHelpCenterOpen={isHelpCenterOpen} {...props} />
  );
};

export const HeaderHelpCenter = styled('div')(({ theme }) => ({
  background: `${theme.palette.primary.main}`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1000,
  [theme.breakpoints.up('sm')]: {
    borderRadius: '6px 6px 0 0',
  },
}));

export const MinimizeIcon = styled(IconButton)(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  justifyContent: 'center',
  color: '#fff',
  alignItems: 'center',
  '&:hover': {
    opacity: '0.8',
    background: `${theme.palette.primary.main}`,
  },
}));

export const FloatingBubble = styled(IconButton)(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  justifyContent: 'center',
  alignItems: 'center',
  height: '54px',
  width: '54px',
  color: '#fff',
  backgroundColor: `${theme.palette.primary.main}`,
  '&:hover': {
    backgroundColor: `${theme.palette.primary.main}`,
  },
}));

/** bottom offset is defined in the HelpStore and exposed to SDK */
export const IconWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isMobile',
})(() => ({
  position: 'fixed',
  right: '32px',
  zIndex: 1000,
}));

export const UnreadMessagesCountBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#0075BB',
  },
}));
