import * as React from 'react';
import { styled as muiStyled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

interface Props {
  className?: string;
  title: JSX.Element | string;
  message: JSX.Element | string;
  centered?: boolean;
}

const ErrorMessageWrapper = muiStyled(Box)({
  padding: '0 25px',
  margin: '50px auto',
  maxWidth: '500px',
});

const CenteredMessageWrapper = muiStyled(Box)({
  position: 'absolute',
  top: '0',
  right: '0',
  bottom: '0',
  left: '0',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  pointerEvents: 'none',
});

const ErrorMessage: React.FC<Props> = ({ title, message, centered }) => {
  const WrappingComponent = centered ? CenteredMessageWrapper : React.Fragment;

  return (
    <WrappingComponent>
      <ErrorMessageWrapper>
        <Typography
          sx={{
            fontSize: '2.625rem',
            lineHeight: '1.2em',
            fontWeight: '300',
            textAlign: centered ? 'center' : 'initial',
          }}
        >
          {title}
        </Typography>
        <Typography sx={{ textAlign: centered ? 'center' : 'initial' }}>
          {message}
        </Typography>
      </ErrorMessageWrapper>
    </WrappingComponent>
  );
};

export default ErrorMessage;
