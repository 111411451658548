const availableLanguages = require('./availableLanguages.prd.js');
const brazeConfig = require('../services/braze/braze.config.prd');
const supportedBrowsers = require('../supportedBrowsers.js');

module.exports = {
  env: 'prd',
  region: 'eu',
  basePath: '',
  devTools: false,
  defaultLocale: 'en',
  authServiceUrl: 'https://z2ib6nvxrj.execute-api.eu-west-1.amazonaws.com/prd',
  apiUrl: 'https://qqf2hakm5d.execute-api.eu-west-1.amazonaws.com/prd/v1',
  bffApiUrl: 'https://bff-api.eu.prd.portal.restaurant',
  defaultOneWebUrl: 'https://partner.foodora.com',
  pluginApiUrl: 'https://plugin.api.eu.prd.portal.restaurant',
  translationsUrl: 'https://d2xzidqie5hn2y.cloudfront.net/portal/{locale}.json',
  availableLanguagesUrl:
    'https://d2xzidqie5hn2y.cloudfront.net/portal/languages.json',
  reportIconsUrl: 'https://d2ypy576uxlbzp.cloudfront.net',
  vendorStatusUrl: 'https://r1yd3plfs4.execute-api.eu-west-1.amazonaws.com/prd',
  reportsApiUrl: 'https://qo27nffpmf.execute-api.eu-west-1.amazonaws.com/prd',
  helpCenterUrl: 'https://helpcenter-eu.usehurrier.com',
  gtmCode: 'GTM-T99BQGN',
  sentry: {
    project: '4',
    key: '3feecde4913b495fa2d2a5df47a5fdf2',
    tracesSampleRate: 0.2,
  },
  datadog: {
    clientToken: 'pubefe42b58dfc921b8a518b8dfa6db84cc',
    level: 'info',
  },
  fwf: {
    region: 'eu',
    environmentToken: 'b4003136-5733-4548-b5c6-d391ec657bbd',
  },
  pxAppId: 'PX24c5Soup',
  platforms: [
    {
      name: 'yemeksepeti',
      nameReadable: 'Yemeksepeti',
      defaultLocale: 'tr',
      color: '#fa0050',
      domains: ['yemeksepeti.portal.restaurant'],
      qualtrics: {
        projectId: 'ZN_38z6vphtVDVqtcG',
      },
      brandFavicon: 'yemeksepeti',
      titlePrefix: 'Yemeksepeti Partner',
      oneWebUrl: 'https://partner-app.yemeksepeti.com',
    },
    {
      name: 'foodora',
      nameReadable: 'Foodora',
      defaultLocale: 'da',
      color: 'rgb(214, 3, 101)',
      domains: ['foodora.portal.restaurant'],
      qualtrics: {
        projectId: 'ZN_aYwmYVXZggJtRQO',
      },
      brandFavicon: 'foodora',
      titlePrefix: 'Foodora Partner',
      oneWebUrl: 'https://partner.foodora.com',
    },
    {
      name: 'efood',
      nameReadable: 'efood',
      defaultLocale: 'el_GR',
      color: 'rgb(237, 46, 46)',
      domains: ['efood.portal.restaurant'],
      qualtrics: {
        projectId: 'ZN_9B9eSyMszdiRyVU',
      },
      brandFavicon: 'efood',
      titlePrefix: 'Efood Partner',
      oneWebUrl: 'https://partner-app.e-food.gr',
    },
    {
      name: 'foody',
      nameReadable: 'foody',
      defaultLocale: 'el_GR',
      color: 'rgb(237, 46, 46)',
      domains: ['foody.portal.restaurant'],
      qualtrics: {
        projectId: 'ZN_9B9eSyMszdiRyVU',
      },
      brandFavicon: 'foody',
      titlePrefix: 'Foody Partner',
      oneWebUrl: 'https://partner-app.foody.com.cy',
    },
  ],
  availableLanguages,
  brazeConfig,
  features: {
    showOnboarding: false,
  },
  supportedBrowsers,
};
