import * as React from 'react';

import { useTheme } from '@mui/material/styles';
import {
  Box,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { PartnerLogo } from '../../../components/PartnerLogo';

const TalabatPrivacy: React.FC = ({}) => {
  const theme = useTheme();

  return (
    <Container maxWidth="lg">
      <Box padding={4}>
        <Box marginBottom={2}>
          <PartnerLogo platform="talabat" width={212} height={40} />
        </Box>
        <Typography
          variant="h1"
          gutterBottom
          sx={{ color: `${theme.palette.primary.main}` }}
        >
          Privacy Policy
        </Typography>

        <Typography variant="body1" paragraph>
          If you are reading this privacy policy, this means you are a
          representative of a vendor that has entered into a legal agreement
          with the Talabat Platform entity in your country (the “Vendor”).
          Please refer below to the entity for your country.
        </Typography>
        <Box marginBottom={4}>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Typography
                      variant="subtitle1"
                      sx={{ color: `${theme.palette.primary.main}` }}
                    >
                      United Arab Emirates
                    </Typography>
                  </TableCell>
                  <TableCell>Delivery Hero Talabat DB LLC</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Typography
                      variant="subtitle1"
                      sx={{ color: `${theme.palette.primary.main}` }}
                    >
                      Kuwait
                    </Typography>
                  </TableCell>
                  <TableCell>
                    Talabat General Trading and Contracting Company WLL
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Typography
                      variant="subtitle1"
                      sx={{ color: `${theme.palette.primary.main}` }}
                    >
                      Bahrain
                    </Typography>
                  </TableCell>
                  <TableCell>Talabat Services Company WLL</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Typography
                      variant="subtitle1"
                      sx={{ color: `${theme.palette.primary.main}` }}
                    >
                      Iraq
                    </Typography>
                  </TableCell>
                  <TableCell>Talabat for Delivery Services LLC</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Typography
                      variant="subtitle1"
                      sx={{ color: `${theme.palette.primary.main}` }}
                    >
                      Oman
                    </Typography>
                  </TableCell>
                  <TableCell>
                    Talabat Electronic and Delivery Services SPC
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Typography
                      variant="subtitle1"
                      sx={{ color: `${theme.palette.primary.main}` }}
                    >
                      Qatar
                    </Typography>
                  </TableCell>
                  <TableCell>Talabat Services Company WLL</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Typography
                      variant="subtitle1"
                      sx={{ color: `${theme.palette.primary.main}` }}
                    >
                      Jordan
                    </Typography>
                  </TableCell>
                  <TableCell>
                    Foodonclick.com Private Shareholding Company
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Typography
                      variant="subtitle1"
                      sx={{ color: `${theme.palette.primary.main}` }}
                    >
                      Egypt
                    </Typography>
                  </TableCell>
                  <TableCell>Delivery Hero Egypt SAE</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Typography variant="body1" paragraph>
          Each entity is referred to individually (depending on your country of
          operation) or collectively as “Talabat” or “we
        </Typography>

        <Typography
          variant="h6"
          sx={{ color: `${theme.palette.primary.main}` }}
        >
          1. Information Collection
        </Typography>
        <Typography variant="body1" paragraph>
          We do not collect any personal data from our users.
        </Typography>
        <Typography variant="body1" paragraph>
          1.1. User Account Information: To access and use our application, you
          will need to create a user account by providing a unique username and
          password that shall be provided to you in your capacity as a
          representative of the Vendor.
        </Typography>
        <Typography variant="body1" paragraph>
          1.2. Log Data: Our application may automatically collect certain
          information when you use it, such as your device's Internet Protocol
          (IP) address, browser type, operating system, the pages or features
          you interact with, and the time and date of your use. However, this
          data is solely used for system administration purposes and does not
          identify individual users.
        </Typography>

        <Typography
          variant="h6"
          sx={{ color: `${theme.palette.primary.main}` }}
        >
          2. Use of Information
        </Typography>
        <Typography variant="body1" paragraph>
          We will use your username and password solely for the purpose of
          allowing you to access and use the application, The non-personally
          identifiable information we collect is used solely for the purpose of
          analyzing website usage patterns and trends. We use this information
          to improve our website, enhance user experience, and make informed
          decisions about our content and services. We do not link this
          information to any individual or attempt to identify specific users.
        </Typography>

        <Typography
          variant="h6"
          sx={{ color: `${theme.palette.primary.main}` }}
        >
          3. Cookies
        </Typography>
        <Typography variant="body1" paragraph>
          We use cookies on our website to collect non-personally identifiable
          information. Cookies are small text files that are stored on your
          computer or device when you visit a website. These cookies help us
          track user interactions, gather demographic information, and analyze
          website performance. You have the option to disable cookies in your
          browser settings, although this may affect certain features and
          functionality of our website.
        </Typography>

        <Typography
          variant="h6"
          sx={{ color: `${theme.palette.primary.main}` }}
        >
          4. Third-Party Services
        </Typography>
        <Typography variant="body1" paragraph>
          We may use third-party analytics services to collect and analyze
          non-personally identifiable information about website visitors. These
          services help us understand website traffic, user behavior, and usage
          patterns. These third-party services have their own privacy policies
          governing the use of information they collect.
        </Typography>

        <Typography
          variant="h6"
          sx={{ color: `${theme.palette.primary.main}` }}
        >
          5. Data Security
        </Typography>
        <Typography variant="body1" paragraph>
          We take reasonable measures to protect the information collected on
          our website from unauthorized access, disclosure, alteration, or
          destruction. However, please note that while we strive to use
          commercially acceptable means to protect your information, we cannot
          guarantee absolute security.
        </Typography>

        <Typography
          variant="h6"
          sx={{ color: `${theme.palette.primary.main}` }}
        >
          6. Data Retention
        </Typography>
        <Typography variant="body1" paragraph>
          We retain your username and password for as long as your user account
          is active.
        </Typography>

        <Typography
          variant="h6"
          sx={{ color: `${theme.palette.primary.main}` }}
        >
          7. Changes to the Privacy Policy
        </Typography>
        <Typography variant="body1" paragraph>
          We may update this privacy policy from time to time to reflect changes
          in our practices or legal requirements. We encourage you to review
          this statement periodically for any updates. Your continued use of our
          website after any modifications to the privacy statement will signify
          your acceptance of those changes.
        </Typography>

        <Typography
          variant="h6"
          sx={{ color: `${theme.palette.primary.main}` }}
        >
          8. Contact Us
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions, concerns, or requests regarding our privacy
          practices, please contact us at dpo@deliveryhero.com
        </Typography>
      </Box>
    </Container>
  );
};

export default TalabatPrivacy;
