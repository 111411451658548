import { inject, injectable } from 'inversify';
import { IConfig } from '../config';

export interface ILangObject {
  language: string;
  code: string;
}

@injectable()
export class AvailableLanguagesStore {
  @inject('config') private config: IConfig;

  availableLanguages: ILangObject[] = [];

  fetchLanguageList = async (): Promise<void> => {
    try {
      const langList = await this.fetch();

      this.availableLanguages = langList;
    } catch (error) {
      throw new Error(`AvailableLanguagesStore error: ${error}`);
    }
  };

  fetch = async (): Promise<any> => {
    try {
      const response = await fetch(this.config.availableLanguagesUrl, {
        mode: 'cors',
      });

      const data = await response.json();

      return data;
    } catch (error) {
      throw new Error(`AvailableLanguagesStore error: ${error}`);
    }
  };
}
