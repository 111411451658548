import { injectable, inject, postConstruct } from 'inversify';
import { IAvailableLanguage } from '../config';
import { QueryStringParser } from '../utils/QueryStringParser';
import i18next from 'i18next';
import { getSubtagLang } from '../utils/getSubtagLanguage';

@injectable()
export class LanguageStore {
  @inject('availableLanguages')
  private availableLanguages: IAvailableLanguage[];
  @inject('window') private window: Window;

  currentLanguage: string;

  @postConstruct() init() {
    const languagePriority = [
      i18next.language,
      i18next.options?.fallbackLng?.[0] || 'en',
    ];

    this.currentLanguage = languagePriority
      // If too specific language code does not work, try with the more generic one (only part before "_" or "-")
      // For this add also the first segment of the language code to the language priority array
      .reduce<string[]>(
        (acc, lang) => [
          ...acc,
          lang,
          getSubtagLang(lang),
          this.getGeneralLang(lang),
        ],
        [],
      )
      // Find first valid language
      .find((lang) => this.validateLanguage(lang));
  }

  setCurrentLanguage = (language: string): void => {
    this.window.localStorage.setItem('language', language);

    const { locale } = QueryStringParser.parse(this.window.location.search);
    if (locale) {
      return this.removeLocaleQueryStringAndReload();
    }

    this.window.location.reload();
  };

  private getGeneralLang = (lang: string): string =>
    lang.includes('_') ? lang.split('_')[0] : lang.split('-')[0];

  private removeLocaleQueryStringAndReload() {
    const query = QueryStringParser.parse(this.window.location.search);
    delete query.locale;
    this.window.location.search = QueryStringParser.stringify(query);
  }

  /**
   * Checks whether current language is within available languages and returns boolean
   */
  private validateLanguage(currentLanguage: string): boolean {
    return !!this.availableLanguages.find(
      (availableLanguage) => availableLanguage.value === currentLanguage,
    );
  }
}
