import { observable } from 'mobx';
import { Api, ImiddlewareOptions } from '@deliveryhero/portal-api-client';
import { injectable, inject, postConstruct } from 'inversify';
import AuthService from '../services/AuthService';
import { SessionStore } from './SessionStore';
import { VendorStore } from './VendorStore';
import defaultTokenExpiredCondition from '../utils/tokenExpiredCondition';
import GtmManager from '../utils/gtm/GtmManager';
import { Logger } from '../services/Logger';
import { TYPES } from '../types';
import { LoggerService } from '../services/LoggerService';
import GtmLoginRenewedEvent from '../utils/gtm/GtmLoginRenewedEvent';

type TokenExpiredCondition = (args: TokenExpiredConditionArgs) => boolean;
type TokenExpiredConditionArgs = {
  res: any;
  payload: any;
};

type ApiDecoratorOptions = {
  tokenExpiredCondition: TokenExpiredCondition;
  getToken: () => string;
  refresh: () => Promise<void>;
  getConnectedApis: () => Api[];
  pluginCode?: string;
  logger?: Logger;
};

/**
 * Manages instances of APIs for plugins and the webapp
 */
@injectable()
export class ApiStore {
  @observable globalApis: Api[] = [];
  @observable globalApisV2: Api[] = [];

  @inject(TYPES.GlobalApi) private globalApi: Api;
  @inject(TYPES.GlobalApiV2) private globalApiV2: Api;
  @inject('bffApiUrl') private bffApiUrl: string;

  @inject(AuthService) private authService: AuthService;
  @inject(TYPES.SessionStore) private sessionStore: SessionStore;
  @inject(TYPES.VendorStore) private vendorStore: VendorStore;
  @inject(GtmLoginRenewedEvent)
  private loginRenewedEvent: GtmLoginRenewedEvent;
  @inject(GtmManager) private gtmManager: GtmManager;
  @inject(TYPES.LoggerService) private loggerService: LoggerService;
  @inject('window') private window: any;

  @postConstruct() init() {
    this.globalApis.push(this.globalApi);
    this.globalApisV2.push(this.globalApiV2);

    this.decorateApi(this.globalApi, {
      ...this.getGlobalApiDecoratorOptions(defaultTokenExpiredCondition),
      logger: this.loggerService.getGlobalLogger(),
    });
    this.decorateApi(this.globalApiV2, {
      ...this.getGlobalApiV2DecoratorOptions(defaultTokenExpiredCondition),
      logger: this.loggerService.getGlobalLogger(),
    });
  }

  createGlobalApi(
    tokenExpiredCondition: TokenExpiredCondition = defaultTokenExpiredCondition,
    logger?: Logger,
    pluginCode?: string,
  ) {
    const api = this.createApi({
      ...this.getGlobalApiDecoratorOptions(tokenExpiredCondition),
      logger,
      pluginCode,
    });

    this.globalApis.push(api);

    return api;
  }

  createGlobalApiV2(
    tokenExpiredCondition: TokenExpiredCondition = defaultTokenExpiredCondition,
    logger?: Logger,
    pluginCode?: string,
  ) {
    const api = this.createApi({
      ...this.getGlobalApiV2DecoratorOptions(tokenExpiredCondition),
      logger,
      pluginCode,
    });

    this.globalApisV2.push(api);

    return api;
  }

  private createApi(options: ApiDecoratorOptions) {
    const api = new Api(options.tokenExpiredCondition);
    this.decorateApi(api, options);
    return api;
  }

  /**
   * Add middleware to API that adds `Authorization` header and subscribes to responses to handle expired condition
   * @param api instance of the API to decorate with middleware and subscription
   * @param options Options for the token (like expiredCondition etc., described by `ApiDecoratorOptions`)
   */
  private decorateApi(
    api: Api,
    {
      getToken,
      refresh,
      getConnectedApis,
      pluginCode = 'global',
      logger,
    }: ApiDecoratorOptions,
  ) {
    const apiMiddleware = this.apiRequestMiddleware(getToken);
    const addPxCustomParamsMiddleware = this.addPxCustomHeaders();
    api.subscribe(({ isTokenExpired }) =>
      this.handleApiSubscription(isTokenExpired, refresh, getConnectedApis),
    );

    api.use(apiMiddleware);

    api.use(addPxCustomParamsMiddleware);

    api.subscribe(({ res }) => {
      pluginCode = pluginCode.toLowerCase();

      if (this.window.performance) {
        // Push API load time for 1% of the requests
        if (this.apiSamplingPercentage(1)) {
          const apiEntry = this.window.performance.getEntriesByName(res.url);

          if (apiEntry && apiEntry.length) {
            this.gtmManager.pushEvent(`${pluginCode}.api_loaded`, {
              timingValue: Math.round(apiEntry[apiEntry.length - 1].duration),
              timingLabel: res.url,
            });
          }
        }
      }

      // Log internal API errors
      if (res.status > 499 && res.status < 510) {
        if (logger?.error) {
          logger.error(`${pluginCode}.internal_api_errored`, { url: res.url });
        }
        this.gtmManager.pushEvent(`${pluginCode}.internal_api_errored`, {
          eventLabel: res.url,
        });
      }
    });
  }

  private apiSamplingPercentage(percent: number) {
    return Math.floor(Math.random() * 100) < percent;
  }

  /**
   * Add `Authorization` header to requests
   * @param getToken function to receive the current token
   */
  private apiRequestMiddleware(getToken: () => string) {
    return ({ url, init, ...rest }): ImiddlewareOptions => {
      const token = getToken();
      return {
        url,
        init: {
          ...init,
          headers: {
            ...init.headers,
            Authorization: token ? `Bearer ${token}` : undefined,
          },
        },
        ...rest,
      };
    };
  }

  /**
   * Add PerimeterX header to requests for creating custom rules
   */
  private addPxCustomHeaders() {
    return ({ url, init, ...rest }): ImiddlewareOptions => {
      // Custom headers are allowed only for these endpoints
      const isBffService = url.includes(this.bffApiUrl);

      const customHeaders = {
        ...(this.sessionStore?.mainSessionInfo?.user?.userId && {
          'X-User-Id': this.sessionStore?.mainSessionInfo?.user?.userId,
        }),
        ...(this.sessionStore?.mainSessionInfo?.user?.country && {
          'X-Country': this.sessionStore?.mainSessionInfo?.user?.country,
        }),
        ...(this.vendorStore?.selectedVendorIds?.length !== 0 && {
          'X-Selected-Vendor-Ids': this.vendorStore.selectedVendorIds,
        }),
        ...(this.vendorStore?.currentVendorId && {
          'X-Current-Vendor-Id': this.vendorStore.currentVendorId,
        }),
        // X-App-Name and X-App-Version are always send
        'X-App-Version': process.env.COMMIT_HASH,
        'X-App-Name': 'vendor-portal',
      };

      return {
        url,
        init: {
          ...init,
          headers: {
            ...init.headers,
            ...(isBffService && customHeaders),
          },
        },
        ...rest,
      };
    };
  }

  /**
   * Handle token refresh when expired condition is met
   */
  private handleApiSubscription(
    isTokenExpired: boolean,
    refresh: () => Promise<void>,
    getConnectedApis: () => Api[],
  ): Promise<void> {
    if (!isTokenExpired) {
      return Promise.resolve();
    }

    const connectedApis = getConnectedApis();

    // Set all related Apis offline, as they all share the same expired token
    connectedApis.forEach((connectedApi) => connectedApi.setOffline());
    return refresh().then(
      // Set all Apis back online and flush all queued requests
      async () => {
        await this.loginRenewedEvent.pushEvent();
        connectedApis.forEach((connectedApi) => connectedApi.setOnline());
      },
      () => {
        // Refreshing failed, clear the queue and set the api back online for a fresh start
        connectedApis.forEach((connectedApi) => connectedApi.clearQueue());
        connectedApis.forEach((connectedApi) => connectedApi.setOnline());
      },
    );
  }

  private getGlobalApiDecoratorOptions(
    tokenExpiredCondition: TokenExpiredCondition,
  ) {
    return {
      tokenExpiredCondition,
      getToken: () => this.sessionStore.mainSessionInfo?.accessToken,
      refresh: () => this.authService.refreshToken(),
      getConnectedApis: () => this.globalApis,
    };
  }

  private getGlobalApiV2DecoratorOptions(
    tokenExpiredCondition: TokenExpiredCondition,
  ) {
    return {
      tokenExpiredCondition,
      getToken: () => this.authService.getAuthToken().accessToken,
      refresh: () => this.authService.refreshToken(),
      getConnectedApis: () => this.globalApisV2,
    };
  }
}
