import { withDependencies } from '@deliveryhero/react-inversify-provider';
import AuthService from '../../services/AuthService';
import HelpCenterContainer from './HelpCenterContainer';
import GtmManager from '../../utils/gtm/GtmManager';
import { TYPES } from '../../types';
import { observer } from 'mobx-react';

const HelpCenter = withDependencies({
  authService: AuthService,
  gtmManager: GtmManager,
  helpStore: TYPES.HelpStore,
  sessionStore: TYPES.SessionStore,
})(observer(HelpCenterContainer));

export default HelpCenter;
