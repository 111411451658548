import * as queryString from 'query-string';
import * as React from 'react';

const routeComponentFactory =
  (Component, customProps = {}) =>
  (routerProps) => {
    routerProps.location.search = queryString.parse(
      routerProps.location.search,
    );
    const props = {
      ...routerProps,
      ...customProps,
    };
    return <Component {...props} />;
  };

export default routeComponentFactory;
