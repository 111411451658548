import * as React from 'react';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { PasswordField } from '../PasswordField';
import { Translate, translate } from '../Translate';
import { FormHelperTextProps } from '@mui/material/FormHelperText';

export type LoginFormProps = {
  email: string;
  password: string;
  isSubmitting: boolean;
  onSubmit: () => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  getError: (key: string) => JSX.Element | string;
};

const LoginForm: React.FC<LoginFormProps> = ({
  email,
  password,
  isSubmitting,
  onSubmit,
  onChange,
  getError,
}) => {
  return (
    <form style={{ marginTop: '25px' }} id="login" onSubmit={onSubmit}>
      <FormControl fullWidth={true}>
        <TextField
          id="login-email-field"
          type="email"
          name="email"
          value={email}
          data-testid="login-email-field"
          label={translate({ code: 'global.login.email' })}
          onChange={onChange}
          fullWidth={true}
          helperText={getError('email')}
          FormHelperTextProps={
            { 'data-testid': 'login-email-helpertext' } as Partial<
              FormHelperTextProps<'p', {}>
            >
          }
        />
      </FormControl>

      <FormControl
        sx={{
          marginTop: '8px',
        }}
        fullWidth={true}
      >
        <PasswordField
          name="password"
          value={password}
          label={translate({ code: 'global.login.password' })}
          onChange={onChange}
          error={!!getError('password')}
          id="login-password-field"
          data-testid="login-password-field"
          fullWidth={true}
          helperText={getError('password')}
          FormHelperTextProps={
            { 'data-testid': 'login-password-helpertext' } as Partial<
              FormHelperTextProps<'p', {}>
            >
          }
        />
      </FormControl>

      <Button
        sx={{
          marginTop: '14px',
          marginBottom: '10px',
        }}
        id="button_login"
        data-testid="login-button"
        fullWidth={true}
        type="submit"
        variant="contained"
        color="primary"
        disabled={isSubmitting}
      >
        <Translate
          code={
            isSubmitting
              ? 'global.login.submit_button_login_in'
              : 'global.login.submit_button'
          }
        />
      </Button>
    </form>
  );
};

export default LoginForm;
