import { inject, injectable } from 'inversify';
import GtmManager from './GtmManager';

@injectable()
export default class GtmVirtualPageViewEvent {
  @inject(GtmManager)
  gtmManager: GtmManager;

  async pushEvent(pageType: string, page: string, data?: {}): Promise<void> {
    await this.gtmManager.pushEvent('virtualPageView', {
      ...data,
      pageType: pageType,
      page: page,
    });
  }
}
