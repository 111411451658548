import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Translate } from '@deliveryhero/vendor-portal-components';
import { forwardRef } from 'react';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';
import GtmManager from '../../utils/gtm/GtmManager';

interface UserMigrationBannerProps {
  brandName: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  gtmManager: GtmManager;
}

const UserMigrationBannerWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[50],
  boxSizing: 'border-box',
  display: 'grid',
  columnGap: '12px',
  gridTemplateColumns: 'min-content 1fr max-content',
  gridTemplateRows: 'auto auto',
  gridTemplateAreas: `
    "icon headline button"
    "icon invite button"
  `,
  padding: '20px',
  width: '100%',
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 11,
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: 'min-content 1fr',
    gridTemplateRows: 'auto auto auto',
    gridTemplateAreas: `
      "icon headline"
      "icon invite"
      "icon button"
    `,
  },
}));

export const UserMigrationBanner = forwardRef<
  HTMLDivElement,
  UserMigrationBannerProps
>((props, ref) => {
  React.useEffect(() => {
    props.gtmManager.pushEvent('dialog_display', {
      section: 'main.home',
      subsection: 'migration_banner',
      timestamp: Date.now(),
    });
  }, []);

  const handleOnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    props.gtmManager.pushEvent('button_tap', {
      section: 'main.home',
      subsection: 'migration_banner',
      timestamp: Date.now(),
    });

    props.onClick(e);
  };

  return (
    <UserMigrationBannerWrapper
      role="alert"
      id="oneweb-migration-banner"
      data-testid="oneweb-migration-banner"
      ref={ref}
    >
      <Box gridArea="icon">
        <Icon>rocket_launch</Icon>
      </Box>
      <Box gridArea="headline">
        <Typography variant="paragraph-600">
          <Translate
            code="global.oneweb_migration_banner.headline"
            params={{
              brandName:
                props.brandName?.charAt(0).toUpperCase() +
                  props.brandName?.slice(1) ?? '',
            }}
          />
        </Typography>
      </Box>
      <Box gridArea="invite">
        <Typography variant="paragraph-500" color="secondary">
          <Translate code="global.oneweb_migration_banner.invite" />
        </Typography>
      </Box>
      <Box gridArea="button">
        <Button
          color="primary"
          variant="contained"
          onClick={handleOnClick}
          data-testid="try-now-btn"
          sx={{ marginTop: { xs: '12px', md: 0 } }}
        >
          <Translate code="global.oneweb_migration_banner.try_now" />
        </Button>
      </Box>
    </UserMigrationBannerWrapper>
  );
});
