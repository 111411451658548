import { withDependencies } from '@deliveryhero/react-inversify-provider';
import { PlatformStore } from '../../stores/PlatformStore';
import { ResetPasswordContainer } from './ResetPasswordContainer';
import AuthService from '../../services/AuthService';

const ResetPassword = withDependencies({
  platformStore: PlatformStore,
  authService: AuthService,
})(ResetPasswordContainer);

export default ResetPassword;
