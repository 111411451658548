import { UtilsDateRange } from '@deliveryhero/vendor-portal-components';
import { observable, action } from 'mobx';
import { injectable } from 'inversify';

@injectable()
export default class MasterDateStore {
  @observable
  currentDateRange: UtilsDateRange.IDateRange =
    UtilsDateRange.predefinedDateRanges.LAST_7_DAYS.clone({
      code: 'CURRENT_WEEK',
    });

  @action setCurrentDateRange(newDateRange: UtilsDateRange.IDateRange) {
    this.currentDateRange = newDateRange;
  }
}
