import * as React from 'react';
import PasswordHandlingContainer from '../PasswordHandlingContainer';
import { PasswordHandlingContainerProps } from '../PasswordHandlingContainer/PasswordHandlingContainer';
import AuthService from '../../services/AuthService';
import { SessionStore } from '../../stores/SessionStore';
import GtmManager from '../../utils/gtm/GtmManager';
import { CaptchaHttpContextProvider } from '@deliveryhero/captcha';

type ResetPasswordContainerProps = PasswordHandlingContainerProps & {
  authService: AuthService;
  sessionStore: SessionStore;
  skipIntro?: boolean;
  isForceReset?: boolean;
  gtmManager: GtmManager;
};

const resetPassword = (authService: AuthService) => {
  return (token: string, newPassword: string) => {
    return authService.resetPassword(token, newPassword);
  };
};
const ResetPasswordContainer: React.FC<ResetPasswordContainerProps> = (
  props,
) => {
  return (
    <CaptchaHttpContextProvider>
      <PasswordHandlingContainer
        translationPrefix="set_password"
        submit={resetPassword(props.authService)}
        skipIntro={props.skipIntro || false}
        isForceReset={props.isForceReset}
        {...props}
      />
    </CaptchaHttpContextProvider>
  );
};

export default ResetPasswordContainer;
