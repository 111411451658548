import * as React from 'react';
import { withDependencies } from '@deliveryhero/react-inversify-provider';
import StartPrompt from './StartPrompt';
import { LanguageStore } from '../../stores/LanguageStore';
import { IPlatform } from '@deliveryhero/vendor-portal-components/dist/interfaces/IPlatform';

export type StartPromptProps = {
  platform: IPlatform;
  RegistrationView?: React.ReactNode;
};

const StartPromptContainer = withDependencies({
  languageStore: LanguageStore,
  availableLanguages: 'availableLanguages',
})(StartPrompt) as React.ComponentType<StartPromptProps>;

export default StartPromptContainer;
