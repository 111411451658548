import * as React from 'react';
import Typography from '@mui/material/Typography';

interface Props {
  children: React.ReactNode;
}

const SubHeaderText: React.FC<Props> = ({ children }) => {
  return (
    <Typography
      variant="secondary-400"
      sx={{ color: '#6B6B6B', textAlign: 'center', mb: '20px' }}
    >
      {children}
    </Typography>
  );
};

export default SubHeaderText;
