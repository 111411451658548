import { Typography, styled } from '@mui/material';
import { getSpacing } from '@deliveryhero/vt-portal-chardonnay/core';

export const Title = styled(Typography)(() => ({
  fontSize: '2.625rem',
  lineHeight: '1.2em',
  fontWeight: 300,
  marginBottom: getSpacing('spacing-8'),
}));

export const ListItem = styled('li')(() => ({
  marginTop: getSpacing('spacing-8'),
}));
