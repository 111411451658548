import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Link, Typography, ListItem, ListItemButton } from '@mui/material';

import {
  getNeutralColor,
  useIsMobile,
} from '@deliveryhero/vt-portal-chardonnay/cjs/core/helpers';

// components
import {
  StyledPluginMenuWrapper,
  PluginMenu,
} from './PluginMenuWrapper.styles';
import { Translate } from '../../../components/Translate';
import PluginSvgIcon from '../../../components/PluginSvgIcon/PluginSvgIcon';

// utils, constants, enums
import { Plugin } from '../../../models/Plugin';
import { getSidebarOverlayPosition } from '../../../utils/overlay';
import { SCROLLABLE_AREA_OFFSET, NO_TOP_OVERLAY } from '../../../utils/overlay';

const ONBOARDING_PLUGIN_CODE = 'ONBOARDING';

type PluginMenuWrapperProps = {
  menuPlugins: Plugin[];
  showOnboarding: boolean;
  redirectToOnboarding: boolean;
};

const PluginMenuWrapper: React.FC<PluginMenuWrapperProps> = ({
  menuPlugins,
  showOnboarding,
  redirectToOnboarding,
}) => {
  const isMobile = useIsMobile();
  const navbarRef = React.useRef(null);
  const [categorizedMenuPlugins, setCategorizedMenuPlugins] = React.useState<
    Map<string, Array<Plugin>>
  >(new Map());
  const [overlayPosition, setOverlayPosition] = React.useState<{
    top: boolean;
    bottom: boolean;
  }>({ top: false, bottom: false });

  const handleScroll = () => {
    const {
      clientHeight,
      scrollHeight,
      scrollTop: scrolledArea,
    } = navbarRef.current;
    const scrollableArea = scrollHeight - clientHeight;

    // Compute only if scrollable area is more than SCROLLABLE_AREA_OFFSET
    if (scrollableArea > SCROLLABLE_AREA_OFFSET) {
      setOverlayPosition(
        getSidebarOverlayPosition({
          scrollableArea,
          scrolledArea,
          condition: NO_TOP_OVERLAY,
        }),
      );
    }
  };

  React.useEffect(() => {
    if (menuPlugins && menuPlugins.length > 0) {
      // NOTE: filter out ONBOARDING plugin if it is not needed
      const filteredListOfPlugins = showOnboarding
        ? menuPlugins
        : menuPlugins.filter(
            (plugin) => plugin.code !== ONBOARDING_PLUGIN_CODE,
          );

      const onboardingPlugin = menuPlugins.find(
        (plugin) => plugin.code === ONBOARDING_PLUGIN_CODE,
      );

      // NOTE: mount only onboarding plugin if it is required
      const mountOnlyOnboarding = redirectToOnboarding && onboardingPlugin;
      const listOfPluginsAfterOnboardingCheck = mountOnlyOnboarding
        ? [onboardingPlugin]
        : filteredListOfPlugins;

      // Create Map to group plugins based on their plugin-key; default: `global.menu.category.manage`
      const categorizedMap: Map<string, Array<Plugin>> = new Map();
      listOfPluginsAfterOnboardingCheck.forEach((plugin: Plugin) => {
        const groupKey = plugin.groupKey;
        const currentCategoryPlugins = categorizedMap.get(groupKey);
        if (currentCategoryPlugins) {
          currentCategoryPlugins.push(plugin);
        } else {
          categorizedMap.set(groupKey, [plugin]);
        }
      });
      setCategorizedMenuPlugins(categorizedMap);
    }
  }, [menuPlugins]);

  React.useEffect(() => {
    if (navbarRef.current) {
      handleScroll();
      navbarRef.current.addEventListener('scroll', handleScroll);

      return () => {
        if (navbarRef.current) {
          navbarRef.current.removeEventListener('scroll', handleScroll);
        }
      };
    }
  }, [navbarRef.current]);

  return (
    <StyledPluginMenuWrapper
      data-testid="plugin-menu-wrapper"
      overlayPosition={overlayPosition}
    >
      <PluginMenu id="plugin-menu" ref={navbarRef} isMobile={isMobile}>
        {categorizedMenuPlugins.size > 0 &&
          Object.entries(
            Object.fromEntries(categorizedMenuPlugins.entries()),
          ).map(([category, plugins]) => (
            <Box
              data-testid={category}
              key={category}
              sx={{ marginBottom: '16px' }}
            >
              <Box
                sx={{
                  padding: '12px 0px 12px 24px',
                }}
              >
                <Typography
                  variant="caption-400"
                  sx={{
                    fontWeight: 500,
                    color: getNeutralColor('gray-50'),
                  }}
                >
                  <Translate code={category} />
                </Typography>
              </Box>

              {plugins.map(({ code, route, iconKey }) => (
                <Link
                  key={code}
                  underline="none"
                  data-testid={`link-${code.toLowerCase()}`}
                  component={RouterLink}
                  to={route}
                  role="plugin-list-item"
                >
                  <ListItem>
                    <ListItemButton
                      selected={location.pathname.startsWith(
                        route.split('?')[0],
                      )}
                      data-testid={`nav-item-${code.toLowerCase()}`}
                      sx={{
                        borderRadius: '0 8px 8px 0',
                        marginRight: '24px',
                        padding: '8px 0px 8px 24px',
                        '&:hover': {
                          '& .MuiTypography-root': {
                            color: getNeutralColor('black'),
                          },
                        },
                        '& .MuiTypography-root': {
                          color: getNeutralColor('gray-50'),
                        },
                        '&.Mui-selected': {
                          '& .MuiTypography-root': {
                            color: getNeutralColor('black'),
                          },
                        },
                      }}
                    >
                      <Box sx={{ marginRight: '16px' }}>
                        <PluginSvgIcon
                          iconKey={iconKey || code.toLowerCase()}
                          color={getNeutralColor('black')}
                        />
                      </Box>
                      <Box>
                        <Typography variant="secondary-400">
                          <Translate
                            code={`global.menu.${code.toLowerCase()}`}
                          />
                        </Typography>
                      </Box>
                    </ListItemButton>
                  </ListItem>
                </Link>
              ))}
            </Box>
          ))}
      </PluginMenu>
    </StyledPluginMenuWrapper>
  );
};

export default PluginMenuWrapper;
