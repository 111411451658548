/* eslint-disable max-len */
import * as React from 'react';

export const FoodpandaPartnerLogo: React.FC = () => (
  <svg
    data-testid="platform-logo-svg"
    width="212"
    height="48"
    viewBox="0 0 212 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M63.5454 36L67.7375 12H150.818L146.657 36H63.5454Z"
      fill="#FF2B85"
    />
    <path
      d="M91.1993 23.28C91.2291 23.1008 91.2476 22.9627 91.2552 22.8655C91.2626 22.7688 91.2665 22.6753 91.2665 22.5857C91.2665 22.0632 91.0986 21.6899 90.7627 21.466C90.4268 21.2423 89.9302 21.1301 89.2734 21.1301C88.7507 21.1301 88.2095 21.231 87.6496 21.4325C87.0898 21.6341 86.5934 21.8915 86.1603 22.2052L85.1525 20.4361C85.7644 20.0032 86.4625 19.6633 87.2465 19.417C88.0301 19.1704 88.8326 19.0474 89.6539 19.0474C90.1613 19.0474 90.6651 19.1035 91.1654 19.2152C91.6655 19.3274 92.1133 19.5103 92.5093 19.7638C92.9046 20.0179 93.222 20.3499 93.4609 20.7604C93.6997 21.1714 93.8193 21.6823 93.8193 22.2946C93.8193 22.429 93.8114 22.5933 93.7966 22.7873C93.7816 22.9816 93.7592 23.1534 93.7295 23.3023L92.9456 27.6693C92.8709 28.0874 92.8074 28.5203 92.7554 28.9682C92.703 29.4163 92.6771 29.7894 92.6771 30.0879H90.2585C90.2585 29.6848 90.3033 29.2593 90.3929 28.8114H90.3481C89.5117 29.812 88.3471 30.3119 86.8545 30.3119C86.4812 30.3119 86.0931 30.2671 85.69 30.1775C85.2869 30.0879 84.921 29.9351 84.5927 29.7185C84.2641 29.5022 83.9954 29.2111 83.7865 28.8449C83.5773 28.4792 83.4731 28.0202 83.4731 27.4678C83.4731 26.587 83.7082 25.8743 84.1785 25.329C84.6488 24.7841 85.2569 24.3586 86.0037 24.0525C86.7502 23.7468 87.5827 23.5413 88.5006 23.4367C89.4188 23.3326 90.3181 23.28 91.1993 23.28ZM90.6394 25.1609C90.1315 25.1609 89.6054 25.1798 89.0605 25.217C88.5152 25.2544 88.0151 25.34 87.5601 25.4746C87.1046 25.609 86.7315 25.7992 86.4405 26.0456C86.1496 26.2919 86.0037 26.6244 86.0037 27.0423C86.0037 27.2962 86.052 27.5088 86.1494 27.6806C86.2462 27.8524 86.3768 27.9907 86.5412 28.0948C86.7054 28.1994 86.8919 28.274 87.1011 28.3187C87.31 28.3635 87.5266 28.3859 87.7505 28.3859C88.1536 28.3859 88.5304 28.3187 88.8813 28.1844C89.2318 28.05 89.5382 27.8674 89.7997 27.6358C90.0606 27.4045 90.2772 27.1357 90.449 26.8296C90.6205 26.5237 90.7436 26.1913 90.8184 25.8329L90.9527 25.1611H90.6394V25.1609Z"
      fill="white"
    />
    <path
      d="M99.4025 21.1301C99.7756 20.503 100.216 19.9992 100.724 19.6185C101.231 19.2378 101.843 19.0474 102.56 19.0474C102.664 19.0474 102.776 19.0513 102.896 19.0587C103.015 19.0663 103.12 19.085 103.209 19.1148L102.851 21.5558C102.687 21.511 102.541 21.4812 102.414 21.4662C102.287 21.4516 102.164 21.4438 102.045 21.4438C101.433 21.4438 100.933 21.5558 100.545 21.7797C100.156 22.0037 99.8426 22.2724 99.6041 22.5859C99.3649 22.8992 99.1931 23.2206 99.089 23.5489C98.9844 23.8776 98.9172 24.139 98.8874 24.3327L97.8797 30.0881H95.2593L96.7597 21.6454C96.8191 21.3618 96.8828 20.9959 96.9502 20.548C97.0174 20.0999 97.0654 19.697 97.0956 19.3387H99.559C99.559 19.4137 99.5514 19.5255 99.5367 19.6748C99.5217 19.8242 99.503 19.9882 99.4806 20.1675C99.4579 20.3467 99.4358 20.5224 99.4134 20.6937C99.3908 20.8657 99.3721 21.0111 99.3573 21.1305H99.4021L99.4025 21.1301Z"
      fill="white"
    />
    <path
      d="M103.502 21.488L103.883 19.3379H105.742L106.279 16.2026H108.877L108.339 19.3379H110.758L110.377 21.488H108.004L107.063 26.5155C107.018 26.7379 106.996 26.9458 106.996 27.1384C106.996 27.5239 107.111 27.7909 107.343 27.9394C107.574 28.0879 107.884 28.1614 108.272 28.1614C108.451 28.1614 108.627 28.1488 108.798 28.1233C108.97 28.0977 109.13 28.0655 109.28 28.0272L109.123 30.0204C108.899 30.1097 108.634 30.1804 108.328 30.2332C108.022 30.2852 107.742 30.3115 107.488 30.3115C106.354 30.3115 105.548 30.0843 105.07 29.6301C104.592 29.1759 104.353 28.5764 104.353 27.8318C104.353 27.6233 104.365 27.396 104.387 27.1504C104.409 26.9047 104.443 26.6777 104.488 26.4692L105.406 21.4878H103.502V21.488Z"
      fill="white"
    />
    <path
      d="M114.836 21.0181C115.209 20.4358 115.683 19.9621 116.258 19.5959C116.833 19.2302 117.523 19.0474 118.329 19.0474C118.927 19.0474 119.446 19.1369 119.886 19.3161C120.326 19.4953 120.692 19.7342 120.983 20.0327C121.274 20.3315 121.491 20.6861 121.633 21.0966C121.774 21.5075 121.845 21.9363 121.845 22.3844C121.845 22.7577 121.815 23.0936 121.756 23.3921L120.569 30.0881H117.949L118.979 24.176C119.009 23.997 119.035 23.8179 119.057 23.6385C119.08 23.4593 119.091 23.2804 119.091 23.101C119.091 22.6233 118.964 22.2015 118.71 21.8358C118.456 21.4699 117.993 21.2873 117.322 21.2873C116.888 21.2873 116.508 21.3694 116.179 21.5336C115.851 21.698 115.564 21.9182 115.317 22.1941C115.071 22.4705 114.869 22.7803 114.713 23.1234C114.556 23.4672 114.447 23.8176 114.388 24.176L113.335 30.0881H110.715L112.193 21.6454C112.252 21.3618 112.316 20.9959 112.384 20.548C112.451 20.0999 112.499 19.697 112.529 19.3387H114.992C114.977 19.5033 114.948 19.7607 114.903 20.1114C114.858 20.4624 114.821 20.7648 114.791 21.0185H114.836L114.836 21.0181Z"
      fill="white"
    />
    <path
      d="M126.024 25.5416C126.009 25.6166 126.002 25.6984 126.002 25.7879C126.002 26.1462 126.072 26.4748 126.215 26.7733C126.356 27.0723 126.554 27.3297 126.808 27.546C127.062 27.7626 127.36 27.9343 127.704 28.0611C128.047 28.1881 128.42 28.2513 128.823 28.2513C129.51 28.2513 130.096 28.1248 130.581 27.8706C131.066 27.6169 131.503 27.2738 131.891 26.8407L133.392 28.2513C132.063 29.6848 130.443 30.4015 128.532 30.4015C127.8 30.4015 127.128 30.293 126.516 30.0766C125.904 29.8605 125.374 29.5505 124.926 29.1473C124.479 28.7442 124.128 28.2481 123.874 27.658C123.62 27.0683 123.493 26.3928 123.493 25.6314C123.493 24.7058 123.65 23.8435 123.963 23.0449C124.277 22.2463 124.714 21.5484 125.273 20.9509C125.833 20.3541 126.497 19.8873 127.267 19.5511C128.035 19.2152 128.875 19.0474 129.786 19.0474C130.428 19.0474 131.021 19.1369 131.566 19.3161C132.111 19.4953 132.585 19.764 132.988 20.1223C133.391 20.4806 133.705 20.9285 133.929 21.466C134.153 22.0037 134.265 22.6305 134.265 23.3471C134.265 23.5863 134.242 23.9181 134.197 24.3436C134.153 24.7691 134.085 25.1687 133.996 25.5418H126.023L126.024 25.5416ZM131.847 23.6607C131.861 23.5563 131.873 23.4589 131.88 23.3693C131.888 23.28 131.892 23.1904 131.892 23.1008C131.892 22.5183 131.686 22.0259 131.276 21.6227C130.865 21.2196 130.286 21.0181 129.54 21.0181C129.077 21.0181 128.659 21.1003 128.286 21.2644C127.913 21.429 127.588 21.6417 127.312 21.9028C127.036 22.1641 126.808 22.4513 126.629 22.7651C126.45 23.0784 126.33 23.3774 126.271 23.6609H131.847V23.6607Z"
      fill="white"
    />
    <path
      d="M139.695 21.1301C140.068 20.503 140.508 19.9992 141.016 19.6185C141.523 19.2378 142.136 19.0474 142.852 19.0474C142.956 19.0474 143.069 19.0513 143.188 19.0587C143.307 19.0663 143.412 19.085 143.502 19.1148L143.144 21.5558C142.979 21.511 142.833 21.4812 142.707 21.4662C142.58 21.4516 142.456 21.4438 142.337 21.4438C141.725 21.4438 141.225 21.5558 140.837 21.7797C140.449 22.0037 140.135 22.2724 139.896 22.5859C139.657 22.8992 139.485 23.2206 139.381 23.5489C139.277 23.8776 139.209 24.139 139.18 24.3327L138.172 30.0881H135.552L137.052 21.6454C137.111 21.3618 137.175 20.9959 137.242 20.548C137.31 20.0999 137.358 19.697 137.388 19.3387H139.851C139.851 19.4137 139.844 19.5255 139.829 19.6748C139.814 19.8242 139.795 19.9882 139.773 20.1675C139.75 20.3467 139.728 20.5224 139.706 20.6937C139.683 20.8657 139.664 21.0111 139.65 21.1305H139.694L139.695 21.1301Z"
      fill="white"
    />
    <path
      d="M71.7722 34.0769L72.7304 28.3643H72.7752C73.0887 28.9978 73.5479 29.4957 74.1526 29.858C74.7572 30.2198 75.5524 30.4011 76.5377 30.4011C77.4033 30.4011 78.1984 30.2252 78.9227 29.8747C79.6467 29.5242 80.2698 29.0461 80.7927 28.4415C81.3152 27.8368 81.722 27.1315 82.0133 26.3253C82.3045 25.5191 82.4499 24.6535 82.4499 23.7275C82.4499 23.0411 82.3414 22.4138 82.1253 21.8463C81.9088 21.2793 81.6026 20.7905 81.2071 20.3796C80.8114 19.9691 80.3335 19.6517 79.7737 19.4277C79.214 19.204 78.5904 19.0918 77.9036 19.0918C77.0974 19.0918 76.3586 19.2636 75.6867 19.6069C75.0149 19.9504 74.4622 20.4357 74.0295 21.0625H73.9621L74.2309 19.3379H71.7451L69.1418 34.0769H71.7722ZM73.6486 23.8421C73.8425 23.3289 74.1076 22.8789 74.4435 22.4919C74.7794 22.1049 75.1786 21.7996 75.6417 21.5765C76.1042 21.3532 76.5971 21.2417 77.1198 21.2417C77.9708 21.2417 78.6237 21.5095 79.0794 22.0453C79.5347 22.581 79.7626 23.2435 79.7626 24.0321C79.7626 24.6126 79.6765 25.1597 79.5049 25.673C79.3329 26.1866 79.0866 26.6331 78.7659 27.0123C78.4445 27.3917 78.0567 27.6931 77.6016 27.9162C77.1459 28.1395 76.6421 28.251 76.0898 28.251C75.2534 28.251 74.5896 27.9907 74.0967 27.4696C73.604 26.9488 73.3579 26.2792 73.3579 25.4604C73.3579 24.8948 73.4547 24.3558 73.649 23.8421H73.6486Z"
      fill="white"
    />
    <path
      d="M0 18.57H2.25468L2.48015 19.5578L2.63039 19.6813L2.75563 19.5578C3.50726 18.817 4.75984 18.3232 6.01242 18.3232C9.26921 18.3232 12.0251 20.7923 12.0251 24.3728C12.0251 27.9534 9.26921 30.4224 6.01242 30.4224C4.78484 30.4224 3.50726 29.9287 2.75563 29.1879L2.63039 29.0644L2.48015 29.1879V35.1238H0V18.57ZM5.8874 28.1999C8.26732 28.1999 9.5199 26.4714 9.5199 24.3726C9.5199 22.2738 8.26732 20.5453 5.8874 20.5453C3.75774 20.5453 2.25468 22.2738 2.25468 24.3726C2.25468 26.4714 3.75774 28.1999 5.8874 28.1999Z"
      fill="#FF2B85"
    />
    <path
      d="M18.3358 22.9697H20.3874V22.4583C20.3874 21.0637 19.4208 20.4128 18.0764 20.4128C16.9917 20.4128 16.2605 20.9242 15.8596 21.9702L13.6192 21.5053C14.1145 19.5296 15.8361 18.2744 18.0999 18.2744C21.2602 18.2744 22.8402 19.8086 22.8402 22.8999V30.1751H20.7649L20.5292 28.641C19.7036 29.7798 18.4774 30.361 16.8502 30.361C14.7514 30.361 13.1948 29.1291 13.1948 26.8048C13.1948 24.4108 15.2467 22.9697 18.3358 22.9697ZM17.2748 28.2226C18.1001 28.2226 18.8313 27.9669 19.4445 27.4556C20.0813 26.9209 20.3876 26.2004 20.3876 25.2474V24.9917H18.2417C16.7323 24.9917 15.7891 25.689 15.7891 26.8046C15.7891 27.6412 16.3079 28.2224 17.2748 28.2224V28.2226Z"
      fill="#FF2B85"
    />
    <path
      d="M25.1521 18.57H27.4068L27.6573 19.5578L27.7825 19.6813L27.9077 19.5578C28.4089 18.817 29.6615 18.3232 30.6636 18.3232C33.6699 18.3232 35.4236 20.2985 35.4236 23.0148V30.1754H32.9183V23.0146C32.843 21.4344 32.0664 20.5453 30.4131 20.5453C28.9348 20.5453 27.7075 21.7305 27.6573 23.1627V30.1752H25.1521V18.57Z"
      fill="#FF2B85"
    />
    <path
      d="M49.0636 30.1754H46.8089L46.5585 29.1877L46.4332 29.0642L46.308 29.1877C45.5564 29.9284 44.3038 30.4222 43.0512 30.4222C39.7944 30.4222 37.0386 27.9531 37.0386 24.3726C37.0386 20.7921 39.7944 18.323 43.0512 18.323C44.2788 18.323 45.5564 18.8168 46.308 19.5575L46.4332 19.681L46.5585 19.5575V13.3638H49.0636V30.1754ZM43.1762 20.5455C40.7963 20.5455 39.5185 22.274 39.5185 24.3728C39.5185 26.4716 40.7963 28.2001 43.1762 28.2001C45.3059 28.2001 46.8089 26.4716 46.8089 24.3728C46.8089 22.274 45.3059 20.5455 43.1762 20.5455Z"
      fill="#FF2B85"
    />
    <path
      d="M56.1966 22.9697H58.2483V22.4583C58.2483 21.0637 57.2816 20.4128 55.9373 20.4128C54.8525 20.4128 54.1213 20.9242 53.7204 21.9702L51.4801 21.5053C51.9754 19.5296 53.6969 18.2744 55.9607 18.2744C59.121 18.2744 60.701 19.8086 60.701 22.8999V30.1751H58.6257L58.39 28.641C57.5645 29.7798 56.3382 30.361 54.711 30.361C52.6122 30.361 51.0557 29.1291 51.0557 26.8048C51.0557 24.4108 53.1075 22.9697 56.1966 22.9697ZM55.1356 28.2226C55.961 28.2226 56.6922 27.9669 57.3053 27.4556C57.9421 26.9209 58.2485 26.2004 58.2485 25.2474V24.9917H56.1025C54.5931 24.9917 53.65 25.689 53.65 26.8046C53.65 27.6412 54.1687 28.2224 55.1356 28.2224V28.2226Z"
      fill="#FF2B85"
    />
  </svg>
);
