/* eslint-disable max-len */
import * as React from 'react';

import { LogoProps } from '../LogoProps';

export const FoodpandaSquaredPartnerLogo: React.FC<LogoProps> = ({
  width = 64,
}) => (
  <svg
    data-testid="platform-logo-svg"
    width={width}
    height={width}
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="64" height="64" fill="#FF2B85" />
    <path
      d="M7.99994 45.6002L10.3056 32.4002H55.9999L53.711 45.6002H7.99994Z"
      fill="white"
    />
    <path
      d="M23.2095 38.6042C23.2259 38.5056 23.2361 38.4297 23.2403 38.3762C23.2443 38.323 23.2465 38.2716 23.2465 38.2223C23.2465 37.935 23.1542 37.7297 22.9694 37.6065C22.7847 37.4834 22.5115 37.4217 22.1503 37.4217C21.8628 37.4217 21.5651 37.4772 21.2572 37.5881C20.9493 37.6989 20.6763 37.8405 20.4381 38.0131L19.8838 37.04C20.2203 36.8019 20.6043 36.615 21.0355 36.4795C21.4665 36.3439 21.9079 36.2762 22.3595 36.2762C22.6386 36.2762 22.9157 36.3071 23.1909 36.3686C23.4659 36.4303 23.7123 36.5308 23.93 36.6703C24.1474 36.8101 24.322 36.9927 24.4534 37.2184C24.5847 37.4445 24.6505 37.7255 24.6505 38.0622C24.6505 38.1361 24.6462 38.2265 24.6381 38.3332C24.6298 38.4401 24.6175 38.5346 24.6011 38.6165L24.17 41.0183C24.1289 41.2483 24.094 41.4864 24.0654 41.7327C24.0366 41.9792 24.0223 42.1844 24.0223 42.3486H22.6921C22.6921 42.1268 22.7167 41.8928 22.766 41.6465H22.7414C22.2813 42.1968 21.6408 42.4717 20.8199 42.4717C20.6146 42.4717 20.4011 42.4471 20.1794 42.3978C19.9577 42.3486 19.7564 42.2645 19.5759 42.1454C19.3952 42.0264 19.2474 41.8663 19.1325 41.6649C19.0174 41.4638 18.9601 41.2113 18.9601 40.9075C18.9601 40.423 19.0894 40.031 19.3481 39.7311C19.6067 39.4314 19.9412 39.1974 20.352 39.029C20.7625 38.8609 21.2204 38.7479 21.7253 38.6904C22.2303 38.6331 22.7249 38.6042 23.2095 38.6042ZM22.9016 39.6387C22.6223 39.6387 22.3329 39.6491 22.0332 39.6695C21.7333 39.6901 21.4582 39.7372 21.2079 39.8112C20.9574 39.8851 20.7522 39.9898 20.5922 40.1253C20.4322 40.2608 20.352 40.4436 20.352 40.6734C20.352 40.8131 20.3785 40.9301 20.4321 41.0245C20.4853 41.119 20.5572 41.1951 20.6476 41.2523C20.7379 41.3099 20.8405 41.3509 20.9555 41.3755C21.0704 41.4001 21.1895 41.4125 21.3127 41.4125C21.5344 41.4125 21.7416 41.3755 21.9347 41.3016C22.1274 41.2277 22.2959 41.1273 22.4398 40.9999C22.5833 40.8727 22.7024 40.7249 22.7968 40.5565C22.8912 40.3882 22.9589 40.2054 23 40.0083L23.0739 39.6388H22.9016V39.6387Z"
      fill="#FF2B85"
    />
    <path
      d="M27.7214 37.4217C27.9266 37.0769 28.1688 36.7998 28.4481 36.5904C28.7271 36.381 29.0638 36.2762 29.458 36.2762C29.5153 36.2762 29.577 36.2784 29.6427 36.2825C29.7084 36.2866 29.7659 36.2969 29.8152 36.3133L29.6182 37.6559C29.5277 37.6312 29.4476 37.6149 29.378 37.6066C29.3081 37.5986 29.2403 37.5943 29.1748 37.5943C28.8381 37.5943 28.563 37.6559 28.3496 37.779C28.136 37.9022 27.9634 38.05 27.8323 38.2225C27.7007 38.3948 27.6062 38.5715 27.549 38.7521C27.4914 38.9329 27.4545 39.0766 27.4381 39.1832L26.8838 42.3487H25.4426L26.2679 37.7051C26.3005 37.5492 26.3356 37.3479 26.3726 37.1016C26.4096 36.8551 26.436 36.6336 26.4526 36.4365H27.8075C27.8075 36.4777 27.8033 36.5392 27.7952 36.6214C27.7869 36.7035 27.7766 36.7937 27.7643 36.8923C27.7519 36.9909 27.7397 37.0875 27.7274 37.1817C27.7149 37.2763 27.7047 37.3563 27.6965 37.422H27.7212L27.7214 37.4217Z"
      fill="#FF2B85"
    />
    <path
      d="M29.976 37.6185L30.1853 36.4359H31.2077L31.5033 34.7115H32.932L32.6364 36.4359H33.9667L33.7573 37.6185H32.4517L31.9344 40.3836C31.9097 40.5059 31.8974 40.6203 31.8974 40.7262C31.8974 40.9382 31.9608 41.0851 32.0883 41.1668C32.2155 41.2484 32.3858 41.2889 32.5995 41.2889C32.6979 41.2889 32.7944 41.2819 32.8889 41.2679C32.9832 41.2538 33.0715 41.2361 33.1538 41.2151L33.0674 42.3113C32.9444 42.3604 32.7985 42.3993 32.6302 42.4284C32.4618 42.4569 32.3079 42.4714 32.1684 42.4714C31.5443 42.4714 31.1009 42.3464 30.8381 42.0966C30.5753 41.8468 30.444 41.5171 30.444 41.1076C30.444 40.9929 30.4502 40.8679 30.4624 40.7328C30.4749 40.5977 30.4933 40.4728 30.5179 40.3581L31.0229 37.6184H29.976V37.6185Z"
      fill="#FF2B85"
    />
    <path
      d="M36.2098 37.3601C36.415 37.0399 36.6757 36.7793 36.9919 36.578C37.3079 36.3768 37.6877 36.2762 38.1312 36.2762C38.4595 36.2762 38.745 36.3255 38.9873 36.424C39.2293 36.5226 39.4307 36.654 39.5908 36.8182C39.7509 36.9825 39.8698 37.1775 39.948 37.4033C40.0258 37.6293 40.0649 37.8651 40.0649 38.1116C40.0649 38.3169 40.0484 38.5017 40.0158 38.6659L39.363 42.3487H37.9218L38.4883 39.097C38.5047 38.9986 38.5192 38.9 38.5314 38.8014C38.5438 38.7028 38.5498 38.6044 38.5498 38.5058C38.5498 38.243 38.48 38.011 38.3404 37.8099C38.2008 37.6086 37.9463 37.5082 37.5768 37.5082C37.3386 37.5082 37.1292 37.5534 36.9486 37.6437C36.7679 37.7341 36.6099 37.8552 36.4743 38.007C36.3388 38.159 36.228 38.3294 36.1419 38.5181C36.0556 38.7071 35.9959 38.8999 35.9632 39.097L35.3843 42.3487H33.9432L34.7562 37.7051C34.7888 37.5492 34.8238 37.3479 34.8609 37.1016C34.8979 36.8551 34.9243 36.6336 34.9409 36.4365H36.2958C36.2875 36.527 36.2712 36.6686 36.2465 36.8615C36.2219 37.0545 36.2013 37.2208 36.1849 37.3604H36.2096L36.2098 37.3601Z"
      fill="#FF2B85"
    />
    <path
      d="M42.3631 39.8481C42.3549 39.8893 42.3508 39.9343 42.3508 39.9836C42.3508 40.1806 42.3897 40.3613 42.4679 40.5255C42.5457 40.6899 42.6546 40.8315 42.7942 40.9505C42.9337 41.0696 43.0979 41.1641 43.2868 41.2338C43.4756 41.3036 43.6811 41.3384 43.9027 41.3384C44.2802 41.3384 44.6027 41.2688 44.8696 41.129C45.1363 40.9895 45.3764 40.8008 45.5901 40.5626L46.4152 41.3384C45.6843 42.1268 44.7934 42.521 43.7424 42.521C43.34 42.521 42.9705 42.4613 42.6338 42.3423C42.297 42.2235 42.0056 42.0529 41.7594 41.8312C41.513 41.6095 41.32 41.3366 41.1805 41.0121C41.0407 40.6878 40.9711 40.3162 40.9711 39.8975C40.9711 39.3884 41.0573 38.9141 41.2297 38.4749C41.402 38.0357 41.6423 37.6518 41.9502 37.3232C42.2581 36.9949 42.6233 36.7382 43.0464 36.5533C43.4692 36.3686 43.9311 36.2762 44.432 36.2762C44.7851 36.2762 45.1115 36.3255 45.4112 36.4241C45.7109 36.5226 45.9717 36.6704 46.1934 36.8675C46.4151 37.0645 46.5875 37.3109 46.7107 37.6065C46.8338 37.9022 46.8955 38.247 46.8955 38.6411C46.8955 38.7727 46.883 38.9551 46.8584 39.1892C46.8337 39.4232 46.7968 39.643 46.7475 39.8482H42.3627L42.3631 39.8481ZM45.5656 38.8136C45.5736 38.7562 45.5798 38.7026 45.584 38.6533C45.5881 38.6042 45.5902 38.5549 45.5902 38.5056C45.5902 38.1853 45.4772 37.9144 45.2514 37.6927C45.0255 37.471 44.7073 37.3601 44.2969 37.3601C44.0423 37.3601 43.8124 37.4053 43.6072 37.4956C43.4017 37.5862 43.2232 37.7031 43.0714 37.8467C42.9194 37.9905 42.7944 38.1484 42.6957 38.321C42.5972 38.4933 42.5313 38.6577 42.4986 38.8137H45.5656V38.8136Z"
      fill="#FF2B85"
    />
    <path
      d="M49.882 37.4217C50.0872 37.0769 50.3293 36.7998 50.6087 36.5904C50.8877 36.381 51.2244 36.2762 51.6186 36.2762C51.6759 36.2762 51.7376 36.2784 51.8033 36.2825C51.869 36.2866 51.9265 36.2969 51.9758 36.3133L51.7788 37.6559C51.6883 37.6312 51.6082 37.6149 51.5386 37.6066C51.4687 37.5986 51.4009 37.5943 51.3354 37.5943C50.9987 37.5943 50.7236 37.6559 50.5102 37.779C50.2966 37.9022 50.124 38.05 49.9928 38.2225C49.8613 38.3948 49.7668 38.5715 49.7095 38.7521C49.652 38.9329 49.6151 39.0766 49.5987 39.1832L49.0444 42.3487H47.6032L48.4285 37.7051C48.4611 37.5492 48.4961 37.3479 48.5332 37.1016C48.5702 36.8551 48.5966 36.6336 48.6132 36.4365H49.9681C49.9681 36.4777 49.9639 36.5392 49.9558 36.6214C49.9475 36.7035 49.9372 36.7937 49.9249 36.8923C49.9125 36.9909 49.9003 37.0875 49.888 37.1817C49.8755 37.2763 49.8652 37.3563 49.8571 37.422H49.8817L49.882 37.4217Z"
      fill="#FF2B85"
    />
    <path
      d="M12.5247 44.5427L13.0517 41.4008H13.0763C13.2487 41.7492 13.5013 42.0231 13.8339 42.2223C14.1664 42.4213 14.6037 42.521 15.1457 42.521C15.6217 42.521 16.0591 42.4243 16.4574 42.2315C16.8556 42.0387 17.1983 41.7758 17.4859 41.4432C17.7733 41.1107 17.997 40.7227 18.1573 40.2793C18.3174 39.8359 18.3974 39.3598 18.3974 38.8505C18.3974 38.473 18.3377 38.128 18.2188 37.8159C18.0997 37.504 17.9314 37.2352 17.7138 37.0092C17.4962 36.7834 17.2334 36.6088 16.9254 36.4857C16.6176 36.3626 16.2747 36.3009 15.8969 36.3009C15.4535 36.3009 15.0471 36.3954 14.6776 36.5842C14.3081 36.7731 14.0041 37.04 13.7662 37.3848H13.7291L13.8769 36.4363H12.5097L11.0779 44.5427H12.5247ZM13.5567 38.9136C13.6633 38.6313 13.8091 38.3838 13.9939 38.1709C14.1786 37.9581 14.3982 37.7902 14.6529 37.6675C14.9072 37.5447 15.1783 37.4833 15.4658 37.4833C15.9339 37.4833 16.293 37.6307 16.5436 37.9253C16.794 38.22 16.9193 38.5843 16.9193 39.0181C16.9193 39.3374 16.872 39.6382 16.7776 39.9206C16.683 40.203 16.5476 40.4486 16.3712 40.6572C16.1944 40.8659 15.9811 41.0316 15.7308 41.1543C15.4802 41.2771 15.2031 41.3385 14.8993 41.3385C14.4393 41.3385 14.0742 41.1953 13.8031 40.9087C13.5321 40.6223 13.3968 40.254 13.3968 39.8036C13.3968 39.4926 13.45 39.1961 13.5569 38.9136H13.5567Z"
      fill="#FF2B85"
    />
    <path
      d="M22.6142 20.8634H23.8543L23.9783 21.4067L24.0609 21.4746L24.1298 21.4067C24.5432 20.9992 25.2321 20.7277 25.921 20.7277C27.7123 20.7277 29.228 22.0856 29.228 24.0549C29.228 26.0242 27.7123 27.3822 25.921 27.3822C25.2459 27.3822 24.5432 27.1106 24.1298 26.7032L24.0609 26.6353L23.9783 26.7032V29.968H22.6142V20.8634ZM25.8523 26.1598C27.1612 26.1598 27.8501 25.2092 27.8501 24.0548C27.8501 22.9005 27.1612 21.9498 25.8523 21.9498C24.681 21.9498 23.8543 22.9005 23.8543 24.0548C23.8543 25.2092 24.681 26.1598 25.8523 26.1598Z"
      fill="white"
    />
    <path
      d="M32.6988 23.2831H33.8272V23.0019C33.8272 22.2349 33.2955 21.8768 32.5562 21.8768C31.9596 21.8768 31.5574 22.1581 31.3369 22.7334L30.1047 22.4777C30.3771 21.3911 31.324 20.7007 32.5691 20.7007C34.3072 20.7007 35.1762 21.5445 35.1762 23.2448V27.2461H34.0348L33.9052 26.4023C33.4511 27.0287 32.7767 27.3484 31.8817 27.3484C30.7274 27.3484 29.8713 26.6708 29.8713 25.3925C29.8713 24.0757 30.9998 23.2831 32.6988 23.2831ZM32.1153 26.1723C32.5692 26.1723 32.9713 26.0316 33.3086 25.7504C33.6588 25.4563 33.8273 25.06 33.8273 24.5359V24.3953H32.647C31.8169 24.3953 31.2981 24.7788 31.2981 25.3923C31.2981 25.8525 31.5835 26.1721 32.1153 26.1721V26.1723Z"
      fill="white"
    />
    <path
      d="M36.4478 20.8634H37.6878L37.8256 21.4067L37.8945 21.4746L37.9633 21.4067C38.239 20.9992 38.9279 20.7277 39.4791 20.7277C41.1325 20.7277 42.0971 21.8141 42.0971 23.308V27.2464H40.7191V23.3079C40.6778 22.4388 40.2506 21.9498 39.3413 21.9498C38.5283 21.9498 37.8532 22.6016 37.8256 23.3893V27.2463H36.4478V20.8634Z"
      fill="white"
    />
    <path
      d="M49.5993 27.2464H48.3592L48.2214 26.7031L48.1525 26.6352L48.0837 26.7031C47.6703 27.1106 46.9813 27.3821 46.2924 27.3821C44.5012 27.3821 42.9855 26.0241 42.9855 24.0548C42.9855 22.0856 44.5012 20.7276 46.2924 20.7276C46.9676 20.7276 47.6703 20.9991 48.0837 21.4066L48.1525 21.4745L48.2214 21.4066V18H49.5993V27.2464ZM46.3612 21.9499C45.0522 21.9499 44.3494 22.9006 44.3494 24.055C44.3494 25.2093 45.0522 26.16 46.3612 26.16C47.5325 26.16 48.3592 25.2093 48.3592 24.055C48.3592 22.9006 47.5325 21.9499 46.3612 21.9499Z"
      fill="white"
    />
    <path
      d="M53.5224 23.2831H54.6508V23.0019C54.6508 22.2349 54.1191 21.8768 53.3797 21.8768C52.7831 21.8768 52.3809 22.1581 52.1604 22.7334L50.9282 22.4777C51.2007 21.3911 52.1475 20.7007 53.3926 20.7007C55.1308 20.7007 55.9998 21.5445 55.9998 23.2448V27.2461H54.8583L54.7287 26.4023C54.2747 27.0287 53.6002 27.3484 52.7053 27.3484C51.5509 27.3484 50.6948 26.6708 50.6948 25.3925C50.6948 24.0757 51.8233 23.2831 53.5224 23.2831ZM52.9388 26.1723C53.3927 26.1723 53.7949 26.0316 54.1321 25.7504C54.4824 25.4563 54.6509 25.06 54.6509 24.5359V24.3953H53.4706C52.6404 24.3953 52.1217 24.7788 52.1217 25.3923C52.1217 25.8525 52.407 26.1721 52.9388 26.1721V26.1723Z"
      fill="white"
    />
  </svg>
);
