import { PasswordFormErrors } from './PasswordContainer';

export type GetPasswordErrorsType = {
  password: string;
  passwordCurrent?: string;
  isForceReset: boolean;
  passwordRepeat: string;
  shouldNewPasswordStrengthRequirementsBeApplied?: boolean;
  shouldShowFieldErrors?: boolean;
};

export const getPasswordErrors = ({
  password,
  passwordCurrent,
  isForceReset,
  passwordRepeat,
  shouldNewPasswordStrengthRequirementsBeApplied = false,
  shouldShowFieldErrors = true,
}: GetPasswordErrorsType) => {
  const errors: PasswordFormErrors = {
    fields: {},
    rules: {
      isTooShort: false,
      hasMissingUppercaseLetter: false,
      hasMissingDigit: false,
      hasMissingSpecialCharacter: false,
      hasWhitespaceCharacters: false,
    },
  };
  const isTooShort = password.length < 8;
  const hasUpperCaseLetter = password !== password.toLowerCase();
  const hasNumber = /\d/.test(password);
  const hasSpecialCharacter = /[!"#$%&'()*+,-./:;<=>?@[\]\^_`{|}~]/.test(
    password,
  );
  const hasWhiteSpaceCharacter = /\s/.test(password);

  if (isForceReset && !passwordCurrent?.length) {
    errors.fields.passwordCurrent =
      'global.password_reset.errors.current_password_empty';
  }

  if (isTooShort) {
    errors.rules.isTooShort = true;
  }

  if (!hasUpperCaseLetter) {
    errors.rules.hasMissingUppercaseLetter = true;
  }

  if (!hasNumber) {
    errors.rules.hasMissingDigit = true;
  }

  if (!hasSpecialCharacter) {
    errors.rules.hasMissingSpecialCharacter = true;
  }

  if (hasWhiteSpaceCharacter) {
    errors.rules.hasWhitespaceCharacters = true;
  }

  // Field errors should show only with submit event
  if (shouldShowFieldErrors) {
    if (shouldNewPasswordStrengthRequirementsBeApplied) {
      if (
        isTooShort ||
        !hasUpperCaseLetter ||
        !hasNumber ||
        !hasSpecialCharacter ||
        hasWhiteSpaceCharacter
      ) {
        errors.fields.password =
          'global.auth.set_password.errors.requirements_not_met';
      }
    } else {
      if (isTooShort) {
        errors.fields.password =
          'global.auth.password_strength.min_length_8_chars';
      }
    }

    if (password !== passwordRepeat) {
      errors.fields.passwordRepeat =
        'global.auth.set_password.errors.repeat_password_does_not_match';
    }
  }
  return errors;
};
