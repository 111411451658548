import i18next from 'i18next';

const TEST_RTL_LANGUAGE = 'ttt';

/**
 * @todo: uncomment the real implementation (below) after testing with TEST_RTL_LANGUAGE is completed
 */

// export const isLTR = () => i18next.dir() === 'ltr';
// export const isRTL = () => i18next.dir() === 'rtl';

export const isRTL = () => i18next.language === TEST_RTL_LANGUAGE;
export const isLTR = () => i18next.language !== TEST_RTL_LANGUAGE;
