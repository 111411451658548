import * as React from 'react';

export enum RenderContext {
  DESKTOP,
  MOBILE_PREVIEW,
  MOBILE_EDIT,
}

export type RenderContextComponentProps = {
  name: string;
  renderContext?: RenderContext;
  onChange?: (value: any) => void;
  setValidity?: (value: boolean) => void;
};

export type BaseFilterProps<V> = RenderContextComponentProps & {
  value: V;
  onChange?: (value: V) => void;
};

export abstract class BaseFilter<
  P extends BaseFilterProps<any>,
  S = {},
> extends React.Component<P, S> {
  render() {
    switch (this.props.renderContext) {
      case RenderContext.DESKTOP:
        return <>{this.renderDesktop()}</>;
      case RenderContext.MOBILE_PREVIEW:
        return <>{this.renderMobilePreview()}</>;
      case RenderContext.MOBILE_EDIT:
        return <>{this.renderMobileEdit()}</>;
      default:
        return <div>UNKNOWN RENDER CONTEXT</div>;
    }
  }

  abstract renderDesktop(): React.ReactNode;
  abstract renderMobilePreview(): React.ReactNode;
  abstract renderMobileEdit(): React.ReactNode;
}
